<template>
  <div class="container-fluid" >

    <div v-if="error" class="alert alert-danger">{{ error }}</div>

    <div v-else class="invoice-container" style="overflow-y: auto; max-height: 90vh;">

      <table>
        <tr>
          <td style="width: 100%;font-weight: bold; font-size: 2em;">TAX INVOICE</td>
          <td style="width: 128px;">
            <picture>
              <source srcset="/assets/img/logo_128.png 1x, /assets/img/logo_256.png 2x, /asset/img/logo_512.png 4x" type="image/png">
              <img alt="Cyruspay Logo" style="width: 128px"
                   src='/assets/img/logo_128.png'
                   loading="lazy"/>
            </picture>
          </td>
        </tr>
        <tr>
          <td></td>
        </tr>
      </table>
      <table>
        <tr>
          <td style="width: 150px; vertical-align: top;" class="label">To</td>
          <td style="width: auto; vertical-align: top;" v-html="invoiceDetail.summary.customerDetailsHtml"></td>
          <td style="width: 150px; vertical-align: top;" class="label">From</td>
          <td style="width: auto; vertical-align: top;" v-html="cyruspayCompanyDetail"></td>
        </tr>
        <tr>
          <td class="label">Invoice Number</td>
          <td>{{ invoiceDetail.id }}</td>
        </tr>
        <tr>
          <td class="label">Issued</td>
          <td>{{ invoiceDateLocal }}</td>
        </tr>
        <tr>
          <td class="label">Period</td>
          <td>{{ period }}</td>
        </tr>
      </table>
      <hr />
      <table style="font-weight: bold;margin-top: 20px;">
        <tr>
          <td class="description-col">Description</td>
          <td class="sub-total-col">Amount (AUD)</td>
        </tr>

      </table>
      <hr />
      <table>
        <tr v-for="line in invoiceDetail.lines" :key="line.description">
          <td class="description-col">{{ line.description }}</td>
          <td class="sub-total-col">{{ formatCurrency(line.subTotalAmount) }}</td>
        </tr>
      </table>
      <hr />
      <table>
        <tr>
          <td style="width: auto;"></td>
          <td style="width: 100px;">GST</td>
          <td style="width: 100px; text-align: right;">{{ formatCurrency(invoiceDetail.summary.totalTax) }}</td>
        </tr>
        <tr>
          <td></td>
          <td style="font-weight: bold;">TOTAL</td>
          <td style="font-weight: bold; text-align: right;">{{ formatCurrency(invoiceDetail.summary.totalAmount) }}</td>
        </tr>
        <tr>
          <td></td>
          <td style="font-weight: bold;">PAID</td>
          <td style="font-weight: bold; text-align: right;">{{ formatCurrency(invoiceDetail.summary.paidAmount) }}</td>
        </tr>
        <tr>
          <td></td>
          <td style="font-weight: bold;">OUTSTANDING</td>
          <td style="font-weight: bold; text-align: right;">{{ formatCurrency(invoiceDetail.summary.toBePaidAmount) }}</td>
        </tr>
      </table>

      <div style="text-align: start" v-if="this.invoiceDetail.summary.toBePaidAmount > 0">
        <h4 class="mt-3">Please enter your card details below to pay</h4>

        <stripe-element-card
            ref="elementRef"
            :pk="publishableKey"
            :testMode="isDevelopment"
            locale="en-AU"
            hide-postal-code
            @token="tokenCreated"
        />
        <button class="btn btn-primary mt-3" @click="pay" :disabled="busy">
          Pay
          <i class="fa fa-spin fa-spinner" v-if="busy"></i>
        </button>
      </div>

    </div>

  </div>
</template>

<script>
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import * as Utils from "@/utils/Utils";
import axios from "axios"
import log4javascript from 'log4javascript';

export default {
  name: 'PayInvoice',
  components: {
    StripeElementCard,
  },
  props: {
    id: String
  },
  data() {
    return {
      error: '',
      cardError: '', // error related to processing of the card
      saveCard: true,
      busy: false,
      invoiceDetail: {
        id: 1,
        summary: {
          customerDetailsHtml: 'Customer Name<br>1 main rd',
          invoiceDateUtc: '2000-01-01T00:00:00Z',
          periodStart: '2000-01-01T00:00:00Z',
          periodEnd: '2000-01-01T00:00:00Z',
          totalAmount: 0,
          paidAmount: 0,
          toBePaidAmount: 0,
          totalTax: 0,
          status: ''
        },
        lines: [{
          description: 'Example 1',
          subTotalAmount: 0.00,
        }, {
          description: 'Example 2',
          subTotalAmount: 0.00,
        }]
      },
    };
  },
  mounted() {
    document.title = 'Pay Invoice | Cyruspay';
    document.body.style.backgroundColor = '#f9f9f9';
    this.fetchInvoiceDetails();
  },
  beforeDestroy() {
    document.body.style.backgroundColor = '';
  },
  created: function() {
        this.$logger = log4javascript.getLogger("PayInvoice");
    },
  methods: {
    async fetchInvoiceDetails() {
      try {
        const url = `/api/invoices/${this.$route.params.id}`;
        const result = await axios.get(url);
        this.invoiceDetail = result.data;
      }
      catch(err) {
        this.$logger.error(`submitForm: ${JSON.stringify(err)}`)
        this.error = err;
      }

    },
    formatCurrency(value) {
      return Utils.formatCurrency(value);
    },
    async tokenCreated(token) {
      try {
        this.busy = true;
        // send token and invoice id to server
        const data = { token: token.id, invoiceId: this.$route.params.id, saveCard: this.saveCard };
        const result = await axios.post('/api/invoices/pay', data, {
          headers: {
            "Content-Type": "application/json"
          }
        });
        
        this.invoiceDetail = result.data;
        Utils.toastSuccess("Payment successful");

        await this.$router.push({path: '/dashboard'});
      }
      catch(err){
        this.$logger.error(`submitForm: ${JSON.stringify(err)}`)
        this.cardError = err;
      }
      finally {
        this.busy = false;
      }
    },
    pay () {
      // this will trigger the process
      this.busy = true;
      this.$refs.elementRef.submit();
    },
  },
  computed: {
    publishableKey() {
      return process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    },
    isDevelopment() {
      return process.env.NODE_ENV === 'development';
    },
    cyruspayCompanyDetail() {
      return process.env.CYRUSPAY_INVOICE_COMPANY_DETAIL;
    },
    invoiceDateLocal() {
      return Utils.formatDateToString(this.invoiceDetail.summary.invoiceDateUtc);
    },
    period() {
      const start = Utils.formatDateToString(this.invoiceDetail.summary.periodStart);
      const end = Utils.formatDateToString(this.invoiceDetail.summary.periodEnd);
      return `${start} to ${end}`;
    },
  }
};
</script>


<style scoped>
table {
  max-width: 800px;
  collapse: collapse;
  width: 100%;
}
hr {
  max-width: 800px;
  display: block;
  text-align: left;
  margin-left: 0;
  border: 1px solid lightgray;
}
.qty-col, .unit-price-col, .sub-total-col {
  text-align: right;
  width: 150px;
}
.invoice-container {
  max-width: 800px;
  margin: 10px auto;
  padding: 20px;
  background-color: white;
  border: 1px solid lightgrey;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.container-fluid {
  background-color: #f9f9f9;
}

.label {
  font-weight: bold;
}

</style>