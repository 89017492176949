import Vue from "vue"
import axios from "axios"
import VueAxios from "vue-axios"
import Token from './Token'
import Bowser from 'bowser'
import * as AES from "@/utils/Encodepassword"
import * as Session from "@/utils/SessionStore"
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import log4javascript from 'log4javascript';

const logger = log4javascript.getLogger('ApiManager');

Vue.use(VueToast);

const browser = Bowser.getParser(window.navigator.userAgent)
var isShowingHandshake = false;
var isCheckServer = false; // this flag is use to check re-entrance to checkServerIsUpdating function
var CODE_HANDSHAKE = 503;
var CODE_AUTHEN = 401;
var CODE_CONNECT = 500;
var CODE_UPDATE = 502;
var md5 = require('md5');
var arrCallApi = [];
var isUpdateKey = false;// not show toast
var isGetHandShake = false
var getHandShakeSussecc = false
var count = 0

const ApiManager = {
    duration: 5000,

    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = this.getUrlApi();
        Vue.axios.defaults.headers = [];
    },

    /** returns the domain of the server serving UI */
    ipServerUI() {
        var domain = process.env.VUE_APP_IP_SERVER_UI;
        return domain
    },

    /** returns the url of the api */
    getUrlApi() {
        var url = process.env.VUE_APP_API_URL;
        return url;
    },

    /** return the url of the server serving the UI  */
    getUrlDomain() {
        var url = process.env.VUE_APP_DOMAIN_URL;
        return url;
    },

    /**
     * Returns the device ID based on the browser information.
     * @returns {string} The device ID.
     */
    getDeviceId() {
        return browser.getBrowserName() + '.' + browser.getBrowserVersion() + '.' + browser.getUA().replace(/\D+/g, '')
    },
    
    
    /**
     * Returns the domain URL of the current UI.
     * @returns {string} The domain URL of the current UI.
     */
    getUrlDomainUI() {
        var url = window.location.protocol + '//' + window.location.hostname;
        if (window.location.port != null && window.location.port != "") {
            url = url + ':' + window.location.port;
        }
        return url;
    },

    /**
     * Sends a GET request to the specified URL.
     * @param {string} url - The URL to send the GET request to.
     * @returns {Promise} A Promise that resolves with the decrypted response data if the request is successful, or rejects with an error if the request fails.
     */
    get(url) {
        return new Promise((success, reject) => {

            var json = AES.encrypt({
                url: url,
                path: window.location.pathname
            })
            

            if (!Token.getCodeHandShake()) {
                reject("Handshake error");
            }

            if (arrCallApi.indexOf(json) >= 0) {
                reject("Duplicate request");
            }
    
            arrCallApi.push(json);

            Vue.axios.get(`${url}`, {
                headers: {
                    'Authorization': "Bearer " + Token.getToken(),
                    'handshake': 'Basic ' + Token.getCodeHandShake(),
                    'Content-Type': 'application/json; charset=utf-8'
                }
            }).then((response) => {
                arrCallApi.splice(arrCallApi.indexOf(json), 1)
                success(this.decryptResponse(response, url));
            }).catch((error) => {
                arrCallApi.splice(arrCallApi.indexOf(json), 1)

                const decrypted = this.decryptResponse(error.response, url);

                const logData = JSON.stringify({error, decrypted});
                logger.debug(`Error in get ${logData}`);

                this.errorHandling(error, url, false).then(() => {
                    Vue.axios.get(`${url}`, {
                        headers: {
                            'Authorization': "Bearer " + Token.getToken(),
                            'handshake': 'Basic ' + Token.getCodeHandShake(),
                            'Content-Type': 'application/json; charset=utf-8'
                        }
                    }).then((response) => {
                        success(this.decryptResponse(response, url));
                    }).catch((error) => {
                        this.errorHandling(error, url, true).then(() => {
                            if(window.location.href != "/updateserver") {
                                this.showPopupHandshake(error)
                            }
                        }).catch((error) => {
                            reject(error)
                        });
                    })
                }).catch((error) => {
                    reject(error)
                });
            });
        });
    },

    post(url, paramsBody) {
        return new Promise((success, reject) => {

            var json = AES.encrypt({
                url: url,
                path: window.location.pathname
            })
    
            if (!Token.getCodeHandShake()) {
                reject("Handshake error");
            }

            if (arrCallApi.indexOf(json) >= 0) {
                reject("Duplicate request");
            }
    
            arrCallApi.push(json);

            Vue.axios.post(`${url}`, paramsBody, {
                headers: {
                    'Authorization': "Bearer " + Token.getToken(),
                    'handshake': 'Basic ' + Token.getCodeHandShake(),
                    'Content-Type': 'application/json; charset=utf-8'
                }
            }).then((response) => {
                arrCallApi.splice(arrCallApi.indexOf(json), 1)
                success(this.decryptResponse(response, url));
            }).catch((error) => {
                arrCallApi.splice(arrCallApi.indexOf(json), 1)
                
                this.errorHandling(error, url, false).then(() => {
                    Vue.axios.post(`${url}`, paramsBody, {
                        headers: {
                            'Authorization': "Bearer " + Token.getToken(),
                            'handshake': 'Basic ' + Token.getCodeHandShake(),
                            'Content-Type': 'application/json; charset=utf-8'
                        }
                    }).then((response) => {
                        success(this.decryptResponse(response, url));
                    }).catch((error) => {
                        this.errorHandling(error, url, true).then(() => {
                            if(window.location.href != "/updateserver") {
                                this.showPopupHandshake(error)
                            }
                        }).catch((error) => {
                            reject(error)
                        });
                    })
                }).catch((error) => {
                    reject(error)
                });
                
            });
        });
    },

    put(url, paramsBody) {
        return new Promise((success, reject) => {

            var json = AES.encrypt({
                url: url,
                path: window.location.pathname
            })

            if (!Token.getCodeHandShake()) {
                reject("Handshake error");
            }

            if (arrCallApi.indexOf(json) >= 0) {
                reject("Duplicate request");
            }
            
            arrCallApi.push(json)

            Vue.axios.put(`${url}`, paramsBody, {
                headers: {
                    'Authorization': "Bearer " + Token.getToken(),
                    'handshake': 'Basic ' + Token.getCodeHandShake(),
                    'Content-Type': 'application/json; charset=utf-8'
                }
            }).then((response) => {
                arrCallApi.splice(arrCallApi.indexOf(json), 1)
                success(this.decryptResponse(response, url));
            }).catch((error) => {
                arrCallApi.splice(arrCallApi.indexOf(json), 1)
                this.errorHandling(error, url, false).then(() => {
                    Vue.axios.put(`${url}`, paramsBody, {
                        headers: {
                            'Authorization': "Bearer " + Token.getToken(),
                            'handshake': 'Basic ' + Token.getCodeHandShake(),
                            'Content-Type': 'application/json; charset=utf-8'
                        }
                    }).then((response) => {
                        success(this.decryptResponse(response, url));
                    }).catch((error) => {
                        this.errorHandling(error, url, true).then(() => {
                            if(window.location.href != "/updateserver") {
                                this.showPopupHandshake(error)
                            }
                        }).catch((error) => {
                            reject(error)
                        });
                    })
                }).catch((error) => {
                    reject(error)
                });
            });
        });
    },

    delete(url) {
        return new Promise((success, reject) => {

            var json = AES.encrypt({
                url: url,
                path: window.location.pathname
            })

            if (!Token.getCodeHandShake()) {
                reject("Handshake error");
            }

            if (arrCallApi.indexOf(json) >= 0) {
                reject("Duplicate request");
            }

            arrCallApi.push(json)

            Vue.axios.delete(`${url}`, {
                headers: {
                    'Authorization': "Bearer " + Token.getToken(),
                    'handshake': 'Basic ' + Token.getCodeHandShake(),
                    'Content-Type': 'application/json; charset=utf-8'
                }
            }).then((response) => {
                arrCallApi.splice(arrCallApi.indexOf(json), 1)
                success(this.decryptResponse(response, url));
            }).catch((error) => {
                arrCallApi.splice(arrCallApi.indexOf(json), 1)
                this.errorHandling(error, url, false).then(() => {
                    Vue.axios.delete(`${url}`, {
                        headers: {
                            'Authorization': "Bearer " + Token.getToken(),
                            'handshake': 'Basic ' + Token.getCodeHandShake(),
                            'Content-Type': 'application/json; charset=utf-8'
                        }
                    }).then((response) => {
                        success(this.decryptResponse(response, url));
                    }).catch((error) => {
                        this.errorHandling(error, url, true).then(() => {
                            if(window.location.href != "/updateserver") {
                                this.showPopupHandshake(error)
                            }
                        }).catch((error) => {
                            reject(error)
                        });
                    })
                }).catch((error) => {
                    reject(error)
                });
            });
        });
    },

    sendFile(url, paramsBody) {
        return new Promise((success, reject) => {

            if (!Token.getCodeHandShake()) {
                reject("Handshake error");
            }

            Vue.axios.post(`${url}`, paramsBody, {
                headers: {
                    'Authorization': "Bearer " + Token.getToken(),
                    'handshake': 'Basic ' + Token.getCodeHandShake(),
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                success(response.data);
            }).catch((error) => {
                var errorData;
                if (error.response != undefined && error.response.data != undefined && error.response.data != "") {
                    errorData = error.response.data;
                } else {
                    errorData = error;
                }
                if (!isUpdateKey) {
                    const errorText = this.getErrorText(errorData);
                    this.showErrorToast(errorText);
                }
                reject(errorData);
            });
        });
    },

    /**
     * Downloads a file from the specified URL.
     * 
     * @param {string} url - The URL of the file to download.
     * @returns {Promise<Blob>} A promise that resolves with the downloaded file as a Blob object.
     */
    downloadFileABA(url) {
        return new Promise((success, reject) => {

            var json = AES.encrypt({
                url: url,
                path: window.location.pathname
            })
            
            if (!Token.getCodeHandShake()) {
                reject("Handshake error");
            }

            if (arrCallApi.indexOf(json) >= 0) {
                reject("Duplicate request");
            }

            arrCallApi.push(json)

            Vue.axios.get(`${url}`, {
                headers: { 
                    'Authorization': "Bearer " + Token.getToken(),
                    'handshake': 'Basic ' + Token.getCodeHandShake(),
                    'Content-Type': 'application/json; charset=utf-8'
                },
                responseType: 'blob'
            }).then((response) => {
                arrCallApi.splice(arrCallApi.indexOf(json), 1)
                let blobFile = new Blob([response.data], { type: response.headers['content-type'] });
                success(blobFile)
            }).catch((error) => {
                arrCallApi.splice(arrCallApi.indexOf(json), 1)
                var errorData;
                if (error.response != undefined && error.response.data != undefined && error.response.data != "") {
                    errorData = error.response.data;
                }else {
                    errorData = error;
                }
                if(!isUpdateKey) {
                    const errorText = this.getErrorText(errorData);
                    this.showErrorToast(errorText);
                }
                reject(errorData);
            });
        });
    },

    /**
     * Sends a POST request with JSON data to the specified URL.
     * @param {string} url - The URL to send the request to.
     * @param {Object} postBody - The JSON data to send in the request body.
     * @returns {Promise<any>} - A promise that resolves to the response data.
     * @throws {string} - Throws an error if there is a handshake error or duplicate request.
     */
    async postJson(url, postBody) {

        const json = AES.encrypt({
            url: url,
            path: window.location.pathname
        })
        
        if (!Token.getCodeHandShake()) {
            throw new Error("Handshake error");
        }

        if (arrCallApi.indexOf(json) >= 0) {
            throw new Error("Duplicate request");
        }

        arrCallApi.push(json)

        try {
            const httpOptions = {
                headers: { 
                    'Authorization': "Bearer " + Token.getToken(),
                    'handshake': 'Basic ' + Token.getCodeHandShake(),
                    'Content-Type': 'application/json; charset=utf-8'
                }
            };

            const response = await Vue.axios.post(url, postBody, httpOptions);

            const encrypted = response.headers['ENCRYPTED'] === "true"; // this header indicates if the response body is encrypted
            const decryptedData = encrypted ? AES.decrypt(response.data, url) : response.data;

            const responseContentType = response.headers['content-type'];


            if (response.status === 200) {
                if (responseContentType && responseContentType.includes('application/json')){
                    return JSON.parse(decryptedData);
                }
                return decryptedData;
            }

            if (response.status === 204)
                return; // no data


            //
            // handle cases of errors
            //
            if (responseContentType) {
                if (responseContentType.includes('application/problem+json') || responseContentType.includes('application/json')){
                    const problem = JSON.parse(decryptedData);
                    throw problem;
                }
            }

            throw new Error(response.statusText);
        }
        finally {
            arrCallApi.splice(arrCallApi.indexOf(json), 1)
        }

    },

    /**
     * Sends a POST request with JSON data to the specified URL.
     * @param {string} url - The URL to send the request to.
     * @param {Object} postBody - The JSON data to send in the request body.
     * @returns {Promise<any>} - A promise that resolves to the response data.
     * @throws {string} - Throws an error if there is a handshake error or duplicate request.
     */
    async postFormData(url, postBody) {

        const json = AES.encrypt({
            url: url,
            path: window.location.pathname
        })
        
        if (!Token.getCodeHandShake()) {
            throw new Error("Handshake error");
        }

        if (arrCallApi.indexOf(json) >= 0) {
            throw new Error("Duplicate request");
        }

        arrCallApi.push(json)

        try {
            const httpOptions = {
                headers: { 
                    'Authorization': "Bearer " + Token.getToken(),
                    'handshake': 'Basic ' + Token.getCodeHandShake(),
                    'Content-Type': 'multipart/form-data'
                }
            };

            const response = await Vue.axios.post(url, postBody, httpOptions);

            const encrypted = response.headers['ENCRYPTED'] === "true"; // this header indicates if the response body is encrypted
            const decryptedData = encrypted ? AES.decrypt(response.data, url) : response.data;

            const responseContentType = response.headers['content-type'];


            if (response.status === 200) {
                if (responseContentType && responseContentType.includes('application/json')){
                    return JSON.parse(decryptedData);
                }
                return decryptedData;
            }

            if (response.status === 204)
                return; // no data


            //
            // handle cases of errors
            //
            if (responseContentType) {
                if (responseContentType.includes('application/problem+json') || responseContentType.includes('application/json')){
                    const problem = JSON.parse(decryptedData);
                    throw problem;
                }
            }

            throw new Error(response.statusText);
        }
        finally {
            arrCallApi.splice(arrCallApi.indexOf(json), 1)
        }

    },

    /**
     * Downloads a file in XML format from the specified URL.
     * 
     * @param {string} url - The URL of the file to download.
     * @returns {Promise<Blob>} A promise that resolves with the downloaded file as a Blob object.
     */
    downloadFileXML(url) {
        return new Promise((success, reject) => {

            var json = AES.encrypt({
                url: url,
                path: window.location.pathname
            })

            if (!Token.getCodeHandShake()) {
                reject("Handshake error");
            }

            if (arrCallApi.indexOf(json) >= 0) {
                reject("Duplicate request");
            }
            
            arrCallApi.push(json)

            Vue.axios.post(`${url}`, {}, {
                headers: {
                    'Authorization': "Bearer " + Token.getToken(),
                    'handshake': 'Basic ' + Token.getCodeHandShake(),
                    'Content-Type': 'application/json; charset=utf-8'
                },
                responseType: 'blob'
            }).then((response) => {
                arrCallApi.splice(arrCallApi.indexOf(json), 1)
                let blobFile = new Blob([response.data], { type: response.headers['content-type'] });
                success(blobFile)
            }).catch((error) => {
                arrCallApi.splice(arrCallApi.indexOf(json), 1)
                var errorData;
                if (error.response != undefined && error.response.data != undefined && error.response.data != "") {
                    errorData = error.response.data;
                } else {
                    errorData = error;
                }
                if (!isUpdateKey) {
                    const errorText = this.getErrorText(errorData);
                    this.showErrorToast(errorText);
                }
                reject(errorData);
            });
        });
    },

    /**
     * Decrypts the response data using AES encryption.
     * 
     * @param {Object} response - The response object containing the encrypted data.
     * @param {string} url - The URL used for encryption.
     * @returns {Object} - The decrypted response data.
     * @throws {string} - Throws an exception if there is an error decrypting the response.
     */
    decryptResponse(response, url) {
        try {
            const decryptedText = AES.decrypt(response.data, url);
            const decryptedObj = JSON.parse(decryptedText)
            return decryptedObj;
        } catch (ex) {
            logger.error(`decryptResponse failed: ${JSON.stringify(ex)}`);
            throw "Exception decrypt response";
        }
    },

    /**
     * Returns the error text based on the provided error object.
     * 
     * valid errorObject are:
     * @example
     * "error", or 
     * {detail: "error"}, or 
     * {errors: {field1: ["this field is required"], field2: ["another error"]}}
     * @returns {string} - The error text.
     */
    getErrorText(errorObject) {
        logger.debug(`getErrorText args ${JSON.stringify(errorObject)}`)

        const genericErrorText = "An error has occurred, please try again later.";

        if (!errorObject) return genericErrorText;

        if (typeof errorObject === 'string') return errorObject;

        if (errorObject?.errors) {
            const firstFieldErrors = Object.values(errorObject?.errors)[0];
            if (Array.isArray(firstFieldErrors)) return firstFieldErrors[0];
        }

        return errorObject?.detail ?? genericErrorText;
    },

    showErrorToast(text) {
        Vue.$toast.error(text, { position: 'top', duration: this.duration });
    },

    /**
     * Handles errors that occur during API calls.
     * 
     * @param {Error} error - The error object.
     * @param {string} url - The URL of the API call.
     * @param {boolean} hasCalBack - Indicates whether the API call has a callback function.
     * @returns {Promise} - A promise that resolves with the success response or rejects with the error response.
     */
    errorHandling(error, url, hasCalBack) {

        const decrypted = this.decryptResponse(error.response, url);

        return new Promise((success, reject) => {
            if (error.response != undefined && (error.response.status === CODE_CONNECT || error.response.status === CODE_UPDATE)) {//500-502
                if (!isCheckServer) {
                    this.checkServerIsUpdating()
                        .then((response) => {
                            if (!response.isEnable) {
                                Token.destroySession();
                                reject(error);
                            } else {
                                var errorData;
                                if (error.response != undefined && error.response.data != undefined && error.response.data != "") {
                                    errorData = error.response.data;
                                } else {
                                    errorData = error;
                                }
                                if (!isUpdateKey) {
                                    const errorText = this.getErrorText(decrypted);
                                    this.showErrorToast(errorText);
                                }
                                reject(errorData);
                            }
                        }).catch(() => {
                            Token.destroySession();
                            reject(error);
                        })
                } else {
                    reject(error)
                }
            } else if (error.response != undefined && error.response.status === CODE_HANDSHAKE) {//503 
                Token.removeCodeHandShake();
                if(!isGetHandShake) {
                    if(!hasCalBack) {
                        this.getHandShake().then((response) => {
                            success(response)
                        }).catch((error) => {
                            if(window.location.href != "/updateserver") {
                                this.showPopupHandshake(error)
                            }
                            reject(error);
                        })
                    } else {
                        success("error handshake")
                    }
                } else {
                    this.waitGetHandShake().then((response) => {
                        success(response)
                    }).catch(() => {
                        reject(error);
                    })
                }
            } else if (error.response != undefined && error.response.status === CODE_AUTHEN && !isUpdateKey) {//401
                this.showErrorToast("Expired Token");
                window.location.href = "login";
                reject(error);
            } else {
                var errorData;
                if (error.response != undefined && error.response.data != undefined && error.response.data != "") {
                    try {
                        var textData = AES.decrypt(error.response.data, url);
                        errorData = JSON.parse(textData);
                        if (error.response.status == 400 && Session.has('isLogin') && Session.get('isLogin')) {
                            Token.destroySession()
                        }
                    } catch (ex) {
                        errorData = error.response.data;
                    }
                } else {
                    errorData = error;
                }
                if ((!Session.has('isSubmitSTP') || !Session.get('isSubmitSTP')) && !isUpdateKey) {
                    const errorText = this.getErrorText(errorData);
                    this.showErrorToast(errorText);
                }
                reject(errorData);
            }
        });
    },

    /**
     * Waits for the handshake to be completed.
     * @returns {Promise<string>} A promise that resolves with a callback string if the handshake is successful, or rejects with an error string if the handshake fails.
     */
    waitGetHandShake() {
        return new Promise((success, reject) => {
            var timeout = setInterval(() => {
                count++
                if(count < 100000000000) {
                    if(!isGetHandShake) {
                        clearInterval(timeout)
                        if(getHandShakeSussecc) {
                            success('callback')
                        } else {
                            reject("error")
                        }
                    }
                } else {
                    reject("error")
                }
            }, 1000)
        })
    },

    showPopupHandshake(error) {
        if (!isShowingHandshake) {
            if (Token.getToken() != undefined) {
                isShowingHandshake = true;
                Vue.prototype.$modal.show('modal-tryhandshake', {
                    error: error
                });
            } else {
                this.showErrorToast("Expired Token");
                window.location.href = "login";
            }
        }
    },

    updateKeyAES() {
        if(!isUpdateKey) {
            isUpdateKey = true
            this.getHandShake().then(() => {
                Token.destroySession()

                Vue.prototype.$swal({
                    title: 'User session expired',
                    text: "You have been logged out. Please log in again.",
                    type: 'message',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Log in'
                }).then((result) => {
                    if (result.value) {
                        if (window.location.pathname != "/login" && window.location.pathname != "/register") {
                            window.location.href = "login"
                        }
                    }
                });
            }).catch((error) => {
                this.showPopupHandshake(error)
            })
        }
        return ""
    },

    /**
     * Retrieves the IP client from the server.
     * @returns {Promise<string>} A promise that resolves with the IP client.
     */
    getIpClient() {
        return new Promise((success, reject) => {
            var url = "/api/Auth/IpClient"
            Vue.axios.get(`${url}`).then((response) => {
                var jsonData = JSON.parse(AES.decrypt(response.data, url))
                var ipClient = jsonData.listIpClient.remoteIpAddress.replace('::1', '127.0.0.1');
                Session.set('cache_ipClient', ipClient)
                if (!Session.has('hasGetLocation') || !Session.get('hasGetLocation')) {
                    Session.set('cache_address', jsonData.city.city + ", " + jsonData.city.country)
                }
                success(ipClient)
            }).catch((error) => {
                if (!error.response || error.response.status === CODE_CONNECT || error.response.status === CODE_UPDATE) {
                    if (!isCheckServer) {
                        this.checkServerIsUpdating()
                            .then((response) => {
                                if (!response.isEnable) {
                                    //window.location.href = "updateserver";
                                    reject(error);
                                } else {
                                    Token.removeCodeHandShake();
                                    Token.destroySession();
                                    reject(error);
                                }
                            }).catch(() => {
                                //window.location.href = "updateserver";
                                reject(error);
                            })
                    } else {
                        reject(error)
                    }
                } else {
                    Token.removeCodeHandShake();
                    Token.destroySession();
                    reject(error);
                }
            })
        })
    },

    getHandShake() {
        isGetHandShake = true
        return new Promise((success, reject) => {
            // DEBUG
            ApiManager.getIpClient().then((ipClient) => {
               
                if (location.hostname == 'localhost' || location.hostname == '127.0.0.1') {
                    // Get Ip Client
                    ApiManager.getLocationCode(ipClient).then((response) => {
                        success(response);
                    }).catch((error) => {
                        Token.removeCodeHandShake();
                        Token.destroySession();
                        reject(error);
                    });
                }
                // RELEASE
                else {
                    // LocationCode
                    ApiManager.getLocationCode(this.ipServerUI()).then((response) => {
                        success(response);
                    }).catch((error) => {
                        Token.removeCodeHandShake();
                        Token.destroySession();
                        reject(error);
                    });
                }
            }).catch((error) => {
                reject(error)
            });
        });
    },

    /** todo: why this method is called getLocationCode but calling /api/Auth/Handshake ? */
    getLocationCode(ipServer) {
        return new Promise((success, reject) => {
            var paramsBody = {
                "clientId" : md5("10000000000001"),
                "clientSecret" : md5("key_secret_development"),
                "xForwardedFor" : ipServer,
                "xDateTime" : new Date(),
                "deviceId": ApiManager.getDeviceId(),
                "deviceName": browser.getBrowserName(),
                "location": Session.get('cache_address'),
                "operatingSystem": "WEB"
            }
            
            var url = '/api/Auth/Handshake?isValid=true'
            // Call api Handshake
            Vue.axios.patch(url, paramsBody, {
                headers: { "Content-Type": "application/json; charset=utf-8" },
            })
                .then((response) => {
                    isUpdateKey = false
                    isShowingHandshake = false;
                    isGetHandShake = false;
                    getHandShakeSussecc = true;
                    const responseData = JSON.parse(AES.decrypt(response.data, url));
                    var keyARM = responseData.xKeyArnLogin
                    Session.set('key-login', keyARM)

                    Session.set('aes-key', responseData.xSecretKey)
                    Session.set('aes-iv', responseData.xIVKey)
                  
                    Token.saveCodeHandShake(responseData.xHandshake);
                    success(responseData);
                })
                .catch((error) => {
                    console.error("Error in getLocationCode");
                    console.error(error);
                    isGetHandShake = false
                    getHandShakeSussecc = false
                    if (error.response) {
                        if (error.response.status === CODE_CONNECT || error.response.status === CODE_UPDATE) {
                            if (!isCheckServer) {
                                this.checkServerIsUpdating()
                                .then((response) => {
                                    if (!response.isEnable) {
                                        //window.location.href = "updateserver";
                                        reject(error);
                                    } else {
                                        var errorData;
                                        if (error.response != undefined && error.response.data != undefined && error.response.data != "") {
                                            errorData = error.response.data;
                                        } else {
                                            errorData = error;
                                        }
                                        const errorText = this.getErrorText(errorData);
                                        this.showErrorToast(errorText);
                                        reject(errorData);
                                    }
                                })
                                .catch(() => {
                                    //window.location.href = "updateserver";
                                    reject(error);
                                });
                            } else {
                                reject(error);
                            }
                        } else {
                            isShowingHandshake = false;
                            var errorData;
                            if (error.response != undefined && error.response.data != undefined && error.response.data != "") {
                                errorData = error.response.data;
                            } else {
                                errorData = error;
                            }
                            reject(errorData);
                        }
                    } else {
                        isShowingHandshake = false;
                        reject(error)
                    }
                });
        });
    },

    getLocation() {
        if (navigator && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (location) {
                const googleApiKey = process.env.VUE_APP_GOOGLE_API_KEY;
                const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.coords.latitude},${location.coords.longitude}&key=${googleApiKey}`;
                Vue.axios.get(`${url}`, {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8'
                    }
                }).then((response) => {
                    var formatted_address = response.data.results[0].formatted_address
                    Session.set('cache_address', formatted_address)
                    Session.set('hasGetLocation', true)
                    ApiManager.getHandShake().then(() => {
                        if (Session.has('Profile') && Session.get('Profile') != null && Session.get('Profile') != "") {
                            ApiManager.saveDevice("/api/Notification/SaveDevice?userId=" + Session.get('Profile').id)
                        }
                    }).catch((error) => {
                        if (window.location.href != "/updateserver") {
                            ApiManager.showPopupHandshake(error)
                        }
                    })
                }).catch((error) => {
                    logger.error(`getLocation: ${error}`)
                });
            });
        }
    },

    saveDevice(url) {
        return new Promise((success, reject) => {

            if (Token.getTokenFCM() != undefined) {
                var paramsBody = {
                    "deviceId": ApiManager.getDeviceId(),
                    "deviceName": browser.getBrowserName(),
                    "tokenFCM": Token.getTokenFCM(),
                    "location": Session.get('cache_address'),
                    "language": document.documentElement.lang
                }
                
                ApiManager.put(url, paramsBody)
                    .then((response) => {
                        success(response)
                    }).catch((error) => {
                        reject(error)
                    })
            } else {
                success()
            }
        })

    },

    removeDevice(url) {
        return new Promise((success, reject) => {
            ApiManager.delete(url)
                .then((response) => {
                    success(response)
                }).catch((error) => {
                    reject(error)
                })
        })
    },

    checkServerIsUpdating() {
        isCheckServer = true
        return new Promise((success, reject) => {
            var url = "/api/Auth/OperatingSystem"
            Vue.axios.post(`${url}`, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            }).then((response) => {
                isCheckServer = false
                const responseData = JSON.parse(AES.decrypt(response.data, url));
                
                success(responseData)
            }).catch((error) => {
                isCheckServer = false
                reject(error)
            });
        })
    },
    removePayItem(payItem) {
        isCheckServer = true;
        return new Promise((success, reject) => {
            var url = "/api/Subscription/RemovePayItem"
            Vue.axios.post(`${url}`,payItem, {headers: {
                'Authorization': "Bearer " + Token.getToken(),
                        'handshake': 'Basic ' + Token.getCodeHandShake(),
                        'Content-Type': 'application/json; charset=utf-8'
            }}).then((response) => {
                const responseData = JSON.parse(AES.decrypt(response.data, url));
                success(responseData)
            }).catch((error) => {
                reject(error)
            });
        })
    },
    addPayItems(data) {
        isCheckServer = true;
        return new Promise((success, reject) => {
            var url = "/api/Subscription/AddPayItems"
            Vue.axios.post(`${url}`,data, {headers: {
                'Authorization': "Bearer " + Token.getToken(),
                        'handshake': 'Basic ' + Token.getCodeHandShake(),
                        'Content-Type': 'application/json; charset=utf-8'
            }}).then((response) => {
                const responseData = JSON.parse(AES.decrypt(response.data, url));
                success(responseData)
            }).catch((error) => {
                reject(error)
            });
        })
    },
    empLeaveDetail(data) {
        isCheckServer = true;
        return new Promise((success, reject) => {
            var url = "/api/Payroll/EmpLeavePayDetail"
            Vue.axios.get(`${url}?employeeId=${data.employeeId}&companyId=${data.companyId}&startPayPeriod=${data.startPayPeriod}&endPayPeriod=${data.endPayPeriod}`, {headers: {
                'Authorization': "Bearer " + Token.getToken(),
                        'handshake': 'Basic ' + Token.getCodeHandShake(),
                        'Content-Type': 'application/json; charset=utf-8'
            }}).then((response) => {
                const responseData = JSON.parse(AES.decrypt(response.data, url));
                success(responseData)
            }).catch((error) => {
                reject(error)
            });
        })
    },

    /** Get the employee termination date */
    employeeTermination(data) {
        isCheckServer = true;
        return new Promise((success, reject) => {
            var url = "/api/Payroll/getEmployeeTerminationDate"
            Vue.axios.get(`${url}?employeeId=${data.employeeId}&companyId=${data.companyId}&startPayPeriod=${data.startPayPeriod}&endPayPeriod=${data.endPayPeriod}`, {headers: {
                'Authorization': "Bearer " + Token.getToken(),
                        'handshake': 'Basic ' + Token.getCodeHandShake(),
                        'Content-Type': 'application/json; charset=utf-8'
            }}).then((response) => {
                const responseData = JSON.parse(AES.decrypt(response.data, url));
                success(responseData)
            }).catch((error) => {
                reject(error)
            });
        })
    },
    terminationApiCall(data) {
        isCheckServer = true;
        return new Promise((success, reject) => {
            var url = "/api/Employee/EmployeeTerminationCheck"
            Vue.axios.get(`${url}?employeeId=${data.employeeId}&companyId=${data.companyId}&startPayPeriod=${data.startPayPeriod}&endPayPeriod=${data.endPayPeriod}`, {headers: {
                'Authorization': "Bearer " + Token.getToken(),
                        'handshake': 'Basic ' + Token.getCodeHandShake(),
                        'Content-Type': 'application/json; charset=utf-8'
            }}).then((response) => {
                const responseData = JSON.parse(AES.decrypt(response.data, url));
                success(responseData)
            }).catch((error) => {
                reject(error)
            });
        })
    }
}

export default ApiManager;
