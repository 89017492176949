<template>
<modal name="modal-viewPaySlip-data" @before-open="beforeOpen" with="auto" height="auto" style="z-index: 1000">
    <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog" aria-labelledby="modal-title" aria-describedby="modal-body" size="xxl" index="0" animate="animate" ng-style="{&#39;z-index&#39;: 1050 + $topModalIndex*10, display: &#39;block&#39;}" tabindex="-1" uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true" style="display: block; overflow: auto;">
        <div class="modal-dialog modal-xxl pdf-modal">
            <div class="modal-content" uib-modal-transclude="">
                <div class="modal-content text-center onboarding-modal modal-centered ng-scope">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel" style="text-align:center;">
                                <span style="color:#334152" class="ng-binding">{{titleName ? titleName : 'Payslip'}}</span>
                            </h5>
                            <button v-on:click="this.closeModal" aria-label="Close" class="box-title close" data-dismiss="modal" type="button"></button>
                        </div>
                        <div class="modal-body" style="padding:0">
                            <div id="example1" style="width: 100%; height: 70vh;" class="pdfobject-container">
                                <!-- <embed :src=linkData type="application/pdf" style="overflow: auto; width: 100%; height: 100%;" /> -->
                                <vue-html2pdf
                                    :show-layout="false"
                                    :enable-download="false"
                                    :preview-modal="true"
                                    :paginate-elements-by-height="1080"
                                    :filename="titleName"
                                    :pdf-quality="2"
                                    :manual-pagination="false"
                                    :headerPage="optionHeader"
                                    :footerPage="optionFooter"
                                    pdf-format="a4"
                                    
                                    pdf-orientation="portrait"
                                    pdf-content-width="800px"
                                    @progress="onProgress($event)"
                                    ref="html2Pdf"
                                >
                                    <section slot="pdf-content" class="container pdf-layout" id="pdf-content">
                                    </section>
                                </vue-html2pdf>
                                <div class="load" v-show="!hasData && !isError">
                                    <v-progress-circular
                                    :rotate="-90"
                                    :size="100"
                                    :width="15"
                                    :value="progress"
                                    color="teal"
                                    >
                                    </v-progress-circular>
                                </div>
                                <p v-show="isError">This browser does not support PDFs or an error has occurred. Please reload or download the PDF to view it: <a :href=linkData>Download PDF</a>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</modal>
</template>

<script>
import axios from "axios"
import Token from '@/api/Token'
import ApiManager from '@/api/ApiManager'
import * as PDF from "@/utils/PDF"
import * as AES from "@/utils/Encodepassword"
import VueHtml2pdf from '@/components/vue-html2pdf'

export default {
    name: "ViewPaySlipModalData",
    props: [],
    components: {
        VueHtml2pdf
    },
    data() {
        return {
            linkData: "",
            titleName: "",
            hasData: false,
            isError: false,
            progress: 0,
            optionHeader: {
                pagination: true,
                titles: []
            },
            optionFooter: {
                pagination: false,
                titles: []
            }
        };
    },
    methods: {
        beforeOpen(event) {
            this.linkData = event.params.link
            this.titleName = event.params.titleName
            this.hasData = false
            this.isError = false
            this.progress = 0;
            var interval = setInterval(() => {
                if (this.progress === 120) {
                    this.hasData = true
                    clearInterval(interval)
                } else if(this.progress != 60 && this.progress != 90) {
                    this.progress += 10
                }
            }, 200)
            
            axios({
                url: this.linkData,
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token.getToken(),
                    handshake: 'Basic ' + Token.getCodeHandShake()
                },
            })
            .then((response) => {
                var dataResponse = JSON.parse(AES.decrypt(response.data));
                this.templateHTML(dataResponse)
            })
            .catch((error) => {
                const decryptedError = error instanceof TypeError ? error : JSON.parse(AES.decrypt(error.response.data));
                this.$logger.error(`beforeOpen: ${JSON.stringify(decryptedError)}`)

                if(error.response && error.response.status === 500){
                    if(!ApiManager.isCheckServer) {
                        ApiManager.checkServerIsUpdating()
                        .then((response) => {
                            if(!response.isEnable) {
                                window.location.href = "updateserver";
                            } else {
                                this.isError = true
                            }
                        }).catch(() => {
                            window.location.href = "updateserver";
                        })
                    }
                } else {
                    this.isError = true
                }
            })
        },

        closeModal() {
            this.$modal.hide('modal-viewPaySlip-data');
            if(this.titleName == undefined) {
                this.$modal.show('modal-payrundetails')
            }
        },
        onProgress(progress) {
            this.progress = progress
        },
        templateHTML(data) {
            this.optionHeader.titles = []
            this.optionFooter.titles = []
            var content = "";
            
            if(this.titleName != undefined) {
                content = this.templateReport(data)
            } else {
                content = this.templatePayslip(data)
            }
            var templateHTML = `${content}`
            
            document.getElementById('pdf-content').insertAdjacentHTML('beforeend',templateHTML);
            this.$refs.html2Pdf.generatePdf()
        },
        templatePayslip(data) {
            var pages = ""
            this.optionFooter.titles.push('Cyruspay Payslip Report')
            if(data.listEmployee != null) {
                var listEmployee = data.listEmployee
                listEmployee.forEach((element, index) => {
                    this.optionHeader.titles.push(`Here is ${element.firstName + ' ' + element.lastName}'s Payslip`)
                    pages += PDF.payslip(data.company, data.payroll, data.listPayrollDetail[index], element, data.listPayrollSummary[index])
                    if(index < listEmployee.length-1) {
                        pages += '<div class="html2pdf__page-break change-header"></div>'
                    }
                });
            } else {
                this.optionHeader.titles.push(`Here is ${data.employee.firstName + ' ' + data.employee.lastName}'s Payslip`)
                pages = PDF.payslip(data.company, data.payroll, data.payrollDetail, data.employee, data.payrollSummary)
            }
            var templatePayslip = pages
            return templatePayslip;
        },
        
        templateReport(data) {
            var content = ""
            switch (this.titleName) {
                case 'Employee Contacts': 
                    content = PDF.employeeContact(data);
                    break;
                case 'Employee Remuneration':
                    content = PDF.employeeRemuneration(data);
                    break;
                case 'Leave Balances':
                    content = PDF.leaveBalances(data);
                    break;
                case 'Payroll Activity Summary':
                    content = PDF.payrollActivitySummary(data);
                    break;
                case 'Payroll Employee Summary':
                    content = PDF.payrollEmployeeSummary(data);
                    break;
            }
            this.optionHeader.titles.push("Cyruspay")
            this.optionFooter.titles.push(`Cyruspay ${this.titleName} Report`)
            var templateReport = `${content}`;
            return templateReport;
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('/assets/css/pdf.css');
</style>
