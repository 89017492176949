<template>
  <div class="menu-position-side full-screen with-content-panel body-wrapper">
    <div class="with-side-panel solid-bg-all ng-scope sideBarOn">
      <!-- START - Top Menu -->
      <v-toolbar class="menu-w" style="
          z-index: 3;
          position: relative;
          background-color: #09b585 !important;
          color: white;
          height: auto !important;
          padding: 0px !important;
        ">
        <div class="topnav">
          <a v-for="item in listMenu" :key="item.id" v-bind:class="idItemMenu == item.id ? 'active' : ''"
            @click="clickItemLeftMenu(item.id)">{{ item.name }}</a>
        </div>
        <v-spacer></v-spacer>

        <v-btn class="notify icon-menu box-title" icon>
          <v-icon style="color: white">mdi-bell</v-icon>
        </v-btn>
        <v-btn class="feedback icon-menu box-title" icon @click="feedbackApp()">
          <v-icon style="color: white">mdi-flash</v-icon>
        </v-btn>

        <v-btn class="help icon-menu box-title" icon @click="collapseRightMenu()">
          <v-icon style="color: white">mdi-help-circle</v-icon>
        </v-btn>

        <div class="top-bar color-scheme-transparent" style="padding-right: 0px; border-bottom: none">
          <div class="top-menu-controls">
            <div v-on:click="clickAvartar" v-on:mouseover="onFocusMenu" v-on:mouseout="onBlurMenu" class="
                top-icon top-settings
                os-dropdown-trigger os-dropdown-position-left">
              <img class="avartar" src="/../assets/img/avatar.png" />
              <div class="os-dropdown menu" style="z-index: 999 !important">
                <ul>
                  <li>
                    <a>
                      <span class="txtHappy mr-0">{{ userName }}!</span>
                    </a>
                    <a>
                      <i class="icon-feather-user"></i>
                      <span>Profile</span>
                    </a>
                  </li>
                  <li>
                    <a v-on:click="logout()">
                      <i class="icon-feather-log-out"></i>
                      <span>Log out</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </v-toolbar>
      <!-- END - Top Menu -->
      <div class="layout-w menu">
        <div class="menu-mobile menu-activated-on-click color-scheme-dark">
          <div class="mm-logo-buttons-w">
            <a class="mm-logo" href="/"><img src="https://test-api.cyruspay.com.au/img/logo_256.png" /></a>
            <div class="mm-buttons">
              <v-btn class="notify icon-menu" style="margin-top: 7px" icon>
                <v-icon>mdi-bell</v-icon>
              </v-btn>
              <v-btn class="feedback icon-menu" style="margin-top: 7px" icon @click="feedbackApp()">
                <v-icon>mdi-flash</v-icon>
              </v-btn>
              <div class="top-bar color-scheme-transparent" style="padding-right: 0px; border-bottom: none">
                <div class="top-menu-controls">
                  <div v-on:click="clickAvartar" v-on:mouseover="onFocusMenu" v-on:mouseout="onBlurMenu" 
                    class="top-icon top-settings os-dropdown-trigger os-dropdown-position-left">
                    <img class="avartar" src="/assets/img/avatar.png" />
                    <div class="os-dropdown menu" style="">
                      <ul>
                        <li>
                          <a>
                            <span class="txtHappy mr-0">{{ userName }}!</span>
                          </a>
                          <a>
                            <i class="icon-feather-user"></i>
                            <span>Profile</span>
                          </a>
                        </li>
                        <li>
                          <a v-on:click="logout()">
                            <i class="icon-feather-log-out"></i>
                            <span>Log out</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mobile-menu-trigger">
                <div v-on:click="toggleMenu2($event)" class="os-icon os-icon-hamburger-menu-1"></div>
              </div>
            </div>
          </div>
          <!-- List menu for (max-width: 1024px) and (min-width: 768px) -->
          <div class="menu-and-user">
            <ul class="main-menu" v-on:click="toggleMenu($event)">
              <li v-for="item in listMenu" :key="item.id">
                <div v-if="idItemMenu == item.id" class="itemMobileClicked" @click="clickItemLeftMenu(item.id)">
                  <div class="icon-w">
                    <div>
                      <img class="iConItemMobile" :src="item.iconActi" />
                    </div>
                  </div>
                </div>
                <div v-if="idItemMenu != item.id" class="itemMobileNotClick" @click="clickItemLeftMenu(item.id)">
                  <div class="icon-w">
                    <div>
                      <img class="iConItemMobile" :src="item.iconNotActi" />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <!-- List menu for (max-width: 767px) -->
          <div class="menu-and-user2">
            <ul class="main-menu" v-on:click="toggleMenu2($event)">
              <li v-for="item in listMenu" :key="item.id">
                <div v-if="idItemMenu == item.id" class="itemMobileClicked" @click="clickItemLeftMenu(item.id)">
                  <div class="icon-w">
                    <div class="text-left" style="display: flex">
                      <img class="iConItemMobile" :src="item.iconActi" />
                      <span class="text-center my-auto txtItemMobile">{{ item.name }}</span>
                    </div>
                  </div>
                </div>
                <div v-if="idItemMenu != item.id" class="itemMobileNotClick" @click="clickItemLeftMenu(item.id)">
                  <div class="icon-w">
                    <div class="text-left" style="display: flex">
                      <img class="iConItemMobile" :src="item.iconNotActi" />
                      <span class="text-center my-auto txtItemMobileNotClick">{{ item.name }}</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="content-w" style="background-color: #cccccc">
          <v-app style="background: transparent">
            <div class="mainContent">
              <div class="content-i ng-scope" ui-view="mainContent" style="">
                <div class="content-box ng-scope disable-scrollbars">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="element-wrapper mb-3">

                        <div class="element-content">
                          <el-row :gutter="20">
                            <el-col style="margin-top: 20px">
                              <div class="grid-content bg-purple">
                                <div class="content-right">
                                  <el-tabs @tab-click="handleClick" type="border-card" v-model="activeName"
                                    style="margin-top: 10px">
                                    <el-tab-pane label="Information" name="first">
                                      <div class="plan_info">
                                        <div class="sub_tab_inn">
                                          <h3>Previous Subscription Details</h3>
                                          <div class="employee-modal text-left">
                                            <div class="row">
                                              <div class="col-sm-4">
                                                <div class="form-group">
                                                  <label for="">Previous Plan</label>
                                                  <div class="bold">
                                                    {{
                                                      CurrentSubscription.planTitle
                                                    }}
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-sm-4">
                                                <div class="form-group">
                                                  <label for="">Employee Allowed</label>
                                                  <div class="bold">
                                                    {{
                                                      CurrentSubscription.empAllowed
                                                    }}
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-sm-4">
                                                <div class="form-group">
                                                  <label for="">Status</label>
                                                  <div class="bold">
                                                    {{
                                                      CurrentSubscription.notes
                                                    }}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="row">
                                              <div class="col-sm-4">
                                                <div class="form-group">
                                                  <label for="">Start Date</label>
                                                  <div class="bold">
                                                    {{ formatDate(CurrentSubscription.subscriptionStartDate) }}
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-sm-4">
                                                <div class="form-group">
                                                  <label for="">End Date</label>
                                                  <div class="bold">
                                                    {{ formatDate(CurrentSubscription.subscriptionEndDate) }}
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-sm-4">
                                                <div class="form-group">
                                                  <label for="">Notes</label>
                                                  <div class="bold">
                                                    {{
                                                      CurrentSubscription.notes
                                                    }}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="row">
                                              <div class="ly-button-setting" style="margin-top: 0px">
                                              </div>
                                            </div>
                                          </div>
                                          <h3>Available Plans</h3>
                                          <div class="row">
                                            <div v-for="item in ListPlans" :key="item.planId" 
                                              class="col-lg-3 col-md-3 col-sm-4 col-xs-6" 
                                                :style="CheckTrialPlan(item) == true
                                                ? 'display:none'
                                                : ''">
                                              <div style="display: none" v-if="CheckTrialPlan(item)"></div>
                                              <div v-else class="plan_block">
                                                <i class="icon">
                                                  <img src="/assets/img/pricing_icon_1.png" alt="" /></i>
                                                <h3>{{ item.title }}</h3>
                                                <div class="price">
                                                  ${{ item.price }}
                                                  <sub>/Month</sub>
                                                </div>
                                                <p>
                                                  Valid for {{ item.period }}
                                                  {{ item.periodType }}
                                                </p>
                                                <p>{{ item.noOfEmployees }} Employee(s)</p>

                                                <label class="switch">
                                                  <input class="PlanSelect" 
                                                    :id="item.id" 
                                                    @change="updateCheckall(item.id, $event)" 
                                                    v-model="SelectedPlann" 
                                                    v-bind:value="item.id"
                                                    type="checkbox" />
                                                  <span class="slider round"></span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="btn-block text-right">
                                            <button @click="ClickPay()" class="btn"
                                              :class="SelectedPlann <= 0 ? 'btn-grey' : 'btn-success'"
                                              :disabled="SelectedPlann <= 0" style="margin-left: 15px">
                                              Pay
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </el-tab-pane>
                                    <el-tab-pane label="Card Details" name="second">
                                      <div class="sub_tab_inn">
                                        <div class="card_payment">
                                          <!-- Nav tabs -->
                                          <ul class="nav nav-tabs" role="tablist">
                                            <li role="presentation" class="active">
                                              <a href="#CardTab1" aria-controls="Cardt1" role="tab"
                                                data-toggle="tab"><img src="/assets/img/payment_logo_1.png"
                                                  alt="" /></a>
                                            </li>
                                            <li role="presentation">
                                              <a href="#CardTab2" aria-controls="Cardt2" role="tab"
                                                data-toggle="tab"><img src="/assets/img/payment_logo_2.png"
                                                  alt="" /></a>
                                            </li>
                                          </ul>
                                          <!-- Tab panes -->
                                          <div class="tab-content">
                                            <div name="stripe" role="tabpanel" class="tab-pane active" id="CardTab1">
                                              <h3>Stripe Payment Details</h3>
                                              <div class="pay_forms">

                                                <div class="row">
                                                  <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div id="card-number"></div>
                                                  </div>
                                                  <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div id="card-expiry"></div>
                                                  </div>
                                                  <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div id="card-cvc"></div>
                                                  </div>
                                                  <div id="card-error"></div>
                                                  <div class="clearfix"></div>
                                                  <div class="col-lg-12 col-md-12 col-sm-12">
                                                    <label class="pay_check"><input type="checkbox" v-model="IsRecurral" name="" />
                                                      Enable Recurring Payments</label>
                                                  </div>
                                                  <div class="col-lg-12 col-md-12 col-sm-12">
                                                    <button :disabled="PlanToBeUpgraded <= 0
                                                      " class="btn" :class="PlanToBeUpgraded <= 0
                                                        ? 'btn-grey'
                                                        : 'btn-success'
                                                        " @click="createToken">
                                                      Make Payment
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div name="cordless" role="tabpanel" class="tab-pane" id="CardTab2">
                                              <h3>Set up a Direct Debit</h3>
                                              <div class="pay_forms">
                                                <div class="row">
                                                  <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <input type="text" name="" class="field"
                                                      placeholder="Enter First Name" />
                                                  </div>
                                                  <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <input type="text" name="" class="field"
                                                      placeholder="Enter Last Nam " />
                                                  </div>
                                                  <div class="col-lg-12 col-md-12 col-sm-12">
                                                    <input type="text" name="" class="field"
                                                      placeholder="Enter Email or Company Name" />
                                                  </div>
                                                  <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <input type="text" name="" class="field"
                                                      placeholder="Enter Sort Code or IBAN" />
                                                  </div>
                                                  <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <input type="text" name="" class="field"
                                                      placeholder="Account Number" />
                                                  </div>
                                                  <div class="col-lg-12 col-md-12 col-sm-12">
                                                    <input type="text" name="" class="field"
                                                      placeholder="Enter Address or Post Code" />
                                                  </div>
                                                  <div class="clearfix"></div>
                                                  <div class="col-lg-12 col-md-12 col-sm-12">
                                                    <label class="pay_check">
                                                      <input type="checkbox" name="" />
                                                      Enable Recurring
                                                      Payments</label>
                                                  </div>
                                                  <div class="col-lg-12 col-md-12 col-sm-12">
                                                    <button class="pay_btns">
                                                      Set up Direct Debit
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </el-tab-pane>
                                    <el-tab-pane label="Subscriptions" name="third">
                                      <SubscriptionHistory ref="SubHistory" />
                                    </el-tab-pane>
                                  </el-tabs>
                                </div>
                              </div>
                            </el-col>
                          </el-row>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-app>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_ACTIVE_PLANS,
  //GET_CURRENT_SUBSCRIPTION,
  CREATE_STRIPE_CUSTOMER,
  DEACTIVATE_SUBSCRIPTION,
  LOGOUT,
  REMOVE_DEVICE,
  SEND_TOKEN_TO_SERVER,
  GET_LAST_SUBSCRIPTION,
} from "@/store/actionsType";
import * as Session from "@/utils/SessionStore";
import * as external from "@/utils/External.js";
import * as Utils from "@/utils/Utils";

import SubscriptionHistory from "../home/settings/components/SubscriptionHistory.vue";
import moment from "moment";
import Token from "@/api/Token.js";
export default {
  name: "SubscriptionPayment",
  components: {
    //StripeCard,
    SubscriptionHistory,
  },
  mounted() {
    external.head_link("/assets/css/style.css");
    external.head_link("/assets/css/angular-confirm.min.css");
    external.head_script("/assets/js/angular-config.min.js");
    const style = {
      base: {
        color: "black",
        fontfamily: "Arial, Helvetica, sans-serif",
        background: "#f7f7f7",
        border: "1px solid #c5c5c5",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };
    this.cardNumber = this.stripeElements.create("cardNumber", { style });
    this.cardNumber.mount("#card-number");
    this.cardExpiry = this.stripeElements.create("cardExpiry", { style });
    this.cardExpiry.mount("#card-expiry");
    this.cardCvc = this.stripeElements.create("cardCvc", { style });
    this.cardCvc.mount("#card-cvc");
  },
  beforeDestroy() {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  },
  destroyed() {
    external.del_link("/assets/css/style.css");
    external.del_link("/assets/scss/angular-confirm.min.css");
    external.del_script("/assets/js/angular-config.min.js");
    external.del_script("/assets/js/widgets.js");
  },
  data() {
    return {
      token: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      IsRecurral: false,
      cardName: null,
      SelectedPlann: [],
      PlanToBeUpgraded: 0,
      Plan: 0,
      userName: Session.get("Username"),
      currenCompanyName: Session.get("currenCompanyName"),
      idItemMenu: 0,
      isCheckAll: false,
      activeName: "first",
      ListPlans: [],
      CurrentPlanId: 0,
      CurrentSubscription: {},
      listMenu: [
        {
          id: 1,
          name: "SubscriptionPayment",
          iconNotActi: "/assets/img/menu/dashboard.png",
          iconActi: "/assets/img/menu/dashboard_acti.png",
        },
      ],
    };
  },
  created: function () {
    this.$root.$refs.SubscriptionPayment = this;
    if (this.SubscriptionExpiredMode == false) {
      if (Session.has("currentEmployeeId")) {
        this.$router.push({
          name: "Employees",
          params: {},
        });
      } else {
        this.$router.push({
          name: "Dashboard",
          params: {},
        });
      }
    }
    this.getCurrentSubscription();
  },
  methods: {
    toggleMenu() {
      var elem = window.$(this).closest("li");
      if (elem.hasClass("active")) {
        elem.removeClass("active");
      } else {
        elem.closest("ul").find("li.active").removeClass("active");
        elem.addClass("active");
      }
    },
    toggleMenu2() {
      window.$(".menu-mobile .menu-and-user2").slideToggle(200, "swing");
      this.toggleMenu();
    },
    clickItemLeftMenu(id) {
      this.idItemMenu = id;
      this.isShowMoreMenu = false;
      switch (id) {
        case 1:
          this.nextPage("/SubscriptionPayment");
          break;
        default:
          "";
      }
    },
    nextPage(url) {
      this.$router.push({
        path: url,
        param: {},
      });
    },
    handleClick(tab) {
      if (tab.label == "Subscriptions") {
        this.LoadSubscriptionList();
      } else if (tab.label == "Card Details") {
        if (this.SelectedPlann.length > 0) {
          this.PlanToBeUpgraded = this.SelectedPlann[0];
          this.UpgradeSubscription();
        }
      }
    },
    LoadSubscriptionList() {
      this.$refs.SubHistory.getSubscriptionList();
    },
    CheckTrialPlan(plan) {
      if (plan.title) {
        var st = plan.title.toLowerCase();
        if (st.indexOf("trial") >= 0) {
          if (plan.id == this.CurrentPlanId) {
            return false;
          } else {
            return true;
          }
        } else return false;
      } else return false;
    },
    clickAvartar() {
      var elem = window.$(".os-dropdown-trigger");
      if (!elem.hasClass("over")) {
        this.onFocusMenu();
      } else this.onBlurMenu();
    },
    onFocusMenu() {
      var elem = window.$(".os-dropdown-trigger");
      if (!elem.hasClass("over")) {
        elem.addClass("over");
      }
    },
    onBlurMenu() {
      var elem = window.$(".os-dropdown-trigger");
      if (elem.hasClass("over")) {
        elem.removeClass("over");
      }
    },
    getListPlans() {
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(GET_ACTIVE_PLANS)
        .then((response) => {
          this.$root.$refs.AppCyrus.finish();
          this.ListPlans = response.reverse().sort((a, b) => a.id - b.id);

          // this.ListPlans = response.reverse();
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },
    getCurrentSubscription() {
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(GET_LAST_SUBSCRIPTION)
        .then((response) => {
          var subsIsActive = response.isActive;
          var subsStatus = response.status;
          if (subsIsActive == true || subsStatus == 2) {
            this.logout();
          }
          this.CurrentSubscription = response;
          this.CurrentPlanId = response.planId;
          this.getListPlans();
          this.$root.$refs.BaseLayout.AfterEmployeeCheck();
          this.$root.$refs.AppCyrus.finish();
          this.ListPlans = response.reverse();
          this.PlanToBeUpgraded = 0;
          this.SelectedPlann = [];
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },
    ClickPay() {
      this.PlanToBeUpgraded = this.SelectedPlann[0];
      this.MoveToOtherTab();
      this.UpgradeSubscription();
    },
    MoveToOtherTab() {
      this.activeName = "second";
    },
    updateCheckall: function (planid, event) {
      this.SelectedPlann = [];
      this.PlanToBeUpgraded = 0;
      this.SelectedPlann.push(planid);
      if (event.target.checked == false) {
        this.SelectedPlann = [];
        this.PlanToBeUpgraded = 0;
      }
    },
    UpgradeSubscription() {
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(CREATE_STRIPE_CUSTOMER)
        .then(() => {
          this.$root.$refs.AppCyrus.finish();
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },
    formatDate(inputDate, dateFormat) {
      var date = moment(inputDate);
      if (date.isValid()) {
        return moment(inputDate).format(dateFormat);
      } else {
        return "-";
      }
    },
    DeactivateSubscription() {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure want to de-activate your subscription? you will have no more access to this platform.",
        type: "message",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          // this.DeactivateSubscriptionService();
        }
      });
    },
    logout() {
      Session.remove("Username");
      Session.remove("currenCompanyName");
      Session.remove("currenCompany");
      Session.remove("workHours");
      this.requestLogout();
    },
    requestLogout() {
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(LOGOUT, Token.getRefreshToken())
        .then(() => {
          this.$store.dispatch(REMOVE_DEVICE, Session.get("Profile").id);
          Token.destroySession();
          this.$root.$refs.AppCyrus.finish();
          this.clearTimeout();
          window.location.href = "login";
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },
    DeactivateSubscriptionService() {
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(DEACTIVATE_SUBSCRIPTION)
        .then(() => {
          var Swal = this.$swal;
          Swal.fire({
            title: "Auto log-out alert!",
            html: "Subscription deactivated. You will be logged-out in 5 seconds.",
            timer: 5000,
            timerProgressBar: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              setInterval(() => {
                var ss = parseInt(Swal.getTimerLeft() / 1000);
                b.textContent = ss;
              }, 100);
            },
            willClose: () => {
              // clearInterval(timerInterval);
            },
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              this.$root.$refs.BaseLayout.requestLogout();
            }
          });

          this.$root.$refs.AppCyrus.finish();
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },
    async sendTokenToServer(_token, Plan) {
      var requestModal = {
        planId: Plan,
        token: _token,
        isRecurral: this.IsRecurral,
        SuccessUrl: ""
      };
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(SEND_TOKEN_TO_SERVER, requestModal)
        .then((resp) => {
          this.$root.$refs.AppCyrus.finish();
          if (resp.status == "succeeded" || resp.status == "active") {
            Utils.toastSuccess("Card saved")
          } else {
            Utils.toastError(resp.status);
          }
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },
    async createToken() {
      if (this.PlanToBeUpgraded > 0) {
        const { token, error } = await this.$stripe.createToken(
          this.cardNumber
        );
        if (error) {
          Utils.toastError(error.message);
          return;
        }
        this.sendTokenToServer(token.id, this.PlanToBeUpgraded);
      }
      else {
        Utils.toastError("Plan not selected.");
        return;
      }
    },
  },
  computed: {
    stripeElements() {
      return this.$stripe.elements();
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("/assets/css/setting.css");
@import url("//unpkg.com/element-ui@2.13.1/lib/theme-chalk/index.css");

.mainContent {
  width: 100%;
  height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f7f7f7;
  position: relative;
}

#card-error {
  color: red;
}

.menu-collapse {
  max-width: 225px;
  display: contents;
}

.menu {
  background-color: #09b585;
}

.modal-content {
  -webkit-box-shadow: 0 25px 65px rgba(15, 24, 33, 0.29);
  box-shadow: 0 25px 65px rgba(15, 24, 33, 0.29);
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  pointer-events: none;
  display: block;
}

.text-center {
  text-align: center !important;
}

strong {
  font-weight: bold;
}

// .content-panel {
//     z-index: 1000;
// }

.content-panel {
  min-width: 350px;
  // z-index: 1000;
  vertical-align: top;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 400px;
  flex: 0 0 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  padding: 2rem 2.5rem;
  background-color: transparent;
}

.content-panel {
  -webkit-animation-name: fadeLeft;
  animation-name: fadeLeft;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

.content-panel-toggler {
  background-color: #22bdbc;
  padding: 4px 10px;
  border-radius: 6px;
  color: #fff;
  font-size: 0.72rem;
  text-transform: uppercase;
  display: none;
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 4;
  cursor: pointer;
}

.content-panel-toggler span {
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
  letter-spacing: 1px;
}

.extra-info {
  margin-top: 33px;
  margin-left: 35px;
  background: white;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  position: absolute;
}

.extra-info .status-pill {
  width: 10px;
  height: 10px;
  margin-top: 2.3px;
  margin-left: 2.3px;
}

.notification-container {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
}

.notification.danger {
  color: white;
  background-color: #f24c3d;
}

.notification {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  height: 44px;
  align-items: center;
  font-size: 14px;
}

.notification>.message {
  width: calc(100% - 50px);
  text-align: center;
}

.message p {
  margin-bottom: 0px;
}

.message a:hover {
  color: white;
  text-decoration: underline;
  padding: 0 5px;
  cursor: pointer;
}

.invoicesLink {
  color: white;
  text-decoration: underline;
  padding: 0 5px;
  cursor: pointer;
}

@import "/assets/css/subscription.css";

.hide-popup {
  display: none;
}

.btn-upg {
  cursor: pointer;
}

.disabled {
  cursor: no-drop;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: rgb(9, 181, 133) !important;
}

input:focus+.slider {
  box-shadow: 0 0 1px rgb(9, 181, 133) !important;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
