<template>
<div>
    <div class="row">
        <div class="col-sm-4">
            <label class="txt-title-input">Job Title</label>
            <div class="form-group" style="height: 10px">
            {{ employment.job }}
            </div>
        </div>
        <div class="col-sm-4">
            <label class="txt-title-input">Employment Type</label>
            <div class="form-group" style="height: 10px">
            {{ employment.employmentType }}
            </div>
        </div>
        </div>
        <br />
        <div class="row">
        <div class="col-sm-4">
            <label class="txt-title-input">Start Date</label>
            <div class="form-group" style="height: 10px">
            {{ computedStartDateFormatted }}
            </div>
        </div>
        <div class="col-sm-4">
            <label class="txt-title-input">Termination Date</label>
            <div class="form-group" style="height: 10px">
            {{ computedTerminationDateFormatted }}
            </div>
        </div>
        <div class="col-sm-4">
            <label class="txt-title-input">Termination Amount</label>
            <div class="form-group" style="height: 10px">
            {{ employment.processTerminationAmount>0 ? employment.processTerminationAmount.toFixed(2) : employment.terminationAmount }}
            </div>
        </div>
        </div>
        <br />
        <div class="row">
        <div class="col-sm-4">
            <label class="txt-title-input">Weekly Work Hours</label>
            <div class="form-group" style="height: 10px">
            {{ employment.weeklyWorkHours }}
            </div>
        </div>
        <div class="col-sm-4">
            <div v-if="employmentType != 'Casual'">
            <label class="txt-title-input">Leave Loading (%)</label>
            <div class="form-group" style="height: 10px">
                {{ employment.leaveLoading }}
            </div>
            </div>
            <div v-else>
            <label class="txt-title-input">Casual Loading (%)</label>
            <div class="form-group" style="height: 10px">
                {{ employment.casualLoading }}
            </div>
            </div>
        </div>
        <div class="col-sm-4">
            <label class="txt-title-input">Pay Period</label>
            <div class="form-group" style="height: 10px">
            {{ employment.payPeriod }}
            </div>
        </div>
        </div>
        <br />
        <div class="row">
        <div class="col-sm-4">
            <label class="txt-title-input">Pay Rate</label>
            <div class="form-group" style="height: 10px">
            ${{ employment.payRate }}
            </div>
        </div>
        <div class="col-sm-4">
            <label class="txt-title-input">Type Rate</label>
            <div class="form-group" style="height: 10px">
            {{ employment.typeRate }}
            </div>
        </div>
        <div class="col-sm-4">
            <label class="txt-title-input">Payment Method</label>
            <div class="form-group" style="height: 10px">
            {{ employment.paymentMethod }}
            </div>
        </div>
        </div>
        <br />
</div>
</template>

<script>
import * as Utils from "@/utils/Utils";

export default {
    props: {
        employment: Object
    },
    computed: {
        computedStartDateFormatted() {
          return Utils.formatDateToString(this.employment.startDate);
        },
        computedTerminationDateFormatted() {
          return Utils.formatDateToString(this.employment.terminationDate);
        }
    }
}
</script>
