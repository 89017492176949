<template>
<div class='content-i ng-scope'>
    <div class="content-box ng-scope">
        <!-- START - modal -->
        <DownloadABAFileModal />
        <PaymentDetailModal />
        <ViewEmployeeModal />
        <!-- END - modal -->
        <div class='col-sm-12'>
            <div class='element-wrapper'>
                <div class="title">
                    <div class="txt-title" style="margin-left: 0px">Process Payroll</div>
                    <div class="txt-date">({{utils.formatDateToString(responseRunPayroll.startPayPeriod)}} - {{utils.formatDateToString(responseRunPayroll.endPayPeriod)}})</div>
                </div>
                <div class='element-content' style="margin-top: 20px;">
                    <div class='row'>
                        <!-- List Run Pay roll -->
                        <div v-show="runPayrollStage == 'Adjust'" class="ly-list-run-payroll">
                            <div class='' style="align-items: center;">
                                <div class='table-responsive' style="padding-bottom:0px">
                                    <div class="payroll-mobile v-application" style="margin-left: 20px">
                                        <div style="display: flex; align-items: center">
                                            <v-checkbox value @click="checkAll()" :input-value="numberCheck == (this.listRunPayroll.length - this.deletedEmployeeIds.length)" :indeterminate="numberCheck > 0 && numberCheck < (this.listRunPayroll.length - this.deletedEmployeeIds.length)" id="checkAll"></v-checkbox>
                                            <span v-show="numberCheck > 0" style="margin-left: 5px">{{numberCheck}}</span>
                                        </div>
                                    </div>
                                    <table class="table table-padded payroll-web" style="min-width: 645px">
                                        <thead class="">
                                            <tr>
                                                <th class="v-application" style="display: flex; width: 50px; align-items: center; justify-content: flex-end;">
                                                    <v-checkbox value @click="checkAll()" :input-value="numberCheck == (this.listRunPayroll.length - this.deletedEmployeeIds.length)" :indeterminate="numberCheck > 0 && numberCheck < (this.listRunPayroll.length - this.deletedEmployeeIds.length)" id="checkAll"></v-checkbox>
                                                    <span v-show="numberCheck > 0" style="margin: 38px; position: absolute">{{numberCheck}}</span>
                                                </th>
                                                <th>Name</th>
                                                <th>Work hours</th>
                                                <th>Pay rate</th>
                                                <th>Gross Earnings</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in listRunPayroll" :key="item.id" class="animatedUp ng-scope">
                                                <td class="v-application" style="padding-top: 0px !important; padding-bottom: 0px !important;">
                                                    <v-checkbox  value v-model="item.isactivePayroll" v-bind:disabled="isDeletedEmployee(item.employeeId)" @change="check(item.isactivePayroll)" :id="'isCheckFindMyAddress' + item.id"></v-checkbox>
                                                </td>
                                                <td style="width: 25%;">
                                                     <label v-bind:disabled="isDeletedEmployee(item.employeeId)" class="txt-name os-dropdown-trigger choose-employee" style="width: max-content;" @click="getListEmployee(item.employeeId)">{{item.employeeData.firstName + ' ' + item.employeeData.lastName }}</label>
                                                     <br/>
                                                     <span v-show="isDeletedEmployee(item.employeeId)" style="font-size: 12px; color: red">
                                                        (Deleted)
                                                    </span>
                                                </td>
                                                <td style="width: 33%">
                                                    <div class="input-group">
                                                        <input v-bind:disabled="isDeletedEmployee(item.employeeId)" 
                                                            v-model.number="item.ordinaryWorkHoursRound" v-on:change="onChangeWeeklyWorkHours(item, index)" class="form-control edit-work" />
                                                        <i v-show="item.isChangeWorkHours" class="icon-feather-rotate-ccw text-primary ng-scope icon-payroll pointer" @click="resetWorkHours(item, index)" style="left: 10px;"></i>
                                                        <!-- <span :title="item.ordinaryWorkHours"><i class="icon-feather-eye text-primary fs-14 icon-payroll" style="vertical-align: middle; right: 50px;"></i></span> -->
                                                        <div class="input-group-append">
                                                            <div class="input-group-text">hr</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style="width: 33%">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">$</div>
                                                        </div>
                                                        <input v-bind:disabled="isDeletedEmployee(item.employeeId)" v-model.number="item.ordinaryPayRateRound" v-on:change="onChangePayRatePerHour(item, index)" class="form-control edit-rate" />
                                                        <i v-show="item.isChangePayRate" class="icon-feather-rotate-ccw text-primary ng-scope icon-payroll pointer" @click="resetPayRate(item, index)" style="right: 10px;"></i>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="gross" style="width: max-content">
                                                        <span class="txtDola">${{roundNumber(item.earnings)}}</span>
                                                        <span @click="seeSuperDetail(index)" class="text-primary box-title super-detail" style="vertical-align: middle;cursor: pointer;">
                                                            ${{roundNumber(item.super)}}
                                                            <i class="icon-feather-help-circle text-primary fs-14"></i>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <button v-on:click="openModalViewMore(index)" v-bind:disabled="isDeletedEmployee(item.employeeId)" class="btn btn-add-more" style="width: max-content;">View more <img class="icon-button" src="/assets/img/icon_next.png" /></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table class="table table-padded payroll-mobile">
                                        <tbody>
                                            <tr v-for="(item, index) in listRunPayroll" :key="item.id" class="animatedUp ng-scope">
                                                <div class="row item">
                                                    <div class="col-auto v-application" style="width: 50px">
                                                        <v-checkbox  value v-model="item.isactivePayroll" @change="check(item.isactivePayroll)" :id="'isCheckFindMyAddress' + item.id"></v-checkbox>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <label class="txt-name os-dropdown-trigger choose-employee" @click="getListEmployee(item.employeeId)">{{item.employeeData.firstName + ' ' + item.employeeData.lastName }}</label>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="row">
                                                            <div class="col-sm-6" style="padding-left: 0px;">
                                                                <div class="input-group" style="margin: 3px 0px 3px 0px;">
                                                                    <input v-model.number="item.ordinaryWorkHoursRound" v-on:change="onChangeWeeklyWorkHours(item, index)" class="form-control edit-work" />
                                                                    <i v-show="item.isChangeWorkHours" class="icon-feather-rotate-ccw text-primary ng-scope icon-payroll pointer" @click="resetWorkHours(item, index)" style="left: 10px;"></i>
                                                                    <div class="input-group-append">
                                                                        <div class="input-group-text">hr</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6" style="padding-left: 0px;">
                                                                <div class="input-group" style="margin: 3px 0px 3px 0px;">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">$</div>
                                                                    </div>
                                                                    <input v-model.number="item.ordinaryPayRateRound" v-on:change="onChangePayRatePerHour(item, index)" class="form-control edit-rate" />
                                                                    <i v-show="item.isChangePayRate" class="icon-feather-rotate-ccw text-primary ng-scope icon-payroll pointer" @click="resetPayRate(item, index)" style="right: 10px;"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <b style="color: rgb(124, 124, 124); font-size: 11px;">Gross Earnings</b>
                                                        <div class="gross">
                                                            <span class="txtDola">${{roundNumber(item.earnings)}}</span>
                                                            <span class="fs-14 txtSuper" style="vertical-align: middle;">+
                                                                <span @click="seeSuperDetail(index)" class="text-primary " style="vertical-align: middle;cursor: pointer;">
                                                                    ${{roundNumber(item.super)}}
                                                                    <i class="icon-feather-help-circle text-primary fs-14"></i>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-1">
                                                        <button v-on:click="openModalViewMore(index)" class="btn btn-add-more">View more <img class="icon-button" src="/assets/img/icon_next.png" /></button>
                                                    </div>
                                                </div>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <!-- Review Run Pay roll -->
                        <div v-show="runPayrollStage == 'Review'" class="ng-scope" style="margin-top: 20px; width: 100%;">
                            <div class="element-box-tp">
                                <table class="table table-padded">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Employees</th>
                                            <th class="text-right">Earnings</th>
                                            <th class="text-right" style="padding-right:26px">Tax</th>
                                            <th class="text-right">Deduction</th>
                                            <th class="text-right">Net pay</th>
                                            <th class="text-right">Amount paid</th>
                                            <th class="text-right">Super</th>
                                            <!-- <th class="text-center text-primary pointer" ng-click="finalInfo()">EOFY Final</th> -->
                                        </tr>
                                    </thead>
                                    <tbody v-for="(item) in listReviewPayroll" :key="item.id" v-show="item.activePayroll == 'ACTIVE'" v-bind:class="(item.isChangeInput) ? 'has-Tax-Template':''" class="ng-scope tr-group">
                                        <tr v-bind:class="(item.isChangeInput) ? 'has-Tax-Template':'transparent'">
                                            <td v-show="item.isChangeInput" style="width: 10px;text-align: center;">
                                                <i class="icon-feather-rotate-ccw text-primary ng-scope" @click="resetData(item.employeeData.id)" style=""></i>
                                            </td>
                                            <td v-show="!item.isChangeInput" style="width: 10px;text-align: center;">

                                            </td>
                                            <td class="ng-binding" style="display: grid">
                                                <span>
                                                    {{item.employeeData.firstName + ' ' + item.employeeData.lastName}}
                                                </span>
                                                <span v-show="item.isTermination" style="font-size: 12px; color: red">
                                                    (Termination)
                                                </span>
                                            </td>
                                            <td class="text-right ng-binding">
                                                <span v-show="item.isTermination" v-on:click="seeETPEarning(item)"><i class="icon-feather-help-circle text-primary fs-14 pointer box-title etp-earning"></i></span>
                                                ${{roundNumber(item.earnings + item.terminationPay + item.lumSumD + item.etpCodeR + item.etpTypeO)}}
                                            </td>
                                            <td class="text-right">
                                                <span v-show="item.isTermination" v-on:click="seeETPTax(item)"><i class="icon-feather-help-circle text-primary fs-14 pointer box-title etp-tax"></i></span>
                                                <div class="input-no-line">
                                                    <currency-input v-model="item.temporaryTax" v-on:change="onChangeTax(item)" locale='en' currency= 'USD' class="text-right ng-pristine ng-untouched ng-valid ng-not-empty ng-valid-step" style="max-width: 80px;"/>
                                                </div>
                                            </td>
                                            <td class="text-right ng-binding">${{roundNumber(runPayRoll.getDeduction(item))}}</td>
                                            <td class="text-right ng-binding">${{roundNumber(runPayRoll.getNetPay(item))}}</td>
                                            <td class="text-right ng-binding">${{roundNumber(runPayRoll.getamountPaid(item))}}</td>
                                            <td class="text-right ng-binding">${{roundNumber(item.super)}}</td>
                                            <td class="text-center">
                                                <!-- <input type="checkbox" disabled :checked="item.eofyFinal" class="ng-pristine ng-untouched ng-valid ng-empty"> -->
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr class="tr-total">
                                            <td class="bold">Total</td>
                                            <td></td>
                                            <td class="text-right ng-binding bold">${{roundNumber(responseReview.totalEarnings)}}</td>
                                            <td class="text-right ng-binding bold">${{roundNumber(runPayRoll.getTotalTax(listReviewPayroll))}}</td>
                                            <td class="text-right ng-binding bold">${{roundNumber(runPayRoll.getTotalDeduction(listReviewPayroll))}}</td>
                                            <td class="text-right ng-binding bold">${{roundNumber(runPayRoll.getTotalNetPay(listReviewPayroll))}}</td>
                                            <td class="text-right ng-binding bold">${{roundNumber(runPayRoll.getTotalAmountPaid(listReviewPayroll))}}</td>
                                            <td class="text-right ng-binding bold">${{roundNumber(responseReview.totalSuper)}}</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row fade">
                                <div class="col-12 text-panel-show-right">
                                    <a class="btn btn-light btn-medium " style="background: white" v-on:click="nextTabsAdjust()">PREVIOUS</a>
                                    <button class="btn btn-primary btn-medium mr-lg-3 mr-xl-0 ng-binding" v-on:click="updateRunPayroll()">UPDATE</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Button Next And Cancel -->
                    <div class='row' v-show="runPayrollStage == 'Adjust'">
                        <div class="ly-button">
                            <button v-on:click="goToRunOverview()" class="btn btn-dark">Back <img class="icon-button" src="/assets/img/icon_cancel.png" /></button>
                            <button v-bind:disabled="this.numberCheck > 0 ? false : true" v-on:click="reviewRunPayroll()" class="btn btn-primary">Next <img class="icon-button" src="/assets/img/icon_next.png" /></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import {
    REVIEW_RUNPAYROLL,
    GET_EMPLOYEE,
    GET_DETAIL_PAYROLL,
    UPDATE_RUNPAYROLL
} from "@/store/actionsType";

import DownloadABAFileModal from './modal/DownloadABAFileModal'
import PaymentDetailModal from './modal/paymentDetailModal'
import ViewEmployeeModal from './modal/ViewEmployeeModal'
import * as Utils from "@/utils/Utils.js"
import * as Session from "@/utils/SessionStore"
import * as RunPayRoll from './RunPayRoll'
import log4javascript from 'log4javascript';

export default {
    name: "UpdateRunPayroll",
    props: [],
    components: {
        DownloadABAFileModal,
        PaymentDetailModal,
        ViewEmployeeModal,
    },
    data() {
        return {
            utils: Utils,
            runPayRoll: RunPayRoll,
            runPayrollStage: "Adjust",
            currentEmployees: "",
            currentIndex: 0,
            numberCheck: 0,
            // run Payroll
            responseRunPayroll: "",
            listRunPayroll: [],
            deletedEmployeeIds: [],
            // Review run payroll
            responseReview: "",
            listReviewPayroll: [],
            annualLeaveAccrued: 0,
            sickLeaveAccrued: 0,
            tax: 0,
            listDataBackUp: [],
            // Payment
            listBonous: [],
            listExtraHours: [],
            listAllowance: [],
            listDeduction: [],
            listTermination: [],
        };
    },
    created: function () {
        this.$logger = log4javascript.getLogger("UpdateRunPayRoll");
        this.$root.$refs.UpdateRunPayroll = this;
        var payrollId = this.$route.query.payrollId;
        this.detailPayroll(payrollId)
    },
    methods: {
        // CALL API
        getListEmployee(id) {
            this.$logger.debug(`getListEmployee(${id})`);
            if (Session.has("currenCompanyId")) {
                this.$root.$refs.AppCyrus.start()
                this.$store.dispatch(GET_EMPLOYEE, Session.get("currenCompanyId"))
                    .then((response) => {
                        this.$root.$refs.AppCyrus.finish()
                        var listEmployees = response
                        for (var i = 0; i < listEmployees.length; i++) {
                            if (id == listEmployees[i].id) {
                                this.$modal.show('modal-viewEmployee', {
                                    employee: listEmployees[i]
                                });
                                break
                            }
                        }
                    }).catch(() => {
                        this.$root.$refs.AppCyrus.fail()
                    });
            } else {
                if (this.$root.$refs.BaseLayout != undefined) {
                    this.$root.$refs.BaseLayout.logout()
                }
            }
        },

        async detailPayroll(id) {
            try {

                this.$root.$refs.AppCyrus.start()
                this.$logger.debug(`detailPayroll(${id})`);

                const response = await this.$store.dispatch(GET_DETAIL_PAYROLL, id);

                var result = response.payrollDto;
                if (!result || !result.adjustReview) return;

                this.responseRunPayroll = result;
                
                this.listRunPayroll = result.adjustReview || [];
                this.deletedEmployeeIds = response.deletedEmployeeIds || [];

                this.numberCheck = this.listRunPayroll.length - this.deletedEmployeeIds.length
                for (var i = 0; i < this.listRunPayroll.length; i++) {
                    if (this.listRunPayroll[i].activePayroll == 'ACTIVE') {
                        if(this.deletedEmployeeIds && this.deletedEmployeeIds.includes(this.listRunPayroll[i].employeeId))
                        {
                            this.listRunPayroll[i].isactivePayroll = false
                        }
                        else{
                            this.listRunPayroll[i].isactivePayroll = true
                        }
                        this.listRunPayroll[i].ordinaryWorkHoursRound = this.roundNumber(this.listRunPayroll[i].ordinaryWorkHours, "RQ")
                        this.listRunPayroll[i].isChangeWorkHours = false;
                        this.listRunPayroll[i].originWorkHours = this.listRunPayroll[i].ordinaryWorkHours
                        this.listRunPayroll[i].ordinaryPayRateRound = this.roundNumber(this.listRunPayroll[i].ordinaryPayRate, "RQ")
                        this.listRunPayroll[i].isChangePayRate = false;
                        this.listRunPayroll[i].originPayRate = this.listRunPayroll[i].ordinaryPayRate;
                    } else {
                        this.listRunPayroll[i].isactivePayroll = false
                    }
                }
                this.$logger.debug("detailPayroll complete")
            }
            catch(err){
                this.$logger.error(`detailPayroll: ${JSON.stringify(err)}`)
                this.$root.$refs.AppCyrus.fail()
            }
            finally {
                this.$root.$refs.AppCyrus.finish()
            }
        },

        reviewRunPayroll() {
            this.$logger.debug(`reviewRunPayroll`);
            this.$root.$refs.AppCyrus.start()
            for (var j = 0; j < this.listRunPayroll.length; j++) {
                if (this.listRunPayroll[j].isactivePayroll) {
                    this.listRunPayroll[j].activePayroll = 'ACTIVE'
                } else {
                    this.listRunPayroll[j].activePayroll = 'INACTIVE'
                }
                this.listRunPayroll[j].employeeData.superGuarantee = 0
            }

            this.responseRunPayroll.adjustReview = this.listRunPayroll

            this.$store.dispatch(REVIEW_RUNPAYROLL, this.responseRunPayroll)
                .then((response) => {
                    this.$root.$refs.AppCyrus.finish()
                    this.listDataBackUp = []
                    this.runPayrollStage = "Review"
                    this.responseReview = response
                    this.listReviewPayroll = response.adjustReview
                    if (this.listReviewPayroll.length > 0) {
                        for (var i = 0; i < this.listReviewPayroll.length; i++) {
                            this.listReviewPayroll[i].annualLeaveAccrued = this.roundNumber(this.listReviewPayroll[i].annualLeaveAccrued, "RQ")
                            this.listReviewPayroll[i].sickLeaveAccrued = this.roundNumber(this.listReviewPayroll[i].sickLeaveAccrued, "RQ")
                            this.listReviewPayroll[i].isChangeInput = false
                            if(this.listReviewPayroll[i].overiteTax != 0 && this.listReviewPayroll[i].overiteTax != undefined) {
                                this.listReviewPayroll[i].temporaryTax = this.roundNumber(this.listReviewPayroll[i].overiteTax, "RQ")
                            } else {
                                this.listReviewPayroll[i].temporaryTax = this.roundNumber(this.listReviewPayroll[i].tax, "RQ")
                            }
                            this.listReviewPayroll[i].oldTax = this.listReviewPayroll[i].temporaryTax
                            if(this.listReviewPayroll[i].listTermination.length > 0) {
                                this.listReviewPayroll[i].isTermination = true
                            } else {
                                this.listReviewPayroll[i].isTermination = false
                            }
                            if (this.listReviewPayroll[i].activePayroll == 'ACTIVE') {
                                this.listDataBackUp.push({
                                    id: this.listReviewPayroll[i].employeeData.id,
                                    tax: this.listReviewPayroll[i].temporaryTax,
                                    overiteTax: this.listReviewPayroll[i].overiteTax,
                                    annualLeaveAccrued: this.listReviewPayroll[i].annualLeaveAccrued,
                                    sickLeaveAccrued: this.listReviewPayroll[i].sickLeaveAccrued,
                                })
                            }
                        }
                    }
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
        },

        updateRunPayroll() {
            this.$root.$refs.AppCyrus.start()
            this.responseReview.tax = this.runPayRoll.getTotalTax(this.listReviewPayroll)
            this.responseReview.netPay = this.runPayRoll.getTotalNetPay(this.listReviewPayroll)
            this.responseReview.amountPaid = this.runPayRoll.getTotalAmountPaid(this.listReviewPayroll)
            this.responseReview.statusPayroll = 'Done'
            this.$store.dispatch(UPDATE_RUNPAYROLL, this.responseReview)
                .then(() => {
                    this.$root.$refs.AppCyrus.finish()
                    this.$router.push({
                        path: "/payrolls",
                    });
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
        },
        // END CALL API

        //Calculate Earnings and Super for Payroller
        getEarnings() {
            return RunPayRoll.getEarnings(this.currentEmployees, this.listExtraHours, this.listBonous, this.listAllowance, this.listTermination)
        },

        getEarningsTax() {
            return RunPayRoll.getEarningsTax(this.currentEmployees, this.listExtraHours, this.listBonous, this.listAllowance)
        },

        getEarningsSuper() {
            return RunPayRoll.getEarningsSuper(this.currentEmployees, this.listExtraHours, this.listBonous, this.listAllowance)
        },

        getSuper() {
            return RunPayRoll.getSuper(this.currentEmployees, this.listExtraHours, this.listBonous, this.listAllowance)
        },
        //End Calculate Earnings and Super for Payroller

        // PayRoller
        openModalViewMore(index) {
            this.$modal.show('modal-paymentdetail', {
                currentIndex: index,
                currentEmployees: this.listRunPayroll[index],
                startPayPeriod: this.responseRunPayroll.startPayPeriod,
                endPayPeriod: this.responseRunPayroll.endPayPeriod,
            });
        },

        seeSuperDetail(index) {
            this.$modal.show('modal-super-detail', {
                superSalary: this.listRunPayroll[index].employeeData.superSalarySacrifice,
                employerContribution: this.listRunPayroll[index].employeeData.employerContribution,
                superTotal: this.listRunPayroll[index].super
            })
        },

        seeETPEarning(data) {
            this.$modal.show('modal-ETP-earning', {
                etpTypeR: data.etpTypeR,
                lumSumD: data.lumSumD,
                etpCodeR: data.etpCodeR,
                etpTypeO: data.etpTypeO,
                terminationPay: data.terminationPay,
                earnings: data.earnings
            });
        },
        seeETPTax(data) {
            this.$modal.show('modal-ETP-tax', {
                taxETPTypeR: data.taxETPTypeR,
                taxETPTypeO: data.taxETPTypeO,
                taxTerminationPay: data.taxTerminationPay,
                tax: data.temporaryTax
            });
        },

        clickSave(currentEmployees, index) {
            this.currentIndex = index
            this.currentEmployees = currentEmployees

            this.listBonous = this.currentEmployees.listBonous
            this.listExtraHours = this.currentEmployees.listExtraHours
            this.listAllowance = this.currentEmployees.listAllowance
            this.listDeduction = this.currentEmployees.listDeduction
            this.listTermination = this.currentEmployees.listTermination

            this.listRunPayroll[this.currentIndex] = currentEmployees
            this.listRunPayroll[this.currentIndex].earnings = this.getEarnings()
            this.listRunPayroll[this.currentIndex].earningsTax = this.getEarningsTax()
            this.listRunPayroll[this.currentIndex].earningsSuper = this.getEarningsSuper()
            this.listRunPayroll[this.currentIndex].super = this.getSuper()
            this.responseRunPayroll.adjustReview = this.listRunPayroll
        },

        changeEndDate(item, json) {
            var unusedLeave = this.getUnusedLeave(item, json.terminationDate)
            json.unusedLeave = this.truncateNumber(unusedLeave)
        },
        
        getUnusedLeave(item, endDate) {
            var start = new Date(item.startPayPeriod)
            var end = new Date(endDate)
            var day = 0;
            while (start <= end) {
                if (start.getDay() != 0 && start.getDay() != 6) {
                    day += 1
                }
                start.setDate(start.getDate() + 1)
            }
            return (item.ordinaryWorkHours / 5 * day) * 4 / 52 + (item.totalAnnualLeave - item.totalAnnualLeaveUsed)
        },

        truncateNumber(number) {
            return this.utils.truncateNumber(number)
        },

        onChangeWeeklyWorkHours(item, index) {
            if(item.originWorkHours != item.ordinaryWorkHoursRound) {
                item.isChangeWorkHours = true;
            } else {
                item.isChangeWorkHours = false;
            }
            item.ordinaryWorkHours = item.ordinaryWorkHoursRound
            item.ordinaryWorkHoursRound = this.roundNumber(item.ordinaryWorkHours, "RQ")
            if(item.listTermination && item.listTermination.length > 0) {
                item.listTermination.forEach(element => {
                    this.changeEndDate(item, element)
                })
            }
            this.clickSave(item, index)
        },

        resetWorkHours(item, index) {
            item.isChangeWorkHours = false;
            item.ordinaryWorkHoursRound = item.originWorkHours
            this.onChangeWeeklyWorkHours(item, index)
        },

        onChangePayRatePerHour(item, index) {
            if(item.originPayRate != item.ordinaryPayRateRound) {
                item.isChangePayRate = true;
            } else {
                item.isChangePayRate = false;
            }
            item.ordinaryPayRate = item.ordinaryPayRateRound
            item.ordinaryPayRateRound = this.roundNumber(item.ordinaryPayRate, "RQ")
            this.clickSave(item, index)
        },

        resetPayRate(item, index) {
            item.isChangePayRate = false;
            item.ordinaryPayRateRound = item.originPayRate
            this.onChangePayRatePerHour(item, index)
        },

        check(boolean) {
            if (!boolean) {
                this.numberCheck--
            } else {
                this.numberCheck++
            }
        },

        checkAll() {
            if (!this.listRunPayroll) return;
            var boolean = !document.getElementById('checkAll').checked
            for (var i = 0; i < this.listRunPayroll.length; i++) {
                if (boolean != this.listRunPayroll[i].isactivePayroll) {
                    if(this.deletedEmployeeIds && !this.deletedEmployeeIds.includes(this.listRunPayroll[i].employeeId)){
                        this.listRunPayroll[i].isactivePayroll = boolean
                        this.check(this.listRunPayroll[i].isactivePayroll)
                    }
                }
            }
        },

        isDeletedEmployee(employeeId){
            if(this.deletedEmployeeIds && this.deletedEmployeeIds.includes(employeeId))
                return true;
            return false;
        },

        resetData(id) {

            if (!this.listReviewPayroll || this.listReviewPayroll.length === 0) return;
            
            for (var i = 0; i < this.listReviewPayroll.length; i++) 
            {
                const shouldProcess = this.listReviewPayroll[i].activePayroll == 'ACTIVE' && this.listReviewPayroll[i].employeeData.id == id;
                if (!shouldProcess) continue;
                for (var j = 0; j < this.listDataBackUp.length; j++) {
                    if (this.listReviewPayroll[i].employeeData.id == this.listDataBackUp[j].id) 
                    {
                        this.listReviewPayroll[i].temporaryTax = this.listDataBackUp[j].tax;
                        this.listReviewPayroll[i].overiteTax = this.listDataBackUp[j].overiteTax;
                        this.listReviewPayroll[i].annualLeaveAccrued = this.listDataBackUp[j].annualLeaveAccrued;
                        this.listReviewPayroll[i].sickLeaveAccrued = this.listDataBackUp[j].sickLeaveAccrued;
                        this.listReviewPayroll[i].isChangeInput = false
                        break;
                    }
                }   
            }
        },

        //Review Run Pay roll
        onChangeTax(item) {
            for (var i = 0; i < this.listDataBackUp.length; i++) {
                if(this.listDataBackUp[i].id == item.employeeData.id) {
                    if (item.temporaryTax != this.listDataBackUp[i].tax) {
                        item.isChangeInput = true
                        item.overiteTax = item.temporaryTax
                    } else {
                        item.isChangeInput = false;
                        item.overiteTax = this.listDataBackUp[i].overiteTax
                    }
                    break;
                }
            }
        },

        roundNumber(number, style) {
            if (style != undefined && style == 'RQ' && number != 0) {
                return Number(this.utils.roundNumber(number).replace(/,/g, ''))
            } else {
                return this.utils.roundNumber(number)
            }
        },

        nextTabsAdjust() {
            this.runPayrollStage = 'Adjust'
        },

        goToRunOverview() {
            this.$router.push({
                path: "/payrolls",
            });
        },

    },
    computed: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
@import url('/assets/css/runpayroll.css');
@import url("//unpkg.com/element-ui@2.13.1/lib/theme-chalk/index.css");

.has-Tax-Template {
    background: #ffeeba !important;
}

.edit-work {
    text-align: center;
    height: 39px;
}

.edit-rate {
    text-align: center;
    height: 39px;
}

.item-check {
    text-align: center;
    justify-content: center;
}

.payroll-web {
    display: block;
}

.payroll-mobile {
    display: none;
}

@media (max-width: 575px) {
    .edit-work {
        padding-left: 50px;
    }

    .edit-rate {
        padding-right: 50px;
    }

    .payroll-web {
        display: none;
    }

    .payroll-mobile {
        display: block;
    }
}

// .choose-employee {
//     color: #0052cc;
// }

.choose-employee:hover {
    color: #3c73c7;
}
</style>
