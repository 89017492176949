<template>
    <div ui-view autoscroll="true" style="min-height: 100%; background: #FFF8E9;" class="ng-scope disable-scrollbars">

        <div class="company__header">
            <div v-if="currentStep > 1 && currentStep != 6 && currentStep != 6" @click="backStepResgitry()"
                class="signup-goback-container ng-scope">
                <i class="icon-feather-arrow-left" style="color: #3f3f3f;"></i>
            </div>

            <div @click="closePage()" class="btnClose ng-scope">
                <i class="icon-feather-x btnClose box-title close-hover" style="color: #3f3f3f;"></i>
            </div>
        </div>

        <div class='content-box ng-scope mainContent disable-scrollbars' style="padding: 0px !important;">
            <div class="menu-side ng-scope disable-scrollbars">
                <v-app>
                    <v-stepper v-model="currentStep" vertical style="background-color: #FFF8E9 !important;">

                        <!-- step 1: setup company -->
                        <div class="row">
                            <div class="col-sm-4">
                                <v-stepper-step :complete="currentStep > 1" step="1" color="#09b585">
                                    Set up your company
                                    <small>Find your company name or ABN</small>
                                </v-stepper-step>
                            </div>
                            <div class="col-sm-6 text-left" style="margin-top: 20px;">
                                <v-card v-if="currentStep > 1" color="white" class="mb-2" style="padding: 0 0 0 10px;">
                                    <div v-if="dataDetailBusiness != undefined" class="company_select"
                                        style="color: rgba(0, 0, 0, .87);">
                                        <span class="ng-binding text-left"> {{ dataDetailBusiness.entityName }}</span>
                                        <span class="ng-binding text-left" style="font-size: 14px; width: 120px;">
                                            {{ dataDetailBusiness.abn }}</span>
                                        <span class="ng-binding text-center" style="width: 150px">
                                            {{ dataDetailBusiness.addressState }}</span>
                                        <span class="ng-binding text-center" style="width: 150px">{{
                                            dataDetailBusiness.addressPostcode }}</span>
                                    </div>
                                </v-card>
                            </div>
                        </div>
                        <v-stepper-content step="1">
                            <div>
                                <div class="d-flex justify-content-center h-30">
                                    <form v-on:submit.prevent="searchBusiness()" action="" method="post" id="searchbar">
                                        <div class="searchbarCompany" v-bind:class="{ active: isActiveSearch }"
                                            @mouseover="mouseOver">
                                            <input v-model="edtSearch" class="search_inputCompany" type="text" name=""
                                                placeholder="Find your company name or ABN" required>
                                            <button class="search_iconCompany btn"><i
                                                    class="fas fa-search"></i></button>
                                        </div>
                                    </form>
                                </div>
                                <div v-if="listBusiness.length > 0" class="support-index text-center row col-sm-12"
                                    style="margin: 20px auto;">
                                    <div class='table-responsive'>
                                        <table class="table table-padded">
                                            <thead class="">
                                                <tr>
                                                    <th class="">Name</th>
                                                    <th class="">ABN</th>
                                                    <th class="text-center">State</th>
                                                    <th class="text-center ">Post code</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item) in listBusiness" :key="item.abn"
                                                    v-on:click="detailBusiness(item.abn)"
                                                    class="animatedUp ng-scope pointer">
                                                    <td class="nowrap">
                                                        <span class="ng-binding"> {{ item.name }}</span>
                                                    </td>
                                                    <td class="">
                                                        <span class="ng-binding text-left "
                                                            style="font-size: 14px; min-width: 120px;">
                                                            {{ item.abn }}</span>
                                                    </td>
                                                    <td class="text-center" style="min-width: 100px;">
                                                        <span class="ng-binding"> {{ item.state }}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span class="ng-binding">{{ item.postcode }}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div v-show="isShowListCompany && listBusiness.length == 0 && currentStep == 1"
                                    class="not-find-abn">
                                    <b>Sorry! We cannot find this ABN.</b>
                                    <span> The number entered ({{ keySearch }}) is not a valid ABN </span>
                                </div>
                                <el-pagination class="pagination" style="margin-bottom: 20px;"
                                    @current-change="handleCurrentChange" v-show="isShowListCompany && totalPage > 1"
                                    background layout="prev, pager, next" :page-size=pageSize :total=totalPage>
                                </el-pagination>
                            </div>
                        </v-stepper-content>

                        <!-- step 2: basic info -->
                        <div class="row">
                            <div class="col-sm-4">
                                <v-stepper-step :complete="currentStep > 2" step="2" color="#09b585">
                                    Set basic information
                                </v-stepper-step>
                            </div>
                            <div class="col-sm-6 text-left" style="margin-top: 20px;">
                                <v-card v-if="currentStep > 2" color="white" class="mb-2" style="padding: 0 0 0 10px;"
                                    height="60px">
                                    <div class="company_select"
                                        style="color: rgba(0, 0, 0, .87); flex-direction:column;">
                                        <span class="ng-binding text-left"> {{ businessName }}</span>
                                        <span class="ng-binding text-left" style="font-size: 14px;">
                                            {{ edtAddress1 + " " + edtSuburb + " " + edtPostcode + " " + edtState }}
                                        </span>
                                    </div>

                                </v-card>
                            </div>
                        </div>

                        <v-stepper-content step="2">
                            <form v-on:submit.prevent="checkCompanyName()" action="" method="post"
                                class="signup-form fade get-started-container ng-pristine ng-invalid ng-invalid-required">
                                <div class="title text-left mb-4">What's your company information?</div>

                                <input v-model="businessName" maxlength="20"
                                    class="form-control signup-input wow fadeInUp ng-pristine ng-empty ng-invalid ng-invalid-required ng-touched ng-businessName"
                                    data-wow-delay="0.4s" type="text" name="businessName " id="name"
                                    placeholder="Short Company Name" required=""
                                    style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;">
                                <vue-google-autocomplete v-on:placechanged="getAddressData"
                                    v-bind:class="(isCheckFindMyAddress) ? 'disabled-address' : ''" country="au"
                                    class="form-control signup-input mt-3 ng-pristine ng-valid ng-empty ng-touched ng-address"
                                    data-wow-delay="0.4s" type="text" name="address" id="address" placeholder="Address"
                                    required=""></vue-google-autocomplete>
                                <div class="form-check text-left" style="margin: 20px 0px 0px 0px">
                                    <input v-model="isCheckFindMyAddress" @click="disableAddress(0)"
                                        class="form-check-input ng-pristine ng-untouched ng-valid ng-empty"
                                        type="checkbox" value="" id="isCheckFindMyAddress"
                                        ng-model="companyData.le.lsl">
                                    <label class="form-check-label" for="isCheckFindMyAddress">
                                        I am unable to find my address
                                    </label>
                                </div>

                                <div v-show="isCheckFindMyAddress">
                                    <input v-model="edtAddress1"
                                        class="form-control signup-input mt-3 ng-pristine ng-valid ng-empty ng-touched ng-address1"
                                        data-wow-delay="0.4s" type="text" name="address1" id="address-1"
                                        placeholder="Address 1" required=""
                                        style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;">
                                    <div class="row wow fadeInUp" data-wow-delay="0.6s"
                                        style="visibility: visible; animation-delay: 0.6s; animation-name: fadeInUp;">
                                        <div class="col-sm-6">
                                            <vue-google-autocomplete v-model="edtAddress2"
                                                class="form-control signup-input mt-3 ng-pristine ng-valid ng-empty ng-touched"
                                                type="text" name="address2" id="address-2" ng-model="companyData.a_2"
                                                placeholder="Address 2 (Optional)" style=""></vue-google-autocomplete>
                                        </div>
                                        <div class="col-sm-6">
                                            <input v-model="edtSuburb" @blur="disableAddress(1)"
                                                class="form-control signup-input mt-3 ng-pristine ng-valid ng-empty ng-touched ng-suburb"
                                                type="text" name="suburb" placeholder="Suburb" style="">
                                        </div>
                                    </div>
                                    <div class="row wow fadeInUp" data-wow-delay="0.8s"
                                        style="visibility: visible; animation-delay: 0.8s; animation-name: fadeInUp;">
                                        <div class="col-sm-6">
                                            <input v-model="edtPostcode" @blur="disableAddress(1)"
                                                class="form-control signup-input mt-3 ng-pristine ng-untouched ng-valid ng-not-empty ng-postcode"
                                                type="number" name="postcode" ng-model="companyData.a_p"
                                                ng-class="{ 'is-invalid-signup' : companyError.a_p}"
                                                placeholder="Postcode" style="">
                                        </div>
                                        <div class="col-sm-6">
                                            <select @change="onChangeState($event)"
                                                class="form-control signup-input mt-3 ng-pristine ng-valid ng-not-empty ng-touched ng-state"
                                                name="state" style="height: 50px;">
                                                <option :selected=setSelected(item.value) v-for="(item) in listState"
                                                    :key="item.value" v-bind:value="item.value">{{ item.value }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-4">
                                        <button v-if="currentStep > 1 && currentStep != 6 && currentStep != 6"
                                            @click="backStepResgitry()" class="btn btn-block mt-3"
                                            style="background: #b5b4b4; border-color: #b5b4b4" data-wow-delay="1s"
                                            type="button">
                                            <i class="icon-feather-arrow-left back-forth"
                                                style="margin-right: 5px;"></i>
                                            Previous
                                        </button>
                                    </div>
                                    <div class="col-sm-4">
                                        <button class="btn btn btn-warning btn-block mt-3 wow fadeInUp"
                                            @click="skipAddress()" data-wow-delay="1s" type="button">
                                            Skip Address
                                            <i class="icon-feather-arrow-right back-forth"></i>
                                        </button>
                                    </div>
                                    <div class="col-sm-4">
                                        <button class="btn btn btn-primary btn-block mt-3 wow fadeInUp"
                                            data-wow-delay="1s" type="submit">
                                            Next
                                            <i class="icon-feather-arrow-right back-forth"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </v-stepper-content>

                        <!-- step 3: work hours -->
                        <div class="row">
                            <div class="col-sm-4">
                                <v-stepper-step :complete="currentStep > 3" step="3" color="#09b585">
                                    Set work hours per day
                                </v-stepper-step>
                            </div>
                            <div class="col-sm-6 text-left" style="margin-top: 20px;">
                                <v-card v-if="currentStep > 3" color="white" class="mb-2" style="padding: 0 0 0 10px;">
                                    <div class="company_select" style="color: rgba(0, 0, 0, .87);">
                                        <span class="ng-binding text-left"> {{ workHours + " hours per day" }}</span>
                                    </div>

                                </v-card>
                            </div>
                        </div>

                        <v-stepper-content step="3">
                            <div v-show="currentStep == 3" class="fade get-started-container">
                                <div class="signup-form">
                                    <div class="title text-left mb-4">{{ title }}</div>
                                    <div @click="getValuesSelected(currentStep, item.value)"
                                        v-for="(item) in list_question" :key="item.id" :id="'question' + item.id"
                                        class="signup-question-container hoverUp pointer wow fadeInUp"
                                        data-wow-delay="0.2s"
                                        style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;">
                                        {{ item.name }}
                                    </div>
                                    <input v-show="isShowInput" v-model.number="edtOther" placeholder="Other"
                                        type="number" ref="other"
                                        class="form-control signup-input wow fadeInUp mt-3 ng-valid ng-touched ng-not-empty ng-dirty ng-valid-parse"
                                        data-wow-delay="0.4s" required
                                        style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;" />
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <button v-if="currentStep > 1 && currentStep != 6 && currentStep != 6"
                                            @click="backStepResgitry()" class="btn btn-block mt-3"
                                            style="background: #b5b4b4; border-color: #b5b4b4" data-wow-delay="1s"
                                            type="button">
                                            <i class="icon-feather-arrow-left back-forth"
                                                style="margin-right: 5px;"></i>
                                            Previous
                                        </button>
                                    </div>
                                    <div class="col-sm-6">
                                        <button id="submit" class="btn btn btn-primary btn-block mt-3 wow fadeInUp"
                                            data-wow-delay="1s"
                                            @click="getValuesSelected(currentStep, !isShowInput ? 'Other' : '')">
                                            {{ nextButtonLabel }}
                                            <i class="icon-feather-arrow-right back-forth"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </v-stepper-content>

                        <!-- step 4: Select pay period -->
                        <div class="row">
                            <div class="col-sm-4">
                                <v-stepper-step :complete="currentStep > 4" step="4" color="#09b585">
                                    Select pay period
                                </v-stepper-step>
                            </div>
                            <div class="col-sm-6 text-left" style="margin-top: 20px;">
                                <v-card v-if="currentStep > 4" color="white" class="mb-2" style="padding: 0 0 0 10px;">
                                    <div class="company_select" style="color: rgba(0, 0, 0, .87);">
                                        <span class="ng-binding text-left"> {{ payPeriod }}</span>
                                    </div>

                                </v-card>
                            </div>
                        </div>

                        <v-stepper-content step="4">
                            <div v-show="currentStep == 4" class="fade get-started-container">
                                <div class="signup-form">
                                    <div class="title text-left mb-4">{{ title }}</div>
                                    <div @click="getValuesSelected(currentStep, item.value)"
                                        v-for="(item) in list_question" :key="item.id" :id="'question' + item.id"
                                        class="signup-question-container hoverUp pointer wow fadeInUp"
                                        data-wow-delay="0.2s"
                                        style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;">
                                        {{ item.name }}
                                    </div>
                                    <input v-show="isShowInput" v-model.number="edtOther" placeholder="Other"
                                        type="number" ref="other"
                                        class="form-control signup-input wow fadeInUp mt-3 ng-valid ng-touched ng-not-empty ng-dirty ng-valid-parse"
                                        data-wow-delay="0.4s" required
                                        style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;" />
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <button v-if="currentStep > 1 && currentStep != 6 && currentStep != 6"
                                            @click="backStepResgitry()" class="btn btn-block mt-3"
                                            style="background: #b5b4b4; border-color: #b5b4b4" data-wow-delay="1s"
                                            type="button">
                                            <i class="icon-feather-arrow-left back-forth"
                                                style="margin-right: 5px;"></i>
                                            Previous
                                        </button>
                                    </div>
                                    <div class="col-sm-6">
                                        <button id="submit" class="btn btn btn-primary btn-block mt-3 wow fadeInUp"
                                            data-wow-delay="1s"
                                            @click="getValuesSelected(currentStep, !isShowInput ? 'Other' : '')">
                                            {{ nextButtonLabel }}
                                            <i class="icon-feather-arrow-right back-forth"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </v-stepper-content>

                        <!-- step 5: Setup MFA - this step is only required on initial sign up. We do not required this when adding additional company -->
                        <div class="row" v-if="requiredMfaSetup">
                            <div class="col-sm-4">
                                <v-stepper-step :complete="currentStep > 5" step="5" color="#09b585">
                                    Setup MFA
                                </v-stepper-step>
                            </div>
                            <div class="col-sm-6 text-left" style="margin-top: 20px;">
                                <v-card v-if="currentStep > 5" color="white" class="mb-2" style="padding: 0 0 0 10px;">
                                    <div class="company_select" style="color: rgba(0, 0, 0, .87);">
                                        <span class="ng-binding text-left"> complete</span>
                                    </div>
                                </v-card>
                            </div>
                        </div>

                        <v-stepper-content step="5" v-if="requiredMfaSetup">
                            <div v-show="currentStep == 5" class="fade get-started-container">
                                <div class="left-align">
                                    <p><strong>Step 1.</strong> Please download one of the following authentication app
                                        on your mobile device </p>
                                    <ul>
                                        <li><a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                                                target="_blank">Google Authenticator for Android</a> or
                                            <a href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                                                target="_blank">Google Authenticator iPhone</a>
                                        </li>
                                        <li>
                                            <a href="https://play.google.com/store/apps/details?id=com.azure.authenticator"
                                                target="_blank">Microsoft Authenticator for Android</a> or
                                            <a href="https://www.microsoft.com/en-us/p/microsoft-authenticator/9nblgggzmcj6?activetab=pivot:overviewtab"
                                                target="_blank">Microsoft Authenticator for Windows Phone</a>
                                        </li>
                                    </ul>
                                    <p><strong>Step 2.</strong> Scan the QR Code below or enter this key <span
                                            class="badge bg-grey bold">{{ sharedKey }}</span>
                                        into your two factor authenticator app. Spaces and casing do not matter.</p>

                                    <VueQRCodeComponent :text="mfaCode" size="200" error-level="L">
                                    </VueQRCodeComponent>
                                    <p><strong>Step 3.</strong> Once you have scanned the QR code or input the key
                                        above, your two factor authentication
                                        app will provide you with a unique code. Enter the code in the confirmation box
                                        below.</p>

                                    <label for="authenticatorCode" class="bold">Verification Code</label>
                                    <div class="form-group" style="display: flex; align-items: center; gap: 10px">
                                        <input v-model="authenticatorCode" class="form-control ng-signatoryName"
                                            type="text" required name="authenticatorCode" />
                                        <button class="btn btn-primary" @click="verifyCode">Verify</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <button v-if="currentStep > 1 && currentStep != 6 && currentStep != 6"
                                            @click="backStepResgitry()" class="btn btn-block mt-3"
                                            style="background: #b5b4b4; border-color: #b5b4b4" data-wow-delay="1s"
                                            type="button">
                                            <i class="icon-feather-arrow-left back-forth"
                                                style="margin-right: 5px;"></i>
                                            Previous
                                        </button>
                                    </div>
                                    <div class="col-sm-6">
                                        <button id="submit" class="btn btn btn-primary btn-block mt-3 wow fadeInUp"
                                            data-wow-delay="1s"
                                            @click="getValuesSelected(currentStep, !isShowInput ? 'Other' : '')">
                                            {{ nextButtonLabel }}
                                            <i class="icon-feather-arrow-right back-forth"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </v-stepper-content>

                    </v-stepper>
                </v-app>

                <div v-if="currentStep > 5" class="get-started-container fade">
                    <div class="row">
                        <div class="col-sm-6">
                            <button v-if="currentStep != 6 && currentStep != 6" @click="backStepResgitry()"
                                class="btn btn-block mt-3" style="background: #b5b4b4; border-color: #b5b4b4"
                                data-wow-delay="1s" type="button">
                                <i class="icon-feather-arrow-left back-forth" style="margin-right: 5px;"></i>
                                Previous
                            </button>
                        </div>
                        <div class="col-sm-6">
                            <button id="submit" class="btn btn btn-primary btn-block mt-3 wow fadeInUp"
                                data-wow-delay="1s"
                                @click="getValuesSelected(currentStep, !isShowInput ? 'Other' : '')">
                                {{ currentStep == 5 ? 'Finish' : 'Next' }}
                                <i class="icon-feather-arrow-right back-forth"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import * as External from "@/utils/External.js";
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import moment from 'moment'
import {
    SEARCH_BUSINESS,
    DETAIN_BUSINESS,
    CHECK_COMPANY_NAME,
    ADD_COMPANY,
    GET_CODE,
    VERIFY_CODE,
} from "@/store/actionsType";

import log4javascript from 'log4javascript';
import * as Session from "@/utils/SessionStore"
import * as Utils from "@/utils/Utils"

import VueQRCodeComponent from "vue-qrcode-component";

export default {
    name: "Register",
    components: {
        VueGoogleAutocomplete,
        VueQRCodeComponent
    },
    mounted() {
        External.head_link("/assets/css/tt/styleRegister.css");
        External.head_link("/assets/css/tt/animate.min.css");
        External.head_link("/assets/css/tt/style(1)Register.css");
    },
    destroyed() {
        External.del_link("/assets/css/tt/styleRegister.css");
        External.del_link("/assets/css/tt/animate.min.css");
        External.del_link("/assets/css/tt/style(1)Register.css");
    },
    data() {
        return {
            isActiveSearch: false,
            isLoading: false,
            isNotBack: false,

            /** the qr code */
            mfaCode: '',
            
            /** the key user need to scan or mannually entered into their authentication app */
            sharedKey: '',
            
            /** the code user enter from their authentication app */
            authenticatorCode: '',

            /** set to true to show step 5 - setup mfa */
            requiredMfaSetup: false,
            
            /** current step starting from 1 */
            currentStep: 1,

            /** the final step number  */
            finalStep: 5,

            steps: [{
                step: 1,
                name: "Find company"
            },
            {
                step: 2,
                name: "Set basic information"
            },
            {
                step: 3,
                name: "Select work hours per day"
            },
            {
                step: 4,
                name: "Select pay period"
            },
            {
                step: 5,
                name: "Setup MFA"
            }],

            // Step 1
            edtSearch: "",
            keySearch: "",
            listBusiness: [],
            listBusinessFull: [],
            dataDetailBusiness: undefined,
            totalPage: 0,
            pageSize: 12,
            currenPage: 1,
            isShowListCompany: false,
            // Step 2
            businessName: "",
            companyABN: "",
            companyName: "",
            address: "",
            isCheckFindMyAddress: false,
            edtAddress1: "",
            edtAddress2: "",
            edtSuburb: "",
            edtPostcode: "",
            edtState: "",
            listState: [{
                name: "ACT",
                value: "ACT"
            }, {
                name: "NSW",
                value: "NSW"
            }, {
                name: "NT",
                value: "NT"
            }, {
                name: "QLD",
                value: "QLD"
            }, {
                name: "SA",
                value: "SA"
            }, {
                name: "TAS",
                value: "TAS"
            }, {
                name: "VIC",
                value: "VIC"
            }, {
                name: "WA",
                value: "WA"
            }],
            // Step 3, 4
            isShowInput: false,
            isClickOther: false,
            title: "",
            list_question: [],
            edtOther: "",
            workHours: 0,
            payPeriod: ""
        };
    },
    created: function () {
        this.$logger = log4javascript.getLogger("CreateCompany");
        this.isNotBack = this.$route.params.isNotBack ?? false;
        this.requiredMfaSetup = this.$route.params.isRegister ?? false; // indicate that this is a new register 
        this.finalStep = this.requiredMfaSetup ? 5 : 4; // if this is a new register, then the final step is 5, otherwise 4
    },
    methods: {
        closePage() {
            if (this.isNotBack) {
                Session.destroy()
                this.$router.push({
                    path: "/login"
                })
            } else {
                this.$router.go(-1)
            }
        },
        getAddressData(addressData) {
            var street_number = ""
            if (addressData.street_number != undefined) {
                street_number = addressData.street_number + " "
            }
            this.edtAddress1 = street_number + addressData.route;
            this.edtSuburb = addressData.locality;
            this.edtPostcode = addressData.postal_code;
            this.edtState = addressData.administrative_area_level_1;
        },
        disableAddress(index) {
            document.getElementsByClassName('pac-container')[index].style.display = "none";

        },

        backStepResgitry() {
            if (this.currentStep == 4 && this.isClickOther) {
                this.isShowInput = true
            }
            this.changeStepRegistry(this.currentStep - 1)
        },
        changeStepRegistry(index) {
            this.currentStep = index;
            
            if (this.currentStep == 3) { // currentStep = 3
                this.title = "Standard Work Hours per day?";
                this.list_question = [{
                    id: 0,
                    name: "7.6 Hours per day",
                    value: "7.6"
                },
                {
                    id: 1,
                    name: "8 Hours per day",
                    value: "8"
                },
                ];
                if (!this.isClickOther) {
                    this.list_question.push({
                        id: 2,
                        name: "Other",
                        value: "Other"
                    })
                }
            } else if (this.currentStep == 4) { // currentStep = 4
                this.title = "What's the pay period?";

                this.list_question = [{
                    id: 0,
                    name: "Weekly",
                    value: "Weekly"
                },
                {
                    id: 1,
                    name: "Fortnightly",
                    value: "Fortnightly"
                },
                {
                    id: 2,
                    name: "Monthly",
                    value: "Monthly"
                },
                ];
            }
            else if (this.currentStep == 5) {
                this.getCode();
            }
        },

        getValuesSelected(index, value) {
            if (index == 3) {
                // here, value is the work hours
                if (value === 'Other') {
                    this.isShowInput = true
                    this.isClickOther = true;
                    this.list_question.splice(2, 2)
                } else {
                    if (value !== '') {
                        this.isShowInput = false
                        this.isClickOther = false;
                        this.edtOther = ""
                        this.list_question.push({
                            id: 2,
                            name: "Other",
                            value: "Other"
                        })
                        this.workHours = Number(value)
                        this.changeStepRegistry(this.currentStep + 1)
                    } else {
                        if (this.validateOther()) {
                            this.isShowInput = false
                            this.workHours = Number(this.edtOther)
                            this.changeStepRegistry(this.currentStep + 1)
                        } else {
                            Utils.toastWarning("Incorrect work hours")
                        }
                    }
                }
            } else if (index == 4) {
                // here, value is the pay period
                if (value === 'Other') {
                    Utils.toastWarning("Please select Pay Period!")
                    return;
                }
                this.payPeriod = value
                if (index === this.finalStep){
                    this.addCompany()
                }
                else {
                    this.changeStepRegistry(this.currentStep + 1)
                }
            } else if (index == 5) {
                this.addCompany()
            }
        },

        setSelected(value) {
            if (value != null && value != undefined) {
                if (value == this.edtState) {
                    return "selected"
                }
            }
            return ""
        },
        nextStep3() {
            this.changeStepRegistry(3)
        },
        gotoDashboard() {
            this.$router.push({
                path: "/dashboard"
            });
        },
        onChangeState(event) {
            this.edtState = event.target.value
        },

        // Request API
        searchBusiness() {
            this.$root.$refs.AppCyrus.start()
            var dataRequest = {
                "keySearch": this.edtSearch,
                "page": "",
                "lenght": ""
            }
            this.keySearch = this.edtSearch
            this.$store.dispatch(SEARCH_BUSINESS, dataRequest)
                .then((response) => {
                    this.listBusiness = []
                    
                    this.isShowListCompany = true;
                    this.listBusinessFull = response.listBusiness
                    if (response.listBusiness != undefined) {
                        this.totalPage = response.listBusiness.length
                    }
                    if (response.listBusiness.length < this.pageSize) {
                        this.pageSize = response.listBusiness.length
                    } else {
                        this.pageSize = 12
                    }
                    for (var i = 0; i < this.pageSize; i++) {
                        this.listBusiness.push(this.listBusinessFull[i])
                    }
                    this.$root.$refs.AppCyrus.finish()
                }).catch((error) => {
                    this.$logger.error(`searchBusiness: ${error}`)
                    this.$root.$refs.AppCyrus.fail()
                });
        },
        handleCurrentChange(page) {
            this.currenPage = page
            this.listBusiness = []
            var from = (this.currenPage - 1) * this.pageSize
            var to = 0
            if (this.pageSize * this.currenPage <= this.totalPage) {
                to = this.pageSize * this.currenPage
            } else {
                to = this.totalPage
            }
            for (var i = from; i < to; i++) {
                this.listBusiness.push(this.listBusinessFull[i])
            }
        },
        detailBusiness(keyAbn) {
            this.$root.$refs.AppCyrus.start()
            this.$store.dispatch(DETAIN_BUSINESS, keyAbn)
                .then((response) => {
                    
                    this.$root.$refs.AppCyrus.finish()
                    this.changeStepRegistry(2)
                    this.dataDetailBusiness = response
                    this.edtPostcode = response.addressPostcode
                    this.edtState = response.addressState
                    this.companyABN = response.abn
                    this.companyName = response.entityName
                }).catch((error) => {
                    this.$logger.error(`detailBusiness: ${error}`)
                    this.$root.$refs.AppCyrus.fail()
                });
        },
        checkCompanyName() {
            //this.listBusiness = []
            this.$root.$refs.AppCyrus.start()
            this.$store.dispatch(CHECK_COMPANY_NAME, this.businessName)
                .then(() => {
                    this.nextStep3()
                    this.$root.$refs.AppCyrus.finish()
                }).catch((error) => {
                    this.$logger.error(`checkCompanyName: ${error}`)
                    this.$root.$refs.AppCyrus.fail()
                });
        },
        skipAddress() {
            if (this.businessName !== '') {
                this.$swal({
                    title: "Skip Address!",
                    text: "I would like to skip address for now and add later.",
                    type: "warning",
                    confirmButtonColor: "#ffcb0c",
                    confirmButtonClass: 'custom_swal',
                    confirmButtonText: "Skip",
                    showCancelButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        // this.edtState = "";
                        //this.edtPostcode = "";
                        this.$store.dispatch(CHECK_COMPANY_NAME, this.businessName)
                            .then(() => {
                                
                                this.nextStep3()
                                this.$root.$refs.AppCyrus.finish()
                            }).catch((error) => {
                                this.$logger.error(`skipAddress - check company name: ${error}`)
                                this.$root.$refs.AppCyrus.fail()
                            });
                        return true;
                    } else {
                        return false;
                    }
                });
            }
            else {
                Utils.toastError("Business name can not be null")
            }
        },
        validateOther() {
            if (this.edtOther != '' && Number(this.edtOther) > 0 && Number(this.edtOther) <= 24) {
                return true
            }
            return false;
        },

        /** add the new company */
        addCompany() {

            this.$root.$refs.AppCyrus.start()
            // Data request

            var companyPayment = {
                "bsb": 0,
                "accountNumber": "",
                "accountName": "",
                "institutionCode": false,
                "apcaUserId": false,
            }

            var companySettings = {
                "workHours": this.workHours,
                "payrollStartDate": new Date(),
                "payPeriod": this.payPeriod,
                "longServiceLeave": false,
                "annualLeaveBalance": false,
                "sickLeaveBalance": false,
            }

            var companySTP = {
                "isEnable": false,
                "providerABN": "70165136033",
                "providerName": "Cyruspay",
                "softwareId": "10703",
                'contactNumber': '',
                "contactEmail": "",
                "branchNumber": 1,
                "signatoryName": "",
                "bmsId": ""
            }

            var dataRequest = {
                "id": 0,
                "businessName": this.businessName,
                "companyABN": this.companyABN,
                "companyName": this.dataDetailBusiness.entityName,
                "address1": this.edtAddress1,
                "address2": this.edtAddress2,
                "suburb": this.edtSuburb,
                "postCode": this.edtPostcode,
                "state": this.edtState,
                "contactNumber": "",
                "contactEmail": "",
                "companyPayment": companyPayment,
                "companySettings": companySettings,
                "companySTP": companySTP,
                "userId": Session.get("Profile").id
            }
            
            this.$store.dispatch(ADD_COMPANY, dataRequest)
                .then(() => {
                    this.$root.$refs.AppCyrus.finish()
                    if (Session.has("currenCompanyId")) {
                        Session.remove("currenCompanyId")
                        Session.remove("currenCompanyName")
                    }
                    if (this.$root.$refs.BaseLayout != undefined) {
                        this.$root.$refs.BaseLayout.clearTimeout();
                    }
                    this.gotoDashboard()
                }).catch((error) => {
                    this.$logger.error(`addCompany: ${error}`)
                    this.$root.$refs.AppCyrus.fail()
                });
        },
        formatDate(date) {
            return moment(date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
        },
        mouseOver() {
            this.isActiveSearch = true
        },

        getCode() {
            this.$root.$refs.AppCyrus.start();
            this.$store
                .dispatch(GET_CODE, {})
                .then((response) => {
                    this.$root.$refs.AppCyrus.finish();
                    this.sharedKey = response.sharedKey;
                    this.mfaCode = response.authenticatorUri;
                })
                .catch((error) => {
                    this.$logger.error(`getCode: ${error}`)
                    this.$root.$refs.AppCyrus.fail();
                });
        },
        verifyCode() {
            this.$root.$refs.AppCyrus.start();
            this.$store
                .dispatch(VERIFY_CODE, this.authenticatorCode)
                .then(() => {
                    this.$root.$refs.AppCyrus.finish();
                    Utils.toastSuccess("Verification code is successful!");
                })
                .catch((error) => {
                    this.$logger.error(`verifyCode: ${error}`)
                    this.$root.$refs.AppCyrus.fail();
                });
        }

    }, // end methods

    computed: {
        nextButtonLabel() {
            return this.currentStep === this.finalStep ? "Finish" : "Next";
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
@import '/assets/css/company.css';
@import '../../../public/assets/css/homePage.css';
@import url("//unpkg.com/element-ui@2.13.1/lib/theme-chalk/index.css");

.pagination {
    justify-content: center;
    text-align: center;
}

.mainContent {
    width: 100%;
    height: 90vh;
    max-width: 950px;
    background-color: #FFF8E9;
    margin: 0 auto;
    position: relative;
}

.left-align {
    text-align: left;
}
</style>
