<template>
    <modal
      class="modal-body"
      name="modal-terminate-employee"
      @before-open="beforeOpen"
      with="auto"
      height="auto">
      <div
        uib-modal-window="modal-window"
        class="modal fade ng-scope ng-isolate-scope show"
        role="dialog"
        aria-labelledby="modal-title"
        aria-describedby="modal-body"
        size="xxl"
        index="0"
        animate="animate"
        ng-style="{&#39;z-index&#39;: 1050 + $topModalIndex*10, display: &#39;block&#39;}"
        tabindex="-1"
        uib-modal-animation-class="fade"
        modal-in-class="show"
        modal-animation="true"
        style="z-index: 1050; display: block; overflow: auto">
        <div class="modal-dialog modal-m">
          <div class="modal-content" uib-modal-transclude="">
            <div
              class="modal-content text-center onboarding-modal modal-centered ng-scope">
              <button
                v-on:click="this.closeModal"
                aria-label="Close"
                class="box-title close"
                data-dismiss="modal"
                type="button"></button>
              <div class="onboarding-slide">
                <div class="onboarding-side-by-side">
                  <div class="headerimg">
                    <img class="img" alt="" src="/assets/img/img_step0.png" />
                  </div>
                  <div class="onboarding-content with-gradient" style="padding-bottom: 0px">
                    <p class="title">Terminate</p>
                    <form class="ng-pristine ng-valid ng-valid-maxlength ng-valid-email" autocomplete="on">
                      <v-app>
                        <div class="row">
                          <div class="col-sm-12" style="margin-bottom: 15px">
                            <div class="form-group">
                              <v-menu
                                v-model="isTerminationCalendarShown"
                                :close-on-content-click="false"
                                nudge-left="400px"
                                origin="top left"
                                min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    :value="computedTerminateDateFormatted"
                                    class="ng-srartDate"
                                    label="* Termination Date"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click:clear="terminationDate = null"
                                    :rules="[rules.required]"
                                    required
                                    outlined
                                    color="success"
                                    append-icon="mdi-calendar">
                                  </v-text-field>
                                </template>
                                <v-date-picker v-model="terminationDate" no-title @change="isTerminationCalendarShown = false"></v-date-picker>
                              </v-menu>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="form-group">
                                <v-select
                                  :menu-props="{ top: true }"
                                  :attach="true"
                                  class="ng-newstate"
                                  v-model="terminationReason"
                                  :items="listTerminationReasons"
                                  item-text="text"
                                  item-value="value"
                                  label="* Termination Reason"
                                  :rules="[rules.required]"
                                  required
                                  outlined
                                  color="success">
                                </v-select>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                                <v-select
                                  :menu-props="{ top: true }"
                                  :attach="true"
                                  class="ng-newstate"
                                  v-model="stpCessationReason"
                                  :items="listStpCessationReasons"
                                  item-text="text"
                                  item-value="value"
                                  label="* STP Cessation Reason"
                                  :rules="[rules.required]"
                                  required
                                  outlined
                                  color="success">
                                </v-select>
                            </div>
                          </div>
                        </div>
                        
                        
                      </v-app>
                    </form>
                  </div>
                </div>
              </div>
              <div style="padding-bottom: 20px; margin-top: 0px">
                <div class="area-2btn text-center">
                  <button type="submit" class="btn btn-warning btn-manually box-title mr-4 ng-scope" @click="handleSubmit">
                    Terminate
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </template>
  
  <script>
  import moment from "moment";
  import ApiManager from "@/api/ApiManager";
  import log4javascript from 'log4javascript';
  import * as eb from "@/utils/EventBus";
  import * as Utils from "@/utils/Utils";

  export default {
    name: "TerminateEmployeeModal",
    props: {},
    components: {},
    created: function() {
        this.$logger = log4javascript.getLogger("TerminateEmployeeModal");
    },
    data() {
      return {
        employeeId: 0,
        terminationDate: null,
        isTerminationCalendarShown: false,
        stpCessationReason: '',
        terminationReason: '',
        listStpCessationReasons: [
          {value: 'Voluntary', text: 'Voluntary Cessation'},
          {value: 'Ill', text: 'Ill Health'},
          {value: 'Deceased', text: 'Deceased'},
          {value: 'Redundancy', text: 'Redundancy'},
          {value: 'Dismissal', text: 'Dismissal'},
          {value: 'Contract', text: 'Contract Cessation'},
          {value: 'Transfer', text: 'Transfer'}
        ],
        listTerminationReasons: [
          {value: 'AER', text: 'Approved Early Retirement'},
          {value: 'CT Termination', text: 'Casual Termination'},
          {value: 'Death', text: 'Death'},
          {value: 'Dismissal', text: 'Dismissal'},
          {value: 'Invalidity', text: 'Invalidity'},
          {value: 'NG-Redundancy', text: 'Non-Genuine Redundancy'},
          {value: 'Redundancy', text: 'Redundancy'},
          {value: 'Resignation', text: 'Resignation'},
          {value: 'Retirement', text: 'Retirement'},
        ],
        rules: {
          required: (value) => !!value || "",
          animal: [(val) => (val || "").length > 0 || "This field is required"],
          nameRules:v => /^[a-zA-Z ]*$/.test(v) || 'Only alphabets',
        },
  
      };
    },
    methods: {
      beforeOpen(e) {
        
        this.employeeId = e.params?.employeeId;
        this.terminationReason = '';
        this.stpCessationReason = '';
        this.terminationDate = e.params?.terminationDate ?? moment().format("YYYY-MM-DD");
      },
      closeModal() {
        this.$modal.hide("modal-terminate-employee");
      },

      async handleSubmit() {
        try
        {  
          if (!this.terminationDate) {
            Utils.toastError("Termination Date is required")
            return;
          }

          if (!this.terminationReason) {
            Utils.toastError("Termination Reason is required")
            return;
          }

          if (!this.stpCessationReason) {
            Utils.toastError("STP Cessation Reason is required")
            return;
          }

          const postData = {
            employeeId: this.employeeId,
            terminationDate: this.terminationDate,
            reason: this.terminationReason,
            stpCessationReason: this.stpCessationReason
          };

          await ApiManager.post(`/api/Employee/TerminateEmployee`, postData);

          // send an event called employeeTerminatedEvent
          eb.EventBus.$emit(eb.EMPLOYEE_TERMINATED_EVENT, postData);

          this.closeModal();
        }
        catch(ex) {
          // ignored
          this.$logger.error(`handleSubmit: ${JSON.stringify(ex)}`)
        }
      },
    },
    computed: {
      computedTerminateDateFormatted() {
        return this.terminationDate ? moment(this.terminationDate).format("DD/MM/YYYY") : "";
      },
    }
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  
  <style scoped>
  .modal-body {
    max-height: calc(100vh - 0px);
    overflow-y: auto;
  }
  </style>
  