<template>
<div class='content-i ng-scope' ui-view='mainContent'>
    <!-- START - modal -->
    <LeaveBalancesModal />
    <PayrollSummary />
    <!-- END - modal -->
    <div class='content-box ng-scope disable-scrollbars'>
        <div class='col-sm-12'>
            <div class='element-wrapper mb-5'>
                <div class="row">
                    <div class="col-md-4">
                        <select class="form-control" v-model="selectFIleReport">
                            <option hidden value="">Choose File</option>
                            <option value="Employee Contacts">Employee Contacts</option>
                            <option value="Employee Remuneration">Employee Remuneration</option>
                            <option value="Leave Balances">Leave Balances</option>
                            <option value="Payroll Activity Summary">Payroll Activity Summary</option>
                            <option value="Payroll Employee Summary">Payroll Employee Summary</option>
                        </select>
                    </div>
                    <div class="col-md-2" style="margin-top: 3px">
                        <button class="btn btn-primary" type="button" @click="viewFileReport">View report</button>
                    </div>
                </div>

                <div class="element-wrapper">
                    <div class="row" style="position: relative;">
                        <h6 style="margin-left: 20px;">Monthly Overview</h6>
                    </div>
                    <div id="inspire" style="background: transparent; margin-bottom: 10px; width: 100%">
                        <v-row style="height: 50px;">
                            <v-col class="d-flex">
                                <v-select :items="dropDownYear" @change="onChange($event)" v-model="select" label="FY" item-text="name" item-value="value" dense outlined style="height: 40px !important;"></v-select>
                                <div style="justify-content: flex-end; display: flex; width: 100%">
                                    <div v-show="isShowColumn" style="right: 5; ">
                                        <div class="text-right">
                                            <v-chip v-for="(item) in listColumn" :key="item.id" class="ma-2" x-small="true" close v-bind:text-color="(item.isActivated) ? 'white' : ''" v-bind:color="(item.isActivated) ? 'green':'grey'" v-bind:outlined="(item.isActivated) ? false:true" @click:close="clickItemcolumn(item)" v-bind:close-icon="(item.isActivated) ? 'mdi-minus-circle' : 'mdi-plus-circle'">
                                                {{item.name}}
                                            </v-chip>
                                        </div>
                                    </div>
                                    <div class="box-title configure-columns">
                                        <v-icon right class="wrench" @click="showChooseColumn(isShowColumn)">mdi-wrench</v-icon>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                </div>

                <div class="table-responsive ng-scope">
                    <div class="element-box-tp">
                        <table class="table table-padded">
                            <thead>
                                <tr>
                                    <th>

                                    </th>
                                    <th v-show="listColumn[0].isActivated">
                                        <span v-on:click="sort('month', false)" class="sort">Date <i :class="(sortKey != 'month') ? 'icon-sort': (reverse) ? 'icon-sort-up':'icon-sort-down'"></i></span>
                                    </th>
                                    <th class="text-right" v-show="listColumn[1].isActivated">
                                        <span v-on:click="sort('earnings', true)" class="sort">Gross <i :class="(sortKey != 'earnings') ? 'icon-sort': (!reverse) ? 'icon-sort-up':'icon-sort-down'"></i></span>
                                    </th>
                                    <th class="text-right" v-show="listColumn[2].isActivated">
                                        <span v-on:click="sort('tax', true)" class="sort">Tax <i :class="(sortKey != 'tax') ? 'icon-sort': (!reverse) ? 'icon-sort-up':'icon-sort-down'"></i></span>
                                    </th>
                                    <th class="text-right" v-show="listColumn[3].isActivated">
                                        <span v-on:click="sort('deduction', true)" class="sort">Deduction <i :class="(sortKey != 'deduction') ? 'icon-sort': (!reverse) ? 'icon-sort-up':'icon-sort-down'"></i></span>
                                    </th>
                                    <th class="text-right" v-show="listColumn[4].isActivated">
                                        <span v-on:click="sort('netPay', true)" class="sort">Net pay <i :class="(sortKey != 'netPay') ? 'icon-sort': (!reverse) ? 'icon-sort-up':'icon-sort-down'"></i></span>
                                    </th>
                                    <th class="text-right" v-show="listColumn[5].isActivated">
                                        <span v-on:click="sort('super', true)" class="sort">Super <i :class="(sortKey != 'super') ? 'icon-sort': (!reverse) ? 'icon-sort-up':'icon-sort-down'"></i></span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr ng-repeat="monthData in monthSummary" class="animatedUp ng-scope">
                                    <td class="bold">
                                        Total
                                    </td>
                                    <td v-show="listColumn[0].isActivated">

                                    </td>
                                    <td class="bold text-right" v-show="listColumn[1].isActivated">
                                        ${{roundNumber(totalEarnings)}}
                                    </td>
                                    <td class="bold text-right" v-show="listColumn[2].isActivated">
                                        ${{roundNumber(totalTax)}}
                                    </td>
                                    <td class="bold text-right" v-show="listColumn[3].isActivated">
                                        ${{roundNumber(totalDeduction)}}
                                    </td>
                                    <td class="bold text-right" v-show="listColumn[4].isActivated">
                                        ${{roundNumber(totalNetPay)}}
                                    </td>
                                    <td class="bold text-right" v-show="listColumn[5].isActivated">
                                        ${{roundNumber(totalSuper)}}
                                    </td>
                                </tr>
                                <tr v-for="(item) in monthlyOverview" :key="item.id" ng-repeat="monthData in monthSummary" class="animatedUp ng-scope">
                                    <td class="nowrap ng-binding">

                                    </td>
                                    <td class="nowrap ng-binding" v-show="listColumn[0].isActivated">
                                        {{formatMonthToString(item.month)}}
                                    </td>
                                    <td class="text-right ng-binding" v-show="listColumn[1].isActivated">
                                        ${{roundNumber(item.earnings + item.termination)}}
                                    </td>
                                    <td class="text-right ng-binding" v-show="listColumn[2].isActivated">
                                        ${{roundNumber(item.tax)}}
                                    </td>
                                    <td class="text-right ng-binding" v-show="listColumn[3].isActivated">
                                        ${{roundNumber(item.deduction)}}
                                    </td>
                                    <td class="text-right ng-binding" v-show="listColumn[4].isActivated">
                                        ${{roundNumber(item.netPay)}}
                                    </td>
                                    <td class="text-right ng-binding" v-show="listColumn[5].isActivated">
                                        ${{roundNumber(item.super)}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import LeaveBalancesModal from '../../home/reports/model/LeaveBalancesModal'
import PayrollSummary from '../../home/reports/model/PayrollSummary'
import * as Utils from "@/utils/Utils.js";
import * as Session from "@/utils/SessionStore"
import ApiManager from "@/api/ApiManager";
import axios from "axios";
import Token from '@/api/Token';
import log4javascript from 'log4javascript';
import {
    GET_INFOR_SUMMARY,

} from "@/store/actionsType";


export default {
    name: "Reports",
    components: {
        LeaveBalancesModal,
        PayrollSummary,
    },
    data() {
        return {
            listPolicy: null,
            sortKey: "",
            reverse: false,
            modelReport: "",
            totalEarnings: 0,
            totalTax: 0,
            totalSuper: 0,
            totalNetPay: 0,
            totalDeduction: 0,
            monthlyOverview: [],
            dropDownYear: [],
            select: this.getcurrentYear(),
            year: this.getcurrentYear(),
            isShowColumn: false,
            listColumn: [{
                    id: 0,
                    isActivated: true,
                    name: 'Date'
                },
                {
                    id: 1,
                    isActivated: true,
                    name: 'Gross'
                },
                {
                    id: 2,
                    isActivated: true,
                    name: 'Tax'
                },
                {
                    id: 3,
                    isActivated: true,
                    name: 'Deduction'
                },
                {
                    id: 4,
                    isActivated: true,
                    name: 'NetPay'
                },
                {
                    id: 5,
                    isActivated: true,
                    name: 'Super'
                },
            ],
            selectFIleReport: ""
        }
    },
    created: function () {
        this.$logger = log4javascript.getLogger("Reports");
        this.$root.$refs.Reports = this;
        this.listPolicy = this.$root.$refs.BaseLayout.listPolicy
        this.createPage()
    },
    methods: {
        createPage() {
            if (Session.has('listColumnReport')) {
                for (var i = 0; i < this.listColumn.length; i++) {
                    this.listColumn[i].isActivated = Session.get('listColumnReport')[i].isActivated
                }
            }

            this.updateDataInfor()

            this.dropDownYear = [{
                    name: this.getcurrentYear() - 2 + " - " + parseInt(this.getcurrentYear()-1),
                    value: this.getcurrentYear() - 2
                },{
                    name: this.getcurrentYear() - 1 + " - " + parseInt(this.getcurrentYear()),
                    value: this.getcurrentYear() - 1
                },
                {
                    name: this.getcurrentYear() + " - " + parseInt(this.getcurrentYear() + 1),
                    value: this.getcurrentYear()
                },
                // {
                //     name: parseInt(this.getcurrentYear() + 1) + " - " + parseInt(this.getcurrentYear() + 2),
                //     value: this.getcurrentYear() + 1
                // },
            ]
            this.selectFIleReport = ""
        },
        clickItemcolumn(item) {
            item.isActivated = !item.isActivated
            Session.set('listColumnReport', this.listColumn)
        },
        showChooseColumn() {
            if (this.isShowColumn) {
                this.isShowColumn = false;
            } else {
                this.isShowColumn = true
            }
        },
        getcurrentYear() {
            var dt = new Date()
            if ((dt.getMonth() + 1) >= 7) {
                var currentYear = dt.getFullYear()
            } else {
                currentYear = dt.getFullYear() - 1
            }
            return parseInt(currentYear)
        },
        onChange(any) {
            this.select = any
            this.getInforSummary()
        },
        sort(column, boolean) {
            if (this.sortKey != column) {
                this.reverse = boolean;
                this.sortKey = column;
            } else {
                this.reverse = !this.reverse;
            }
            Utils.sortListJson(this.monthlyOverview, this.sortKey, this.reverse);
        },
        roundNumber(number) {
            return Utils.roundNumber(number)
        },
        viewPaySlipData(dataPdf, titleName) {
            this.$modal.show('modal-viewPaySlip-data', {
                link: dataPdf,
                titleName: titleName
            });
        },
        viewPaySlipPdf(link, titleName) {
            this.$modal.show('modal-viewPaySlip-pdf', {
                link: link,
                titleName: titleName
            });
        },
        openLeaveBalancesModal() {
            this.$modal.show('modal-Leave-Balances', {

            });
        },
        openPayrollSummary() {
            this.$modal.show('modal-Payroll-Summary', {
                apiName: "openPayrollSummary"
            });
        },
        openEmployeeSummary() {
            this.$modal.show('modal-Payroll-Summary', {
                apiName: "openEmployeeSummary"
            });
        },
        formatDateToString(inputDate) {
            var m_names = new Array("Jan", "Feb", "Mar",
                "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");
            var date = new Date(inputDate);
            //var day = date.getDate();
            var month = date.getMonth();
            var year = date.getFullYear();
            var dateShow = m_names[month] + " " + year;
            //day + "-" +
            return dateShow
        },
        formatMonthToString(month) {
            var m_names = new Array("Jan", "Feb", "Mar",
                "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");
            // var m_names = new Array("January", "February", "March",
            //     "April", "May", "June", "July", "August", "September", "October", "November", "December");
            //var day = date.getDate();
            var year = this.year
            if (month < 7) {
                year++
            }
            var dateShow = m_names[month - 1] + " " + year;
            //day + "-" +
            return dateShow
        },
        updateDataInfor() {
            //getInforSummary
            this.modelReport = Session.get('summary')
            if (this.modelReport != undefined) {
                this.totalEarnings = this.modelReport.totalEarnings + this.modelReport.totalTermination
                this.totalTax = this.modelReport.totalTax
                this.totalSuper = this.modelReport.totalSuper
                this.totalNetPay = this.modelReport.totalNetPay
                this.totalDeduction = this.modelReport.totalDeduction
                this.monthlyOverview = this.modelReport.monthlyOverview
            }
        },
        //Request API
        getInforSummary() {
            this.$root.$refs.AppCyrus.start()
            var endYear = parseInt(this.select) + 1
            var modelRequest = {
                companyId: Session.get("currenCompanyId"),
                fromDate: this.select + "-07-01",
                toDate: endYear + "-06-30",
            }
            this.$store.dispatch(GET_INFOR_SUMMARY, modelRequest)
                .then((response) => {
                    this.modelReport = response
                    this.totalEarnings = this.modelReport.totalEarnings + this.modelReport.totalTermination
                    this.totalTax = this.modelReport.totalTax
                    this.totalSuper = this.modelReport.totalSuper
                    this.totalNetPay = this.modelReport.totalNetPay
                    this.totalDeduction = this.modelReport.totalDeduction
                    this.monthlyOverview = this.modelReport.monthlyOverview
                    this.year = this.select
                    this.$root.$refs.AppCyrus.finish()
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
        },
        gotoPayment() {
            this.$root.$refs.BaseLayout.gotoPayment()
        },
        viewFileReport() {
            switch (this.selectFIleReport) {
                case "Employee Contacts":
                    if(this.listPolicy.reportEmployeeContacts) {
                        this.getEmployeeContacts()
                    } else {
                        this.gotoPayment()
                    }
                    break;
                case "Employee Remuneration":
                    if(this.listPolicy.reportEmployeeRemuneration) {
                        this.getEmployeeRemuneration()
                    } else {
                        this.gotoPayment()
                    }
                    break;
                case "Leave Balances":
                    if(this.listPolicy.reportLeaveBalances) {
                        this.openLeaveBalancesModal()
                    } else {
                        this.gotoPayment()
                    }
                    break;
                case "Payroll Activity Summary":
                    if(this.listPolicy.reportPayrollActivity) {
                        this.openPayrollSummary()
                    } else {
                        this.gotoPayment()
                    }
                    break;
                case "Payroll Employee Summary":
                    if(this.listPolicy.reportPayrollEmployee) {
                        this.openEmployeeSummary()
                    } else {
                        this.gotoPayment()
                    }
                    break;
            }
        },

        openReportPdfInNewTab(linkAPI){
            axios({
                url: linkAPI,
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token.getToken(),
                    handshake: 'Basic ' + Token.getCodeHandShake()
                },
                responseType: 'blob'
                // responseType: "arraybuffer",
                // headers: {
                //     'Accept': 'application/pdf'
                // }
            })
            .then((response) => {
                var file = new Blob([response.data], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                window.open(encodeURI(fileURL));
            })
            .catch((error) => {
                this.$logger.error(`openReportPdfInNewTab: ${error}`)
                if(error.response && error.response.status === 500){
                    if(!ApiManager.isCheckServer) {
                        ApiManager.checkServerIsUpdating()
                        .then((response) => {
                            if(!response.isEnable) {
                                window.location.href = "updateserver";
                            } else {
                                this.isError = true
                            }
                        }).catch(() => {
                            window.location.href = "updateserver";
                        })
                    }
                } else {
                    this.isError = true
                }
            });
        },

        getEmployeeContacts() {
            var linkAPI = ApiManager.getUrlApi() + "/api/Report/EmployeeContacts?companyId=" + Session.get("currenCompanyId")
            this.openReportPdfInNewTab(linkAPI);
        },
        getEmployeeRemuneration() {
            var linkAPI = ApiManager.getUrlApi() + "/api/Report/EmployeeRemuneration?companyId=" + Session.get("currenCompanyId")
            this.openReportPdfInNewTab(linkAPI);
        },

    }
}
</script>

<style scoped>
@import url("//unpkg.com/element-ui@2.13.1/lib/theme-chalk/index.css");

.el-chart-w canvas {
    position: relative;
    z-index: 2;
    max-width: 100%;
}

.header-table {
    background: #6554E0;
    color: white;
    font-weight: bold;
    font-size: 13px;
    line-height: 21px;
}

.table-radius {
    background: white;
    border-radius: 20px 20px 0px 0px;
}

.repoer_1 {
    background: url('../../../../public/assets/img/report_1.png') no-repeat center;
    background-size: 100% 100%;
}

.repoer_2 {
    background: url('../../../../public/assets/img/report_2.png') no-repeat center;
    background-size: 100% 100%;
}

.repoer_3 {
    background: url('../../../../public/assets/img/report_3.png') no-repeat center;
    background-size: 100% 100%;
}

.repoer_4 {
    background: url('../../../../public/assets/img/report_4.png') no-repeat center;
    background-size: 100% 100%;
}

.mdi-close-circle::before {
    margin-top: 10px !important;
}

.wrench {
    margin-bottom: 10px !important;
    border-radius: 50%;
    border: 2px gray solid;
    font-size: 15px;
    padding: 5px;
}

.wrench:hover {
    background: gainsboro;
}
</style>
