import ApiManager from "@/api/ApiManager";
import { ADD_COMPANY, GET_LIST_COMPANY, SEARCH_COMPANY, DETAIL_COMPANY, UPDATE_COMPANY, MOVE_TO_TRASH, RESTORE, DELETE_COMPANY, CHECK_COMPANY_NAME } from "../actionsType.js";

// initial state
const state = {
};

// getters
const getters = {};

// actions
const actions = {
  [ADD_COMPANY](context, request) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/Company/Add", request)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_LIST_COMPANY]() {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Company/List")
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [SEARCH_COMPANY](context, keySearch) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Company/Search?keySearch=" + keySearch)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DETAIL_COMPANY](context, companyId) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Company/Detail?companyId=" + companyId)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_COMPANY](context, modelRequest) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/Company/Update", modelRequest)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [MOVE_TO_TRASH](context, companyId) {
    return new Promise((success, reject) => {
      ApiManager.delete("/api/Company/SaveToDraft?companyId=" + companyId +"&type=INACTIVE")
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [RESTORE](context, companyId) {
    return new Promise((success, reject) => {
      ApiManager.delete("/api/Company/SaveToDraft?companyId=" + companyId +"&type=ACTIVE")
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DELETE_COMPANY](context, companyId) {
    return new Promise((success, reject) => {
      ApiManager.delete("/api/Company/Delete?companyId=" + companyId)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CHECK_COMPANY_NAME](context, companyName) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Company/CheckCompanyName?companyName=" + companyName)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

// mutations
const mutations = {
};

export default {
  state,
  getters,
  actions,
  mutations
};
