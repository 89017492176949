import firebase from 'firebase/app';
import 'firebase/messaging';
import Token from './api/Token';
import log4javascript from 'log4javascript'

const logger = log4javascript.getLogger("pushNotification");

var firebaseUid = '';
var tokenFCM = '';

export default function _initializeFirebaseMessage() {
  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();
    messaging.requestPermission()
      .then(function() {
        return messaging.getToken();
      })
      .then(function(currentToken) {
        logger.debug('TokenFCM = ' + currentToken);
        if (Token.getTokenFCM() != currentToken) {
          tokenFCM = currentToken;
          Token.saveTokenFCM(tokenFCM)
        }
      })
      .catch(function(error) {
        if (error.code === 'messaging/permission-blocked') {
            logger.error('Please Unblock Notification Request Manually');
        }else {
            logger.error('Error: ' + error);
        }
      });

    // Receiving foreground message
    messaging.onMessage(function(payload) {
        logger.debug(`Receiving foreground message: ${payload.data.message}`);
        var sender = JSON.parse(payload.data.message);
        // Customize notification here
        if (sender.data.entities.sender.entity.uid != firebaseUid) {
            var notificationTitle = 'Chat Notification';
            var notificationOptions = {
                body: payload.data.alert,
                icon: sender.data.entities.sender.entity.avatar,
            };
            var notification = new Notification(notificationTitle, notificationOptions);
            notification.onclick = function() {
                notification.close();
            };
        }
    });
  }
}

export function updateFirebaseLoggedInUser(uid) {
  if (firebase.messaging.isSupported()) {
    firebaseUid = uid;
  }
}