import Vue from "vue";

let axiosConfig = {
    headers: {
        "Content-Type": "application/json;charset=UTF-8",
    },
};

const ApiPaymentManager = {
    url: function(endPoint) {
        var urlResult = "https://payment.cyruspay.com.au" + endPoint;
        return urlResult;
    },

    get(endPoint, params) {
        // var json = AES.encrypt({
        //     url: url,
        //     path: window.location.pathname
        // })
        // if (Token.getCodeHandShake() != undefined && arrCallApi.indexOf(json) == -1) {
        //     arrCallApi.push(json)
        return new Promise((success, reject) => {
            Vue.axios
                .get(`${this.url(endPoint)}`, { params: params })
                .then((response) => {
                    success(response);
                    // arrCallApi.splice(arrCallApi.indexOf(json), 1)
                    // success(this.decryptResponse(response, url));
                })
                .catch((error) => {
                    reject(error);
                });
        });
        // }
    },

    post(endPoint, data) {
        return new Promise((success, reject) => {
            Vue.axios
                .post(`${this.url(endPoint)}`, data, axiosConfig)
                .then((response) => {
                    success(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    put(endPoint, data) {
        return new Promise((success, reject) => {
            Vue.axios
                .put(`${this.url(endPoint)}`, data, axiosConfig)
                .then((response) => {
                    success(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    delete(endPoint, data) {
        return new Promise((success, reject) => {
            Vue.axios
                .delete(`${this.url(endPoint)}`, { data: data })
                .then((response) => {
                    success(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

export default ApiPaymentManager;
