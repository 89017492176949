<template>
<modal name="modal-Leave-Balances" @before-open="beforeOpen" with="auto" height="auto">
    <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog" aria-labelledby="modal-title" aria-describedby="modal-body" size="xxl" index="0" animate="animate" ng-style="{&#39;z-index&#39;: 1050 + $topModalIndex*10, display: &#39;block&#39;}" tabindex="-1" uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true" style="display: block; overflow: auto;">
        <div class="modal-dialog modal-l">
            <div class="modal-content" uib-modal-transclude="">
                <div class="modal-content text-center onboarding-modal modal-centered ng-scope">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel" style="text-align:center;">
                                <span style="color:#334152">Leave Balances Summary</span>
                            </h5>
                            <button @click="closeModal()" aria-label="Close" class="box-title close" data-dismiss="modal" type="button"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row text-left mt-3">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label for="">Select employee</label>
                                        <el-autocomplete @focus="clickAutocomplete()" @blur="getBackAutocompete()" class="inline-input" style="width: 100%; !important;" v-model="edtEmployeeName" :fetch-suggestions="querySearch" placeholder="Please Input" @select="handleSelect"></el-autocomplete>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label for="">Effective Date</label>
                                        <date-picker v-model="effectiveDate" type="date" value-type="YYYY-MM-DD" format="DD/MM/YYYY" style="width: 100%;"></date-picker>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="modal-footer">
                            <button class="btn btn-light" data-dismiss="modal" type="button" @click="closeModal()"> Close</button>
                            <button class="btn btn-primary" type="button" @click="getLeaveBalances()"> View</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</modal>
</template>

<script>
import {
    GET_LIST_EMPLOYEE_REPORT,
    // GET_LEAVEBALALCES
} from "@/store/actionsType";
import DatePicker from 'vue2-datepicker';
import ApiManager from '@/api/ApiManager';
import * as Session from "@/utils/SessionStore"
//import * as Utils from "@/utils/Utils.js";
import axios from "axios";
import Token from '@/api/Token';
import log4javascript from 'log4javascript';
import 'vue2-datepicker/index.css';


export default {
    name: "LeaveBalancesModal",
    props: [],
    components: {
        DatePicker
    },
    created: function() {
        this.$logger = log4javascript.getLogger("LeaveBalancesModal");
    },
    data() {
        return {
            listEmployee: [],
            listDropEmployee: [],
            edtEmployeeName: "",
            oldEmployeeName: "",
            employeeName: "",
            employeeSubmit: "",
            effectiveDate: this.getDate(new Date()),
        };
    },
    methods: {
        beforeOpen() {
            this.listEmployee = [],
                this.listDropEmployee = [];
            this.edtEmployeeName = "All"
            this.employeeSubmit = ""
            this.getListEmployeeReport()
        },
        closeModal() {
            this.$modal.hide('modal-Leave-Balances');
        },
        clickAutocomplete() {
            this.oldEmployeeName = this.edtEmployeeName
            this.edtEmployeeName = ""
        },
        getBackAutocompete() {
            var timeout = setTimeout(() => {
                if(this.edtEmployeeName == "") {
                    this.edtEmployeeName = this.oldEmployeeName
                }
                clearTimeout(timeout)
            }, 300);
        },
        querySearch(queryString, cb) {
            var listDrop = this.listDropEmployee;
            queryString = this.edtEmployeeName
            var results = queryString ? listDrop.filter(this.createFilter(queryString)) : listDrop;
            cb(results);
        },
        createFilter(queryString) {
            return (listDropEmployee) => {
                return (listDropEmployee.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        handleSelect(item) {
            for (var i = 0; i < this.listEmployee.length; i++) {
                if (item.employeeId == this.listEmployee[i].employeeId) {
                    this.employeeSubmit = this.listEmployee[i]
                }
            }
        },

        viewPaySlip(dataPdf, titleName) {
            this.$modal.show('modal-viewPaySlip-data', {
                link: dataPdf,
                titleName: titleName
            });
        },
        viewPaySlipPdf(link, titleName) {
            this.$modal.show('modal-viewPaySlip-pdf', {
                link: link,
                titleName: titleName
            });
        },
        getDate(inputDate) {
            var date = new Date(inputDate);
            var day = date.getDate()
            var mount = date.getMonth() + 1
            var year = date.getFullYear()
            if (day.toString().length == 1) {
                day = "0" + day
            }
            if (mount.toString().length == 1) {
                mount = "0" + mount
            }
            return year + "-" + mount + "-" + day
        },
        //Request API
        getListEmployeeReport() {
            this.$root.$refs.AppCyrus.start()
            var request = {
                "companyId": Session.get("currenCompanyId"),
                "typeListEmployee": "LEAVE_BALANCES",
            }
            this.$store.dispatch(GET_LIST_EMPLOYEE_REPORT, request)
                .then((response) => {
                    this.$root.$refs.AppCyrus.finish()
                    this.listEmployee = response
                    this.listDropEmployee.push({
                        "employeeId": 0,
                        "value": "All",
                    })
                    for (var i = 0; i < this.listEmployee.length; i++) {
                        this.listDropEmployee.push({
                            "employeeId": this.listEmployee[i].employeeId,
                            "value": this.listEmployee[i].firstName + " " + this.listEmployee[i].lastName,
                        })
                    }
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
        },
        openReportPdfInNewTab(linkAPI){
            axios({
                url: linkAPI,
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token.getToken(),
                    handshake: 'Basic ' + Token.getCodeHandShake()
                },
                responseType: 'blob'
                // responseType: "arraybuffer",
                // headers: {
                //     'Accept': 'application/pdf'
                // }
            })
            .then((response) => {
                var file = new Blob([response.data], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                window.open(encodeURI(fileURL));
            })
            .catch((error) => {
                this.$logger.error(`openReportPdfInNewTab: ${error}`)
                if(error.response && error.response.status === 500){
                    if(!ApiManager.isCheckServer) {
                        ApiManager.checkServerIsUpdating()
                        .then((response) => {
                            if(!response.isEnable) {
                                window.location.href = "updateserver";
                            } else {
                                this.isError = true
                            }
                        }).catch(() => {
                            window.location.href = "updateserver";
                        })
                    }
                } else {
                    this.isError = true
                }
            });
        },
        getLeaveBalances() {
            this.closeModal()
            var employeeId = 0;
            if (this.employeeSubmit.employeeId != undefined) {
                employeeId = this.employeeSubmit.employeeId
            }
            var request = {
                "companyId": Session.get("currenCompanyId"),
                "employeeId": employeeId,
                "effectiveDate": this.effectiveDate
            }
            var linkAPI = ApiManager.getUrlApi() + "/api/Report/LeaveBalances?companyId=" + request.companyId + "&employeeId=" + request.employeeId + "&effectiveDate=" + request.effectiveDate
            this.openReportPdfInNewTab(linkAPI);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
@import url("//unpkg.com/element-ui@2.13.1/lib/theme-chalk/index.css");
</style>
