<template>
  <div class="content-i ng-scope" ui-view="mainContent" style="">
    <!--------------------
    START - modal
    -------------------->
    <SettingsOrganisationModal />
    <SettingsResetPass />
    <SettingsPaymentFileModal />
    <SettingsAddAccountant />
    <!--------------------
    END - modal
    -------------------->
    <div class="content-box ng-scope disable-scrollbars">
      <div class="row">
        <div class="col-sm-12">
          <div class="element-wrapper mb-3">
            <div class="element-content">
              <el-row :gutter="20">
                <el-col>
                  <div class="grid-content bg-purple">
                    <!-- icons -->
                    <div class="content-left">
                      <!-- organisation icon -->
                      <div class="item-Left">
                        <div class="support-ticket bg-item">
                          <div @click="clickOptions('Organisation')" class=" st-body bg-icon os-dropdown-trigger  menu-setting active" id="Organisation">
                            <img
                              v-bind:class="
                                optionChoose == 'Organisation' ? 'active' : ''
                              "
                              class="icon organisation"
                              src="/assets/img/icon_organisation_not_acti.png"
                            />
                          </div>
                          <div class="text-icon">Organisation</div>
                        </div>
                      </div>

                      <!-- personal icon -->
                      <div class="item-Left">
                        <div class="support-ticket bg-item">
                          <div
                            @click="clickOptions('Personal')"
                            class="
                              st-body
                              bg-icon
                              os-dropdown-trigger
                              menu-setting
                            "
                            id="Personal"
                          >
                            <img
                              v-bind:class="
                                optionChoose == 'Personal' ? 'active' : ''
                              "
                              class="icon personal"
                              src="/assets/img/icon_personal.png"
                            />
                          </div>
                          <div class="text-icon">Personal</div>
                        </div>
                      </div>

                      <!-- STP icon -->
                      <div class="item-Left">
                        <div class="support-ticket bg-item">
                          <div
                            @click="clickOptions('STP')"
                            class="
                              st-body
                              bg-icon
                              os-dropdown-trigger
                              menu-setting
                            "
                            id="STP"
                          >
                            <img
                              v-bind:class="
                                optionChoose == 'STP' ? 'active' : ''
                              "
                              class="icon stp"
                              src="/assets/img/icon_stp.png"
                            />
                          </div>
                          <div class="text-icon">STP</div>
                        </div>
                      </div>

                      <!-- 2FA icon -->
                      <div class="item-Left">
                        <div class="support-ticket bg-item">
                          <div
                            @click="clickOptions('2FA')"
                            class="
                              st-body
                              bg-icon
                              os-dropdown-trigger
                              menu-setting
                            "
                            id="2FA"
                          >
                            <img
                              v-bind:class="
                                optionChoose == '2FA' ? 'active' : ''
                              "
                              class="icon twofa"
                              src="/assets/img/icon_2fa.png"
                            />
                          </div>
                          <div class="text-icon">2FA</div>
                        </div>
                      </div>

                      <!-- Subscription icon -->
                      <div class="item-Left">
                        <div class="support-ticket bg-item">
                          <div
                            @click="clickOptions('Subscription')"
                            class="
                              st-body
                              bg-icon
                              os-dropdown-trigger
                              menu-setting
                            "
                            id="Subscription"
                          >
                            <img
                              v-bind:class="
                                optionChoose == 'Subscription' ? 'active' : ''
                              "
                              class="icon payment"
                              src="/assets/img/icon_employee_payment.png"
                            />
                          </div>
                          <div class="text-icon">Subscription</div>
                        </div>
                      </div>

                      <!-- Invite icon -->
                      <div class="item-Left" v-show="role == 'User'">
                        <div class="support-ticket bg-item">
                          <div
                            @click="clickOptions('Add-ons')"
                            class="
                              st-body
                              bg-icon
                              os-dropdown-trigger
                              menu-setting
                            "
                            id="Add-ons"
                          >
                            <img
                              v-bind:class="
                                optionChoose == 'Add-ons' ? 'active' : ''
                              "
                              class="icon other"
                              src="/assets/img/icon_accountant.png"
                            />
                          </div>
                          <div class="text-icon">Invited</div>
                        </div>
                      </div>

                      <!-- Pay Items icon -->
                      <div class="item-Left">
                        <div class="support-ticket bg-item">
                          <div
                            @click="clickOptions('PayItems')"
                            class=" st-body bg-icon os-dropdown-trigger menu-setting "
                            id="PayItems">
                            <img v-bind:class="optionChoose == 'PayItems' ? 'active' : ''"
                              class="icon payment"
                              src="/assets/img/icon_employee_payment.png"
                            />
                          </div>
                          <div class="text-icon">Pay Items</div>
                        </div>
                      </div>

                      <!-- export icon -->
                      <div class="item-Left">
                        <div class="support-ticket bg-item">
                          <div
                            @click="clickOptions('Export')"
                            class=" st-body bg-icon os-dropdown-trigger menu-setting "
                            id="Export">
                            <i class="fa-sharp fa-solid fa-file-export"></i>
                          </div>
                          <div class="text-icon">Export</div>
                        </div>
                      </div>

                      <!-- import icon -->
                      <div class="item-Left">
                        <div class="support-ticket bg-item">
                          <div
                            @click="clickOptions('Import')"
                            class=" st-body bg-icon os-dropdown-trigger menu-setting "
                            id="Import">
                            <i class="fa-sharp fa-solid fa-file-import"></i>
                          </div>
                          <div class="text-icon">Import</div>
                        </div>
                      </div>

                    </div>
                  </div>
                </el-col>

                <el-col style="margin-top: 20px">
                  <div class="grid-content bg-purple">
                    <div class="content-right">
                      <div v-show="optionChoose == 'Organisation'">
                        <div class="create-company">Set up a company</div>
                        <div class="txt-edit-company">
                          Edit company, payroll and payslip details
                        </div>
                        <el-tabs
                          type="border-card"
                          v-model="activeName"
                          style="margin-top: 10px"
                        >
                          <el-tab-pane label="Company Details" name="first">
                            <div class="row mt-2">
                              <div class="col-md-6">
                                <label class="txt-title-input">
                                  Business Name
                                  <span class="font-weight-normal"
                                    >(Shown in payslips)</span
                                  >
                                  <span class="text-danger">*</span>
                                </label>
                                <input
                                  v-model="currentCompany.businessName"
                                  name="businessName"
                                  type="text"
                                  class="form-control col-sm-12 ng-businessName"
                                  :rules="[rules.nameRules]"
                                />
                              </div>
                              <div class="col-md-6">
                                <label class="txt-title-input"
                                  >Contact number</label
                                >
                                <div class="form-group">
                                  <vue-tel-input
                                    v-model="contactNumber"
                                    class="form-control ng-contactNumber"
                                    :rules="[rules.numberRules]"
                                  ></vue-tel-input>
                                </div>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-6">
                                <label class="txt-title-input">ABN</label>
                                <div class="form-group" style="height: 10px">
                                  {{ currentCompany.companyABN }}
                                </div>
                              </div>
                              <div class="col-md-6">
                                <label class="txt-title-input"
                                  >Company Legal Name</label
                                >
                                <div class="form-group" style="height: 10px">
                                  {{ currentCompany.companyName }}
                                </div>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-6">
                                <label class="txt-title-input"
                                  >Address
                                  <span class="text-danger">*</span></label
                                >
                                <div class="form-group">
                                  <vue-google-autocomplete
                                    country="au"
                                    v-bind:class="
                                      isCheckFindMyAddress
                                        ? 'disabled-address'
                                        : ''
                                    "
                                    types="address"
                                    id="address"
                                    v-on:placechanged="getAddressData"
                                    v-model="address"
                                    name="address"
                                    type="text"
                                    placeholder="Address"
                                    class="form-control ng-address"
                                    required
                                  ></vue-google-autocomplete>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <label class="txt-title-input"
                                  >Email <span class="text-danger"></span
                                ></label>
                                <div class="form-group" style="height: 10px">
                                  {{ currentCompany.contactEmail }}
                                </div>
                              </div>
                            </div>
                            <div class="form-check" style="margin-bottom: 10px">
                              <input
                                v-model="isCheckFindMyAddress"
                                class="
                                  form-check-input
                                  ng-pristine ng-untouched ng-valid ng-empty
                                "
                                type="checkbox"
                                value=""
                                id="isCheckFindMyAddress"
                              />
                              <label
                                class="form-check-label"
                                for="isCheckFindMyAddress"
                              >
                                I am unable to find my address
                              </label>
                            </div>
                            <div v-show="isCheckFindMyAddress">
                              <div class="row mt-2">
                                <div class="col-md">
                                  <label class="txt-title-input"
                                    >Address 1
                                    <span class="text-danger">*</span></label
                                  >
                                  <div class="form-group">
                                    <input
                                      types="address"
                                      id="address1"
                                      v-model="currentCompany.address1"
                                      name="address1"
                                      type="text"
                                      class="form-control ng-address1"
                                      :required="isCheckFindMyAddress"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="row mt-2">
                                <div class="col-md-6">
                                  <label class="txt-title-input"
                                    >Address 2</label
                                  >
                                  <div class="form-group">
                                    <vue-google-autocomplete
                                      id="address2"
                                      v-model="currentCompany.address2"
                                      name="address2"
                                      type="text"
                                      placeholder="Address 2"
                                      class="form-control"
                                    ></vue-google-autocomplete>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <label class="txt-title-input"
                                    >Suburb
                                    <span class="text-danger">*</span></label
                                  >
                                  <input
                                    v-model="currentCompany.suburb"
                                    name="suburb"
                                    type="text"
                                    class="form-control col-sm-12 ng-suburb"
                                    :required="isCheckFindMyAddress"
                                  />
                                </div>
                              </div>
                              <div class="row mt-2">
                                <div class="col-md-6">
                                  <label class="txt-title-input"
                                    >Postcode
                                    <span class="text-danger">*</span></label
                                  >
                                  <div class="form-group">
                                    <input
                                      v-model="currentCompany.postCode"
                                      name="postCode"
                                      type="text"
                                      class="form-control ng-postcode"
                                      :required="isCheckFindMyAddress"
                                      :rules="[rules.numberRules]"
                                    />
                                    <!-- <vue-google-autocomplete  id="postcode" v-on:placechanged="getPostCodeData" v-model="currentCompany.postCode" name="postCode" class="form-control" types="(regions)"></vue-google-autocomplete> -->
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <label class="txt-title-input"
                                    >State
                                    <span class="text-danger">*</span></label
                                  >
                                  <div class="form-group">
                                    <select
                                      id="state"
                                      v-model="currentCompany.state"
                                      class="form-control ng-state"
                                      name="state"
                                      ng-model="companyData.a_s"
                                      style=""
                                    >
                                      <option value="ACT">ACT</option>
                                      <option value="NSW">NSW</option>
                                      <option value="NT">NT</option>
                                      <option value="QLD">QLD</option>
                                      <option value="SA">SA</option>
                                      <option value="TAS">TAS</option>
                                      <option value="VIC">VIC</option>
                                      <option value="WA">WA</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </el-tab-pane>

                          <el-tab-pane label="Payroll Details" name="second">
                            <div class="row mt-2">
                              <div class="col-md-6">
                                <label class="txt-title-input"
                                  >Payroll Start Date<span class="text-danger"
                                    >*</span
                                  ></label
                                >
                                <div class="form-group">
                                  <date-picker
                                    v-model="companySettings.payrollStartDate"
                                    type="date"
                                    value-type="YYYY-MM-DD"
                                    format="DD/MM/YYYY"
                                    style="width: 100%"
                                    required
                                     :rules="[rules.dateRules]"
                                  ></date-picker>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <label class="txt-title-input"
                                  >Pay Period</label
                                >
                                <div class="form-group">
                                  <select
                                    v-model="companySettings.payPeriod"
                                    class="
                                      form-control
                                      ng-pristine
                                      ng-valid
                                      ng-not-empty
                                      ng-touched
                                    "
                                    name="state"
                                    ng-model="companyData.a_s"
                                    style=""
                                  >
                                    <option value="Weekly" selected="selected">
                                      Weekly
                                    </option>
                                    <option value="Fortnightly">
                                      Fortnightly
                                    </option>
                                    <option value="Monthly">Monthly</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-6">
                                <label class="txt-title-input"
                                  >Work Hours
                                  <span class="text-danger">*</span></label
                                >
                                <div class="form-group">
                                  <select
                                    v-model="workHours"
                                    @change="onWorkHoursChange($event)"
                                    name="workHours"
                                    class="
                                      form-control
                                      ng-pristine
                                      ng-valid
                                      ng-not-empty
                                      ng-touched
                                    "
                                    style=""
                                  >
                                    <option value="7.6" selected="selected">
                                      7.6 Hours per day
                                    </option>
                                    <option value="8">8 Hours per day</option>
                                    <option value="Other">Other</option>
                                  </select>
                                </div>
                              </div>
                              <div
                                v-show="this.workHours == 'Other'"
                                class="col-md-6"
                              >
                                <label class="txt-title-input"
                                  >Hours per day<span class="text-danger"
                                    >*</span
                                  ></label
                                >
                                <div class="form-group">
                                  <input
                                    v-model.number="workHoursOther"
                                    type="number"
                                    min="1"
                                    max="10"
                                    maxlength="2"
                                    class="form-control ng-other"
                                    :required="this.workHours == 'Other'"
                                  />
                                </div>
                              </div>
                            </div>
                          </el-tab-pane>

                          <el-tab-pane label="Leave Settings" name="third">
                            <div
                              ng-show="currentTab == 'leave'"
                              class=""
                              style=""
                            >
                              <form
                                name="payslipOptionsForm"
                                class="ng-pristine ng-valid"
                              >
                                <div class="row">
                                  <div class="col-sm-12">
                                    <h6 class="mb-2">Leave Settings</h6>
                                    <div
                                      class="form-check"
                                      style="margin: 5px 0"
                                    >
                                      <input
                                        v-model="
                                          companySettings.longServiceLeave
                                        "
                                        class="
                                          form-check-input
                                          ng-pristine
                                          ng-untouched
                                          ng-valid
                                          ng-empty
                                        "
                                        type="checkbox"
                                        value=""
                                        id="allowLongServiceLeave"
                                        ng-model="companyData.le.lsl"
                                      />
                                      <label
                                        class="form-check-label"
                                        for="allowLongServiceLeave"
                                      >
                                        Allow long service leave (Coming soon)
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                                <div class="row">
                                  <div class="col-sm-12">
                                    <h6 class="mb-2">Shown in payslip</h6>
                                    <div
                                      class="form-check mt-3"
                                      style="margin: 5px 0"
                                    >
                                      <input
                                        v-model="
                                          companySettings.annualLeaveBalance
                                        "
                                        class="
                                          form-check-input
                                          ng-pristine
                                          ng-untouched
                                          ng-valid
                                          ng-not-empty
                                        "
                                        type="checkbox"
                                        value=""
                                        id="annualLeaveBalanceInPayslip"
                                        ng-model="companyData.psl.al"
                                      />
                                      <label
                                        class="form-check-label"
                                        for="annualLeaveBalanceInPayslip"
                                      >
                                        Annual leave balance (Coming soon)
                                      </label>
                                    </div>
                                    <div
                                      class="form-check ng-hide"
                                      style="margin: 5px 0"
                                      ng-show="companyData.le &amp;&amp; companyData.le.lsl"
                                    >
                                      <input
                                        class="
                                          form-check-input
                                          ng-pristine
                                          ng-untouched
                                          ng-valid
                                          ng-empty
                                        "
                                        type="checkbox"
                                        value=""
                                        id="longServiceLeaveBalanceInPayslip"
                                        ng-model="companyData.psl.lsl"
                                      />
                                      <label
                                        class="form-check-label"
                                        for="longServiceLeaveBalanceInPayslip"
                                      >
                                        Long service leave balance
                                      </label>
                                    </div>
                                    <div
                                      class="form-check"
                                      style="margin: 5px 0"
                                    >
                                      <input
                                        v-model="
                                          companySettings.sickLeaveBalance
                                        "
                                        class="
                                          form-check-input
                                          ng-pristine
                                          ng-untouched
                                          ng-valid
                                          ng-empty
                                        "
                                        type="checkbox"
                                        value=""
                                        id="sickLeaveBalanceInPayslip"
                                        ng-model="companyData.psl.sl"
                                      />
                                      <label
                                        class="form-check-label"
                                        for="sickLeaveBalanceInPayslip"
                                      >
                                        Personal/Carer's leave (Sick leave)
                                        balance (Coming soon)
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </el-tab-pane>

                          <el-tab-pane label="Bank Details" name="fourth">
                            <div class="row mt-2">
                              <div class="col-md-6">
                                <label class="txt-title-input">
                                  Account Number
                                  <span class="text-danger">*</span>
                                </label>
                                <v-text-field
                                  v-model="companyPayment.accountNumber"
                                  name="bankName"
                                  class="ng-bank-name"
                                  type="text"
                                  maxlength="9"
                                  :rules="[rules.required,rules.numberRules]"
                                  required
                                  outlined
                                  color="success"
                                ></v-text-field>
                              </div>
                              
                              <div class="col-md-6">
                                <label class="txt-title-input">Account Name</label>
                                <v-text-field
                                  v-model="companyPayment.accountName"
                                  name="bankName"
                                  class="ng-bank-name"
                                  type="text"
                                  maxlength="40"
                                  :rules="[rules.required, rules.nameRules]"
                                  required
                                  outlined
                                  color="success"
                                ></v-text-field>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-6">
                                <label class="txt-title-input">Bank Name</label>
                                <v-text-field
                                  v-model="companyPayment.bankName"
                                  name="bankName"
                                  class="ng-bank-name"
                                  type="text"
                                  maxlength="40"
                                  :rules="[rules.required, rules.nameRules]"
                                  required
                                  outlined
                                  color="success"
                                ></v-text-field>
                              </div>
                              <div class="col-md-6">
                                <label class="txt-title-input">
                                  Bank Abbreviation
                                  <span class="text-danger">*</span>
                                </label>
                                <v-text-field
                                  v-model="companyPayment.bankAbbreviation"
                                  name="bankAbbreviation"
                                  class="ng-first-name"
                                  placeholder="WBC"
                                  type="text"
                                  maxlength="3"
                                  :rules="[rules.required, rules.nameRules]"
                                  required
                                  outlined
                                  color="success"
                                ></v-text-field>
                                <!-- class="form-control col-sm-12 ng-bankAbbreviation" -->
                              </div>
                              
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-6">
                                <label class="txt-title-input">
                                  APCA Number
                                  <span class="text-danger">*</span>
                                </label>
                                <v-text-field
                                  v-model="companyPayment.apcaNumber"
                                  name="aPCA"
                                  class="ng-aPCA"
                                  placeholder=""
                                  type="text"
                                  maxlength="6"
                                  :rules="[rules.required]"
                                  required
                                  outlined
                                  color="success"
                                ></v-text-field>
                              </div>
                              <div class="col-md-6">
                                <label class="txt-title-input"
                                  >BSB (Bank/State/Branch)
                                </label>
                                <v-text-field
                                  v-model="companyPayment.bsb"
                                  name="bsb"
                                  class="ng-first-name"
                                  placeholder="000000"
                                  type="text"
                                  maxlength="6"
                                  :rules="[rules.required,rules.numberRules]"
                                  required
                                  outlined
                                  color="success"
                                ></v-text-field>
                              </div>
                            </div>
                              <div class="row mt-2">
                              <div class="col-md-6">
                                <label class="txt-title-input"
                                  >Employer's Amount withholding tax
                                </label>
                                <v-text-field
                                  v-model="companyPayment.withHoldingTaxAmount"
                                  name="withholdingTax"
                                  class="ng-first-name"
                                  placeholder=""
                                  type="text"
                                  maxlength="7"
                                  :rules="[rules.required,rules.numberRules]"
                                  required
                                  outlined
                                  color="success"
                                ></v-text-field>
                              </div>
                              </div>
                          </el-tab-pane>
                        </el-tabs>
                        <div class="ly-button-setting">
                          
                          <button
                            type="submit"
                            @click="updateCompany()"
                            class="btn btn-primary"
                            style="margin-left: 15px"
                          >
                            Save
                            <img
                              class="icon-button"
                              src="/assets/img/icon_save.png"
                            />
                          </button>
                        </div>
                      </div>
                      <div v-show="optionChoose == 'Personal'">
                        <div class="create-company">
                          Personal Settings
                          <button
                            class="btn btn-transparent ngdo ngdo-bottom-left"
                            ng-dropover="59"
                            ng-dropover-options="{'position': 'bottom-left'}"
                          >
                            <i
                              class="fa fa-ellipsis-v"
                              ng-click="closeDropover()"
                            ></i>
                            <div
                              ng-dropover-contents=""
                              class="ngdo-contents"
                              style="
                                position: absolute;
                                left: 197.312px;
                                top: 48.2969px;
                                display: none;
                              "
                            >
                              <a
                                class="popover-contents text-danger"
                                ng-click="removeAccount()"
                                >Remove this account</a
                              >
                            </div>
                          </button>
                        </div>
                        <div class="row mb-3">
                          <div class="col-8">
                            <div
                              ng-class="{'d-none': newEmailFormView}"
                              style="margin-top: 8px; margin-bottom: 8px"
                              class="ng-binding"
                            >
                              {{ currentCompany.contactEmail }}
                            </div>
                            <input
                              class="
                                form-control
                                ng-pristine ng-untouched ng-valid ng-empty
                                d-none
                              "
                              placeholder="New email address"
                              type="text"
                              ng-class="{'d-none': !newEmailFormView, 'is-invalid' : newEmailError}"
                              ng-model="newEmail"
                              name="new-email"
                            />
                          </div>
                          <div class="col-4 text-right">
                            <div
                              class="
                                btn btn-transparent
                                ng-binding
                                text-primary
                              "
                              ng-class="newEmailFormView ? 'btn-primary' : 'text-primary'"
                              style="margin-top: 3px"
                              ng-click="!newEmailFormView ? newEmailFormView = true : changeEmail()"
                            >
                              Change
                            </div>
                          </div>
                          <div class="first-left" style="margin-top: 20px">
                            <div v-if="firstName != ''">
                              <label class="txt-title-input">First Name</label>
                              <span class="text-danger">*</span>
                              <div class="form-group">
                                <input
                                  v-model="firstName"
                                  name="firstName"
                                  type="text"
                                  class="form-control"
                                />
                              </div>
                              <label class="txt-title-input">Last Name</label>
                              <span class="text-danger">*</span>
                              <div class="form-group">
                                <input
                                  v-model="lastName"
                                  name="lastName"
                                  type="text"
                                  class="form-control"
                                />
                              </div>
                            </div>
                            <div v-else>
                              <label class="txt-title-input">Full Name</label>
                              <span class="text-danger">*</span>
                              <div class="form-group">
                                <input
                                  v-model="lastName"
                                  name="lastName"
                                  type="text"
                                  class="form-control"
                                />
                              </div>
                            </div>
                            <button
                              class="btn btn-outline-danger"
                              style="border: 0"
                              ng-disabled="loading"
                              @click="openSettingsResetPass()"
                            >
                              Reset Password
                            </button>
                            <button
                              class="btn btn-outline-primary"
                              style="border: 0"
                              ng-disabled="loading"
                              @click="enableSecurityQuestion()"
                            >
                              Enable Security Question
                            </button>
                            <button
                              type="submit"
                              class="btn btn-primary"
                              style="margin-left: 15px"
                              @click="updateProfile()"
                            >
                              Save
                              <img
                                class="icon-button"
                                src="/assets/img/icon_save.png"
                              />
                            </button>
                          </div>
                          <!-- <div class="col-4 text-right">
                                                    <div class="col-12 col-lg-4 text-right">

                                                    </div>
                                                </div> -->
                        </div>
                      </div>
                      <div v-show="optionChoose == 'STP'">
                        <div class="employee-modal text-left">
                          <div style="display: flex">
                            <h5>STP Details</h5>
                            <button
                              class="btn btn-primary"
                              @click="enableSTP()"
                              style="margin-left: 20px; height: 30.4px"
                            >
                              {{ !companySTP.isEnable ? "Enable" : "Disable" }}
                            </button>
                          </div>

                          <div class="row">
                            <div class="col-sm-6">
                              <div class="form-group">
                                <label for="">Software Provider Name</label>
                                <div class="bold">
                                  {{ companySTP.providerName }}
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class="form-group">
                                <label for="">Software Provider ABN</label>
                                <div class="bold">
                                  {{ companySTP.providerABN }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <form
                            name="stpForm"
                            ng-show="!company.stp.on || !company.si"
                          >
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label for="">Software ID</label>
                                  <div class="bold ng-binding">
                                    {{ companySTP.softwareId }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <label for=""
                                  >Business Management Software (BMS) ID
                                  <i
                                    class="
                                      icon-feather-help-circle
                                      text-primary
                                    "
                                    ng-click="helpBms()"
                                  ></i
                                ></label>
                                <div class="form-group">
                                  <input
                                    v-model="companySTP.bmsId"
                                    class="
                                      form-control
                                      ng-pristine
                                      ng-untouched
                                      ng-valid
                                      ng-not-empty
                                      ng-valid-maxlength
                                    "
                                    placeholder="XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"
                                    ng-model="bmsi"
                                    name="bms-id"
                                    ng-class="{ 'is-invalid' : stpError.bmsi}"
                                    maxlength="36"
                                  />
                                </div>
                              </div>
                            </div>
                            <h5>Organisation</h5>
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label for="" class="ng-binding">ABN</label>
                                  <div class="bold ng-binding">
                                    {{ currentCompany.companyABN }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label for="">Organisation Name </label>
                                  <div class="bold ng-binding">
                                    {{ currentCompany.companyName }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-sm-6">
                                <label for="branchNumber">Branch Number</label>
                                <div class="form-group">
                                  <input
                                    v-bind:disabled="
                                      !companySTP.isEnable ? false : true
                                    "
                                    v-model.number="companySTP.branchNumber"
                                    class="form-control ng-branchNumber"
                                    placeholder="1"
                                    maxlength="5"
                                    ng-model="stp.bn"
                                    name="branchNumber"
                                  />
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <label for="contactName"
                                  >Signatory Name
                                  <span
                                    helper=""
                                    contents="The signatory name needs to be of a person - this is not the company name. This name can also not contain any numbers or special characters."
                                    ><i
                                      class="
                                        icon-feather-help-circle
                                        text-primary
                                        fs-14
                                        pointer
                                      "
                                      style="vertical-align: middle"
                                      ng-click="getInfo_defaultPayrollLiabilityAccount()"
                                    ></i
                                  ></span>
                                </label>
                                <div class="form-group">
                                  <input
                                    v-bind:disabled="
                                      !companySTP.isEnable ? false : true
                                    "
                                    v-model="companySTP.signatoryName"
                                    class="form-control ng-signatoryName"
                                    type="text"
                                    name="contactName"
                                  />
                                </div>
                              </div>
                            </div>
                            <h5 class="mt-2">Contact</h5>
                            <div class="row">
                              <div class="col-sm-6">
                                <label for="email">Email address</label>
                                <div class="form-group">
                                  <input
                                    v-bind:disabled="
                                      !companySTP.isEnable ? false : true
                                    "
                                    v-model="companySTP.contactEmail"
                                    class="form-control ng-email"
                                    placeholder="someone@company.com"
                                    type="email"
                                    required
                                    name="email"
                                  />
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <label for="">Contact number</label>
                                <div class="form-group">
                                  <input
                                    v-bind:disabled="
                                      !companySTP.isEnable ? false : true
                                    "
                                    v-model="companySTP.contactNumber"
                                    class="form-control ng-contactNumberSTP"
                                    placeholder=""
                                    type="phone"
                                    name="phone"
                                    @input="formatPhoneNumberSTP"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div class="ly-button-setting" style="margin-top: 0px">
                          
                          <button
                            @click="updateCompany()"
                            class="btn btn-primary"
                            style="margin-left: 15px"
                          >
                            Save 
                            <img
                              class="icon-button"
                              src="/assets/img/icon_save.png"
                            />
                          </button>
                        </div>
                      </div>
                      <div v-show="optionChoose == '2FA'">
                        <div v-if="isUse2FA">
                          <div class="employee-modal text-left">
                            <h5>Two-Factor Authentication is registered</h5>
                            <div
                              v-show="isRemove"
                              class="badge bg-yellow text-left"
                              style="
                                color: black;
                                padding: 18px;
                                margin-bottom: 10px;
                                width: 100%;
                              "
                            >
                              <div class="bold" style="margin-bottom: 10px">
                                If you reset your authenticator key your
                                authenticator app will not work until you
                                reconfigure it.
                              </div>
                              <span>
                                This process disables 2FA until you verify your
                                authenticator app. If you do not complete your
                                authenticator app configuration you may lose
                                access to your account.
                              </span>
                            </div>
                          </div>
                          <div
                            class="ly-button-setting"
                            style="margin-top: 0px; text-align: left"
                          >
                            <button
                              @click="removeCode()"
                              class="btn btn-danger"
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                        <div v-else>
                          <div class="employee-modal text-left">
                            <h5>Configure authenticator app</h5>
                            <spam
                              >To use an authenticator app go through the
                              following steps:</spam
                            >
                            <div class="col-sm">
                              <div style="margin-bottom: 10px">
                                1. Download a two-factor authenticator app like
                                Microsoft Authenticator for
                                <a
                                  href="https://www.microsoft.com/en-us/p/microsoft-authenticator/9nblgggzmcj6?activetab=pivot:overviewtab"
                                  target="_blank"
                                  >Windows Phone</a
                                >,
                                <a
                                  href="https://play.google.com/store/apps/details?id=com.azure.authenticator"
                                  target="_blank"
                                  >Android</a
                                >
                                and
                                <a
                                  href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
                                  target="_blank"
                                  >IOS</a
                                >
                                or Google Authenticator for
                                <a
                                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                                  target="_blank"
                                  >Android</a
                                >
                                and
                                <a
                                  href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                                  target="_blank"
                                  >IOS</a
                                >.
                              </div>
                              <div style="margin-bottom: 10px">
                                2. Scan the QR Code or enter this key
                                <span class="badge bg-grey bold">{{
                                  sharedKey
                                }}</span>
                                into your two factor authenticator app. Spaces
                                and casing do not matter.
                              </div>
                              <div
                                class="badge bg-blue bold text-left"
                                style="
                                  padding: 18px;
                                  margin-bottom: 10px;
                                  width: 100%;
                                  height: 50px;
                                "
                              >
                                Learn how to enable QR code generation.
                              </div>
                              <VueQRCodeComponent
                                :text="code"
                                size="200"
                                error-level="L"
                              >
                              </VueQRCodeComponent>
                              <div style="margin-bottom: 10px">
                                3. Once you have scanned the QR code or input
                                the key above, your two factor authentication
                                app will provide you with a unique code. Enter
                                the code in the confirmation box below.
                              </div>
                              <div class="col-sm-6">
                                <label for="contactName" class="bold"
                                  >Verification Code</label
                                >
                                <div class="form-group">
                                  <input
                                    v-model="authenticatorCode"
                                    class="form-control ng-signatoryName"
                                    type="text"
                                    required
                                    name="contactName"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="ly-button-setting"
                            style="margin-top: 0px"
                          >
                            <button
                              @click="verifyCode()"
                              class="btn btn-primary"
                              style="margin-left: 15px"
                            >
                              Verify
                            </button>
                          </div>
                        </div>
                      </div>
                      <div v-show="optionChoose == 'Subscription'" style="position: relative">
                        <Subscription ref="subscription" />
                      </div>
                      <div v-show="optionChoose == 'Add-ons'">
                        <div class="row">
                          <div class="col-3">
                            <button
                              class="btn btn-primary"
                              @click="openSettingsAddAccountant()"
                            >
                              Add accountant
                            </button>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <!-- list data -->
                            <div class="table-responsive">
                              <table class="table table-padded">
                                <thead>
                                  <tr>
                                    <th>
                                      <span
                                        v-on:click="sort('typeActive', false)"
                                        class="sort"
                                        >Status
                                        <i
                                          :class="
                                            sortKey != 'typeActive'
                                              ? 'icon-sort'
                                              : reverse
                                              ? 'icon-sort-up'
                                              : 'icon-sort-down'
                                          "
                                        ></i
                                      ></span>
                                    </th>
                                    <th>
                                      <span
                                        v-on:click="sort('email', false)"
                                        class="sort"
                                        >Email
                                        <i
                                          :class="
                                            sortKey != 'email'
                                              ? 'icon-sort'
                                              : !reverse
                                              ? 'icon-sort-up'
                                              : 'icon-sort-down'
                                          "
                                        ></i
                                      ></span>
                                    </th>
                                    <!-- <th class="text-center">
                                                                        <span v-on:click="sort('type', false)" class="sort" >Type <i :class="(sortKey != 'type') ? 'icon-sort': (reverse) ? 'icon-sort-up':'icon-sort-down'"></i></span>
                                                                    </th>
                                                                    <th class="text-right">
                                                                        <span v-on:click="sort('totalHours', true)" class="sort" >Leave <i :class="(sortKey != 'totalHours') ? 'icon-sort': (!reverse) ? 'icon-sort-up':'icon-sort-down'"></i></span>
                                                                    </th> -->
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="item in listAccountant"
                                    :key="item.id"
                                    :ref="item.id"
                                    class="animatedUp ng-scope"
                                  >
                                    <td style="width: 200px">
                                      <span
                                        v-bind:class="
                                          'badge status ng-binding ' +
                                          (item.typeActive == 'PENDING'
                                            ? 'bg-yellow'
                                            : 'bg-green')
                                        "
                                        >{{ item.status }}</span
                                      >
                                    </td>
                                    <td style="width: 70%">
                                      <span class="ng-binding">{{
                                        item.email
                                      }}</span>
                                      <!-- <span class="smaller lighter ml-0 ng-binding" style="padding-left: 10px;">{{item.payPeriod}}</span> -->
                                    </td>
                                    <!-- <td class="text-center">
                                                                        <div v-bind:class="(item.type == 'Sick')?'badge ng-binding badge-sick':'badge ng-binding badge-annual'">{{item.type}}</div>
                                                                    </td> -->
                                    <!-- <td class="text-right bolder nowrap">
                                                                        <span class="ng-binding">{{item.totalHours}} hours</span>
                                                                    </td> -->
                                    <td
                                      class="bolder nowrap text-center"
                                      style="padding: 10px 0"
                                    >
                                      <button
                                        class="btn btn-md action"
                                        style="color: red"
                                        @click="showPopupDelete(item.email)"
                                        ng-disabled="loading"
                                      >
                                        <i class="icon-feather-trash-2"></i>
                                      </button>
                                    </td>
                                    <!-- <td style="width: 30px" class="text-right bolder nowrap btn">
                                                                        <button style="margin-right: 20px; color: red" v-on:click="showPopupDelete(item.email)">
                                                                            <i class="icon-feather-trash-2"></i>
                                                                        </button>
                                                                    </td> -->
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="bgPagination" v-show="false">
                              <div class="itemPagination bgNotClick">
                                <div id="img-left"></div>
                              </div>
                              <ul class="pagination itemPagination">
                                <li class="bgClick"><span>1</span></li>
                                <li class="bgNotClick"><span>2</span></li>
                                <li class="bgNotClick"><span>3</span></li>
                                <li class="bgNotClick"><span>4</span></li>
                                <li class="bgNotClick"><span>5</span></li>
                              </ul>
                              <div class="itemPagination bgNotClick">
                                <div id="img-right"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-show="optionChoose == 'PayItems'">
                            <div class="create-company">Pay Items</div>
                            <div class="txt-edit-company">
                                Edit pay items
                            </div>
                            <el-tabs type="border-card" v-model="activeName" style="margin-top: 10px">
                                <el-tab-pane label="Earnings" name="first" >
                                   <div class="row">
                                        <div v-for="(item, index) in earnings" :key="item.id" :ref="item.id" class="col-lg-4 col-sm-12 mt-2">
                                            <el-card class="box-card">
                                                <div slot="header" class="clearfix">
                                                    <h6 class="mb-0 text-capitalize" style="display: inline-block">
                                                      {{item.payslipName.toLowerCase() === 'other' ? item.otherName : item.payslipName.toLowerCase().split('_').join(' ')}}
                                                    </h6>
                                                    <i class="el-icon-close" style="float: right;color:red;font-weight: bold; cursor: pointer" @click="removePayItem(item)"></i>
                                                </div>
                                                <div>
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <label class="txt-title-input">
                                                                Payslip name
                                                                <span class="text-danger">*</span>
                                                            </label>
                                                            <input name="payslipName" type="text"  v-model="item.payslipName" @change="checkEarningName()" :class="item.payslipName.split(' ').join('_')" class="form-control payslip_name "/>
                                                        </div>
                                                        <div class="col-lg-12 mt-2">
                                                            <label class="txt-title-input">
                                                                Normal rate Multiplied x
                                                                <span class="text-danger">*</span>
                                                            </label>
                                                            <select name="normalRate" v-model="item.normalRate" class=" form-control">
                                                                <option value="1.0">1.0</option>
                                                                <option value="1.5">1.5</option>
                                                                <option value="2.0">2.0</option>
                                                                <option value="fixed" selected="selected">Fixed</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-lg-12 mt-2">
                                                            <div class="form-check" style="margin: 5px 0">
                                                                <input class="form-check-input" type="checkbox" v-model="item.calculateTax" :id="'calculateTax-'+index" style="margin-top: 0.1rem;"/>
                                                                <label class="form-check-label" :for="'calculateTax-'+index">
                                                                    Calculate Tax
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 mt-2">
                                                            <div class="form-check" style="margin: 5px 0">
                                                                <input class="form-check-input" type="checkbox" v-model="item.calculateSuper" :id="'calculateSuper-'+index" style="margin-top: 0.1rem;"/>
                                                                <label class="form-check-label" :for="'calculateSuper-'+index">
                                                                    Calculate Super
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 mt-2">
                                                            <div class="form-check" style="margin: 5px 0">
                                                                <input class="form-check-input" type="checkbox" v-model="item.leave" :id="'leave-'+index" style="margin-top: 0.1rem;"/>
                                                                <label class="form-check-label" :for="'leave-'+index">
                                                                    Annual/Sick leave
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 mt-2">
                                                            <v-select
                                                              label="ATO Reporting Category"
                                                              :items="atoReportingCategoryEarnings"
                                                              outlined
                                                              v-model="item.atoReportingCategory"
                                                            ></v-select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-card>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div>
                                            <button  @click="addEarning()" type="button" class="btn btn-primary" style="margin-left: 15px">
                                                Add more earning
                                            </button>
                                        </div>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="Allowances" name="second" >
                                    <div class="row">
                                        <div v-for="(item , index) in allowances" :key="item.id" :ref="item.id" class="col-lg-4 col-sm-12 mt-2">
                                            <el-card class="box-card">
                                                <div slot="header" class="clearfix">
                                                    <h6 class="mb-0 text-capitalize" style="display: inline-block">
                                                      {{item.payslipName.toLowerCase() === 'other' ? item.otherName : item.payslipName.toLowerCase().split('_').join(' ')}}
                                                    </h6>
                                                    <i class="el-icon-close" style="float: right;color:red;font-weight: bold; cursor: pointer" @click="removePayItem(item)"></i>
                                                </div>
                                                <div>
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                             <label class="txt-title-input">
                                                                 Payslip name
                                                                <span class="text-danger">*</span>
                                                            </label>
                                                            <select name="payslipName" placeholder="Select Payslip" type="text" v-model="item.payslipName" @change="checkPayslipAllowanceName()" :class="item.payslipName.split(' ').join('_')" class="form-control">
                                                                <option value="CAR">Car</option>
                                                                <option value="TRANSPORT">Transport</option>
                                                                <option value="LAUNDRY">Laundry</option>
                                                                <option value="MEALS">Meals</option>
                                                                <option value="TRAVEL">Travel</option>
                                                                <option value="OTHER">Other</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-lg-12 mt-2">
                                                             <label class="txt-title-input">
                                                                Other name
                                                                <span class="text-danger">*</span>
                                                            </label>
                                                           
                                                            <input name="otherName" type="text" :readonly="item.payslipName !== 'OTHER'" placeholder="Enter other name" :id="'other-'+index" @change="checkPayslipAllowanceOtherName()"  :class="item.otherName ? item.otherName.split(' ').join('_') : ''"  v-model.lazy="item.otherName"   class="form-control otherName"/>
                                                        </div>
                                                        <div class="col-lg-12 mt-2">
                                                            <label class="txt-title-input">
                                                                Normal rate Multiplied x
                                                                <span class="text-danger">*</span>
                                                            </label>
                                                            <select v-model="item.normalRate" name="normalRate" class=" form-control">
                                                                <option value="1.0">1.0</option>
                                                                <option value="1.5">1.5</option>
                                                                <option value="2.0">2.0</option>
                                                                <option value="fixed" selected="selected">Fixed</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-lg-12 mt-2">
                                                            <div class="form-check" style="margin: 5px 0">
                                                                <input class="form-check-input" type="checkbox" v-model="item.calculateTax" id="calculateTax" style="margin-top: 0.1rem;"/>
                                                                <label class="form-check-label" for="calculateTax">
                                                                    Calculate Tax
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 mt-2">
                                                            <div class="form-check" style="margin: 5px 0">
                                                                <input class="form-check-input" type="checkbox" v-model="item.calculateSuper" id="calculateSuper" style="margin-top: 0.1rem;"/>
                                                                <label class="form-check-label" for="calculateSuper">
                                                                    Calculate Super
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 mt-2">
                                                            <div class="form-check" style="margin: 5px 0">
                                                                <input class="form-check-input" type="checkbox" v-model="item.leave" id="leave" style="margin-top: 0.1rem;"/>
                                                                <label class="form-check-label" for="leave">
                                                                    Annual/Sick leave
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 mt-2">
                                                            <v-select
                                                              label="ATO Reporting Category"
                                                              :items="atoReportingCategoryAllowances"
                                                              outlined
                                                              v-model="item.atoReportingCategory"
                                                            ></v-select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-card>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div>
                                            <button  @click="addAllowance()" type="button" class="btn btn-primary" style="margin-left: 15px">
                                                Add more allowance
                                            </button>
                                        </div>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="Deductions" name="third" >
                                    <div class="row">
                                        <div v-for="(item) in deductions" :key="item.id" :ref="item.id" class="col-lg-4 col-sm-12 mt-2">
                                            <el-card class="box-card">
                                                <div slot="header" class="clearfix">
                                                    <h6 class="mb-0 text-capitalize" style="display: inline-block">
                                                      {{item.payslipName.toLowerCase() === 'other' ? item.otherName : item.payslipName.toLowerCase().split('_').join(' ')}}
                                                    </h6>
                                                  <i class="el-icon-close" style="float: right;color:red;font-weight: bold; cursor: pointer" @click="removePayItem(item)"></i>
                                                </div>
                                                <div>
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <label class="txt-title-input">
                                                                Payslip name
                                                                <span class="text-danger">*</span>
                                                            </label>
                                                             <select name="payslipName" placeholder="Select Payslip" type="text" v-model="item.payslipName" @change="checkPayslipDeductionName()" :class="item.payslipName.split(' ').join('_')" class="form-control">
                                                                <option value="UNION_FEES">Union Fees</option>
                                                                <option value="WORKPLACE_GIVING">Workplace Giving</option>
                                                                <option value="SUPER_SALARY_SACRIFICE">Super Salary Sacrifice</option>
                                                                <option value="SUPER_PERSONAL_CONTRIBUTIONS">Super Personal Contributions</option>
                                                                <option value="ETP">Etp</option>
                                                                <option value="OTHER">Other</option>
                                                            </select>
                                                        </div>
                                                         <div class="col-lg-12 mt-2">
                                                             <label class="txt-title-input">
                                                                Other name
                                                                <span class="text-danger">*</span>
                                                            </label>
                                                            <input name="otherName" type="text" :readonly="item.payslipName !== 'OTHER'" @change="checkPayslipDeductionOtherName()" :class="item.otherName ? item.otherName.split(' ').join('_'):''" placeholder="Enter other name" v-model.lazy="item.otherName"  class="form-control"/>
                                                        </div>
                                                        <div class="col-lg-12 mt-2">
                                                            <label class="txt-title-input">
                                                                Normal rate Multiplied x
                                                                <span class="text-danger">*</span>
                                                            </label>
                                                            <select v-model="item.normalRate" name="normalRate" class=" form-control">
                                                                <option value="1.0">1.0</option>
                                                                <option value="1.5">1.5</option>
                                                                <option value="2.0">2.0</option>
                                                                <option value="fixed" selected="selected">Fixed</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-lg-12 mt-2">
                                                          <v-select
                                                              label="ATO Reporting Category"
                                                              :items="atoReportingCategoryDeductions"
                                                              outlined
                                                              v-model="item.atoReportingCategory"
                                                            ></v-select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-card>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div>
                                            <button  @click="addDeduction()" type="button" class="btn btn-primary" style="margin-left: 15px">
                                                Add more deduction
                                            </button>
                                        </div>
                                    </div>
                                </el-tab-pane>
                            </el-tabs>
                            <div class="ly-button-setting">
                                <button  type="submit" @click="addPayItems()" class="btn btn-primary" style="margin-left: 15px">
                                    Save
                                    <img class="icon-button" src="/assets/img/icon_save.png"/>
                                </button>
                            </div>
                      </div>
                      <div v-show="optionChoose == 'Export'" style="padding:10px">
                        <ExportCsv />
                      </div>
                      <div v-show="optionChoose == 'Import'" style="padding:10px">
                        <ImportCsv />
                      </div>
                    </div>

                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const EARNINGS = 'Earnings';
const ALLOWANCES = 'Allowances';
const DEDUCTIONS = 'Deductions';

import SettingsOrganisationModal from "./modal/SettingsOrganisationModal";
import SettingsResetPass from "./modal/SettingsResetPass";
import SettingsPaymentFileModal from "./modal/SettingsPaymentFileModal";
import SettingsAddAccountant from "./modal/SettingsAddAccountant";

import DatePicker from "vue2-datepicker";
import * as DataSelect from "@/utils/DataSelect.js";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import "vue2-datepicker/index.css";
import ApiManager from "@/api/ApiManager";

import * as Validate from "@/utils/Validate.js";
import log4javascript from 'log4javascript';
import * as Utils from "@/utils/Utils";
import * as Session from "@/utils/SessionStore";

import ImportCsv from "@/components/ImportCsv";
import ExportCsv from "@/components/ExportCsv";

import VueQRCodeComponent from "vue-qrcode-component";

import {
  ACCOUNTANTS,
  ADD_ACCOUNTANT_BY_EMAIL,
  DELETE_ACCOUNTANT,
  DETAIL_COMPANY,
  UPDATE_COMPANY,
  CHECK_CODE,
  GET_CODE,
  VERIFY_CODE,
  REMOVE_CODE,
  UPDATE_PROFILE,
} from "@/store/actionsType";

import Subscription from "./Subscription";

export default {
  name: "Settings",
  props: [],
  components: {
    SettingsOrganisationModal,
    SettingsResetPass,
    SettingsPaymentFileModal,
    SettingsAddAccountant,
    DatePicker,
    VueGoogleAutocomplete,
    VueQRCodeComponent,
    Subscription,
    ImportCsv,
    ExportCsv
  },
  data() {
    return {
      optionChoose: "Organisation",
      activeName: "first",
      address: "",
      isCheckFindMyAddress: false,
      currentCompany: "",
      contactNumber: "",
      startDate: "",
      workHours: "",
      workHoursOther: 8,
      companySettings: "",
      companySTP: "",
      isValidate: true,
      isEnable: false,
      firstName: Session.get("Profile").firstName,
      lastName: Session.get("Profile").lastName,
      role: Session.get("Profile").role,
      code: "",
      sharedKey: "",
      authenticatorCode: "",
      isUse2FA: false,
      isRemove: false,
      planSelected: undefined,
      listAccountant: [],
      currentEmail: "",
      sortKey: "",
      earnings: [],
      allowances: [],
      deductions: [],
      reverse: false,
      companyPayment:"",
      timer:null,
      payslipError:false,
      //Validatecompany
      otherName:false,
      isDeductionOtherNameEmpty:false,
      isOtherAllowanceNameEmpty:false,
      settingsPayItems:DataSelect.settingsPayItems,

      atoReportingCategoryEarnings: [
        { text: "Ordinary Time Earnings", value: "ORD" },
        { text: "Overtime Earnings", value: "OT" },
        { text: "Allowance", value: "ALW" },
        { text: "Employment Termination Payment", value: "ETP" },
        { text: "Lump Sum E", value: "E" },
        { text: "Lump Sum W", value: "W" },
        { text: "Bonuses and Commissions", value: "COM" },
        { text: "Directors Fees", value: "DIR" },
        { text: "Paid Parental Leave", value: "PARENTAL" },
        { text: "Workers' Compensation", value: "WORK" },
      ],

      atoReportingCategoryDeductions: [
        { text: "Workplace Giving", value: "GIVING" },
        { text: "Union / Association Fees", value: "UNION" },
        { text: "Other / None", value: "OTHER" },
      ],

      atoReportingCategoryAllowances: [
        { text: "Allowance – accommodation – domestic, amount does not exceed ATO reasonable amount", value: "Not Reported" },
        { text: "Allowance – accommodation – domestic, amount exceeds ATO reasonable amount", value: "RD"},
        { text: "accommodation – overseas, for business purposes", value: "OD,G1"},
        { text: "accommodation – overseas, for private purposes", value: "OD,ND"},
        { text: "car – flat rate", value: "OD,V1"},
        { text: "cents per km - for a car in excess of the ATO rate for business related travel", value: "CD"},
        { text: "cents per km – for a car up to the ATO rate for business related travel", value: "CD"},
        { text: "cents per km – for private travel such as travel between home and work.", value: "CD,ND"},
        { text: "cents per km – for vehicles other than a car such as a motorbike or van.", value: "OD,V1"},
        { text: "equipment", value: "TD"},
        { text: "first aid", value: "KN"},
        { text: "home office equipment", value: "OD,H1"},
        { text: "internet", value: "OD,H1"},
        { text: "laundry", value: "LD"},
        { text: "meals and incidentals", value: "RD"},
        { text: "tools of trade", value: "TD"}
      ],

      rules: {
        required: (value) => !!value || "",
        animal: [(val) => (val || "").length > 0 || "This field is required"],
        isPhone: (val) =>
          (!isNaN(val.replace(/[\s()+]+/g, "")) &&
            ((val.indexOf("0") == 0 && val.replace(/\s+/g, "").length <= 11) ||
              (val.indexOf("+") == 1 && val.replace(/\s+/g, "").length <= 15) ||
              "Phone numbers have a maximum of 11 numbers")) ||
          "phone number must be number",
        nameRules: (v) => /^[a-zA-Z ]*$/.test(v) || "Only alphabets",
         numberRules: (v) =>/^[0-9]*$/.test(v) || "Only numbers",
         dateRules:(v) => /^(1[0-2]|0[1-9])-(3[01]|[12][0-9]|0[1-9])-[0-9]{4}$/.test(v) || "Only Date",
        //min: v => v.length >= 0 || 'Min 8 characters',
        // emailMatch: () => (`The email and password you entered don't match`),
      },
    };
  },
  created: function () {
    this.$logger = log4javascript.getLogger("Settings");
    this.$root.$refs.Setting = this;
    this.createPage();
  },
  mounted: function () {
    if (this.$route.params.tab != undefined) {
      this.clickOptions(this.$route.params.tab);
    } else {
      this.optionChoose = "Organisation";
    }
    let companyId = Session.get("currenCompanyId")
    this.getPayItems(companyId)
  },
  
  methods: {
    
    getPayItems(companyId) {

      const data = {
        allowancesPayItem: [],
        deductionsEmpPayItem: [],
        earningsPayItem: [],
        companyId: companyId
      };

      ApiManager.addPayItems(data).then((response) => {
        if (response.data.allowancesPayItem.length > 0 && response.data.deductionsEmpPayItem.length > 0 && response.data.earningsPayItem.length > 0) {
          this.allowances = response.data.allowancesPayItem
          this.deductions = response.data.deductionsEmpPayItem
          this.earnings = response.data.earningsPayItem
        } else {
          this.settingsPayItems.allowancesPayItem.map((allowance) => {
            return allowance.companyId = companyId;
          })
          this.settingsPayItems.deductionsEmpPayItem.map((deduction) => {
            return deduction.companyId = companyId;
          })
          this.settingsPayItems.earningsPayItem.map((earnings) => {
            return earnings.companyId = companyId;
          })

          let data = { ...this.settingsPayItems, companyId: companyId };

          ApiManager.addPayItems(data).then((response) => {
            this.allowances = response.data.allowancesPayItem
            this.deductions = response.data.deductionsEmpPayItem
            this.earnings = response.data.earningsPayItem
          })
        }

      })
    },

    addPayItems() {

      this.checkError();
      if (this.payslipError) {
        return;
      }

      const data = {
        allowancesPayItem: this.allowances,
        deductionsEmpPayItem: this.deductions,
        earningsPayItem: this.earnings,
        companyId: Session.get("currenCompanyId")
      };

      ApiManager.addPayItems(data).then((response) => {
        this.allowances = response.data.allowancesPayItem
        this.deductions = response.data.deductionsEmpPayItem
        this.earnings = response.data.earningsPayItem
        Utils.toastSuccess("Update PayItems successful!")
      })
    },

    checkEarningName() {
      const duplicateEarnings = this.findDuplicatePayslipNames(this.earnings);
      if (duplicateEarnings.length > 0) {
        const errorMessage = `Earning ${duplicateEarnings[0]} is duplicated`;
        this.payslipError = true;
        
        this.$logger.error(`checkEarningName: ${errorMessage}`)

        Utils.toastError(errorMessage);
      }
    },

    findDuplicatePayslipNames(payitems) {
        const payslipNameCount = payitems.reduce((countMap, payitem) => {
            countMap[payitem.payslipName] = (countMap[payitem.payslipName] || 0) + 1;
            return countMap;
        }, {});

        return Object.keys(payslipNameCount).filter(payslipName => payslipName !== 'OTHER' && payslipNameCount[payslipName] > 1);
    },

    /**
     * Finds duplicate payslip other names in the given payitems array.
     * 
     * @param {Array} payitems - The array of payitems to search for duplicate other names.
     * @returns {Array} - An array of duplicate other names found in the payitems array.
     */
    findDuplicatePayslipOtherNames(payitems) {
      const otherNameCount = payitems.reduce((countMap, payitem) => {
        countMap[payitem.otherName] = (countMap[payitem.otherName] || 0) + 1;
        return countMap;
      }, {});

      return Object.keys(otherNameCount).filter(otherName => otherName && otherName !== '' && otherNameCount[otherName] > 1);
    },

    /**
     * Filters the given payitems array and returns an array of payitems that do not have an "otherName" property.
     *
     * @param {Array} payitems - The array of payitems to filter.
     * @returns {Array} - An array of payitems without an "otherName" property.
     */
    findEmptyOtherName(payitems) {
      return payitems.filter(payitem => (payitem.payslipName === 'OTHER' || payitem.payslipName === '') && (!payitem.otherName || payitem.otherName === ''));
    },

    checkPayslipAllowanceName() {
      const duplicateAllowances = this.findDuplicatePayslipNames(this.allowances);
      if (duplicateAllowances.length > 0) {
        this.payslipError = true;
        const errorMessage = `Allowance ${duplicateAllowances[0]} is duplicated`;
        
        this.$logger.error(`checkPayslipAllowanceName: ${errorMessage}`)
        Utils.toastError(errorMessage);
      }
    },

    checkPayslipAllowanceOtherName() {
      const emptyOtherName = this.findEmptyOtherName(this.allowances);
      this.isOtherAllowanceNameEmpty = emptyOtherName.length > 0;
      if (emptyOtherName.length > 0) {
        this.payslipError = true;
        const errorMessage = `Allowance ${emptyOtherName[0].payslipName} has an empty other name`;
        Utils.toastError(errorMessage);
        return;
      }

      const duplicateAllowanceOthers = this.findDuplicatePayslipOtherNames(this.allowances);
      if (duplicateAllowanceOthers.length > 0) {
        this.payslipError = true;
        const errorMessage = `Allowance other ${duplicateAllowanceOthers[0]} is duplicated`;
        
        Utils.toastError(errorMessage);
      }
    },

    /**
     * Validates the payroll items for any duplicates and sets the 'payslipError' flag accordingly.
     * 
     * The function performs the following checks in order:
     * 1. Checks for duplicate earning names. If any duplicates are found, sets 'payslipError' to true.
     * 2. Checks for duplicate allowance names, but only if 'payslipError' is still false.
     * 3. Checks for duplicate "other" allowance names, but only if 'payslipError' is still false.
     * 4. Checks for duplicate deduction names, but only if 'payslipError' is still false.
     * 5. Checks for duplicate "other" deduction names, but only if 'payslipError' is still false.
     * 
     * If a duplicate is found during any of the checks, an error message is logged and displayed
     * via a toast notification.
     */
    checkError() {

    
      this.payslipError=false;
      this.checkEarningName();
      if (!this.payslipError) {
        this.checkPayslipAllowanceName();
      }
      if (!this.payslipError) {
        this.checkPayslipAllowanceOtherName();
      }
      if (!this.payslipError) {
        this.checkPayslipDeductionName();
      }
      if (!this.payslipError) {
        this.checkPayslipDeductionOtherName();
      }
    },

    async removePayItem(item) {

      // prompt user to confirm
      const name = item.otherName || item.payslipName
      if (!window.confirm(`Are you sure you want to delete ${item.payItemType} ${name}?`)) {
        return;
      }
      
      // remove item from this.deductions
      if (item.payItemType === EARNINGS) {
        this.earnings.splice(this.earnings.indexOf(item), 1);
      }
      else if (item.payItemType === ALLOWANCES) {
        this.allowances.splice(this.allowances.indexOf(item), 1);
      }
      else if (item.payItemType === DEDUCTIONS) {
        this.deductions.splice(this.deductions.indexOf(item), 1);
      }
      
      // remove from server
      if (item.id > 0) {
        await ApiManager.removePayItem(item);
      }
    },

    checkPayslipDeductionName() {
      var duplicateDeductions = this.findDuplicatePayslipNames(this.deductions);
      if (duplicateDeductions.length > 0) {
        this.payslipError = true;
        const errorMessage = `Deduction ${duplicateDeductions[0]} is duplicated`;
        Utils.toastError(errorMessage);
      } 
    },

    checkPayslipDeductionOtherName() {
      const emptyOtherName = this.findEmptyOtherName(this.deductions);
      this.isDeductionOtherNameEmpty = emptyOtherName.length > 0;
      if (emptyOtherName.length > 0) {
        this.payslipError = true;
        const errorMessage = `Deduction ${emptyOtherName[0].payslipName} has an empty other name`;
        Utils.toastError(errorMessage);
        return;
      }

      const duplicateDeductionOthers = this.findDuplicatePayslipOtherNames(this.deductions);
      if (duplicateDeductionOthers.length > 0) {
        this.payslipError = true;
        const errorMessage = `Deduction other ${duplicateDeductionOthers[0]} is duplicated`;
        Utils.toastError(errorMessage);
      }
    },


    sort(column, boolean) {
      if (this.sortKey != column) {
        this.reverse = boolean;
        this.sortKey = column;
      } else {
        this.reverse = !this.reverse;
      }
      Utils.sortListJson(this.listAccountant, this.sortKey, this.reverse);
    },
    createPage() {
      this.getCompany();
    },
    getAddressData: function (addressData) {
      var street_number = "";
      if (addressData.street_number != undefined) {
        street_number = addressData.street_number + " ";
      }
      this.currentCompany.address1 = street_number + addressData.route;
      this.currentCompany.suburb = addressData.locality;
      this.currentCompany.postCode = addressData.postal_code;
      this.currentCompany.state = addressData.administrative_area_level_1;
    },
    getPostCodeData: function (data) {
      this.currentCompany.address1 = "";
      this.currentCompany.suburb = data.locality;
      this.currentCompany.postCode = data.postal_code;
      this.currentCompany.state = data.administrative_area_level_1;
    },
    openSettingsOrganisationModal() {
      this.$modal.show("modal-settingsorganisation", {
        data: "data",
      });
    },
    closeSettingsOrganisationModal() {
      this.$modal.hide("modal-settingsorganisation");
    },
    openSettingsResetPass() {
      this.$modal.show("modal-settingsResetPass", {
        data: "data",
      });
    },
    closeSettingsResetPass() {
      this.$modal.hide("modal-settingsResetPass");
    },
    openSettingsAddAccountant() {
      this.$modal.show("modal-settingsAddAccountant", {
        data: "data",
      });
    },
    closeSettingsAddAccountant() {
      this.$modal.hide("modal-settingsAddAccountant");
    },
    openSettingsPaymentFileModal() {
      this.hidePopupEmployeePaymentOptions();
      this.$modal.show("modal-settingsPaymentFile", {
        data: "data",
      });
    },

    closeSettingsPaymentFileModal() {
      this.$modal.hide("modal-settingsPaymentFile");
    },

    handleClick() {
      //(tab, event)
      if (
        this.companySettings.workHours == 7.6 ||
        this.companySettings.workHours == 8
      ) {
        this.workHours = this.companySettings.workHours;
      } else {
        this.workHours = "Other";
        this.workHoursOther = this.companySettings.workHours;
      }
    },
    onWorkHoursChange(event) {
      this.workHours = event.target.value;
      if (this.workHours == "Other") {
        this.companySettings.workHours = Number(this.workHoursOther);
      } else {
        this.companySettings.workHours = Number(this.workHours);
      }
    },
    clickOptions(option) {
      window.$(".menu-setting.active").removeClass("active");
      var icon = window.$("#" + option);
      this.optionChoose = option;
      icon.addClass("active");
      if (option == "2FA") {
        this.isRemove = false;
        this.checkCode();
      } else if (option == "Add-ons") {
        this.getListAccountant();
      }
    },

    nextPage(url) {
      this.$router.push({
        path: url,
        param: {},
      });
    },

    // Request API
    getCompany() {
      this.$root.$refs.AppCyrus.start();
      if (Session.has("currenCompanyId")) {
        this.$store
          .dispatch(DETAIL_COMPANY, Session.get("currenCompanyId"))
          .then((response) => {
            this.$root.$refs.AppCyrus.finish();
            this.getInfoCompany(response);
          })
          .catch(() => {
            this.$root.$refs.AppCyrus.fail();
          });
      }
    },
    getInfoCompany(response) {
      this.currentCompany = response;
      this.contactNumber = this.currentCompany.contactNumber;
      Session.set("currenCompanyName", this.currentCompany.businessName);
      Session.set("companyABN", this.currentCompany.companyABN);
      this.companySettings = this.currentCompany.companySettings;
      this.handleClick();
      this.companySTP = this.currentCompany.companySTP;
      this.isEnable = this.companySTP.isEnable;
      if(this.currentCompany.address1 && this.currentCompany.suburb) {
        this.address =
        this.currentCompany.address1 +
        ", " +
        this.currentCompany.suburb +
        ", " +
        this.currentCompany.state;
      } else  {
        this.address = this.currentCompany.state
      }
      
        this.companyPayment= this.currentCompany.companyPayment;


       let companyId = this.currentCompany.id
       this.getPayItems(companyId)
    },

    enableSTP() {
      this.checkValidateSTP();
      if (this.isValidate || this.isEnable) {
        this.isEnable = !this.companySTP.isEnable;
        this.updateCompany();
      }
    },
    updateCompany() {
      if (this.workHours == "Other") {
        this.companySettings.workHours = Number(this.workHoursOther);
      } else {
        this.companySettings.workHours = Number(this.workHours);
      }
      this.currentCompany.companyPayment= this.companyPayment;
      this.currentCompany.companySTP = this.companySTP;
      this.currentCompany.companySTP.contactNumber =
        this.companySTP.contactNumber.replace(/[\s()]+/g, "");
      this.currentCompany.companySettings = this.companySettings;
      Session.set("payPeriod", this.companySettings.payPeriod);
      Session.set("payrollStartDate", this.companySettings.payrollStartDate);
      this.checkValidate();
      if (this.isValidate) {
        this.companySTP.isEnable = this.isEnable;
        this.currentCompany.contactNumber = this.contactNumber.replace(
          /\s+/g,
          ""
        );
        this.$root.$refs.AppCyrus.start();
        this.$store
          .dispatch(UPDATE_COMPANY, this.currentCompany)
          .then(() => {
            this.$root.$refs.AppCyrus.finish();
            Session.set("currenCompany", this.currentCompany);
            Utils.toastSuccess("Update company successful!");
          })
          .catch(() => {
            this.$root.$refs.AppCyrus.fail();
          });
      }
    },
    getListAccountant() {
      this.$root.$refs.AppCyrus.start();
      if (Session.has("currenCompanyId")) {
        this.$store
          .dispatch(ACCOUNTANTS, Session.get("currenCompanyId"))
          .then((response) => {
            this.$root.$refs.AppCyrus.finish();
            this.listAccountant = response.listAccountant;
            for (var i = 0; i < response.size; i++) {
              switch (this.listAccountant[i].typeActive) {
                case "PENDING":
                  this.listAccountant[i].status = "Pending";
                  break;
                case "APPROVED":
                  this.listAccountant[i].status = "Approved";
                  break;
              }
            }
          })
          .catch(() => {
            this.$root.$refs.AppCyrus.fail();
          });
      }
    },
    addAccountant(email) {
      this.$modal.hide("modal-settingsAddAccountant");
      var request = {
        callbackUrlLogin: ApiManager.getUrlDomainUI() + "/login",
        callbackUrlRegistry: ApiManager.getUrlDomainUI() + "/register",
        companyId: this.currentCompany.id,
        email: email,
      };
      this.$swal({
        title: "Invitation is sending to email.",
        imageUrl: "assets/img/send-email.gif",
        imageWidth: 400,
        imageHeight: 200,
        showConfirmButton: false,
        padding: 0,
        customClass: {
          header: "not-padding",
          image: "not-margin image-swal",
        },
        didOpen: () => {
          this.$store
            .dispatch(ADD_ACCOUNTANT_BY_EMAIL, request)
            .then(() => {
              this.$swal({
                icon: "success",
                title: "Invitation sent to email!",
              }).then((result) => {
                if (result.isConfirmed) {
                  this.getListAccountant();
                }
              });
            })
            .catch((err) => {
              this.$logger.error(`addAccountant: ${JSON.stringify(err)}`);
              this.$swal({
                icon: "error",
                title: "Invitation failed!",
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$modal.show("modal-settingsAddAccountant");
                }
              });
            });
        },
      });
    },
    showPopupDelete(email) {
      this.currentEmail = email;
      var elem = window.$("#popupDeleteAccountant");
      elem.removeClass("hide-popup");
    },
    removeAccountant() {
      var request = {
        companyId: this.currentCompany.id,
        email: this.currentEmail,
      };
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(DELETE_ACCOUNTANT, request)
        .then(() => {
          this.$root.$refs.AppCyrus.finish();
          Utils.toastSuccess("Delete accountant is successful!");
          this.getListAccountant();
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },
    updateProfile() {
      this.$root.$refs.AppCyrus.start();
      var request = {
        id: Session.get("Profile").id,
        firstName: this.firstName,
        lastName: this.lastName,
        birthDate: Session.get("Profile").birthDate,
        email: this.currentCompany.contactEmail,
      };
      this.$store
        .dispatch(UPDATE_PROFILE, request)
        .then(() => {
          this.$root.$refs.AppCyrus.finish();
          Utils.toastSuccess("Update profile is successful!");
          if (this.firstName != "") {
            Session.set("Username", this.firstName + " " + this.lastName);
          } else {
            Session.set("Username", this.lastName);
          }
          Session.set("Profile", {
            id: Session.get("Profile").id,
            firstName: this.firstName,
            lastName: this.lastName,
            birthDate: Session.get("Profile").birthDate,
            email: this.currentCompany.contactEmail,
          });
          this.$root.$refs.BaseLayout.userName = Session.get("Username");
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },
    checkCode() {
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(CHECK_CODE, {})
        .then((response) => {
          this.$root.$refs.AppCyrus.finish();
          if (!response.isTwoFactorEnabled) {
            this.isUse2FA = false;
            this.getCode();
          } else {
            this.isUse2FA = true;
          }
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },
    getCode() {
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(GET_CODE, {})
        .then((response) => {
          this.$root.$refs.AppCyrus.finish();
          this.sharedKey = response.sharedKey;
          this.code = response.authenticatorUri;
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },
    verifyCode() {
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(VERIFY_CODE, this.authenticatorCode)
        .then(() => {
          this.$root.$refs.AppCyrus.finish();
          Utils.toastSuccess("Verification code is successful!");
          this.isUse2FA = true;
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },
    removeCode() {
      if (this.isRemove) {
        this.$root.$refs.AppCyrus.start();
        this.$store
          .dispatch(REMOVE_CODE, {})
          .then(() => {
            this.$root.$refs.AppCyrus.finish();
            Utils.toastSuccess("Remove code is successful!");
            this.isUse2FA = false;
            this.isRemove = false;
            Session.set("2FAToken", "");
            this.getCode();
          })
          .catch(() => {
            this.$root.$refs.AppCyrus.fail();
          });
      } else {
        this.isRemove = true;
      }
    },
    checkValidate() {
      this.isValidate = true;
      window.$(".is-invalid").removeClass("is-invalid");
      // window.$('.el-tabs__item').css('color', '#909399')
      // window.$('.el-tabs__item.is-active').css('color', '#409EFF')
      var validate = window.$(".ng-businessName");
      // var icon = window.$('#Organisation')
      // var tab = window.$('#tab-first')
      if (Validate.isEmpty(this.currentCompany.businessName)) {
        validate.addClass("is-invalid");
        // icon.addClass('is-invalid')
        // tab.css('color', 'red')
        this.isValidate = false;
      }
      validate = window.$(".ng-contactNumber");
      if (this.contactNumber != null && this.contactNumber != "") {
        var phone = this.contactNumber.replace(/\s+/g, "");
        if (!Validate.isPhone(phone)) {
          validate.addClass("is-invalid");
          // icon.addClass('is-invalid')
          // window.$('.STP').addClass('is-invalid')
          // tab.css('color', 'red')
          this.isValidate = false;
        }
      }

      if (!this.isCheckFindMyAddress) {
        validate = window.$(".ng-address");
        if (Validate.isEmpty(this.address)) {
          validate.addClass("is-invalid");
          // icon.addClass('is-invalid')
          // tab.css('color', 'red')
          this.isValidate = false;
        }
      } else {
        validate = window.$(".ng-address1");
        if (Validate.isEmpty(this.currentCompany.address1)) {
          validate.addClass("is-invalid");
          // icon.addClass('is-invalid')
          // tab.css('color', 'red')
          this.isValidate = false;
        }
        validate = window.$(".ng-suburb");
        if (Validate.isEmpty(this.currentCompany.suburb)) {
          validate.addClass("is-invalid");
          // icon.addClass('is-invalid')
          // tab.css('color', 'red')
          this.isValidate = false;
        }
        validate = window.$(".ng-postcode");
        if (Validate.isEmpty(this.currentCompany.postCode)) {
          validate.addClass("is-invalid");
          // icon.addClass('is-invalid')
          // tab.css('color', 'red')
          this.isValidate = false;
        }
        validate = window.$(".ng-state");
        if (Validate.isEmpty(this.currentCompany.state)) {
          validate.addClass("is-invalid");
          // icon.addClass('is-invalid')
          // tab.css('color', 'red')
          this.isValidate = false;
        }
      }

      // tab = window.$('#tab-second')
      validate = window.$(".mx-input");
      if (Validate.isEmpty(this.companySettings.payrollStartDate)) {
        validate.addClass("is-invalid");
        // icon.addClass('is-invalid')
        // tab.css('color', 'red')
        this.isValidate = false;
      }
      if (this.workHours == "Other") {
        validate = window.$(".ng-other");
        if (Validate.isEmpty(this.workHoursOther)) {
          validate.addClass("is-invalid");
          // icon.addClass('is-invalid')
          // tab.css('color', 'red')
          this.isValidate = false;
        }
      }
    },
    checkValidateSTP() {
      this.isValidate = true;
      window.$(".is-invalid").removeClass("is-invalid");
      var validate = window.$(".ng-signatoryName");
      if (Validate.isEmpty(this.companySTP.signatoryName)) {
        validate.addClass("is-invalid");
        // icon.addClass('is-invalid')
        this.isValidate = false;
      }
      if (!Validate.isSignName(this.companySTP.signatoryName)) {
        validate.addClass("is-invalid");
        // icon.addClass('is-invalid')
        this.isValidate = false;
      }

      validate = window.$(".ng-branchNumber");
      if (
        Validate.isEmpty(this.companySTP.branchNumber) ||
        this.companySTP.branchNumber < 1
      ) {
        validate.addClass("is-invalid");
        // icon.addClass('is-invalid')
        this.isValidate = false;
      }

      validate = window.$(".ng-email");
      if (Validate.isEmpty(this.companySTP.contactEmail)) {
        validate.addClass("is-invalid");
        // icon.addClass('is-invalid')
        this.isValidate = false;
      }
      if (!Validate.isEmail(this.companySTP.contactEmail)) {
        validate.addClass("is-invalid");
        // icon.addClass('is-invalid')
        this.isValidate = false;
      }
      validate = window.$(".ng-contactNumberSTP");
      if (Validate.isEmpty(this.companySTP.contactNumber)) {
        validate.addClass("is-invalid");
        // icon.addClass('is-invalid')
        this.isValidate = false;
      }
      var phone = this.companySTP.contactNumber.replace(/[\s()]+/g, "");
      if (!Validate.isPhone(phone)) {
        validate.addClass("is-invalid");
        // icon.addClass('is-invalid')
        // window.$('.STP').addClass('is-invalid')
        // tab.css('color', 'red')
        this.isValidate = false;
      }
    },
    formatPhoneNumberSTP() {
      var x = this.companySTP.contactNumber
        .replace(/[^+\d]/g, "")
        .match(/(\+\d{0,5}|\d{0,4})(\d{0,3})(\d{0,3})/);
      if (x[1].indexOf("+") == 0) {
        this.companySTP.contactNumber = !x[2]
          ? "(" + x[1].slice(0, 3) + ") " + x[1].slice(3)
          : "(" +
            x[1].slice(0, 3) +
            ") " +
            x[1].slice(3) +
            " " +
            x[2] +
            (x[3] ? " " + x[3] : "");
      } else {
        this.companySTP.contactNumber = !x[2]
          ? x[1]
          : "" + x[1] + " " + x[2] + (x[3] ? " " + x[3] : "");
      }
    },

    addEarning() {
      this.earnings.push({
        id: 0,
        payItemType: EARNINGS,
        payslipName: '',
        normalRate: 'fixed',
        calculateTax: true,
        calculateSuper: false,
        leave: false,
        atoReportingCategory: '',
        otherName:''
      });
    },

    addAllowance() {
      this.allowances.push({
        id: 0,
        payItemType: ALLOWANCES,
        payslipName: '',
        normalRate: '1.0',
        calculateTax: true,
        calculateSuper: false,
        leave: false,
        atoReportingCategory: '',
        otherName:''
      });
      this.isOtherAllowanceNameEmpty = true;
    },

    addDeduction() {    
      this.deductions.push(
          {
              id: 0,
              payItemType: DEDUCTIONS,
              payslipName: '',
              normalRate: '1.0',
              otherName:'',
              atoReportingCategory: ''
          }
      );
      this.isDeductionOtherNameEmpty = true;
    },
    

    reloadChild() {
      this.$refs.subscription.getPaymentInfo();
    },
  },

  watch: {
    "companySTP.contactNumber": function (val) {
      var x = val
        .replace(/[^+\d]/g, "")
        .match(/(\+\d{0,5}|\d{0,4})(\d{0,3})(\d{0,3})/);
      if (x[1].indexOf("+") == 0) {
        this.companySTP.contactNumber = !x[2]
          ? "(" + x[1].slice(0, 3) + ") " + x[1].slice(3)
          : "(" +
            x[1].slice(0, 3) +
            ") " +
            x[1].slice(3) +
            " " +
            x[2] +
            (x[3] ? " " + x[3] : "");
      } else {
        this.companySTP.contactNumber = !x[2]
          ? x[1]
          : "" + x[1] + " " + x[2] + (x[3] ? " " + x[3] : "");
      }
    },
    activeTabSubscription: function (val) {
      if (val == "subscription") {
        this.getPaymentInfo();
      } else {
        this.getListSubscription();
      }
    },
    // "contactNumber": function(val) {
    //     var x = val.replace(/[^+\d]/g, '').match(/(\+\d{0,5}|\d{0,4})(\d{0,3})(\d{0,3})/);
    //     this.contactNumber = !x[2] ? x[1] : '' + x[1] + ' ' + x[2] + (x[3] ? ' ' + x[3] : '');
    // }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
@import url("/assets/css/setting.css");
@import url("//unpkg.com/element-ui@2.13.1/lib/theme-chalk/index.css");

.hide-popup {
  display: none;
}
</style>
