<template>
    <div class="export-csv">
        <v-select
            :attach="true"
            v-model="exportEntity"
            :items="listEntity"
            label="Data to export"
            required
            outlined
            color="success"
        ></v-select>
        <v-select
            :attach="true"
            v-model="fieldSeparator"
            :items="listFieldSeparator"
            label="Field separator"
            required
            outlined
            color="success"
        ></v-select>
        <button type="button" class="btn btn-primary" @click="performExport" :disabled="isExporting">
        <span v-if="!isExporting">Export</span>
        <span v-else><i class="fas fa-spinner fa-spin"></i> Exporting...</span>
        </button>
    </div>
  </template>
  
  <script>
  import ApiManager from "@/api/ApiManager";
  import * as Utils from "@/utils/Utils";
  import log4javascript from 'log4javascript';

  export default {
    name: 'ExportCsv',
    data() {
      return {
        listEntity: [
            {value: "employees", text: "Employees"},
            {value: "payroll", text: "Payroll"},
            {value: "leave", text: "Leave"},
        ],
        exportEntity: "employees",
        isExporting: false,
        listFieldSeparator: [
            {value: ",", text: "Comma (,)"},
            {value: ";", text: "Semi-colon (;)"},
            {value: ":", text: "Colon (:)"},
            {value: "|", text: "Pipe (|)"},
            {value: "\t", text: "TAB"},
        ],
        fieldSeparator: "\t",
      };
    },
    methods: {
        async performExport() {
            this.isExporting = true;
            try {
                const options = {
                entity: this.exportEntity,
                fieldSeparator: this.fieldSeparator,
                };
                const data = await ApiManager.postJson("/api/exports", options);
                Utils.downloadBlob(data, `${this.exportEntity}_exported.csv`);

                Utils.toastSuccess("Export complete");
            }
            catch(error){
                this.$logger.error(`performExport: ${error}`)
                Utils.toastError("Export failed");
            }
            finally {
                this.isExporting = false;
            }
        },
    },
    created: function() {
        this.$logger = log4javascript.getLogger("ExportCsv");
    },
  };
  </script>
  
  <style scoped>
  
  </style>