<template>
<body class="menu-position-side menu-side-left full-screen with-content-panel">
    <div ui-view autoscroll="true" style="min-height: 100%;" class="ng-scope">
        <div class="all-wrapper menu-side with-pattern ng-scope" style="padding: 0px !important; background: #09b585; background-size: 100% 100%;" ng-class="{'d-none' : emailSent}">
            <div class="auth-box-w" style="margin-top: 40px">
                <div class="logo-w">
                    <h3 class="h3">Cyruspay</h3>
                </div>
                <h4 class="auth-header">Reset Password</h4>
                <form name="login_form" v-on:submit.prevent="resetPass()" action="" method="post" class="ng-pristine ng-invalid ng-invalid-required">
                    <p>Please enter your new password.</p>
                    <div class="form-group text-left">
                        <label for>New password</label>
                        <v-app>
                            <v-col class="format-input" cols="0" sm="12" md="12" style="padding-left: 0px">
                                <v-text-field v-on:keydown.space="(event) => event.preventDefault()" v-on:keyup="passwordStrength($event)" v-model="password" style="height: 40px" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'" name="password" class="input-group--focused" @click:append="show1 = !show1" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required outlined></v-text-field>
                            </v-col>
                            <div id="StrengthDisp"></div>
                        </v-app>
                    </div>
                    <div class="form-group text-left">
                        <label for>Confirm new password</label>
                        <v-app>
                            <v-col class="format-input" cols="0" sm="12" md="12" style="padding-left: 0px">
                                <v-text-field v-model="confirmPassword" style="height: 40px" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" :type="show2 ? 'text' : 'password'" name="confirmPassword" class="input-group--focused" @click:append="show2 = !show2" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required outlined></v-text-field>
                            </v-col>
                        </v-app>
                    </div>
                    <div class="row">
                        <div class="col text-left">
                            <button class="btn btn-primary" type="submit" ng-disabled="loading">
                                <span class="spinner-grow spinner-grow-sm ng-hide" ng-show="loading"></span>
                                Submit
                            </button>
                        </div>
                        <div class="form-check-inline">
                            <router-link ui-sref="login" to="/login">Log in</router-link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</body>
</template>

<script>
import * as External from '@/utils/External.js'
import log4javascript from 'log4javascript';
import * as Encode from '@/utils/Encodepassword'
import * as Utils from '@/utils/Utils'

import {
    FORGOT_PASSWORD_EMAIL,
    CHECK_TOKEN_FORGOT_PASSWORD
} from "@/store/actionsType";
let strongPassword = new RegExp(
  "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{10,})"
);
let mediumPassword = new RegExp(
  "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
);
export default {
    name: "ResetPassword",
    mounted() {
        External.head_link("/assets/css/tt/styleRegister.css");
        External.head_link("/assets/css/tt/themeRegister.css");
        External.head_link("/assets/css/tt/mainLogin.css");
        External.head_link("/assets/css/tt/style(1)Login.css");
        External.head_link("/assets/css/tt/rocket.css");
    },
    destroyed() {
        External.del_link("/assets/css/tt/styleRegister.css");
        External.del_link("/assets/css/tt/themeRegister.css");
        External.del_link("/assets/css/tt/mainLogin.css");
        External.del_link("/assets/css/tt/style(1)Login.css");
        External.del_link("/assets/css/tt/rocket.css");
    },
    data() {
        return {
            password: "",
            show1: false,
            confirmPassword: "",
            show2: false,
        };
    },
    created: function () {
        this.$logger = log4javascript.getLogger("ResetPassword");
        this.checkCodeEncode();
    },
    methods: {
        checkCodeEncode(){
            this.$root.$refs.AppCyrus.start()
            var dataRequest = {"userId": this.$route.query.userId, "code": this.$route.query.code}
            this.$store.dispatch(CHECK_TOKEN_FORGOT_PASSWORD, dataRequest)
                .then((response) => {
                    this.$root.$refs.AppCyrus.finish()
                    if(!response.result){
                        this.$router.push({
                            path: "/link-expired"
                        });
                    }
                }).catch((error) => {
                    this.$logger.error(`checkCodeEcode: ${error}`)
                    this.$root.$refs.AppCyrus.fail()
                })
        },
        passwordStrength(event) {
         this.strengthChecker(event.target.value);
        },
        strengthChecker(PasswordParameter) {
            let divStrengthDisp = document.getElementById("StrengthDisp");
            if (strongPassword.test(PasswordParameter)) {
            divStrengthDisp.classList = "Strong";
            divStrengthDisp.textContent = "Strong";
            } else if (mediumPassword.test(PasswordParameter)) {
            divStrengthDisp.classList = "Good";
            divStrengthDisp.textContent = "Medium";
            } else {
            divStrengthDisp.classList = "Weak";
            divStrengthDisp.textContent = "Weak";
            }
        },
        async resetPass() {
            if (this.confirmPassword !== this.password) {
                Utils.toastError("Confirm new password must be the same as New password!")
                return;
            }

            var code = await Encode.encryptPass(this.password)
            if (!code) {
                this.$logger.error("encrypted password is empty")
                return;
            }

            var request = {
                'userId': this.$route.query.userId,
                'code': this.$route.query.code,
                'newPassword': this.password,
            }
            
            this.$root.$refs.AppCyrus.start()

            this.$store.dispatch(FORGOT_PASSWORD_EMAIL, request)
            .then (() => {
                this.$root.$refs.AppCyrus.finish()
                Utils.toastSuccess("Reset password successful!")
                this.$router.push({
                    path: "/login"
                });
            }).catch((error) => {
                this.$logger.error(`resetPass: ${error}`)
                this.$root.$refs.AppCyrus.fail()
            });
            
        },
    }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
@import '/assets/css/company.css';
@import url("//unpkg.com/element-ui@2.13.1/lib/theme-chalk/index.css");

.pagination {
    justify-content: center;
    text-align: center;
}
#StrengthDisp {
  position: relative;
  height: 3px;
  font-weight: 500;
  font-size: 12px;
  border-radius: 25px;

}
.Weak {
  width: 20%;
  background-color: #dc3545;
 color: #dc3545;
}
.Good {
  width: 70%;
  background-color: #28a745;
 color: #28a745;

}
.Strong {
  width: 100%;
  background-color: #d39e00;
 color: #d39e00;

}
.validationError {
width: 100%;
margin-top:0.25rem;
font-size: 15px;
color: #e65252;
}

</style>
