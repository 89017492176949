import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firebase-remote-config';

export default function _initializeFirebaseRemoteConfig() {
    const remoteConfig = firebase.remoteConfig();
    remoteConfig.settings = {
        minimumFetchIntervalMillis: 3600,
    };
    remoteConfig.defaultConfig = ({
        'isLogin': 'false',
    });
    remoteConfig.fetchAndActivate().then(() => {
        
    }).catch((err) => {
        console.error(err);
    });
}