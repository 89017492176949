import Vue from "vue"
import moment from "moment";

function roundNumber(number) {
    
    if (number != undefined && number != '') {
        var resultNumber =  Math.round(number * 100)/100;
         return resultNumber.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
        //return resultNumber.toFixed(2)
    }
    return 0
}
function roundNumberR1(number) {
    if (number != undefined && number != '') {
        var resultNumber =  Math.round(number * 100)/100;
        return resultNumber.toFixed(2)
    }
    return 0
}

function truncateNumber(number) {
    if (number != undefined && number != '') {
        var resultNumber =  Math.floor(number * 100)/100;
        return resultNumber.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
    }
    return 0
}

function isDisabled() {
    return true
}

function isLoadLinkPaySlip() {
    return true
}

/** return date string in australian format DD/MM/YYYY */
function formatDateToString(inputDate, format) {
    if (!inputDate) return "";

    return moment(inputDate).local().format(format ?? "DD/MM/YYYY");
}

function formatDateTimeToString(inputDate, format) {
    if (!inputDate) return "";
    return moment(inputDate).local().format(format ?? "DD/MM/YYYY HH:mm");
}

function formatCurrency(input) {
    if (!input) return "";

    const currencyLookup = {
        'en-AU': "AUD",
        "en-US": "USD"
    };

    const currency = currencyLookup[document.documentElement.lang];
    return new Intl.NumberFormat(document.documentElement.lang, { style: 'currency', currency: currency }).format(input);
}

function checkDate(startDate, endDate) {
    var d1 = new Date(endDate);
    var d2 = new Date(startDate);
    if (d1.getTime() <= d2.getTime()) {
        return false
    }
    return true
}

function sortEmployee(listEmployee) {
    var listActive = []
    var listPending = []
    var listInActive = []
    if (listEmployee.length > 0) {
        for (var i = 0; i < listEmployee.length; i++) {
            if (listEmployee[i].active == 'ACTIVE') {
                listActive.push(listEmployee[i])
            }
            if (listEmployee[i].active == 'PENDING') {
                listPending.push(listEmployee[i])
            }
            if (listEmployee[i].active == 'INACTIVE') {
                listInActive.push(listEmployee[i])
            }
        }
    }

    listActive = sortbyName(listActive)
    listPending = sortbyName(listPending)
    listInActive = sortbyName(listInActive)

    var listAll = []
    for (var a = 0; a < listActive.length; a++) {
        listAll.push(listActive[a])
    }
    for (var b = 0; b < listPending.length; b++) {
        listAll.push(listPending[b])
    }
    for (var c = 0; c < listInActive.length; c++) {
        listAll.push(listInActive[c])
    }
    return listAll
}
function sortbyName(listSort) {
    listSort.sort(function(a, b) {
        var textA = a.firstName.toUpperCase();
        var textB = b.firstName.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    return listSort
}

function sortListJson(list, sortKey, reverse) {
    list.sort(function (a, b) {
        const varA =
            typeof a[sortKey] === "string" ?
            a[sortKey].toUpperCase() :
            a[sortKey];
        const varB =
            typeof b[sortKey] === "string" ?
            b[sortKey].toUpperCase() :
            b[sortKey];

        let comparison = 0;
        if (varA > varB) comparison = 1;
        else if (varA < varB) comparison = -1;
        return reverse ? comparison * -1 : comparison;
    })
}

function calculateDateWithHolidays(startDate, endDate, listHoidays) {
    var star = new Date(startDate)
    var end = new Date(endDate)
    var count = 0;
    var curDate = star;
    while (curDate <= end) {
        var dayOfWeek = curDate.getDay();
        var checkHolidays = false
        if (!((dayOfWeek == 6) || (dayOfWeek == 0))) {
            for (var i = 0; i < listHoidays.length; i++) {
                var day = ""
                if (listHoidays[i].length >= 10) {
                    day = listHoidays[i].substring(0, 10)
                } else {
                    day = ""
                }
                var holiday = new Date(day)
                if (holiday.getTime() === curDate.getTime()) {
                    checkHolidays = true
                    break
                }
            }
            if (!checkHolidays) {
                count++;
            }
        }
        curDate.setDate(curDate.getDate() + 1);
    }
    return count;
}

function isCheckHolidays(startDate, endDate, listHoidays) {
    var star = new Date(startDate)
    var end = new Date(endDate)
    var curDate = star;
    var checkHolidays = false;
    while (curDate <= end && !checkHolidays) {
        var dayOfWeek = curDate.getDay();
        if (!((dayOfWeek == 6) || (dayOfWeek == 0))) {
            for (var i = 0; i < listHoidays.length; i++) {
                var day = ""
                if (listHoidays[i].length >= 10) {
                    day = listHoidays[i].substring(0, 10)
                } else {
                    day = ""
                }
                var holiday = new Date(day)
                if (holiday.getTime() === curDate.getTime()) {
                    checkHolidays = true
                    break
                }
            }
        } else {
            checkHolidays = true
        }
        curDate.setDate(curDate.getDate() + 1);
    }
    return checkHolidays;
}

function getUserName(item) {
    if (item.lastName.includes(" ")) {
        return item.firstName + " " + item.lastName.substring(0, item.lastName.indexOf(" "))
    } else if (item.firstName.includes(" ")) {
        return item.firstName.substring(0, item.firstName.indexOf(" ")) + " " + item.lastName
    } else {
        return item.firstName + " " + item.lastName
    }
}

function toastInfo(message, duration) {
    Vue.$toast.info(message, { position: "top", duration: duration ?? 5000 });
}

function toastSuccess(message, duration) {
    Vue.$toast.success(message, { position: "top", duration: duration ?? 5000 });
}

function toastError(message, duration) {
    Vue.$toast.error(message, { position: "top", duration: duration ?? 5000 });
}

function toastWarning(message, duration) {
    Vue.$toast.warning(message, { position: "top", duration: duration ?? 5000 });
}

/**
 * Downloads a blob of data as a file.
 *
 * @param {Blob} data - The blob of data to download.
 * @param {string} fileName - The name of the file to be downloaded.
 */
function downloadBlob(data, fileName) {
    const blob = new Blob([data], { type: "application/octet-stream" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
}

/**
 * Opens a blob object in a new window.
 *
 * @param {any} data - The data to be converted into a blob.
 * @param {string} contentType - The MIME type of the data.
 * @param {string} target - The target window or tab to open the blob in.
 */
function openBlob(data, contentType, target) {
    const blob = new Blob([data], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    window.open(url, target);
    window.URL.revokeObjectURL(url);
}

export {
    roundNumber,
    roundNumberR1,
    truncateNumber,
    isDisabled,
    isLoadLinkPaySlip,
    formatDateToString,
    formatDateTimeToString,
    formatCurrency,
    sortEmployee,
    sortListJson,
    calculateDateWithHolidays,
    isCheckHolidays,
    checkDate,
    getUserName,
    toastSuccess,
    toastError,
    toastWarning,
    toastInfo,
    downloadBlob,
    openBlob
}
