<template>
<modal name="modal-viewPaySlip-pdf" @before-open="beforeOpen" with="auto" height="auto" style="z-index: 1000">
    <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog" aria-labelledby="modal-title" aria-describedby="modal-body" size="xxl" index="0" animate="animate" ng-style="{&#39;z-index&#39;: 1050 + $topModalIndex*10, display: &#39;block&#39;}" tabindex="-1" uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true" style="display: block; overflow: auto;">
        <div class="modal-dialog modal-xxl">
            <div class="modal-content" uib-modal-transclude="">
                <div class="modal-content text-center onboarding-modal modal-centered ng-scope">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel" style="text-align:center;">
                                <span style="color:#334152" class="ng-binding">{{titleName ? titleName : 'Payslip'}}</span>
                            </h5>
                            <button v-on:click="this.closeModal" aria-label="Close" class="box-title close" data-dismiss="modal" type="button"></button>
                        </div>
                        <div class="modal-body" style="padding:0">
                            <div id="example1" style="width: 100%; height: 580px;" class=" pdfobject-container">
                                <!-- <embed :src=linkData type="application/pdf" style="overflow: auto; width: 100%; height: 100%;" /> -->
                                <div class="load" v-show="!isError && !hasData">
                                    <v-progress-circular
                                    :rotate="-90"
                                    :size="100"
                                    :width="15"
                                    :value="progress"
                                    color="teal"
                                    >
                                    </v-progress-circular>
                                </div>
                                <object v-show="hasData" :data='urlPdf' type='application/pdf' width='100%' height='100%'>
                                    <p v-show="isError">This browser does not support PDFs or an error has occurred. Please reload or download the PDF to view it: <a :href=linkData>Download PDF</a>.</p>
                                </object>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</modal>
</template>

<script>
import axios from "axios"
import Token from '@/api/Token'
import ApiManager from '@/api/ApiManager'
import log4javascript from 'log4javascript';

export default {
    name: "ViewPaySlipModalPdf",
    props: [],
    components: {},
    created: function() {
        this.$logger = log4javascript.getLogger("ViewPaySlipModalPdf");
    },
    data() {
        return {
            linkData: "",
            titleName: "",
            urlPdf: null,
            isError: false,
            hasData: false,
            progress: 0,
        };
    },
    methods: {
        beforeOpen(event) {
            this.linkData = event.params.link
            this.titleName = event.params.titleName
            this.urlPdf = null
            this.isError = false
            this.hasData = false
            this.progress = 0;
            var interval = setInterval(() => {
                if (this.progress === 120) {
                    this.hasData = true
                    clearInterval(interval)
                } else if(this.progress != 90) {
                    this.progress += 10
                }
            }, 200)

            axios({
                url: this.linkData,
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token.getToken(),
                    handshake: 'Basic ' + Token.getCodeHandShake()
                },
                responseType: 'blob'
            })
            .then((response) => {
                this.progress = 100
                this.urlPdf = URL.createObjectURL(response.data)
            })
            .catch((error) => {
                this.$logger.error(`beforeOpen: ${error}`)
                if(error.response && error.response.status === 500){
                    if(!ApiManager.isCheckServer) {
                        ApiManager.checkServerIsUpdating()
                        .then((response) => {
                            if(!response.isEnable) {
                                window.location.href = "updateserver";
                            } else {
                                this.isError = true
                            }
                        }).catch(() => {
                            window.location.href = "updateserver";
                        })
                    }
                } else {
                    this.isError = true
                }
            })
        },

        closeModal() {
            this.$modal.hide('modal-viewPaySlip-pdf');
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
