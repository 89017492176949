<template>
<div>
    <modal name="modal-payrundetails" @before-open="beforeOpen" with="auto" height="auto">
        <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog" aria-labelledby="modal-title" aria-describedby="modal-body" size="xxl" index="0" animate="animate" ng-style="{display: &#39;block&#39;}" tabindex="-1" uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true" style="display: block; overflow: auto;">
            <div class="modal-dialog modal-xxl">
                <div class="modal-content" uib-modal-transclude="">
                    <div class="modal-content text-center onboarding-modal modal-centered ng-scope">
                        <div class="modal-content">
                            <div class="modal-header" style="padding-bottom: 5px !important;">
                                <h5 class="modal-title bold" id="exampleModalLabel" style="text-align:center; ">
                                    <span style="color:#334152">Payroll Details</span>
                                    <button v-show="detailRun.statusPayroll !== 'Lodged' || isShowUpdateUpdateLastSTP" v-on:click="listPolicy.updatePayroll ? showPopupEditPayRun() : gotoPayment()" v-bind:disabled="detailRun.numberOfUpdates > 0" class="btn btn-warning" style="margin-right: 20px">
                                        {{showTextEdit()}}<img class="iconedit" src="/assets/img/edit_while.png" />
                                    </button>
                                    <button v-on:click="getPayslipForPayRoll()" class="btn btn-warning" ng-disabled="loading" style="margin-right: 20px">
                                        View Payslip
                                        <i class="icon-feather-eye"></i>
                                    </button>
                                    <!-- <button v-on:click="getPayslipForSummary()" class="btn btn-warning" ng-disabled="loading" style="margin-right: 20px">
                                        View Summary
                                        <i class="icon-feather-eye"></i>
                                    </button> -->
                                </h5>
                                <button v-on:click="this.closeModal" aria-label="Close" class="box-title close" data-dismiss="modal" type="button"></button>
                                <div class="date-run-payroll">
                                    <div class="title-extra">
                                        <h6 class="bold">Pay period</h6>
                                        <h7>{{formatDateToString(detailRun.startPayPeriod)}} - {{formatDateToString(detailRun.endPayPeriod)}}</h7>
                                    </div>
                                    <div class="title-extra">
                                        <h6 class="bold">Pay Date</h6>
                                        <h7>{{formatDateToString(detailRun.paymentDate)}}</h7>
                                    </div>
                                </div>
                            </div>
                            <span class="text-left ng-binding bold" style="margin-left: 15px; color: gray;">(Id: {{detailRun.id}})</span>
                            <div class="modal-body">
                                <div class="element-box-tp">
                                    <!-- <div class="box-id">
                                        <h6 class="text-left bold" style="color: #f7f7f7; margin-bottom: 0px"></h6>
                                    </div> -->
                                    <table class="table table-padded" style="background: #F7F7F7; padding: 5px;">
                                        <thead>
                                            <tr>
                                                <th class="size-title">Employees</th>
                                                <th class="text-right size-title">Earnings</th>
                                                <th class="text-right size-title">Tax</th>
                                                <th class="text-right size-title">Deduction</th>
                                                <th class="text-right size-title">Net pay</th>
                                                <th class="text-right size-title">Amount <br>paid</th>
                                                <th class="text-right size-title">Super</th>
                                                <!-- <th class="text-center size-title">EOFY<br>Final</th> -->
                                                <th class="text-center size-title">Payslip</th>
                                            </tr>
                                        </thead>
                                        <tbody v-for="(item) in detailRun.adjustReview" :key="item.id">
                                            <tr class="ng-scope">
                                                <td class="text-left ng-binding" style="display: grid">
                                                    <span>
                                                        {{item.employeeData.firstName + ' ' + item.employeeData.lastName}}
                                                    </span>
                                                    <span v-show="item.isTermination" style="font-size: 12px; color: red">
                                                        (Termination)
                                                    </span>
                                                </td>
                                                <!-- <td class="text-left ng-binding">
                                                    <div class="ng-binding">{{item.employeeData.firstName + ' ' + item.employeeData.lastName}}</div>
                                                </td> -->
                                                <td class="text-right ng-binding">
                                                    <span v-show="item.isTermination" v-on:click="seeETPEarning(item)"><i class="icon-feather-help-circle text-primary fs-14 pointer box-title etp-earning"></i></span>
                                                    ${{roundNumber(item.earnings + item.terminationPay + item.lumSumD + item.etpCodeR + item.etpTypeO)}}
                                                </td>
                                                <td class="text-right ng-binding">
                                                    <span v-show="item.isTermination" v-on:click="seeETPTax(item)"><i class="icon-feather-help-circle text-primary fs-14 pointer box-title etp-tax"></i></span>
                                                    ${{roundNumber(item.temporaryTax)}}
                                                </td>
                                                <td class="text-right ng-binding">{{roundNumber(item.totalDeduction)}}</td>
                                                <td class="text-right ng-binding">${{roundNumber(item.netPay)}}</td>
                                                <td class="text-right ng-binding">${{roundNumber(item.amountPaid)}}</td>
                                                <td class="text-right ng-binding">${{roundNumber(item.super)}}</td>
                                                <!-- <td class="text-center">
                                                    <i class="icon-feather-check ng-hide" ng-show="payslip.fn"></i>
                                                </td> -->
                                                <td class="text-center" style="position: relative;">
                                                    <button class="btn btn-sm-2 text-success btn-icon-sm view-payslip box-title" style="margin-right: 10px" @click="getPayslipEmployee(item.id)">
                                                        <i class="icon-feather-file-text"></i>
                                                    </button>
                                                    <button class="btn btn-sm text-primary btn-icon-sm send-payslip box-title" v-bind:disabled="paySlipId != 0 || (deletedEmployeeIds && deletedEmployeeIds.includes(item.employeeId))" @click="sendPaySlip(item.id)">
                                                        <i class="icon-feather-send"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            <!-- <tr v-if="item.listTermination.length > 0" class="adjacent">
                                                <td class="bold text-left">
                                                    Termination payment
                                                </td>
                                                <td class="text-right ng-binding">
                                                    <span title="ETP Earning" v-on:click="seeETPEarning(item)"><i class="icon-feather-help-circle text-primary fs-14 pointer"></i></span>
                                                    ${{roundNumber(item.terminationPay + item.lumSumD + item.etpCodeR + item.etpTypeO)}}
                                                </td>
                                                <td class="text-right ng-binding">
                                                    <span title="ETP Withholding Tax" v-on:click="seeETPTax(item)"><i class="icon-feather-help-circle text-primary fs-14 pointer"></i></span>
                                                    ${{roundNumber(item.taxTerminationPay + item.taxETPTypeO + item.taxETPTypeR)}}
                                                </td>
                                                <td colspan="5"></td>
                                                <td></td>
                                            </tr> -->
                                        </tbody>
                                        <tbody>
                                            <tr class="tr-total">
                                                <td class="text-left text-success bold">Total</td>
                                                <td class="text-right ng-binding bold">${{roundNumber(detailRun.totalEarnings)}}</td>
                                                <td class="text-right ng-binding bold">${{roundNumber(detailRun.totalTax)}}</td>
                                                <td class="text-right ng-binding bold">${{roundNumber(detailRun.totalDeduction)}}</td>
                                                <td class="text-right ng-binding bold">${{roundNumber(detailRun.totalNetPay)}}</td>
                                                <td class="text-right ng-binding bold">${{roundNumber(detailRun.totalAmountPaid)}}</td>
                                                <td class="text-right ng-binding bold">${{roundNumber(detailRun.totalSuper)}}</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="alert alert-danger fade show text-left ng-hide" ng-show="failureArray &amp;&amp; failureArray.length&gt;0">
                                </div>
                                <div class="progress fade v-application" v-show="paySlipId == detailRun.id">
                                    <v-progress-linear
                                        :background-opacity="0.3"
                                        :buffer-value="nextProgress"
                                        :height="20"
                                        rounded
                                        striped
                                        :color="colorLinear"
                                        :value="loadSendFile"
                                    ></v-progress-linear>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <div class="row fade" style="width:100%">
                                    <div class="col-12 col-lg-6 text-left">
                                        <button v-if="detailRun.statusPayroll != 'Lodged' && detailRun.statusPayroll != 'Pushed'" v-on:click="listPolicy.deletePayroll ? showPopupDeletePayRun() : gotoPayment()" class="btn btn-danger ng-scope" ng-if="!disableDelete" style="margin-right: 20px;">
                                            <span>Delete Payroll</span> <i class="icon-feather-trash-2"></i>
                                        </button>
                                        
                                    </div>
                                    <div class="col-12 col-lg-6 text-right">
                                        <!-- <a v-on:click="this.closeModal" class="btn btn-light" style="margin-right: 20px">Close</a> -->
                                        <button v-on:click="this.sendAllPaySlip" v-bind:disabled="paySlipId != 0" class="btn btn-send" style="margin-right: 20px;">
                                            <span>SEND</span> <i class="icon-feather-send"></i>
                                        </button>
                                        <button v-on:click="this.showPopupDownloadABA" class="btn btn-send box-title" style="margin-right: 20px;">
                                            <span>Pay</span><img src="/assets/img/outline_attach_money_white.png" />
                                        </button>
                                        <button v-show="detailRun.statusPayroll != 'Lodged'" id="getResult" v-bind:disabled="detailRun.statusPayroll != 'Pushed' && detailRun.statusPayroll != 'Error' ? true : false" v-on:click="getSTPResult()" class="btn btn-primary ng-scope" ng-disabled="reporting" style="margin-right: 20px">
                                            <span>Get STP Result</span>
                                        </button>
                                        <button id="submitSTP" v-show="detailRun.statusPayroll != 'Lodged' && !isDraft" v-bind:disabled="new Date(detailRun.endPayPeriod) > new Date() || detailRun.statusPayroll == 'Pushed' ? true : false" v-on:click="listPolicy.submitPayroll ? showPopupAccuracy() : gotoPayment()" class="btn btn-success ng-scope" ng-disabled="reporting" style="margin-right: 20px">
                                            <span>{{detailRun.numberOfUpdates >= 1 ? 'Update STP' :'Submit STP'}}</span> <i class="icon-feather-radio"></i>
                                        </button>
                                        <button id="confirm" v-show="isDraft" v-on:click="submitRunPayroll()" class="btn btn-warning ng-scope" ng-disabled="reporting" style="margin-right: 20px">
                                            <span>Done</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>

    <modal name="modal-accuracy" with="auto" height="auto" style="z-index: 2000">
        <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog" aria-labelledby="modal-title" aria-describedby="modal-body" size="md" index="0" animate="animate" tabindex="-1" uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true" style="z-index: 1050; display: block; overflow: auto;">
            <div class="modal-dialog modal-md">
                <div class="modal-content" uib-modal-transclude="">
                    <div class="modal-content text-center onboarding-modal modal-centered ng-scope">
                        <div class="modal-content" style="height: 300px">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel" style="text-align:center;">
                                    <span class="ng-binding">STP sender authentication</span>
                                </h5>
                            </div>
                            <div class="modal-body employee-modal text-left">
                                <label class="alert alert-warning" style="width: 100%">Currently the person responsible for sending STPs is <span class="bold">{{companySTP.signatoryName}}</span>. If you agree to send, click button <span class="badge bg-blue">Send</span>, and if you want to change the information of the responsible person then click button <span class="badge bg-yellow">Edit</span>, and if you do not agree to submit click button <span class="badge bg-grey">Cancel.</span></label>
                            </div>
                            <div class="modal-footer">
                                <button @click="$modal.hide('modal-accuracy');" class="btn btn-grey">Cancel</button>
                                <button @click="editSTP()" class="btn btn-warning">Edit</button>
                                <button @click="$modal.hide('modal-accuracy'); reportSTP()" class="btn btn-primary">Send</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>

    <modal name="modal-settingSTP" with="auto" height="auto" style="z-index: 2000">
        <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog" aria-labelledby="modal-title" aria-describedby="modal-body" size="md" index="0" animate="animate" tabindex="-1" uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true" style="z-index: 1050; display: block; overflow: auto;">
            <div class="modal-dialog modal">
                <div class="modal-content" uib-modal-transclude="">
                    <div class="modal-content text-center onboarding-modal modal-centered ng-scope">
                        <div class="modal-content" style="height: 400px">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel" style="text-align:center;">
                                    <span class="ng-binding">Setting STP</span>
                                </h5>
                            </div>
                            <div class="modal-body employee-modal text-left">
                                <label v-show="!isEditSTP" class="alert alert-warning" style="width: 100%">To be able submit STP, please update your name and contact number.</label>
                                <form name="stpForm" ng-show="!company.stp.on || !company.si">
                                    <div class=" row">
                                        <div class="col-sm-6">
                                            <label for="branchNumber">Branch Number
                                                <span class="text-danger">*</span>
                                            </label>
                                            <div class="form-group  ">
                                                <input v-model.number="companySTP.branchNumber" class="form-control ng-branchNumber" placeholder="1" maxlength="5" ng-model="stp.bn" name="branchNumber">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label for="contactName">Signatory Name
                                                <span helper="" contents="The signatory name needs to be of a person - this is not the company name. This name can also not contain any numbers or special characters."><i class="icon-feather-help-circle text-primary fs-14 pointer" style="vertical-align: middle" ng-click="getInfo_defaultPayrollLiabilityAccount()"></i></span>
                                                <span class="text-danger">*</span>
                                            </label>
                                            <div class="form-group">
                                                <input v-model="companySTP.signatoryName" class="form-control ng-signatoryName" type="text" required name="contactName">
                                            </div>
                                        </div>

                                        <div class="col-sm-6">
                                            <label for="contactName">Contact Email
                                                <span class="text-danger">*</span>
                                            </label>
                                            <div class="form-group">
                                                <input v-model="companySTP.contactEmail" class="form-control ng-email" type="text" required name="email">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label for="">Contact number <span class="text-danger">*</span></label>
                                            <div class="form-group  ">
                                                <input v-model="companySTP.contactNumber" @input="formatPhoneNumber" class="form-control ng-contactNumber" placeholder="" type="phone" required name="phone">
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button @click="$modal.hide('modal-settingSTP');" class="btn btn-cancel btn-grey">Do it later</button>
                                <button @click="updateCompany('modal-settingSTP')" class="btn btn-primary" style="margin-left: 15px;">Enable STP</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>

    <modal name="modal-enableSTP" with="auto" height="auto" style="z-index: 2000">
        <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog" aria-labelledby="modal-title" aria-describedby="modal-body" size="md" index="0" animate="animate" tabindex="-1" uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true" style="z-index: 1050; display: block; overflow: auto;">
            <div class="modal-dialog modal-md">
                <div class="modal-content" uib-modal-transclude="">
                    <div class="modal-content text-center onboarding-modal modal-centered ng-scope">
                        <div class="modal-content" style="height: 230px">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel" style="text-align:center;">
                                    <span class="ng-binding">Enable STP</span>
                                </h5>
                            </div>
                            <div class="modal-body employee-modal text-left">
                                <label class="alert alert-warning" style="width: 100%">STP is currently disabled. Do you want to enable it?</label>
                            </div>
                            <div class="modal-footer">
                                <button @click="$modal.hide('modal-enableSTP');" class="btn btn-cancel btn-grey">No, disable STP</button>
                                <button @click="updateCompany('modal-enableSTP')" class="btn btn-primary" style="margin-left: 15px;">Yes, enable STP</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>

    <!--------------------
    START - popup EditPayRun
    -------------------->
    <div id="popupEditPayRun" class="ng-confirm ng-confirm-white ng-confirm-type-default hide-popup">
        <div class="ng-confirm-bg" style="transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);"></div>
        <div v-on:click="this.hilight" class="ng-confirm-scrollpane">
            <div class="ng-bs3-container container">
                <div class="ng-bs3-row row justify-content-md-center justify-content-sm-center justify-content-xs-center justify-content-lg-center">
                    <div class="ng-confirm-box-p col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">
                        <div class="ng-confirm-box ng-confirm-hilight-shake ng-confirm-type-animated" role="dialog" aria-labelledby="ng-confirm-box48316" tabindex="-1" style="margin-top: 259.86px; margin-bottom: 0px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
                            <div class="ng-confirm-closeIcon ng-confirm-el-hide"><span>×</span></div>
                            <div class="ng-confirm-title-c">
                                <span class="ng-confirm-icon-c ng-confirm-el-hide"><i></i></span>
                                <span class="ng-confirm-title">Edit pay run</span>
                            </div>
                            <div class="ng-confirm-content-pane" style="height: 21px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
                                <div class="ng-confirm-content" id="ng-confirm-box48316">
                                    <div class="ng-scope">Are you sure you want to edit this pay run?</div>
                                </div>
                            </div>
                            <div class="ng-confirm-buttons">
                                <button v-on:click="this.submitPopupEditPayRun" type="button" class="btn btn-primary">
                                    <span class="ng-confirm-btn-text">Yes!</span>
                                </button>
                                <button v-on:click="this.hidePopupEditPayRun" type="button" class="btn btn-default">
                                    <span class="ng-confirm-btn-text">close</span>
                                </button>
                            </div>
                            <div class="ng-confirm-clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--------------------
    END - popup EditPayRun
    -------------------->

    <!--------------------
    START - popup Delete
    -------------------->
    <div id="popupDeletePayRun" class="ng-confirm ng-confirm-white ng-confirm-type-default hide-popup">
        <div class="ng-confirm ng-confirm-white ng-confirm-type-default">
            <div class="ng-confirm-bg" style="transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);"></div>
            <div v-on:click="this.hilight" class="ng-confirm-scrollpane">
                <div class="ng-bs3-container container">
                    <div class="ng-bs3-row row justify-content-md-center justify-content-sm-center justify-content-xs-center justify-content-lg-center">
                        <div class="ng-confirm-box-p col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">
                            <div class="ng-confirm-box ng-confirm-hilight-shake ng-confirm-type-animated" role="dialog" aria-labelledby="ng-confirm-box418771" tabindex="-1" style="margin-top: 76.3595px; margin-bottom: 0px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
                                <div class="ng-confirm-closeIcon ng-confirm-el-hide"><span>×</span></div>
                                <div class="ng-confirm-title-c">
                                    <span class="ng-confirm-icon-c ng-confirm-el-hide"><i></i></span>
                                    <span class="ng-confirm-title">Delete pay run</span>
                                </div>
                                <div class="ng-confirm-content-pane" style="height: 63px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
                                    <div class="ng-confirm-content" id="ng-confirm-box418771">
                                        <div class="ng-scope">Please note that this pay run data cannot be restored once you delete it. Would you like to proceed?</div>
                                    </div>
                                </div>
                                <div class="ng-confirm-buttons">
                                    <button v-on:click="deleteRunPayRoll()" type="button" class="btn btn-primary">
                                        <span class="ng-confirm-btn-text">Yes!</span>
                                    </button>
                                    <button v-on:click="this.hidePopupDeletePayRun" type="button" class="btn btn-default">
                                        <span class="ng-confirm-btn-text">close</span>
                                    </button>
                                </div>
                                <div class="ng-confirm-clear"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--------------------
    END - popup Delete
    -------------------->

    <!--------------------
    START - popup Download ABA file
    -------------------->
    <div id="popupDownloadABA" class="ng-confirm ng-confirm-white ng-confirm-type-default hide-popup">
        <div class="ng-confirm-bg" style="transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);"></div>
        <div v-on:click="this.hilight" class="ng-confirm-scrollpane">
            <div class="ng-bs3-container container">
                <div class="ng-bs3-row row justify-content-md-center justify-content-sm-center justify-content-xs-center justify-content-lg-center">
                    <div class="ng-confirm-box-p col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">
                        <div class="ng-confirm-box ng-confirm-hilight-shake ng-confirm-type-animated" role="dialog" aria-labelledby="ng-confirm-box203454" tabindex="-1" style="margin-top: 242.359px; margin-bottom: 0px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
                            <div class="ng-confirm-closeIcon ng-confirm-el-hide"><span>×</span></div>
                            <div class="ng-confirm-title-c"><span class="ng-confirm-icon-c ng-confirm-el-hide"><i></i></span><span class="ng-confirm-title">Employee Payment Options</span></div>
                            <div class="ng-confirm-content-pane" style="height: 51px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
                                <div class="ng-confirm-content" id="ng-confirm-box203454">
                                    <div class="ng-scope">
                                        <div style="padding: 15px 50px 0px">
                                            <button v-on:click="this.downloadABAFile" class="btn btn-grey btn-block" style="">Download ABA file</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="ng-confirm-buttons">
                                <button v-on:click="this.hidePopupDownloadABA" type="button" class="btn btn-default">
                                    <span class="ng-confirm-btn-text">close</span>
                                </button>
                            </div>
                            <div class="ng-confirm-clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--------------------
    END - popup Download ABA file
    -------------------->

    
    <!--------------------
    START - data file pdf
    --------------------->
    <vue-html2pdf
        :show-layout="false"
        :enable-download="false"
        :preview-modal="false"
        :paginate-elements-by-height="1080"
        :filename="titleName"
        :pdf-quality="2"
        :manual-pagination="false"
        :headerPage="optionHeader"
        :footerPage="optionFooter"
        pdf-format="a4"
        
        pdf-orientation="portrait"
        pdf-content-width="800px"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
    >
        <section slot="pdf-content" class="container pdf-layout" id="pdf-content">
        </section>
    </vue-html2pdf>
    <!--------------------
    END - data file pdf
    --------------------->
</div>
</template>

<script>
import {
    DELETE_RUNPAYROLL,
    DETAIL_RUNPAYROLL,
    STP_RUNPAYROLL,
    SUBMIT_RUNPAYROLL,
    UPDATE_COMPANY,
    PAYSLIP_FOR_RUNPAYROLL,
    PAYSLIP_FOR_EMPLOYEE,
    SEND_PAYSLIP,
    SEND_PAYSLIP_FOR_EMPLOYEE,
    SEND_PAYSLIP_FOR_PAYROLL,
    DOWNLOAD_XML,
    DOWNLOAD_ABA
} from "@/store/actionsType";
import * as Utils from "@/utils/Utils.js";
import * as Validate from "@/utils/Validate.js";
import * as Session from "@/utils/SessionStore"
import ApiManager from "@/api/ApiManager"
import * as RunPayRoll from '../RunPayRoll'
import VueHtml2pdf from '@/components/vue-html2pdf'
import * as PDF from "@/utils/PDF"
import axios from "axios";
import Token from '@/api/Token';
import moment from 'moment';
import log4javascript from 'log4javascript';

export default {
    name: "PayRunDetailsModal",
    props: [],
    components: {
        VueHtml2pdf
    },
    created: function() {
        this.$logger = log4javascript.getLogger("PayRunDetailsModal");
    },
    data() {
        return {
            listPolicy: {
                updatePayroll: true,
                deletePayroll: true
            },
            payroll: RunPayRoll,
            detailRun: "",
            deletedEmployeeIds : null,
            runpayroll: "",
            successCount: 50,
            company: "",
            companySTP: "",
            isDraft: false,
            isEditSTP: false,
            isSubmitSTP: false,
            isShowUpdateUpdateLastSTP: false,
            // send payslip
            optionHeader: {
                pagination: true,
                titles: []
            },
            optionFooter: {
                pagination: false,
                titles: []
            },
            emails: [],
            subject: [],
            htmlContent: [],
            password: [],
            files: [],
            size: 0,
            formData: null,
            fileName: '',
            isCreateFile: false,
            level: 0,
            nextProgress: 30,
            loadSendFile: 0,
            isSendFile: false,
            colorLinear: 'light-blue',
            paySlipId: 0,
        };
    },
    methods: {
        roundNumber(number) {
            return Utils.roundNumber(number)
        },
        formatDateToString(inputDate) {
            // return date string in australian format
            return Utils.formatDateToString(inputDate)
        },
        showTextEdit() {
            if (this.detailRun.statusPayroll === 'Lodged') {
                return "Update last STP record"
            } else {
                return "Edit"
            }
        },
        // Request API
        deleteRunPayRoll() {
            this.$root.$refs.AppCyrus.start()
            var request = {
                "companyId": this.runpayroll.companyId,
                "id": this.runpayroll.id,
            }
            this.$store.dispatch(DELETE_RUNPAYROLL, request)
                .then(() => {
                    this.$root.$refs.AppCyrus.finish()
                    this.$root.$refs.BaseLayout.deleteSTPInQueue(this.runpayroll.id)
                    this.closeModal()
                    this.hidePopupDeletePayRun()
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
        },
        getDetailPayroll() {
            this.$root.$refs.AppCyrus.start()
            this.$store.dispatch(DETAIL_RUNPAYROLL, this.runpayroll.id)
                .then((response) => {
                    this.$root.$refs.AppCyrus.finish()
                    this.detailRun = response.payrollDto
                    this.deletedEmployeeIds = response.deletedEmployeeIds;
                    if (this.detailRun.statusPayroll == "Draft") {
                        this.isDraft = true;
                    } else {
                        this.isDraft = false;
                    }
                    for(var i = 0; i < this.detailRun.adjustReview.length; i++) {
                        const element = this.detailRun.adjustReview[i]
                        if(element.listTermination.length > 0) {
                            element.isTermination = true
                        } else {
                            element.isTermination = false
                        }
                        if(element.overiteTax != 0 && element.overiteTax != undefined) {
                            element.temporaryTax = element.overiteTax
                        } else {
                            element.temporaryTax = element.tax
                        }
                    }
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
        },
        getPayslipForSummary() {
            var link = ApiManager.getUrlApi() + "/api/PaySlip/PaySlipForSummary?payrollId=" + this.runpayroll.id;
            this.openReportPdfInNewTab(link);
            // if(Utils.isLoadLinkPaySlip()) {
            //     this.viewPaySlipPdf(link);
            // } else {
            //     this.viewPaySlipData(link);
            // }
        },
        getPayslipForPayRoll() {
            var link = ApiManager.getUrlApi() + "/api/PaySlip/PayslipForPayRoll?payrollId=" + this.runpayroll.id;
            this.openReportPdfInNewTab(link);
            // if(Utils.isLoadLinkPaySlip()) {
            //     this.viewPaySlipPdf(link);
            // } else {
            //     this.viewPaySlipData(link);
            // }
            
        },
        getPayslipEmployee(payrollDetailId) {
            var link = ApiManager.getUrlApi() + "/api/PaySlip/PayslipForEmployee?payrollDetailId=" + payrollDetailId;
            this.openReportPdfInNewTab(link);
            // if(Utils.isLoadLinkPaySlip()) {
            //     this.viewPaySlipPdf(link);
            // } else {
            //     this.viewPaySlipData(link);
            // }
        },
        openReportPdfInNewTab(linkAPI){
            axios({
                url: linkAPI,
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token.getToken(),
                    handshake: 'Basic ' + Token.getCodeHandShake()
                },
                responseType: 'blob'
                // responseType: "arraybuffer",
                // headers: {
                //     'Accept': 'application/pdf'
                // }
            })
            .then((response) => {
                var file = new Blob([response.data], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                window.open(encodeURI(fileURL));
            })
            .catch((error) => {
                this.$logger.error(`openReportPdfInNewTab: ${error}`)
                if(error.response && error.response.status === 500){
                    if(!ApiManager.isCheckServer) {
                        ApiManager.checkServerIsUpdating()
                        .then((response) => {
                            if(!response.isEnable) {
                                window.location.href = "updateserver";
                            } else {
                                this.isError = true
                            }
                        }).catch(() => {
                            window.location.href = "updateserver";
                        })
                    }
                } else {
                    this.isError = true
                }
            });
        },
        seeETPEarning(data) {
            this.$modal.show('modal-ETP-earning', {
                etpTypeR: data.etpTypeR,
                lumSumD: data.lumSumD,
                etpCodeR: data.etpCodeR,
                etpTypeO: data.etpTypeO,
                terminationPay: data.terminationPay,
                earnings: data.earnings
            });
        },
        seeETPTax(data) {
            this.$modal.show('modal-ETP-tax', {
                taxETPTypeR: data.taxETPTypeR,
                taxETPTypeO: data.taxETPTypeO,
                taxTerminationPay: data.taxTerminationPay,
                tax: data.temporaryTax
            });
        },
        
        closeModal() {
            if (this.$root.$refs.RunOverview != undefined) {
                this.$root.$refs.RunOverview.reloadDataRunOverView(true);
            }
            document.getElementById('getResult').disabled = false
            this.$modal.hide('modal-payrundetails');
        },
        checkValidate() {
            var isValidate = true;
            window.$('.is-invalid').removeClass('is-invalid')
            var validate = window.$('.ng-signatoryName')
            if (Validate.isEmpty(this.companySTP.signatoryName)) {
                validate.addClass('is-invalid')
                isValidate = false;
            }
            if (!Validate.isSignName(this.companySTP.signatoryName)) {
                validate.addClass('is-invalid')
                isValidate = false;
            }
            validate = window.$('.ng-branchNumber')
            if (Validate.isEmpty(this.companySTP.branchNumber) || this.companySTP.branchNumber < 1) {
                validate.addClass('is-invalid')
                // icon.addClass('is-invalid')
                this.isValidate = false;
            }
            validate = window.$('.ng-contactNumber')
            if (Validate.isEmpty(this.companySTP.contactNumber)) {
                validate.addClass('is-invalid')
                isValidate = false;
            }
            var phone = this.companySTP.contactNumber.replace(/[\s()]+/g, '')
            if (!Validate.isPhone(phone)) {
                validate.addClass('is-invalid')
                isValidate = false;
            }
            validate = window.$('.ng-email')
            if (Validate.isEmpty(this.companySTP.contactEmail)) {
                validate.addClass('is-invalid')
                // icon.addClass('is-invalid')
                this.isValidate = false;
            }
            if (!Validate.isEmail(this.companySTP.contactEmail)) {
                validate.addClass('is-invalid')
                // icon.addClass('is-invalid')
                this.isValidate = false;
            }
            return isValidate
        },
        beforeOpen(event) {
            this.listPolicy = this.$root.$refs.BaseLayout.listPolicy
            if(event.params.runpayroll != undefined) {
                this.detailRun = ""
                this.runpayroll = event.params.runpayroll;
                this.isShowUpdateUpdateLastSTP = event.params.isShowUpdateUpdateLastSTP;
                this.getDetailPayroll()
                if (Session.has("currenCompany")) {
                    this.company = Session.get("currenCompany")
                    this.companySTP = Session.get("currenCompany").companySTP
                }
            }
        },
        updateCompany(modal) {
            if (this.checkValidate()) {
                this.$root.$refs.AppCyrus.start()
                this.companySTP.isEnable = true
                this.company.companySTP = this.companySTP
                this.company.companySTP.contactNumber = this.companySTP.contactNumber.replace(/[\s()]+/g, '')
                this.$store.dispatch(UPDATE_COMPANY, this.company)
                    .then(() => {
                        this.$root.$refs.AppCyrus.finish()
                        Session.set("currenCompany", this.company)
                        Utils.toastSuccess("Update company successful!");
                        this.$modal.hide(modal)
                        this.isEditSTP = false
                        if(this.isSubmitSTP) {
                            this.reportSTP()
                        } else {
                            this.downloadXMLFile()
                        }
                    }).catch(() => {
                        this.$root.$refs.AppCyrus.fail()
                    });
            }
        },
        hilight() {
            var elem = window.$(".ng-confirm-type-animated")
            if (elem.hasClass("ng-confirm-hilight")) {
                elem.removeClass("ng-confirm-hilight")
                elem.addClass("ng-confirm-hilight");
            } else {
                elem.addClass("ng-confirm-hilight");
                setTimeout(function () {
                    elem.removeClass("ng-confirm-hilight")
                }, 500);
            }
        },
        gotoPayment() {
            this.closeModal()
            this.$root.$refs.BaseLayout.gotoPayment()
        },
        showPopupEditPayRun() {
            var elem = window.$("#popupEditPayRun")
            elem.removeClass("hide-popup")
        },
        hidePopupEditPayRun() {
            var elem = window.$("#popupEditPayRun")
            elem.addClass("hide-popup")
        },
        submitPopupEditPayRun() {
            this.$router.push({
                path: '/updateRunpayroll',
                query: {
                    payrollId: this.detailRun.id
                }
            });
        },
        showPopupDeletePayRun() {
            var elem = window.$("#popupDeletePayRun")
            elem.removeClass("hide-popup")
        },
        hidePopupDeletePayRun() {
            var elem = window.$("#popupDeletePayRun")
            elem.addClass("hide-popup")
        },
        showPopupDownloadABA() {
            var elem = window.$("#popupDownloadABA")
            elem.removeClass("hide-popup")
        },
        hidePopupDownloadABA() {
            var elem = window.$("#popupDownloadABA")
            elem.addClass("hide-popup")
        },
        downloadABAFile() {
            this.$store.dispatch(DOWNLOAD_ABA, this.runpayroll.id)
            .then((blobFile) => {
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blobFile);
                const dateString = moment(new Date()).format("YYYY_MM_DD");
                link.setAttribute('download', `pay_${dateString}.zip`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch(() => {})
        },

        downloadXMLFile() {
            this.isSubmitSTP = false
            if (!this.checkValidate()) {
                this.$modal.show('modal-settingSTP');
            } else if (!this.companySTP.isEnable) {
                this.$modal.show('modal-enableSTP');
            } else {
                this.$store.dispatch(DOWNLOAD_XML, this.runpayroll.id)
                    .then((blobFile) => {
                        let link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blobFile);
                        link.setAttribute('download', 'Payroll_' + this.runpayroll.id + '.zip');
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    }).catch(() => {})
            }
        },

        viewPaySlipPdf(link) {
            this.$modal.show('modal-viewPaySlip-pdf', {
                link: link
            });
        },

        viewPaySlipData(dataPdf, titleName) {
            this.$modal.hide('modal-payrundetails')
            this.$modal.show('modal-viewPaySlip-data', {
                link: dataPdf,
                titleName: titleName
            });
        },

        submitRunPayroll() {
            this.detailRun.statusPayroll = 'Done'
            this.$root.$refs.AppCyrus.start()
            confirm.disabled = true
            
            this.$store.dispatch(SUBMIT_RUNPAYROLL, this.detailRun)
                .then(() => {
                    this.$root.$refs.AppCyrus.finish()
                    confirm.disabled = false
                    this.closeModal();

                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                    confirm.disabled = false
                });
        },
        showPopupAccuracy() {
            this.isSubmitSTP = true
            if (!this.checkValidate()) {
                this.$modal.show('modal-settingSTP');
            } else if (!this.companySTP.isEnable) {
                this.$modal.show('modal-enableSTP');
            } else {
                this.$modal.show('modal-accuracy');
            }
        },
        editSTP() {
            this.isEditSTP = true
            this.$modal.hide('modal-accuracy');
            this.$modal.show('modal-settingSTP');
        },
        reportSTP() {
            document.getElementById('submitSTP').disabled = true;
            this.$root.$refs.AppCyrus.start()
            Session.set('isSubmitSTP', true)
            this.$store.dispatch(STP_RUNPAYROLL, this.runpayroll.id)
                .then((response) => {
                    Session.set('isSubmitSTP', false)
                    
                    var data = response
                    if (data.message == 'Success' && (data.data.messageStatus == 'PARTIAL' || data.data.messageStatus == 'QUEUED' || data.data.messageStatus == 'PUSHED')) {
                        this.$root.$refs.AppCyrus.finish()
                        Utils.toastSuccess("Submit STP successful!");
                        // Đưa payroll vào hàng đợi
                        var now = new Date();
                        now.setMinutes(now.getMinutes() + 1);
                        this.$root.$refs.BaseLayout.sortQueue(this.runpayroll.id, now.getTime());
                        this.$root.$refs.BaseLayout.checkQueue();
                    } else {
                        this.$root.$refs.AppCyrus.fail()
                        Utils.toastError(JSON.parse(data).data);
                    }
                    document.getElementById('submitSTP').disabled = false;
                    this.closeModal()
                }).catch((error) => {
                    Session.set('isSubmitSTP', false)
                    this.$root.$refs.AppCyrus.fail()
                    document.getElementById('submitSTP').disabled = false;
                    this.closeModal()
                    try {
                        var detail = JSON.parse(error.detail)
                        
                        if (detail.data != null && detail.data != "") {
                            var data = []
                            
                            var array = detail.data.split('\n\t[error] ')
                            
                            array.shift();
                            for (var i = 0; i < array.length; i++) {
                                var ar = array[i].split(': ')
                                
                                data.push({
                                    severity_Code: "Error",
                                    error_Code: ar[0],
                                    short_Description: ar[1]
                                });
                            }
                            this.$root.$refs.BaseLayout.$modal.show('modal-messageSTP', {
                                data: data
                            });
                        }
                    } catch(e) {
                        detail = error.detail
                        Utils.toastError(detail);
                    }
                });
        },
        
        getSTPResult() {
            // Xóa payroll khỏi hàng đợi
            document.getElementById('getResult').disabled = true
            var stp = this.$root.$refs.BaseLayout.queue.find(element => element.id == this.runpayroll.id);
            this.$root.$refs.BaseLayout.queue.splice(this.$root.$refs.BaseLayout.queue.indexOf(stp), 1);
            clearTimeout(this.$root.$refs.BaseLayout.timeOut)
            this.$root.$refs.BaseLayout.getSTPStatus(this.runpayroll.id, false);
        },
        // Method send payslip
        sendPaySlip(payrollDetailId) {
            this.bulkSend()
            if(Utils.isLoadLinkPaySlip()) {
                this.isSendFile = true
                this.nextProgress = 100
                this.$store.dispatch(SEND_PAYSLIP_FOR_EMPLOYEE, payrollDetailId)
                    .then(() => {
                        this.loadSendFile = 100
                        this.isSendFile = false
                
                    }).catch(() =>{
                        this.colorLinear = 'red lighten-1'
                        this.loadSendFile = 100
                        this.isSendFile = false
                    })
            } else {
                this.$store.dispatch(PAYSLIP_FOR_EMPLOYEE, payrollDetailId)
                    .then((response) => {
                        
                        this.startSendFile(response, 1)
                    }).catch(() => {
                        this.colorLinear = 'red lighten-1'
                        this.loadSendFile = 100
                    });
            }
        },
        sendAllPaySlip() {
            this.bulkSend()
            if(Utils.isLoadLinkPaySlip()) {
                this.isSendFile = true
                this.nextProgress = 100
               //......
               this.$swal({
                        title: "Payslips are being sent to employee's email.",
                        imageUrl: "assets/img/send-email.gif",
                        imageWidth: 400,
                        imageHeight: 200,
                        showConfirmButton: false,
                        padding: 0,
                        customClass: {
                            header: 'not-padding',
                            image: 'not-margin image-swal'
                        },
                        didOpen: () => {
                            this.$store.dispatch(SEND_PAYSLIP_FOR_PAYROLL, this.runpayroll.id)
                            .then(() => {
                                 this.loadSendFile = 100
                                 this.isSendFile = false
                               // this.$root.$refs.BaseLayout.AfterEmployeeCheck();
                                   this.$swal({
                                    icon: 'success',
                                    title: 'Payslips sent to employee’s email!',
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        //this.closeModal()
                                    }
                                })
                            }).catch(() => {
                                 this.colorLinear = 'red lighten-1'
                        this.loadSendFile = 100
                        this.isSendFile = false
                                this.$$swal({
                                    icon: 'error',
                                    title: 'Payslips sent to employee’s email has error!',
                                }).then((result) => {
                                    if(result.isConfirmed) {
                               //         this.$modal.show("modal-newemployeestep4");
                                    }
                                })
                            });
                        }
                    })
                    //----
            //    this.$store.dispatch(SEND_PAYSLIP_FOR_PAYROLL, this.runpayroll.id)
            //         .then(() => {
            //             this.loadSendFile = 100
            //             this.isSendFile = false
            //         }).catch(() =>{
            //             this.colorLinear = 'red lighten-1'
            //             this.loadSendFile = 100
            //             this.isSendFile = false
            //         })
            // .....
            } else {
                this.$store.dispatch(PAYSLIP_FOR_RUNPAYROLL, this.runpayroll.id)
                    .then((response) => {
                        
                        var listEmployee = response.listEmployee
                        this.startSendFile(response, listEmployee.length)
                    }).catch(() => {
                        this.colorLinear = 'red lighten-1'
                        this.loadSendFile = 100
                    });
            }
        },
        bulkSend() {
            window.onbeforeunload = function() {
                return 'You are sending files to the server! Are you sure want to exit this page?';
            }
            this.paySlipId = this.detailRun.id
            var interval = setInterval(() => {
                var progress = this.loadSendFile
                var nextProgress = this.nextProgress
                
                if(progress >= 100) {
                    clearInterval(interval)
                    setTimeout(() => {
                        this.resetLoadSendFile()
                    }, 2000);
                    window.onbeforeunload = null
                } else if(progress >= 95 && !this.isSendFile) {
                    this.formData = new FormData()
                    let index = 0
                    this.sendFilePayslip(index)
                } else if(progress + 5 < nextProgress && progress + 5 < 95) {
                    this.loadSendFile += 5
                }
            }, 200)
        },
        startSendFile(response, lengthData) {
            this.files = []
            this.emails = []
            this.subject = []
            this.htmlContent = []
            this.password = []
            this.size = 0
            this.level = 70/lengthData
            
            this.nextProgress += this.level
            if(lengthData == 1) {
                setTimeout(() => {
                    this.createFilePDF(response.company, response.payroll, response.payrollDetail, response.employee, response.payrollSummary)
                }, 200);
            } else {
                var listEmployee = response.listEmployee
                var index = 0
                var element = listEmployee[index]
                var interval2 = setInterval(() => {
                    if (this.loadSendFile >= 95) {
                        clearInterval(interval2)
                    } else {
                        if(!this.isCreateFile) {
                            if(element == undefined) {
                                this.loadSendFile = 95
                            } else {
                                this.createFilePDF(response.company, response.payroll, response.listPayrollDetail[index], element, response.listPayrollSummary[index])
                                index++
                                element = listEmployee[index]
                            }
                        }
                    }
                }, 200)
            }
        },
        createFilePDF(company, payroll, payrollDetail, infoEmployee, summary) {
            this.isCreateFile = true
            this.optionHeader.titles = []
            this.optionFooter.titles = []
            this.optionHeader.titles.push(`Here is ${infoEmployee.firstName + ' ' + infoEmployee.lastName}'s Payslip`)
            this.optionFooter.titles.push('Cyruspay Payslip Report')
            var templateHtml = PDF.payslip(company, payroll, payrollDetail, infoEmployee, summary)
            
            document.getElementById('pdf-content').innerHTML = templateHtml;

            // todo: server should handle this
            this.emails.push(infoEmployee.email)
            this.subject.push('Pay Period from ' + this.formatDate(payroll.startPayPeriod) + ' to ' + this.formatDate(payroll.endPayPeriod) + '. Payment Date: ' + this.formatDate(payroll.paymentDate))
            this.htmlContent.push('Dear Empployee, <br><br>Your Payroll for period <strong>' + this.formatDateText(payroll.startPayPeriod) + '-' + this.formatDateText(payroll.endPayPeriod) + '</strong> can be viewed, printed or download as PDF from the attachment below.')
            this.password.push(this.formatDate(infoEmployee.birthDate).split('/').join(''))
            var name = infoEmployee.firstName + infoEmployee.lastName
            var date = this.formatDateToString(payroll.endPayPeriod)
            this.fileName = ("PayslipEnding" + date + "-" + name + ".pdf").split(' ').join('')
            this.$refs.html2Pdf.generatePdf()
        },
        hasGenerated(pdfFile) {
            this.files.push(new File([pdfFile], this.fileName, { type: 'application/pdf'}))
            this.$refs.html2Pdf.hasAlreadyParsed = false
            if(this.nextProgress >= 95) {
                this.loadSendFile = 95
            } else {
                this.loadSendFile = this.nextProgress
            }
            this.nextProgress += this.level
            this.isCreateFile = false
        },
        sendFilePayslip(index) {
            
            this.isSendFile = true
            if(this.size + this.files[index].size > (350000*3)) {
                this.$store.dispatch(SEND_PAYSLIP, this.formData).then(() => {
                    this.formData = new FormData()
                    this.size = 0
                    this.sendFilePayslip(index)
                }).catch(() => {
                    this.colorLinear = 'red lighten-1'
                    this.loadSendFile = 100
                    this.isSendFile = false
                })
            } else {
                this.formData.append('emails', this.emails[index])
                this.formData.append('subject', this.subject[index])
                this.formData.append('htmlContent', this.htmlContent[index])
                this.formData.append('password', this.password[index])
                this.formData.append('files', this.files[index])
                this.size += this.files[index].size
                if(this.files[index+1] == undefined) {
                    this.$store.dispatch(SEND_PAYSLIP, this.formData).then(() => {
                        this.loadSendFile = 100
                        this.isSendFile = false
                }).catch(() => {
                    this.colorLinear = 'red lighten-1'
                    this.loadSendFile = 100
                    this.isSendFile = false
                })
                } else {
                    this.sendFilePayslip(index+1)
                }
            }
        },
        resetLoadSendFile() {
            this.colorLinear = 'light-blue'
            this.loadSendFile = 0
            this.paySlipId = 0
            this.nextProgress = 30;
        },
        formatDate(inputDate) {
            var date = new Date(inputDate);
            var day = date.getDate();
            if(day < 10) {
                day = '0' + day
            }
            var month = date.getMonth()+1;
            if(month < 10) {
                month = '0' + month
            }
            var year = date.getFullYear();
            var dateShow = day + "/" + month + "/" + year;
            return dateShow
        },
        formatDateText(inputDate) {
            var m_names = new Array("Jan", "Feb", "Mar",
                "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");
            var date = new Date(inputDate);
            var day = date.getDate();
            if(day < 10) {
                day = '0' + day
            }
            var month = date.getMonth();
            
            var dateShow = day + " " + m_names[month];
            return dateShow
        },
        formatPhoneNumber() {
            var x = this.companySTP.contactNumber.replace(/[^+\d]/g, '').match(/(\+\d{0,5}|\d{0,4})(\d{0,3})(\d{0,3})/);
            if (x[1].indexOf('+') == 0) {
                this.companySTP.contactNumber = !x[2] ? '(' + x[1].slice(0,3) + ') ' + x[1].slice(3) : '(' + x[1].slice(0,3) + ') ' + x[1].slice(3) + ' ' + x[2] + (x[3] ? ' ' + x[3] : '');
            } else {
                this.companySTP.contactNumber = !x[2] ? x[1] : '' + x[1] + ' ' + x[2] + (x[3] ? ' ' + x[3] : '');
            }
        },
    },
    watch: {
        "companySTP.contactNumber": function (val) {
            var x = val.replace(/[^+\d]/g, '').match(/(\+\d{0,5}|\d{0,4})(\d{0,3})(\d{0,3})/);
            if (x[1].indexOf('+') == 0) {
                this.companySTP.contactNumber = !x[2] ? '(' + x[1].slice(0,3) + ') ' + x[1].slice(3) : '(' + x[1].slice(0,3) + ') ' + x[1].slice(3) + ' ' + x[2] + (x[3] ? ' ' + x[3] : '');
            } else {
                this.companySTP.contactNumber = !x[2] ? x[1] : '' + x[1] + ' ' + x[2] + (x[3] ? ' ' + x[3] : '');
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.hide-popup {
    display: none
}

.table th {
    font-weight: bolder;
    font-size: 0.8rem;
    vertical-align: baseline;
}

.size-title {
    font-size: 10px !important;
}

.date-run-payroll {
    padding-right: 15px;
    text-align: center;
    display: flex;
}

.title-extra {
    display: grid;
    margin-right: 15px;
}
</style>
