<template>
<div class='content-i ng-scope'>
    <CompanySettingModal />
    <div class="content-box ng-scope tb-listCompany">
        <!-- Layout -->
        <div class="row">
            <div class="col-sm-12">
                <div class="element-wrapper">
                    <h3 class='element-header'>
                        List Company
                        <!-- <a style="margin-top: 5px" @click="openModalCreateRequest('Annual')" class='ml-2 mb-1 btn  ng-binding ng-scope btn-primary'>
                            <i class='icon-feather-plus white'></i>
                            Annual Leave
                        </a>
                        <a  style="margin-top: 5px" @click="openModalCreateRequest('Sick')" class='ml-2 mb-1 btn  ng-binding ng-scope btn-primary'>
                            <i class='icon-feather-plus white'></i>
                            Sick Leave
                        </a> -->
                    </h3>
                    <div class="d-flex h-10">
                        <div style="width: 250px;">
                            <v-text-field v-on:keyup="searchCompany()" v-model="edtSearch" label="Search" append-icon="mdi-magnify" outlined style="margin-right: 20;"></v-text-field>
                        </div>
                        <a v-on:click="gotoCreateCompany()" class="btn btn-primary" style="height: 30px; margin-top: 5px">Set up a company</a>
                    </div>
                    <!-- Content -->
                    <div class="element-content">
                        <div class="row bg-card">
                            <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick" style="width: 100%">
                                <el-tab-pane label="Active" name="first">
                                    <div class="col">
                                        <!-- list data -->
                                        <div class="element-wrapper ng-scope">
                                            <div class="element-box-tp">
                                                <div class="table-responsive">
                                                    <table class="table table-padded" style="background: #F7F7F7; padding: 5px;">
                                                        <thead>
                                                            <tr>
                                                                <th class="text-center">
                                                                    <v-list-item-icon style="margin-top: 0px">
                                                                        <v-icon color="grey" style="font-size: 25px;">mdi-star</v-icon>
                                                                    </v-list-item-icon>
                                                                </th>
                                                                <th>
                                                                    <span v-on:click="sort('businessName', false)" class="sort">Business Name <i :class="(sortKey != 'businessName') ? 'icon-sort': (reverse) ? 'icon-sort-up':'icon-sort-down'"></i></span>
                                                                </th>
                                                                <th>
                                                                    <span v-on:click="sort('companyName', false)" class="sort">Trading Name <i :class="(sortKey != 'companyName') ? 'icon-sort': (reverse) ? 'icon-sort-up':'icon-sort-down'"></i></span>
                                                                </th>
                                                                <th>
                                                                    <span v-on:click="sort('companyABN', true)" class="sort">ABN <i :class="(sortKey != 'companyABN') ? 'icon-sort': (!reverse) ? 'icon-sort-up':'icon-sort-down'"></i></span>
                                                                </th>
                                                                <th class="text-center">
                                                                    <span v-on:click="sort('payrollStartDate', true)" class="sort">Start Date <i :class="(sortKey != 'payrollStartDate') ? 'icon-sort': (!reverse) ? 'icon-sort-up':'icon-sort-down'"></i></span>
                                                                </th>
                                                                <!-- <th class="">Leave</th> -->
                                                                <th style="width: 30px"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody v-click-outside="moreActive">
                                                            <tr v-for="(item) in listCompany" :key="item.id" :ref="item.id" class="animatedUp ng-scope">
                                                                <td class="text-center">
                                                                    <v-list-item-icon v-bind:class="item.favories > 0 ? 'starred' : 'recent'" @click="updateCompany(item)" class="v-application box-title" style="margin-top: 0px; cursor: pointer;">
                                                                        <v-icon v-bind:color="item.favories > 0 ? 'yellow' : 'gray'" style="font-size: 25px;">mdi-star</v-icon>
                                                                    </v-list-item-icon>
                                                                </td>
                                                                <td class="text-left">
                                                                    <span class="choose-company" @click="onClickItemCompany(item)">{{item.businessName}}</span>
                                                                </td>
                                                                <td class="text-left">
                                                                    <!-- <span class="status-pill smaller green" v-bind:class="(item.status == 'Reject')?'red': (item.status == 'Pending')? 'yellow' : ''"></span> -->
                                                                    <span class="ng-binding">{{item.companyName}}</span>
                                                                </td>
                                                                <td class="text-left">
                                                                    <span class="ng-binding">{{item.companyABN}}</span>
                                                                    <!-- <span class="smaller lighter ml-0 ng-binding" style="padding-left: 10px;">{{item.payPeriod}}</span> -->
                                                                </td>
                                                                <td class="text-center">
                                                                    <div class="ng-binding">{{formatDate(item.payrollStartDate)}}</div>
                                                                </td>
                                                                <!-- <td class="text-right bolder nowrap">
                                                                    <span class="ng-binding">{{item.totalHours}}</span>
                                                                </td> -->
                                                                <td class="bolder nowrap text-center" style="padding: 10px 0;">
                                                                    <button class="btn btn-md action" style="color: black" @click="openMore(item.id)" ng-disabled="loading">
                                                                        <i class="icon-feather-more-horizontal"></i>
                                                                    </button>
                                                                </td>
                                                                <div class="layout-more" v-show="isShowMore && id == item.id && activeName == 'first'">
                                                                    <button class="btn item-more" style="color: black;" @click="openModalCompanySetting(item.id)" ng-disabled="loading">
                                                                        <span>Company setting</span>
                                                                    </button>
                                                                    <button class="btn item-more" style="color: black" @click="showPopupMoveToTrash(item.id)" ng-disabled="loading">
                                                                        <span>Move to trash</span>
                                                                    </button>
                                                                </div>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bgPagination" v-show="true">
                                            <div class="itemPagination bgNotClick">
                                                <div id="img-left"></div>
                                            </div>
                                            <ul class="pagination itemPagination">
                                                <li class="bgClick"><span>1</span></li>
                                                <li class="bgNotClick"><span>2</span></li>
                                                <li class="bgNotClick"><span>3</span></li>
                                                <li class="bgNotClick"><span>4</span></li>
                                                <li class="bgNotClick"><span>5</span></li>
                                            </ul>
                                            <div class="itemPagination bgNotClick">
                                                <div id="img-right"></div>
                                            </div>
                                        </div>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="Invited" name="three">
                                    <div class="col">
                                        <!-- list data -->
                                        <div class="element-wrapper ng-scope">
                                            <div class="element-box-tp">
                                                <div class="table-responsive">
                                                    <table class="table table-padded" style="background: #F7F7F7; padding: 5px;">
                                                        <thead>
                                                            <tr>
                                                                <th class="text-center">
                                                                    <v-list-item-icon style="margin-top: 0px">
                                                                        <v-icon color="grey" style="font-size: 25px;">mdi-star</v-icon>
                                                                    </v-list-item-icon>
                                                                </th>
                                                                <th>
                                                                    <span v-on:click="sort('businessName', false)" class="sort">Business Name <i :class="(sortKey != 'businessName') ? 'icon-sort': (reverse) ? 'icon-sort-up':'icon-sort-down'"></i></span>
                                                                </th>
                                                                <th>
                                                                    <span v-on:click="sort('companyName', false)" class="sort">Trading Name <i :class="(sortKey != 'companyName') ? 'icon-sort': (reverse) ? 'icon-sort-up':'icon-sort-down'"></i></span>
                                                                </th>
                                                                <th>
                                                                    <span v-on:click="sort('companyABN', true)" class="sort">ABN <i :class="(sortKey != 'companyABN') ? 'icon-sort': (!reverse) ? 'icon-sort-up':'icon-sort-down'"></i></span>
                                                                </th>
                                                                <th class="text-center">
                                                                    <span v-on:click="sort('payrollStartDate', true)" class="sort">Start Date <i :class="(sortKey != 'payrollStartDate') ? 'icon-sort': (!reverse) ? 'icon-sort-up':'icon-sort-down'"></i></span>
                                                                </th>
                                                                <!-- <th class="">Leave</th> -->
                                                                <th style="width: 30px"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(item) in listCompanyInvited" :key="item.id" :ref="item.id" class="animatedUp ng-scope">
                                                                <td class="text-center">
                                                                    <v-list-item-icon v-bind:class="item.favories > 0 ? 'starred' : 'recent'" @click="updateCompany(item)" class="v-application box-title" style="margin-top: 0px; cursor: pointer;">
                                                                        <v-icon v-bind:color="item.favories > 0 ? 'yellow' : 'gray'" style="font-size: 25px;">mdi-star</v-icon>
                                                                    </v-list-item-icon>
                                                                </td>
                                                                <td class="text-left">
                                                                    <span class="choose-company" @click="onClickItemCompany(item)">{{item.businessName}}</span>
                                                                </td>
                                                                <td class="text-left">
                                                                    <!-- <span class="status-pill smaller green" v-bind:class="(item.status == 'Reject')?'red': (item.status == 'Pending')? 'yellow' : ''"></span> -->
                                                                    <span class="ng-binding">{{item.companyName}}</span>
                                                                </td>
                                                                <td class="text-left">
                                                                    <span class="ng-binding">{{item.companyABN}}</span>
                                                                    <!-- <span class="smaller lighter ml-0 ng-binding" style="padding-left: 10px;">{{item.payPeriod}}</span> -->
                                                                </td>
                                                                <td class="text-center">
                                                                    <div class="ng-binding">{{formatDate(item.payrollStartDate)}}</div>
                                                                </td>
                                                                <!-- <td class="text-right bolder nowrap">
                                                                    <span class="ng-binding">{{item.totalHours}}</span>
                                                                </td> -->
                                                                <td class="bolder nowrap text-center btn" style="padding: 20px 10px;">
                                                                    <button style="color: blue" v-on:click="openModalCompanySetting(item.id)">
                                                                        <i class="icon-feather-settings"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bgPagination" v-show="true">
                                            <div class="itemPagination bgNotClick">
                                                <div id="img-left"></div>
                                            </div>
                                            <ul class="pagination itemPagination">
                                                <li class="bgClick"><span>1</span></li>
                                                <li class="bgNotClick"><span>2</span></li>
                                                <li class="bgNotClick"><span>3</span></li>
                                                <li class="bgNotClick"><span>4</span></li>
                                                <li class="bgNotClick"><span>5</span></li>
                                            </ul>
                                            <div class="itemPagination bgNotClick">
                                                <div id="img-right"></div>
                                            </div>
                                        </div>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="Archived" name="second">
                                    <div class="col">
                                        <!-- list data -->
                                        <div class="element-wrapper ng-scope">
                                            <div class="element-box-tp">
                                                <div class="table-responsive">
                                                    <table class="table table-padded" style="background: #F7F7F7; padding: 5px;">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <span v-on:click="sort('businessName', false)" class="sort">Business Name <i :class="(sortKey != 'businessName') ? 'icon-sort': (reverse) ? 'icon-sort-up':'icon-sort-down'"></i></span>
                                                                </th>
                                                                <th>
                                                                    <span v-on:click="sort('companyName', false)" class="sort">Trading Name <i :class="(sortKey != 'companyName') ? 'icon-sort': (reverse) ? 'icon-sort-up':'icon-sort-down'"></i></span>
                                                                </th>
                                                                <th>
                                                                    <span v-on:click="sort('companyABN', true)" class="sort">ABN <i :class="(sortKey != 'companyABN') ? 'icon-sort': (!reverse) ? 'icon-sort-up':'icon-sort-down'"></i></span>
                                                                </th>
                                                                <th class="text-center">
                                                                    <span v-on:click="sort('payrollStartDate', true)" class="sort">Start Date <i :class="(sortKey != 'payrollStartDate') ? 'icon-sort': (!reverse) ? 'icon-sort-up':'icon-sort-down'"></i></span>
                                                                </th>
                                                                <!-- <th class="">Leave</th> -->
                                                                <th style="width: 30px"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody v-click-outside="moreArchived">
                                                            <tr v-for="(item) in listCompanyArchived" :key="item.id" :ref="item.id" class="animatedUp ng-scope">
                                                                <td>
                                                                    <span class="ng-binding">{{item.businessName}}</span>
                                                                </td>
                                                                <td class="">
                                                                    <!-- <span class="status-pill smaller green" v-bind:class="(item.status == 'Reject')?'red': (item.status == 'Pending')? 'yellow' : ''"></span> -->
                                                                    <span class="ng-binding">{{item.companyName}}</span>
                                                                </td>
                                                                <td>
                                                                    <span class="ng-binding">{{item.companyABN}}</span>
                                                                    <!-- <span class="smaller lighter ml-0 ng-binding" style="padding-left: 10px;">{{item.payPeriod}}</span> -->
                                                                </td>
                                                                <td class="text-center">
                                                                    <div class="ng-binding">{{formatDate(item.payrollStartDate)}}</div>
                                                                </td>
                                                                <!-- <td class="text-right bolder nowrap">
                                                                    <span class="ng-binding">{{item.totalHours}}</span>
                                                                </td> -->
                                                                <td class="bolder nowrap text-center" style="padding: 10px 0;">
                                                                    <button class="btn btn-md action" style="color: black" @click="openMore(item.id)" ng-disabled="loading">
                                                                        <i class="icon-feather-more-horizontal"></i>
                                                                    </button>
                                                                </td>
                                                                <div class="layout-more" v-show="isShowMore && id == item.id && activeName == 'second'">
                                                                    <button class="btn item-more" style="color: black;" @click="restore(item.id)" ng-disabled="loading">
                                                                        <span>Restore</span>
                                                                    </button>
                                                                    <button class="btn item-more" style="color: black" @click="showPopupDelete(item.id)" ng-disabled="loading">
                                                                        <span>Delete</span>
                                                                    </button>
                                                                </div>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bgPagination" v-show="false">
                                            <div class="itemPagination bgNotClick">
                                                <div id="img-left"></div>
                                            </div>
                                            <ul class="pagination itemPagination">
                                                <li class="bgClick"><span>1</span></li>
                                                <li class="bgNotClick"><span>2</span></li>
                                                <li class="bgNotClick"><span>3</span></li>
                                                <li class="bgNotClick"><span>4</span></li>
                                                <li class="bgNotClick"><span>5</span></li>
                                            </ul>
                                            <div class="itemPagination bgNotClick">
                                                <div id="img-right"></div>
                                            </div>
                                        </div>
                                    </div>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import CompanySettingModal from './modal/CompanySettingModal'
import * as Session from "@/utils/SessionStore"

import * as Utils from "@/utils/Utils"
import {
    GET_LIST_COMPANY,
    DETAIL_COMPANY,
    UPDATE_COMPANY,
    RESTORE
} from "@/store/actionsType";

export default {
    name: "companies",
    props: [],
    components: {
        CompanySettingModal
    },
    data() {
        return {
            sortKey: "",
            reverse: false,
            listCompany: [],
            listCompanyInvited: [],
            listCompanyArchived: [],
            listAllCompany: [],
            activeName: 'first',
            isShowMore: false,
            isShowStatus: false,
            id: 0,
            moreArchived: {
                handler: this.outsideTableArchived,
                middleware: this.middlewareMore,
                events: ["dblclick", "click"]
            },
            moreActive: {
                handler: this.outsideTableActive,
                middleware: this.middlewareMore,
                events: ["dblclick", "click"]
            },
        };
    },
    created: function () {
        this.$root.$refs.listCompany = this;
        this.getListCompany()
    },
    methods: {
        updateCompany(company) {
            var favories = company.favories ? 0 : 1
            this.$root.$refs.AppCyrus.start()
            this.$store.dispatch(DETAIL_COMPANY, company.id)
                .then((response) => {
                    this.$root.$refs.AppCyrus.finish()
                    var currentCompany = response
                    currentCompany.favories = favories
                    this.$store.dispatch(UPDATE_COMPANY, currentCompany)
                        .then(() => {
                            this.$root.$refs.AppCyrus.finish()
                            company.favories = favories
                            this.$root.$refs.BaseLayout.updateFavoriesMenu(favories, company.id)
                        }).catch(() => {
                            this.$root.$refs.AppCyrus.fail()
                        });
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
        },
        updateFavories(favories, id) {
            
            for (var i = 0; i < this.listCompany.length; i++) {
                if (this.listCompany[i].id == id) {
                    this.listCompany[i].favories = favories
                    break;
                }
            }
        },
        sort(column, boolean) {
            if (this.sortKey != column) {
                this.reverse = boolean;
                this.sortKey = column;
            } else {
                this.reverse = !this.reverse;
            }
            Utils.sortListJson(this.listCompany, this.sortKey, this.reverse);
            Utils.sortListJson(this.listCompanyArchived, this.sortKey, this.reverse);
        },
        middlewareMore() {
            return true;
        },
        openModalCompanySetting(id) {
            this.$root.$refs.AppCyrus.start()
            this.$store.dispatch(DETAIL_COMPANY, id)
                .then((response) => {
                    this.$root.$refs.AppCyrus.finish()
                    this.$modal.show('modal-companySetting', {
                        currentCompany: response
                    });
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
            this.openMore(this.id);
        },
        showPopupDelete(idCompany) {
            Session.set("idCompany", idCompany)
            var elem = window.$("#popupDeleteCompany")
            elem.removeClass("hide-popup")
            this.openMore(this.id);
        },
        showPopupMoveToTrash(idCompany) {
            var elem;
            if (Session.get('currenCompanyId') == idCompany) {
                elem = window.$("#popupNotMoveComapnyToTrash")
                elem.removeClass("hide-popup")
            } else {
                Session.set("idCompany", idCompany)
                elem = window.$("#popupMoveComapnyToTrash")
                elem.removeClass("hide-popup")
            }
            this.openMore(this.id);
        },
        onClickItemCompany(item) {
            Session.set("currenCompanyId", item.id)
            Session.set("currenCompanyName", item.businessName)
            Session.set("companyABN", item.companyABN)
            this.$root.$refs.BaseLayout.getDetailCompany();
            this.$root.$refs.BaseLayout.getListRunPayRoll();
            this.$root.$refs.BaseLayout.getListEmployee()
            this.$router.push({
                path: "/dashboard"
            });
        },
        formatDate(inputDate) {
            var m_names = new Array("Jan", "Feb", "Mar",
                "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");
            var date = new Date(inputDate);
            var day = date.getDate();
            var month = date.getMonth();
            var year = date.getFullYear();
            var dateShow = day + " " + m_names[month] + " " + year;
            //+ "-" + year
            return dateShow
        },
        calculateDate(startDate, endDate) {
            var star = new Date(startDate)
            var end = new Date(endDate)
            var date = end.getTime() - star.getTime()
            var Difference_In_Days = Math.round(date / (1000 * 3600 * 24))
            return Difference_In_Days + 1
        },
        // Request API
        getListCompany() {
            this.$root.$refs.AppCyrus.start()
            this.$store.dispatch(GET_LIST_COMPANY)
                .then((response) => {
                    
                    this.$root.$refs.AppCyrus.finish()
                    this.onLoadListCompany(response.listCompany)
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
        },
        searchCompany() {
            this.onLoadListCompany(this.listAllCompany)
        },
        onLoadListCompany(listCompany) {
            this.listAllCompany = listCompany
            
            this.listCompany = [];
            this.listCompanyInvited = [];
            this.listCompanyArchived = [];
            var list = listCompany;
            for (var i = 0; i < list.length; i++) {
                var company = list[i]
                if (this.edtSearch != null && this.edtSearch != "") {
                    var text = this.edtSearch.toLowerCase()
                    if (company.businessName.toLowerCase().indexOf(text) != -1 || company.companyName.toLowerCase().indexOf(text) != -1 || company.companyABN.indexOf(text) != -1) {
                        this.addCompanyToList(company)
                    }
                } else {
                    this.addCompanyToList(company)
                }
            }
        },
        addCompanyToList(company) {
            if (company.userEmail != Session.get("Profile").email) {
                this.listCompanyInvited.push(company)
            } else {
                if (company.active == "ACTIVE") {
                    this.listCompany.push(company);
                } else {
                    this.listCompanyArchived.push(company);
                }
            }
        },
        gotoCreateCompany() {
            this.$router.push({
                name: "company",
                params: {
                    isNotBack: false
                }
            })
        },
        openMore(id) {

            if (this.isShowMore && this.id == id) {
                this.isShowMore = false;
                this.$refs[id][0].classList.remove('transform');
            } else {
                window.$('.transform').removeClass("transform");
                this.isShowMore = true;
                this.$refs[id][0].classList.add('transform');
                this.id = id;
            }
        },
        outsideTableArchived() {
            if (this.isShowMore && this.activeName == 'second') {
                this.openMore(this.id)
            }
        },
        outsideTableActive() {
            if (this.isShowMore && this.activeName == 'first') {
                this.openMore(this.id)
            }
        },
        openStatus() {
            this.isShowStatus = true;
        },
        closeStatus() {
            this.isShowStatus = false;
        },
        restore(id) {
            this.$root.$refs.AppCyrus.start()
            this.$store.dispatch(RESTORE, id)
                .then(() => {
                    this.$root.$refs.AppCyrus.finish()
                    this.changeActive(id);
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
            this.openMore(this.id);
        },
        changeActive(id) {
            var company = null
            for (var i = 0; i < this.listCompanyArchived.length; i++) {
                company = this.listCompanyArchived[i]
                if (company.id == id) {
                    company.active = "ACTIVE"
                    this.listCompany.push(company);
                    this.listCompanyArchived.splice(i, 1);
                    this.$root.$refs.BaseLayout.changeActive(id)
                    break;
                }
            }
            this.changeListAllCompany(company)
        },
        changeArchived(id) {
            var company = null
            for (var i = 0; i < this.listCompany.length; i++) {
                company = this.listCompany[i]
                if (company.id == id) {
                    company.active = "INACTIVE"
                    this.listCompanyArchived.push(company);
                    this.listCompany.splice(i, 1);
                    this.$root.$refs.BaseLayout.changeArchived(id)
                    break;
                }
            }
            this.changeListAllCompany(company)
        },
        changeListAllCompany(company) {
            for (var i = 0; i < this.listAllCompany.length; i++) {
                if (this.listAllCompany[i].id == company.id) {
                    this.listAllCompany[i] = company
                }
            }
        },
        deleteArchived(id) {
            for (var i = 0; i < this.listCompanyArchived.length; i++) {
                if (this.listCompanyArchived[i].id == id) {
                    this.listCompanyArchived.splice(i, 1);
                }
            }
            for (i = 0; i < this.listAllCompany.length; i++) {
                if (this.listAllCompany[i].id == id) {
                    this.listAllCompany.splice(i, 1)
                }
            }
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.grey {
    color: rgb(145, 150, 150);
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
}

.yellow {
    color: #ffd651;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
}

.bg-card {
    background: white;
    padding: 10px;
    border-radius: 15px;
}

.choose-company {
    cursor: pointer;
    color: #0052cc;
}

.choose-company:hover {
    color: #3c73c7;
    text-decoration-line: underline;
}
</style>
