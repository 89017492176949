<template>
<div class="login-wrapper">
    <div v-if="showError" class="form-login" style="min-height: 300px;">
        <h2>Cyruspay</h2>
        <div style="height: 150px;">
            <h5>{{errorMes.status}}</h5>
            <h6>{{errorMes.message}}</h6>
        </div>
        <div class="login">
            <button class="submit-btn btn btn-primary" type="button" @click="gotoLogin()">
                <span class="span">Go to login</span>
                <img class="imgbtnLogin" src="/assets/img/icon_login.png" />
            </button>
        </div>
    </div>
    <div v-else-if="unregistered" class="form-login" style="min-height: 300px;">
        <h2>Cyruspay</h2>
        <div style="height: 150px;">
            <v-app>
                <h5 class="text-left">Welcome {{infoUser.email}}</h5>
                <h6 class="text-left">How are you?</h6>
                <v-row style="margin-top: 20px">
                    <v-col class="format-input" cols="0" sm="12" md="12">
                        <v-select :attach="true" v-model="role" :items="listRole" label="You are" outlined></v-select>
                    </v-col>
                </v-row>
            </v-app>
        </div>
        <div class="login">
            <button class="submit-btn btn btn-primary" type="button" @click="loginWithGoogle()">
                <span class="span">Sign up</span>
                <img class="imgbtnLogin" src="/assets/img/icon_login.png" />
            </button>
        </div>
    </div>
    <v-app style="background: transparent" v-else>
        <v-progress-circular
        indeterminate
        color="green"
        size="50"
        width="5"
        ></v-progress-circular>
    </v-app>
</div>
</template>

<script>
// import firebase from 'firebase/app';
import axios from "axios"
import 'firebase/auth';
import {
    CHECK_CODE_ENCODE,
    CHECK_GOOGLE_REGISTER,
    LOGIN_WITH_GOOGLE,
    ADD_ACCOUNTANT,
    SAVE_DEVICE,
    USER_INFO
} from "@/store/actionsType";
import * as Session from "@/utils/SessionStore"
import log4javascript from 'log4javascript';
import * as DataSelect from "@/utils/DataSelect"
import ApiManager from "@/api/ApiManager"

export default {
    name: "Login",
    components: {},
    data() {
        return {
            showError: false,
            unregistered: false,
            infoUser: "",
            id_token: "",
            errorMes: null,
            role: "User",
            listRole: DataSelect.getList('listRole'),
            isInvitationAccountant: false,
            code: "",
            companyId: "",
        };
    },
    created: function () {
        this.$logger = log4javascript.getLogger("AccuracyLoginGmail");
        if(Session.has('dataAddAccountant')) {
            this.code = Session.get('dataAddAccountant').code
            this.companyId = Session.get('dataAddAccountant').companyId
            this.checkCodeEncode();
        } else {
            this.getInfomationGmail()
        }
    },
    methods: {
        checkCodeEncode(){
            this.$root.$refs.AppCyrus.start()
            this.$store.dispatch(CHECK_CODE_ENCODE, this.code)
                .then((response) => {
                    this.$root.$refs.AppCyrus.finish()
                    if(!response.result){
                        this.$router.push({
                            path: "/link-expired"
                        });
                    } else {
                        this.isInvitationAccountant = true
                        this.getInfomationGmail()
                    }
                }).catch(() => {
                    this.$root.$refs.AppCyrus.finish()
                })
        },
        getInfomationGmail() {
            if(this.$route.query.state != undefined && Session.has('state') && this.$route.query.state == Session.get('state')) {
                axios({
                    url: "https://oauth2.googleapis.com/token",
                    method: 'POST',
                    params: {
                        code: this.$route.query.code,
                        client_id: "391983556908-9pr4072jg6qj8ktres7nq2bbfrk33p90.apps.googleusercontent.com", //"628259458435-p1p1tamt62gfef4q5qidukmfb1govr17.apps.googleusercontent.com",
                        client_secret: "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", //"zqVW2haxDdoFvbKzXXanJtZu",
                        redirect_uri: ApiManager.getUrlDomainUI()+"/accuracy-gmail",
                        grant_type: "authorization_code"
                    },
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded"
                    },
                }).then((response) => {
                    this.id_token = response.data.id_token
                    axios({
                        url: "https://oauth2.googleapis.com/tokeninfo?id_token="+this.id_token,
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }).then((GoogleUser) => {
                        this.infoUser = GoogleUser.data
                        this.checkGoogle()
                    }).catch((error) => {
                        this.errorHandling(error)
                    })
                }).catch((error) => {
                    this.errorHandling(error)
                })
            }
        },
        checkGoogle() {
            var request = {
                "id_token": this.id_token,
                "firstName": this.infoUser.family_name,
                "lastName": this.infoUser.given_name,
                "email": this.infoUser.email,
                "role": this.role
            }
            this.$store.dispatch(CHECK_GOOGLE_REGISTER, request)
                .then((response) => {
                    
                    if(response.isRegisted) {
                        this.role = response.role
                        this.loginWithGoogle()
                    } else {
                        if (this.isInvitationAccountant) {
                            this.role = "Accountant"
                            this.loginWithGoogle()
                        } else {
                            this.unregistered = true
                        }
                    }
                }).catch((error) => {
                    this.$logger.error(`checkGoogle: ${error}`)
                    this.showError = true
                    if(Session.get('hasGetHandshake')) {
                        ApiManager.getHandShake().then(() => {
                            Session.set('hasGetHandshake', false)
                            this.checkGoogle()
                        }).catch(() => {
                            if (this.$global.path != '/updateserver') {
                                this.$modal.show('modal-tryhandshake', {
                                    data: ''
                                });
                            }
                            this.errorMes = {
                                status: '400',
                                message: error.detail == undefined ? error : error.detail
                            }
                        });
                    } else {
                        this.errorMes = {
                            status: '400',
                            message: error.detail == undefined ? error : error.detail
                        }
                    }
                });
        },
        loginWithGoogle() {
            // Login with google by Google-oAuth
            var modelLogin = {
                "id_token": this.id_token,
                "firstName": this.infoUser.family_name,
                "lastName": this.infoUser.given_name,
                "email": this.infoUser.email,
                "role": this.role
            }
            Session.set('isLogin', true)
            console.log("ModelLogin ", JSON.stringify(modelLogin))
            this.$store.dispatch(LOGIN_WITH_GOOGLE, modelLogin)
                .then((response) => {
                    if(response != undefined) {
                        if(this.isInvitationAccountant) {
                            var dataRequest = {
                                codeEncode: this.code,
                                companyId: this.companyId,
                                email: this.infoUser.email
                            }
                            this.$root.$refs.AppCyrus.start()
                            this.$store.dispatch(ADD_ACCOUNTANT, dataRequest)
                                .then(() => {
                                    Session.remove('dataAddAccountant')
                                    this.$root.$refs.AppCyrus.finish()
                                    this.successHandling(response)
                                }).catch((error) => {
                                    this.$root.$refs.AppCyrus.fail()
                                    this.errorHandlingLogin(error)
                                })
                        } else {
                            this.successHandling(response)
                        }
                    }
                }).catch((error) => {
                    this.errorHandlingLogin(error)
                });
        },
        successHandling(accuracy) {
            this.$store.dispatch(USER_INFO)
                .then((response) => {
                Session.remove('state')
                Session.set('isLogin', false)
                var name = response.firstName + " " + response.lastName
                Session.remove("currenCompanyId")
                Session.remove("currenCompanyName")
                Session.set("Username", name)
                var date = new Date()
                date.setHours(0)
                date.setMinutes(0)
                date.setSeconds(0)
                date.setMilliseconds(0)
                Session.set("dateLogin", date)
                Session.set("Profile", {
                    'id': response.id,
                    "firstName": response.firstName,
                    'lastName': response.lastName,
                    "birthDate": date,
                    "email": this.infoUser.email,
                    "role": response.role,
                    "creationTime": response.creationTime
                })
                this.$store.dispatch(SAVE_DEVICE, response.id)
                if (this.isInvitationAccountant || accuracy.isSetupCompany) {
                    this.$router.push({
                        path: "/dashboard"
                    });
                } else {
                    this.$router.push({
                        name: "company",
                        params: {
                            isNotBack: true
                        }
                    });
                }
            }).catch((error) => {
                this.errorHandlingLogin(error)
            })
        },
        errorHandlingLogin(error) {
            this.showError = true
            Session.set('isLogin', false)
            if(Session.get('hasGetHandshake')) {
                ApiManager.getHandShake().then(() => {
                    Session.set('hasGetHandshake', false)
                    this.loginWithGoogle()
                }).catch(() => {
                    if (this.$global.path != '/updateserver') {
                        this.$modal.show('modal-tryhandshake', {
                            data: ''
                        });
                    }
                    this.errorMes = {
                        status: '400',
                        message: error.detail == undefined ? error : error.detail
                    }
                });
            } else {
                this.errorMes = {
                    status: '400',
                    message: error.detail == undefined ? error : error.detail
                }
            }
        },
        errorHandling(error) {
            this.showError = true
            var errorData;
            if (error.response != undefined && error.response.data != undefined) {
                errorData = error.response.data;
                
            }else {
                errorData = error;
                
            }
            this.errorMes = {
                status: error.response.status,
                message: errorData.detail == undefined ? errorData : errorData.detail
            }
        },
        gotoLogin() {
            var query = ""
            if(this.isInvitationAccountant) {
                query = "?companyid="+this.companyId+"&code="+this.code
            }
            this.$router.push({
                path: "/login"+query
            });
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '/assets/css/login.css';
</style>
