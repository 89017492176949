import Vue from 'vue'
import AppCashbook from './AppCashbook.vue'
import store from './store'
import router from './router'
import ApiManager from './api/ApiManager'
import ClickOutside from './components/clickOutside.js';

Vue.directive('click-outside', ClickOutside);

Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.config.silent = true;


// setup logging
import log4javascript from 'log4javascript';

// configure log to send to console
const consoleAppender = new log4javascript.BrowserConsoleAppender();
const logLayout = new log4javascript.PatternLayout("%d{HH:mm:ss} %-5p [%c] - %m%n");
consoleAppender.setLayout(logLayout);

// configure log to send to remote endpoint via ajax
const ajaxAppender = new log4javascript.AjaxAppender(process.env.VUE_APP_LOG_URL);
const jsonLayout = new log4javascript.JsonLayout();
ajaxAppender.setLayout(jsonLayout);
ajaxAppender.addHeader("Content-Type", "application/json");
ajaxAppender.setThreshold(log4javascript.Level.ERROR); // Send ERROR or higher only
ajaxAppender.setBatchSize(4);
ajaxAppender.setSendAllOnUnload(true); // send all logs on page unload
ajaxAppender.setPostVarName(null); // send data as raw JSON

const rootLogger = log4javascript.getRootLogger();
rootLogger.addAppender(consoleAppender);
rootLogger.addAppender(ajaxAppender);

rootLogger.setLevel(process.env.NODE_ENV === 'production' ? log4javascript.Level.INFO : log4javascript.Level.DEBUG);
Vue.prototype.$logger = rootLogger;

Vue.config.errorHandler = (err, vm, info) => {
    const componentName = vm.$options.name || '<anonymous>';
    rootLogger.error(`Error in ${componentName} [info:${info}] ${err.message} -> ${err.stack}`);
};

rootLogger.info("Starting App")

window.$ = window.jQuery = require('jquery') // why use jquery?


// Progressbar
const options = {
    color: '#6554E0',
    failedColor: 'red',
    thickness: '5px',
    autoRevert: true,
    location: 'top',
    inverse: false,
    autoFinish: false
}

// For Vuelidate Control
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate);

import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, options)

// VueSession
import VueSession from 'vue-session'
Vue.use(VueSession, { persist: true }) //persist Giá trị Boolean để xác định xem dữ liệu được lưu trữ trong phiên có thể tồn tại giữa các tab và phiên bản trình duyệt hay không.

// VModal
import VModal from 'vue-js-modal'
Vue.use(VModal)

// Toast
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);

// Element-ui
import Element from 'element-ui'
Vue.use(Element)

// Component UI
import vuetify from '../src/plugins/vuetify.js'

// Tel-input
import VueTelInput from 'vue-tel-input'
Vue.use(VueTelInput, {
    validCharactersOnly: true,
    inputOptions: {
        showDialCode: false,
        tabindex: 0
    }
})

// Click-outside
import vco from "v-click-outside";
Vue.use(vco)

// Letter-avatar
import VueLetterAvatar from 'vue-letter-avatar';
Vue.use(VueLetterAvatar);

//vue-currency-input
import VueCurrencyInput from 'vue-currency-input'
Vue.use(VueCurrencyInput)

// vue-sweetalert2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

// Initialize Firebase
// todo: verify the config below
import firebase from 'firebase/app';
const firebaseConfig = {
    apiKey: "AIzaSyDnV2boe7t5X-g_yF6zK8dGdv99_4Woib0",
    authDomain: "cyruspay-1587960530490.firebaseapp.com",
    databaseURL: "https://cyruspay-1587960530490.firebaseio.com",
    projectId: "cyruspay-1587960530490",
    storageBucket: "cyruspay-1587960530490.appspot.com",
    messagingSenderId: "628259458435",
    appId: "1:628259458435:web:f10d61e7f4c65449f0bab6",
    measurementId: "G-189BEGGCLC"
};
if (!firebase.apps.length) {   
    firebase.initializeApp(firebaseConfig);
}
// Firebase Notification
//import _initializeFirebaseMessage from './pushNotification';
//_initializeFirebaseMessage;
// Firebase RemoteConfig
//import _initializeFirebaseRemoteConfig from './remoteConfig';
//_initializeFirebaseRemoteConfig();

// Login with google GoogleAuth
import GoogleAuth from '../src/config/google_oAuth.js'
const gauthOption = {
    clientId: '628259458435-p1p1tamt62gfef4q5qidukmfb1govr17.apps.googleusercontent.com',
    scope: 'profile email',
    prompt: 'select_account'
}
Vue.use(GoogleAuth, gauthOption)

// Paramerter globally
Vue.prototype.$global = { path: "/" };


import { StripePlugin } from "@vue-stripe/vue-stripe";

// options for stripe
const spOptions = {
  pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
  stripeAccount: process.env.VUE_APP_STRIPE_ACCOUNT,
  apiVersion: process.env.VUE_APP_STRIPE_API_VERSION,
  locale: process.env.LOCALE,
};

Vue.use(StripePlugin, spOptions);

// Init API
ApiManager.init();

const component = new Vue({
    router,
    store,
    vuetify,
    render: h => h(AppCashbook), // i.e AppCashbook.vue
    watch: {
        '$route': function(value) {
            this.$global.path = value.fullPath
        },
    },
})
component.$mount('#app_cashbook')