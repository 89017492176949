<template>
<modal name="modal-settingsorganisation" with="auto" height="auto" style="z-index: 9999">
    <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog" aria-labelledby="modal-title" aria-describedby="modal-body" size="lg" index="0" animate="animate" ng-style="{&#39;z-index&#39;: 1050 + $topModalIndex*10, display: &#39;block&#39;}" tabindex="-1" uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true" style="z-index: 1050; display: block; overflow: auto;">
        <div class="modal-dialog modal-lg">
            <div class="modal-content" uib-modal-transclude="">
                <div class="modal-content text-center onboarding-modal modal-centered ng-scope">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 id="exampleModalLabel" class="modal-title" style="text-align:center;">
                                <span style="color:#334152">Organisation Settings</span>
                                <button v-show="true" class="btn btn-transparent ng-scope ng-isolate-scope ngdo ngdo-bottom-left" ng-dropover="254" ng-dropover-options="{&#39;position&#39;: &#39;bottom-left&#39;}">
                                    <i v-on:click="this.openDropover" class="fa fa-ellipsis-v"></i>
                                    <div ng-dropover-contents="" class="ngdo-contents" style="position: absolute; left: 236.484px; top: 48.2969px; display: none;">
                                        <a v-on:click="this.changeStatusToAgent" class="popover-contents text-primary">Change account type to Agent</a>
                                    </div>
                                </button>
                            </h5>
                            <button v-on:click="this.closeModal" aria-label="Close" class="box-title close" data-dismiss="modal" type="button"><span aria-hidden="true"> ×</span></button>
                        </div>

                        <div class="modal-body text-left">
                            <div class="os-tabs-w">
                                <div class="os-tabs-controls os-tabs-complex">
                                    <ul class="nav nav-tabs">
                                        <li class="nav-item">
                                            <a v-on:click="changeTabs('company')" v-bind:class="['nav-link', this.currentTab === 'company' ? 'active' : '']" :aria-expanded="this.currentTab != 'company'">
                                                <span class="tab-label">Organisation Details</span>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a v-on:click="changeTabs('payroll')" v-bind:class="['nav-link', this.currentTab === 'payroll' ? 'active' : '']" :aria-expanded="this.currentTab != 'payroll'">
                                                <span class="tab-label">Payroll Details</span>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a v-on:click="changeTabs('leave')" v-bind:class="['nav-link', this.currentTab === 'leave' ? 'active' : '']" :aria-expanded="this.currentTab != 'leave'">
                                                <span class="tab-label">Leave Settings</span>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a v-on:click="changeTabs('linkedAgent')" class="nav-link ng-hide" :aria-expanded="this.currentTab != 'linkedAgent'">
                                                <span class="tab-label">Linked Agent</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!-- ngShow: main-content "company"-->
                            <div v-show="this.currentTab === 'company'">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="">Business Name (Shown in payslips)</label>
                                            <input class="form-control error ng-pristine ng-untouched ng-valid ng-empty" placeholder="" type="text" name="business-name" ng-class="{ &#39;is-invalid&#39; : companyError.businessName }" ng-model="companyData.bn">
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <label class="dropdown">
                                            <div class="dd-button ng-binding">
                                                ABN
                                            </div>
                                            <input type="checkbox" class="dd-input">
                                            <ul class="dd-menu">
                                                <li ng-click="useWPN = false;companyData.wpn = null">ABN</li>
                                                <li ng-click="useWPN = true;companyData.abn = null">WPN</li>
                                            </ul>
                                        </label>
                                        <span class="text-danger">*</span>
                                    </div>
                                    <div class="col-sm-6 d-sm-block d-none ng-binding">
                                        Company Legal Name <span class="text-danger">*</span>
                                    </div>
                                </div>
                                <form ng-submit="checkABN()" name="abnForm" ng-show="!useWPN &amp;&amp; !companyData.abn" class="ng-pristine ng-valid ng-valid-maxlength ng-hide">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-sm-6 mb-1">
                                                <input class="form-control error ng-pristine ng-untouched ng-valid ng-empty ng-valid-maxlength" type="text" placeholder="12 345 678 900" ng-class="{ &#39;is-invalid&#39; : companyError.abn }" ng-model="abn_temp" maxlength="14">
                                            </div>
                                            <div class="col-sm-6 mb-1">
                                                <input class="btn btn-primary" style="margin-top: 4px;" type="submit" value="Search">
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <form ng-show="companyData.abn &amp;&amp; !useWPN" class="ng-pristine ng-valid">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <!-- <label for=""> ABN</label> -->
                                                <div ng-click="companyData.abn=null" class="text-primary pointer ng-binding">12341234123</div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <!-- <label for="">Company Legal Name </label> -->
                                                <div ng-click="companyData.abn=null" class="text-primary pointer ng-binding" ng-bind-html="companyData.c">DEMO Pty Ltd.</div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <hr>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="">Address 1 <span class="text-danger">*</span></label>
                                            <input class="form-control error ng-pristine ng-untouched ng-valid ng-not-empty" placeholder="" type="text" name="address1" ng-class="{ &#39;is-invalid&#39; : companyError.address1 }" ng-model="companyData.a_1">
                                            <!-- <input class="form-control" placeholder="" type="text"
                                            name="address1"
                                            ng-places-autocomplete
                                            ng-model="query"
                                            pa-options="paOptions"
                                            pa-on-place-ready="placesCallback"
                                            pa-trigger="paTrigger"
                                            autocomplete="off"
                                            > -->
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="">Address 2</label>
                                            <input class="form-control ng-pristine ng-untouched ng-valid ng-empty" name="address2" ng-model="companyData.a_2" type="text">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="">Suburb <span class="text-danger">*</span></label>
                                            <input class="form-control ng-pristine ng-untouched ng-valid ng-not-empty" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" name="suburb" ng-class="{ &#39;is-invalid&#39; : companyError.suburb}" ng-model="companyData.a_c" type="text">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="">Postcode <span class="text-danger">*</span></label>
                                            <input class="form-control ng-pristine ng-untouched ng-valid ng-not-empty" name="postcode" ng-class="{ &#39;is-invalid&#39; : companyError.postcode }" ng-model="companyData.a_p" type="text">
                                        </div>
                                    </div>

                                </div>
                                <div class="row">
                                    <!-- <div class="col-sm-6">
                                        <div class="form-group">
                                        <label for="">Business Name <span class="text-danger">*</span></label>
                                        <input class="form-control" placeholder="" tybusinessNamepe="text"
                                            name="business-name" ng-class="{ 'is-invalid' : companyError.businessName }"
                                            ng-model="companyData.businessName">
                                        </div>
                                    </div> -->
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for=""> State <span class="text-danger">*</span></label>
                                            <select class="form-control ng-pristine ng-untouched ng-valid ng-not-empty" name="state" ng-model="companyData.a_s">
                                                <option value="ACT">ACT</option>
                                                <option value="NSW" selected="selected">NSW</option>
                                                <option value="NT">NT</option>
                                                <option value="QLD">QLD</option>
                                                <option value="SA">SA</option>
                                                <option value="TAS">TAS</option>
                                                <option value="VIC">VIC</option>
                                                <option value="WA">WA</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="">Contact number <span class="text-danger">*</span></label>
                                            <input class="form-control ng-pristine ng-untouched ng-valid ng-not-empty" placeholder="" type="phone" name="phone" ng-class="{ &#39;is-invalid&#39; : companyError.phone }" ng-model="companyData.ph">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- ngShow: main-content "payroll"-->
                            <div v-show="this.currentTab == 'payroll'">
                                <form ng-submit="completePayrollDetail()" name="payrollDetailsForm" class="ng-pristine ng-valid ng-valid-step">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label for="">Work Hours</label>
                                                <select class="form-control ng-pristine ng-untouched ng-valid ng-not-empty" ng-model="companyData.wh">
                                                    <option value="7.6" selected="selected">
                                                        7.6 Hours per day
                                                    </option>
                                                    <option value="8">
                                                        8 Hours per day
                                                    </option>
                                                    <option value="0">
                                                        Other
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 ng-hide" ng-show="companyData.wh == 0">
                                            <div class="input-group mt-sm-4  mt-xs-0 mb-3" style="padding-top:5px">
                                                <input class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-step" type="number" step=".01" ng-class="{ &#39;is-invalid&#39; : companyError.workHours_other}" ng-model="companyData.workHours_other">
                                                <div class="input-group-append">
                                                    <div class="input-group-text">
                                                        hours per day
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label for="">Payroll Start Date</label>
                                                <date-picker v-model="time1" valueType="format" class="form-control date-picker ng-pristine ng-untouched ng-valid ng-isolate-scope ng-not-empty" style="padding-top: 8px;padding-bottom: 8px;"></date-picker>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label for="">Pay Period</label>
                                                <select class="form-control ng-pristine ng-untouched ng-valid ng-not-empty" ng-model="companyData.pp">
                                                    <option value="w">Weekly</option>
                                                    <option value="f" selected="selected">Fortnightly</option>
                                                    <option value="m">Monthly</option>
                                                    <option value="q">Quarterly</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <!-- ngShow: main-content "leave"-->
                            <div v-show="this.currentTab == 'leave'">
                                <form name="payslipOptionsForm" class="ng-pristine ng-valid">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h6 class="mb-2">Leave Settings</h6>
                                            <div class="form-check" style="margin: 5px 0;">
                                                <input class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" value="" id="allowLongServiceLeave" ng-model="companyData.le.lsl">
                                                <label class="form-check-label" for="allowLongServiceLeave">
                                                    Allow long service leave (Beta)
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h6 class="mb-2">Shown in payslip</h6>
                                            <div class="form-check mt-3" style="margin: 5px 0;">
                                                <input class="form-check-input ng-pristine ng-untouched ng-valid ng-not-empty" type="checkbox" value="" id="annualLeaveBalanceInPayslip" ng-model="companyData.psl.al">
                                                <label class="form-check-label" for="annualLeaveBalanceInPayslip">
                                                    Annual leave balance
                                                </label>
                                            </div>
                                            <div class="form-check ng-hide" style="margin: 5px 0;" ng-show="companyData.le &amp;&amp; companyData.le.lsl">
                                                <input class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" value="" id="longServiceLeaveBalanceInPayslip" ng-model="companyData.psl.lsl">
                                                <label class="form-check-label" for="longServiceLeaveBalanceInPayslip">
                                                    Long service leave balance
                                                </label>
                                            </div>
                                            <div class="form-check" style="margin: 5px 0;">
                                                <input class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" value="" id="sickLeaveBalanceInPayslip" ng-model="companyData.psl.sl">
                                                <label class="form-check-label" for="sickLeaveBalanceInPayslip">
                                                    Personal/Carer's leave (Sick leave) balance
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <!-- ngShow: main-content "linkedAgent"-->
                            <div v-show="this.currentTab == 'linkedAgent'">
                                <form name="payslipOptionsForm" class="ng-pristine ng-valid">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Linked Agent</h5>
                                            <div class="ml-2 mt-2 ng-binding" style="position:relative">
                                                <b class="ng-binding"></b>
                                                <button class="btn text-danger pointer" style="background-color:transparent;position: absolute;top:-5px;right:0" ng-click="unlinkAgent()" ng-show="company.ai !== company._id">
                                                    <i class="icon-feather-slash"></i> Unlink
                                                </button>
                                            </div>
                                            <hr>
                                            <button class="btn btn-primary" type="button" ng-show="company.ai !== company._id" ng-click="unlinkAndLinkAgent()" ng-disabled="loading">
                                                <span class="spinner-grow spinner-grow-sm ng-hide" ng-show="loading"></span> Unlink current agent and link another
                                            </button>
                                            <!-- <div class="form-check" style="margin: 5px 0;">
                                                <input class="form-check-input" type="checkbox" value="" id="annualLeaveBalanceInPayslip" ng-model="companyData.psl.al">
                                                <label class="form-check-label" for="annualLeaveBalanceInPayslip">
                                                Annual leave balance
                                                </label>
                                            </div>
                                            <div class="form-check" style="margin: 5px 0;">
                                                <input class="form-check-input" type="checkbox" value="" id="sickLeaveBalanceInPayslip" ng-model="companyData.psl.sl">
                                                <label class="form-check-label" for="sickLeaveBalanceInPayslip">
                                                Personal/Carer's leave (Sick leave) balance
                                                </label>
                                            </div> -->
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>

                        <div class="modal-footer">
                            <button v-on:click="this.closeModal" class="btn btn-light" data-dismiss="modal" type="button"> Close</button>
                            <button v-on:click="this.save" class="btn btn-primary" type="button" ng-hide="currentTab == &#39;linkedAgent&#39;" ng-disabled="loading">
                                <span class="spinner-grow spinner-grow-sm ng-hide" ng-show="loading"></span> Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</modal>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    name: "SettingsOrganisationModal",
    props: [],
    components: {
        DatePicker
    },
    data() {
        return {
            currentTab: "company", //linkedAgent-leave-payroll-company
            time1: null,
        };
    },
    methods: {
        openModal() {
            this.$modal.show('modal-settingsorganisation', {
                data: 'data'
            });
        },
        closeModal() {
            this.$modal.hide('modal-settingsorganisation');
        },
        openDropover() {
            var progress = window.$(".ngdo-contents")
            if (progress.css('display') == 'none') {
                progress.css({
                    display: 'inline'
                });
            } else {
                progress.css({
                    display: 'none'
                });
            }
        },
        changeStatusToAgent() {
            this.openDropover()
        },
        changeTabs(valueTab) {
            this.currentTab = valueTab
        },
        save() {
            this.closeModal()
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
// $default-color: #555;
// $primary-color: #1284e7;
// $input-color: #495057;
// $input-border-color: transparent;
// $input-hover-border-color: transparent;
// @import '~vue2-datepicker/scss/index.scss'; //https://github.com/mengxiong10/vue2-datepicker/blob/master/src/style/index.scss
.date-picker {
    padding: 0px 0px 0px 0px !important;
    width: auto;
    height: auto;
}

.date-picker .mx-input-wrapper .mx-input {
    border-radius: 0px;
    border: 0px;
    box-shadow: none;
}
</style>
