<template>
<modal name="modal-downloadABAfile" with="auto" height="auto" style="z-index: 9999">
    <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog" aria-labelledby="modal-title" aria-describedby="modal-body" size="lg" index="0" animate="animate" ng-style="{&#39;z-index&#39;: 1050 + $topModalIndex*10, display: &#39;block&#39;}" tabindex="-1" uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true" style="z-index: 1050; display: block; overflow: auto;">
        <div class="modal-dialog modal-lg">
            <div class="modal-content" uib-modal-transclude="">
                <div class="modal-content text-center onboarding-modal modal-centered ng-scope">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel" style="text-align:center;">
                                <span style="color:#334152">Download ABA File</span>
                            </h5>
                            <button v-on:click="this.closeModal" aria-label="Close" class="box-title close" data-dismiss="modal" type="button">
                                <span aria-hidden="true"> ×</span>
                            </button>
                        </div>
                        <div class="modal-body text-left element-wrapper">
                            <div class="element-box scroll">
                                <h6 class="element-header" style="margin-bottom: 1rem;">Descriptive Record</h6>
                                <div class="row">
                                    <div class="col-6 col-sm-3">
                                        <div class="form-group">
                                            <label class="small-label" style="color:#5a637e7d">Institution Code</label>
                                            <div class="ng-binding">WBC</div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3">
                                        <div class="form-group">
                                            <label class="small-label" style="color:#5a637e7d">APCA User Id</label>
                                            <div class="ng-binding">301500</div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3">
                                        <div class="form-group">
                                            <label class="small-label" style="color:#5a637e7d">Name of Use supplying file</label>
                                            <div class="ng-binding">DEMO Pty Ltd</div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3">
                                        <div class="form-group">
                                            <label class="small-label" style="color:#5a637e7d">Account Name</label>
                                            <div class="ng-binding">Demo</div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3">
                                        <div class="form-group">
                                            <label class="small-label" style="color:#5a637e7d">BSB</label>
                                            <div class="ng-binding">121-241</div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3">
                                        <div class="form-group">
                                            <label class="small-label" style="color:#5a637e7d">Account Number</label>
                                            <div class="ng-binding">12341234</div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3">
                                        <div class="form-group">
                                            <label class="small-label" style="color:#5a637e7d">Description</label>
                                            <div class="ng-binding">Payroll03Aug</div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3">
                                        <div class="form-group">
                                            <label class="small-label" style="color:#5a637e7d">Reference (Payee)</label>
                                            <div class="ng-binding">DEMOPtyLtd 03Aug</div>
                                        </div>
                                    </div>
                                </div>

                                <br>
                                <h6 class="element-header" style="margin-bottom: 1rem;">Detail Records</h6>
                                <!-- ngIf: !detailRecords || detailRecords.length == 0 -->
                                <div class="table-responsive" ng-show="detailRecords &amp;&amp; detailRecords.length &gt; 0">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Name</th>
                                                <th>BSB</th>
                                                <th>Acc. Number</th>
                                                <th class="text-right">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <!-- ngRepeat: detailRecord in detailRecords -->
                                            <tr ng-repeat="detailRecord in detailRecords" class="ng-scope">
                                                <td class="text-center">
                                                    <input type="checkbox" class="form-check-input ng-pristine ng-untouched ng-valid ng-not-empty" ng-model="detailRecord.selected" style="position:relative;margin-left:0">
                                                </td>
                                                <td class="ng-binding">DavidBrown</td>
                                                <td class="ng-binding">123-123</td>
                                                <td class="ng-binding">12341234</td>
                                                <td class="text-right ng-binding">$1,630.92</td>
                                            </tr>
                                            <!-- end ngRepeat: detailRecord in detailRecords -->
                                            <tr ng-repeat="detailRecord in detailRecords" class="ng-scope">
                                                <td class="text-center">
                                                    <input type="checkbox" class="form-check-input ng-pristine ng-untouched ng-valid ng-not-empty" ng-model="detailRecord.selected" style="position:relative;margin-left:0">
                                                </td>
                                                <td class="ng-binding">EmilyJones</td>
                                                <td class="ng-binding">123-123</td>
                                                <td class="ng-binding">12341234</td>
                                                <td class="text-right ng-binding">$2,014.00</td>
                                            </tr>
                                            <!-- end ngRepeat: detailRecord in detailRecords -->
                                            <tr ng-repeat="detailRecord in detailRecords" class="ng-scope">
                                                <td class="text-center">
                                                    <input type="checkbox" class="form-check-input ng-pristine ng-untouched ng-valid ng-not-empty" ng-model="detailRecord.selected" style="position:relative;margin-left:0">
                                                </td>
                                                <td class="ng-binding">JamesSmith</td>
                                                <td class="ng-binding">123-123</td>
                                                <td class="ng-binding">12341234</td>
                                                <td class="text-right ng-binding">$1,834.54</td>
                                            </tr>
                                            <!-- end ngRepeat: detailRecord in detailRecords -->
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button v-on:click="this.closeModal" class="btn btn-light" data-dismiss="modal" type="button"> Close</button>
                            <!-- <button v-on:click="this.download" class="btn btn-primary" type="button"> Download</button> -->
                            <a v-on:click="this.download" href="../../assets/Payroll01Aug.aba" download class="btn btn-primary"> Download</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</modal>
</template>

<script>
export default {
    name: "DownloadABAFileModal",
    props: [],
    components: {},
    data() {
        return {};
    },
    methods: {
        openModal() {
            this.$modal.show('modal-downloadABAfile', {
                data: 'data'
            });
        },
        closeModal() {
            this.$modal.hide('modal-downloadABAfile');
        },
        download() {
            this.closeModal()
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
</style>
