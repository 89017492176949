<template>
  <div class="content-i ng-scope" ui-view="mainContent">
    <div class="content-box ng-scope tb-people disable-scrollbars">
      <div class="row">
        <div class="col-sm-12">
          <div class="element-wrapper mb-5">
            <!-- Header title -->
            <div style="display: flex">
              <div style="width: 450px">
                <v-text-field
                  v-if="$root.$refs.BaseLayout.UserRole!='Employee'"
                  v-on:keyup="searchEmployee()"
                  v-model="keySearch"
                  label="Search"
                  append-icon="mdi-magnify"
                  outlined
                  style="margin: 20px 0 0 10px"
                  hint="Search by name, email, phone, job title">
              </v-text-field>
              </div>
              <h3
                v-if="$root.$refs.BaseLayout.UserRole != 'Employee'"
                class="element-header"
                style="
                  z-index: 1 !important;
                  position: relative;
                  justify-content: flex-end;
                  display: flex;
                  align-items: center;
                  margin-bottom: 10px;
                  padding-bottom: 0px;
                  width: 100%;">
                <a
                  style="margin-top: 5px"
                  @click="
                    listPolicy.addEmployee ? OpenPopUp() : gotoPayment()
                  "
                  class="ml-2 mb-1 btn ng-binding ng-scope btn-primary">
                  New Employee
                </a>
                <a
                  style="margin-top: 5px"
                  v-show="isAddDemo"
                  @click="addEmployeeDemo()"
                  class="ml-2 mb-1 btn ng-binding ng-scope btn-primary"
                >
                  New Employee Demo
                </a>
                <div
                  class="hover-change-view box-title"
                  style="margin-left: 10px; padding: 7px">
                  <img
                    @click="clickChangeView()"
                    class="change-view btn-light os-dropdown-trigger"
                    :src="iconChangeView"
                  />
                </div>
                <div v-show="isShowPopup" class="popup-view">
                  <div class="">
                    <div
                      class="item-popup os-dropdown-trigger"
                      v-on:click="changeView(true)"
                    >
                      <img
                        v-show="!isChangeView"
                        class="icon-change-view icon-check"
                        src="/assets/img/icon_transparent.png"
                      />
                      <img
                        v-show="isChangeView"
                        class="icon-change-view icon-check"
                        src="/assets/img/ic_check.svg"
                      />
                      <span>List View</span>
                    </div>
                    <div
                      class="item-popup os-dropdown-trigger"
                      v-on:click="changeView(false)"
                    >
                      <img
                        v-show="!isChangeView"
                        class="icon-change-view icon-check"
                        src="/assets/img/ic_check.svg"
                      />
                      <img
                        v-show="isChangeView"
                        class="icon-change-view icon-check"
                        src="/assets/img/icon_transparent.png"
                      />
                      <span>Detail View</span>
                    </div>
                  </div>
                </div>
              </h3>
            </div>
            
            <!-- List data -->
            <div v-if="isAddDemo" class="text-center">
              <img src="/assets/img/employees.png" class="icon_employee" />
              <h4>Let's add your employees</h4>
              <h6>
                Build your team! Add your first employee so you can pay them
                with Cyruspay
              </h6>
              <a
                style="margin-top: 15px"
                @click="
                  listPolicy.addEmployee ? OpenPopUp() : gotoPayment()
                "
                class="ml-2 mb-1 btn ng-binding ng-scope btn-primary"
              >
                Add your first employee
              </a>
            </div>
            <div v-else>
              <div class="user_indicator">
              <ul  style="list-style-type:none;" v-show="$root.$refs.BaseLayout.UserRole !== 'Employee'">
                <li class="pyellow"><i class="fa fa-circle"></i> Registration Pending</li>
                <li class="pgreen"><i class="fa fa-circle"></i> Active</li>
                <li class="pred"><i class="fa fa-circle"></i> In-Active</li>
              </ul>
            </div>
              <div
                v-if="isChangeView"
                class="element-content fade"
                style="margin-top: 10px"
              >
                <div class="support-index">
                  <div
                    v-for="item in listEmployees"
                    :key="item.id"
                    class="itemPeople"
                  >
                    <div class="col-md-13 col-lg-13 ng-scope">
                      <div class="support-ticket">
                        <div class="st-meta">
                          <div
                            class="btnedit pointer box-title edit-employe"
                            style="display: flex"
                            v-on:click="openModalEdit(item)">
                            <img class="iconedit" src="/assets/img/edit.png" />
                          </div>
                        </div>
                        <div @click="openModalDetail(item)">
                          <div class="st-body body">

                            <avatar
                            v-bind:background-color="
                              item.gender =='Male' ? '#3F51B5'
                            : item.gender =='FeMale'? 'rgb(255, 64, 129)'
                            : 'rgb(255,193,7)'"
                              size="55"
                              :username="utils.getUserName(item)"
                              style="margin: auto"
                            ></avatar>
                            <div class="extra-info">
                              <div
                                v-bind:class="
                                  item.active == 'ACTIVE'
                                    ? 'green'
                                    : item.active == 'PENDING'
                                    ? 'yellow'
                                    : item.active == 'INACTIVE'
                                    ? 'red'
                                    : 'black'
                                "
                                class="status-pill"
                                style="width: 10px; height: 10px"
                              ></div>
                            </div>
                            <div
                              class="ticket-content"
                              style="max-width: 100%; margin-top: 15px"
                            >
                              <h6 class="ticket-title">
                                {{ item.firstName + " " + item.lastName }}
                              </h6>
                            </div>
                            <div class="footer">
                              <div class="type-people">
                                {{ showEmploymentType(item.employmentType) }}
                              </div>
                            </div>
                            <div class="st-body footerItem bg_active">
                              <div class="footer">
                                <img
                                  class="icon job_active"
                                  src="/assets/img/icon_job.png"
                                />
                                <div class="title-people txt_active">
                                  {{ item.job }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="body-mobile">
                            <div class="avatar">
                              
                              <avatar
                                v-bind:background-color="
                                  item.gender =='Male' ? '#3F51B5'
                                : item.gender =='FeMale'? 'rgb(255, 64, 129)'
                                : 'rgb(255,193,7)'"
                                  size="40"
                                  :username="utils.getUserName(item)"
                                  style="margin: auto">
                              </avatar>
                              <div class="extra-info">
                                <div
                                  v-bind:class="
                                    item.active == 'ACTIVE'
                                      ? 'green'
                                      : item.active == 'PENDING'
                                      ? 'yellow'
                                      : item.active == 'INACTIVE'
                                      ? 'red'
                                      : 'black'
                                  "
                                  class="status-pill"
                                  style="width: 10px; height: 10px"
                                ></div>
                              </div>
                              <div class="footer">
                                <div class="type-people txt_active">
                                  {{ showEmploymentType(item.employmentType) }}
                                </div>
                              </div>
                            </div>
                            <div class="info">
                              <div
                                class="ticket-content"
                                style="max-width: 100%; margin-top: 15px">
                                <h7 class="ticket-title">{{
                                  item.firstName + " " + item.lastName
                                }}</h7>
                              </div>
                              <div>
                                <div class="footer">
                                  <span class="title-people txt_active">{{
                                    item.job
                                  }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <NewEditEmployee
                v-else
                v-show="listEmployees.length > 0"
                style="z-index: 0 !important;"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_EMPLOYEE,
  ADD_EMPLOYEE_DEMO,
  GET_INDIVIDUAL_EMPLOYEE,
  HOLD_SUBSCRIPTION,
} from "@/store/actionsType";

import ApiManager from "@/api/ApiManager";

import NewEditEmployee from "@/views/home/employees/NewEditEmployee";

import * as Utils from "@/utils/Utils";
import * as Session from "@/utils/SessionStore";
import Avatar from "vue-avatar";


export default {
  name: "People",
  props: {},
  components: {
    NewEditEmployee,
    Avatar,
  },
  data() {
    return {
      TriggerForHoldSubscription: false,
      utils: Utils,
      isAddDemo: false,
      listAllEmployees: [],
      listEmployees: [],
      listPolicy: {
        addEmployee: true,
        updateEmployee: true,
      },
      isShowAvatar: true,
      isShowPopup: false,
      isChangeView: "",
      iconChangeView: "",
      currentEmployee: "",
      keySearch: "",
    };
  },
  created: function () {
    this.$root.$refs.People = this;
    this.listPolicy = this.$root.$refs.BaseLayout.listPolicy;
    this.createPage();
  },

  methods: {
    async searchEmployee() {
      if (
        this.$root.$refs.NewEditEmployee == undefined ||
        (await this.$root.$refs.NewEditEmployee.checkChangeInfoEmployee())
      ) {
        this.listEmployees = [];
        for (let i = 0; i < this.listAllEmployees.length; i++) {
          const employee = this.listAllEmployees[i];
          if (this.checkKeySearch(employee)) {
            this.listEmployees.push(employee);
          }
        }
        if (this.$root.$refs.NewEditEmployee) {
          this.$root.$refs.NewEditEmployee.getListCurrentEmployee();
        }
      }
    },
    checkKeySearch(employee) {
      var nameEmployee = employee.firstName + " " + employee.lastName;
      if (
        nameEmployee.toLowerCase().indexOf(this.keySearch.toLowerCase()) != -1
      ) {
        return true;
      }
      if (employee.email.indexOf(this.keySearch) != -1) {
        return true;
      }
      if (
        employee.contactNumber != undefined &&
        employee.contactNumber.indexOf(this.keySearch) != -1
      ) {
        return true;
      }
      if (
        employee.job.toLowerCase().indexOf(this.keySearch.toLowerCase()) != -1
      ) {
        return true;
      }
      return false;
    },
    createPage() {
      this.isChangeView = Session.get("isChangeView");
      if (Session.get("isChangeView")) {
        this.iconChangeView = "/assets/img/icon_change_view.png";
      } else {
        this.iconChangeView = "/assets/img/icon_change_view_1.png";
      }

      //window.$('.not-pulse').addClass('pulse-itself');

      this.getListEmployee();
    },
    // Request API
    getListEmployee() {
      if (this.$root.$refs.BaseLayout.UserRole == "Employee") {
        if (Session.has("currentEmployeeId")) {
          this.$root.$refs.AppCyrus.start();
          this.$store
            .dispatch(GET_INDIVIDUAL_EMPLOYEE, Session.get("currentEmployeeId"))
            .then((response) => {
              this.$root.$refs.AppCyrus.finish();
              this.listAllEmployees = this.utils.sortEmployee(response);
              this.listEmployees = this.listAllEmployees;
              this.isAddDemo = false;
              if (this.$root.$refs.NewEditEmployee != undefined) {
                this.NewEditEmployee =
                  this.$root.$refs.NewEditEmployee.getListCurrentEmployee();
              }
            })
            .catch(() => {
              this.$root.$refs.AppCyrus.fail();
            });
        }
      } else {
        if (Session.has("currenCompanyId")) {
          this.$root.$refs.AppCyrus.start();
          this.$store
            .dispatch(GET_EMPLOYEE, Session.get("currenCompanyId"))
            .then((response) => {
              this.$root.$refs.AppCyrus.finish();
              this.listAllEmployees = this.utils.sortEmployee(response);
              this.listEmployees = this.listAllEmployees;
              if (this.listEmployees.length > 0) {
                this.isAddDemo = false;
              } else {
                this.isAddDemo = true;
              }
              if (this.$root.$refs.NewEditEmployee != undefined) {
                this.NewEditEmployee =
                  this.$root.$refs.NewEditEmployee.getListCurrentEmployee();
              }
            })
            .catch(() => {
              this.$root.$refs.AppCyrus.fail();
            });
        } else {
          if (this.$root.$refs.BaseLayout != undefined) {
            this.$root.$refs.BaseLayout.logout();
          }
        }
      }
    },
    gotoPayment() {
      this.$root.$refs.BaseLayout.gotoPayment();
    },

    addEmployeeDemo() {
      if (Session.has("currenCompanyId")) {
        this.$root.$refs.AppCyrus.start();
        this.$store
          .dispatch(ADD_EMPLOYEE_DEMO, Session.get("currenCompanyId"))
          .then(() => {
            this.$root.$refs.AppCyrus.finish();
            this.getListEmployee();
          })
          .catch(() => {
            this.$root.$refs.AppCyrus.fail();
          });
      } else {
        if (this.$root.$refs.BaseLayout != undefined) {
          this.$root.$refs.BaseLayout.logout();
        }
      }
    },

    renderAvatar(avatar) {
      if (avatar != "") {
        this.isShowAvatar = true;
        return ApiManager.getUrlDomain() + avatar;
      } else {
        this.isShowAvatar = false;
        return "";
      }
    },

    HoldCurrentSubscription() {
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(HOLD_SUBSCRIPTION)
        .then(() => {
          this.$root.$refs.AppCyrus.finish();
          this.$root.$refs.BaseLayout.AfterEmployeeCheck();
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },

    async OpenPopUp() {
      if (this.$root.$refs.NewEditEmployee == undefined ||
        (await this.$root.$refs.NewEditEmployee.checkChangeInfoEmployee()) ) 
      {
        this.$modal.show("modal-newemployeestep0", {
          data: "data",
          newEmployeeInfo: {
            startDate: '',
            dobDay: '',
            dobMonth: '',
            dobYear: '',
            birthDate: '',
            firstName: '',
            lastName: '',
            email: '',
            contactNumber: '',
            job: '', // job title
            address: '',
            address1: "",
            address2: "",
            suburb: "",
            postcode: "",
            state: "OTHER",
            countryCode: "au",
            country: "",
            paymentMethod: "Cash",
            bankAccountName: "",
            bankAccountBSB: "",
            bankAccountNumber: "",
            superFundType: "Regulated Superannuation Fund",
            contributionRate: 9.5,
            fundName: "",
            fundABN: "",
            fundUSI: "",
            fundAddress: "",
            fundEmployerNumber: "",
            fundBankName: "",
            fundBankBSB: "",
            fundBankNumber: "",
            memberNumber: "",
            // Supper Fund
            superFund: "",
            organisationName: "",
            identifierValue: "",
            isExemption: false,
            tfnExemption: null,
            statusResidency: "AustraliaResident",
            taxNumber: "",
            claimTaxFreeThreshold: true,
            hasHecsOrHelpDebt: false,
            hasFinancialSupplementDebt: false,
            taxFileNumberDeclaration: false,
            hasTaxTemplate: false,
            taxTemplate: 0,
            employmentType: "Casual",
            weeklyWorkHours: Session.get("workHours") * 5,
            leaveLoading: 0,
            casualLoading: 25,
            payRatePerHour: 0,
            payRate: 0,
            typeRate: "PerHour",
            payPeriod: Session.get("payPeriod"),
            companyId: Session.get("currenCompanyId"),
          }
        });
      }
    },

    openModalDetail(employee) {
      this.$modal.show("modal-detailemployee", {
        employee: employee,
      });
    },

    openModalEdit(employee) {
      this.$modal.show("modal-editemployee", {
        employee: employee,
      });
    },

    showStatus(status) {
      if (this.$root.$refs.BaseLayout != undefined) {
        return this.$root.$refs.BaseLayout.showStatus(status);
      }
    },

    showEmploymentType(type) {
      if (this.$root.$refs.BaseLayout != undefined) {
        return this.$root.$refs.BaseLayout.showEmploymentType(type);
      }
    },

    async changeView(isCheck) {
      if (
        this.$root.$refs.NewEditEmployee == undefined ||
        (await this.$root.$refs.NewEditEmployee.checkChangeInfoEmployee())
      ) {
        if (this.isChangeView != isCheck) {
          if (Session.get("isChangeView")) {
            this.isChangeView = isCheck;
            Session.set("isChangeView", isCheck);
            this.iconChangeView = "/assets/img/icon_change_view_1.png";
          } else {
            this.isChangeView = isCheck;
            Session.set("isChangeView", isCheck);
            this.iconChangeView = "/assets/img/icon_change_view.png";
          }
        }
        this.clickChangeView();
      }
    },

    clickChangeView() {
      var changeView = window.$(".hover-change-view");
      if (this.isShowPopup) {
        this.isShowPopup = false;
        changeView.removeClass("active-change-view");
      } else {
        this.isShowPopup = true;
        changeView.addClass("active-change-view");
      }
    },

    reloadDataEmployee(isReload) {
      if (isReload) {
        this.getListEmployee();
      }
    },
  },

  computed: {
    showEmployeeCard() {
      return this.listEmployees.length > 0 && this.$root.$refs.BaseLayout.UserRole !== 'Employee';
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

@media (min-width: 1025px) {
  .itemPeople {
    width: 180px;
    margin-right: 25px;
    margin-bottom: 30px;
  }

  .itemPeople .body {
    height: 180px;
    padding: 0px;
    padding-top: 20px;
    display: block;
  }

  .support-index .st-body .avatar {
    padding-right: 0px;
  }

  .extra-info {
    margin-top: -15px;
    margin-left: 100px;
    background: white;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    position: absolute;
  }

  .extra-info .status-pill {
    width: 10px;
    height: 10px;
    margin-top: 2px;
  }

  .type-people {
    font-weight: 500;
    font-size: 12px;
    text-align: left;
    color: #999999;
    opacity: 0.8;
  }

  .itemPeople:hover .type-people {
    color: #44665c;
    opacity: 1;
  }

  .footerItem {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 45px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    background: #f9f9f9;
    border-radius: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    justify-content: center;
    text-align: center;
  }

  .itemPeople .footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    overflow: hidden;
  }

  .itemPeople .title-people {
    font-weight: 500;
    font-size: 12px;
    text-align: left;
    line-height: 150%;
    color: #999999;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 2px;
  }

  .itemPeople .body-mobile {
    display: none;
  }

  .support-index .support-ticket:hover .txt_active,
  .support-index .support-ticket.active .txt_active {
    font-weight: 500;
    font-size: 12px;
    text-align: left;
    line-height: 150%;
    color: #ffffff;
  }
}

@media (max-width: 1024px) {
  .itemPeople {
    min-width: 160px;
    width: 23%;
    max-width: 208px;
    margin-right: 5px;
    margin-bottom: 30px;
    display: grid;
    justify-items: center;
  }

  .itemPeople .support-ticket {
    widows: 160px;
  }

  .itemPeople .body {
    display: none;
  }

  .itemPeople .body-mobile {
    padding: 0px;
    padding-top: 10px;
    padding-left: 10px;
    display: flex;
    cursor: pointer;
  }

  .itemPeople .body-mobile .avatar {
    margin-top: 7px;
  }

  .itemPeople .body-mobile .avatar img {
    width: 40px;
  }

  .extra-info {
    margin-top: -10px;
    margin-left: 25px;
    background: white;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    position: absolute;
  }

  .extra-info .status-pill {
    width: 10px;
    height: 10px;
    margin-left: 2px;
    margin-top: 2px;
  }

  .itemPeople .body-mobile .info {
    height: 80px;
    padding-left: 5px;
    padding-right: 5px;
    width: 105px;
  }

  .type-people {
    font-weight: 500;
    font-size: 12px;
    text-align: left;
    color: #09b585;
    width: max-content;
  }

  .ticket-title {
    font-weight: bolder;
  }

  .footer {
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .itemPeople .title-people {
    font-weight: 500;
    font-size: 12px;
    text-align: left;
    line-height: 150%;
    color: #999999;
    white-space: nowrap;
  }

  .support-index .support-ticket:hover .title-people.txt_active,
  .support-index .support-ticket.active .title-people.txt_active {
    text-align: left;
    color: #696969;
  }

  .support-index .support-ticket:hover .type-people.txt_active,
  .support-index .support-ticket.active .type-people.txt_active {
    text-align: left;
    color: #12e6a9;
  }
}

.itemPeople .line {
  width: 2px;
  height: 45px;
  background: #eaeaea;
}

.itemPeople .icon {
  display: inline-block;
  justify-items: center;
  width: 15px;
  height: 15px;
  margin-right: 7px;
  margin-top: 2px;
}

.support-index .support-ticket:hover .bg_active,
.support-index .support-ticket.active .bg_active {
  background: #09b585;
}

.support-index .support-ticket:hover .job_active,
.support-index .support-ticket.active .job_active {
  content: url("/assets/img/icon_job_acti.png");
}

.support-index .support-ticket:hover .time_active,
.support-index .support-ticket.active .time_active {
  content: url("/assets/img/icon_time_acti.png");
}

.icon_employee {
  width: 150px;
  margin: 20px auto;
  display: flex;
}
.user_indicator {
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: right;
}
.user_indicator ul {
  padding: 0;
  margin: 0;
  display: -webkit-inline-box;
}
.user_indicator ul li {
  padding: 0;
  margin: 0 0 10px 20px;
  font-size: 14px;
  color: #333;
}

.user_indicator ul li.pyellow i {
  color:#ffeb3b ;
  font-size: 12px;
}
.user_indicator ul li.pred i {
  color: #f44336 ;font-size: 12px;
}
.user_indicator ul li.pgreen i {
  color: #4caf50 ;font-size: 12px;
}

</style>
