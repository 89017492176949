<template>
<modal name="modal-Payroll-Summary" @before-open="beforeOpen" with="auto" height="auto">
    <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog" aria-labelledby="modal-title" aria-describedby="modal-body" size="xxl" index="0" animate="animate" ng-style="{&#39;z-index&#39;: 1050 + $topModalIndex*10, display: &#39;block&#39;}" tabindex="-1" uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true" style="display: block; overflow: auto;">
        <div class="modal-dialog modal-l">
            <div class="modal-content" uib-modal-transclude="">
                <div class="modal-content text-center onboarding-modal modal-centered ng-scope">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" style="text-align:center;">
                                <span style="color:#334152"> {{apiName == 'openPayrollSummary' ? 'Payroll Activity Summary' : 'Payroll Employee Summary' }} </span>
                            </h5>
                            <button @click="closeModal()" aria-label="Close" class="box-title close" data-dismiss="modal" type="button"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row text-left mt-3">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label for="">Select employee</label>
                                        <el-autocomplete @focus="clickAutocomplete()" @blur="getBackAutocompete()" class="inline-input" style="width: 100%; !important;" v-model="edtEmployeeName" :fetch-suggestions="querySearch" placeholder="Please Input" @select="handleSelect"></el-autocomplete>
                                    </div>
                                </div>
                                <div v-bind:class="(typeReport == 'Custom') ? 'col-sm-6':'col-sm-12'">
                                    <div class="form-group">
                                        <label for="">Period</label>
                                        <select v-model="typeReport" @change="onTypeTypeReportChange($event)" class="form-control ng-pristine ng-valid ng-not-empty ng-touched" name="state" ng-model="leaveType" style="">
                                            <option value="ThisMonth">This month</option>
                                            <option value="LastMonth">Last month</option>
                                            <option value="ThisQuarter">This quarter</option>
                                            <option value="LastQuarter">Last quarter</option>
                                            <option value="ThisFinancialYear">This financial year</option>
                                            <option value="LastFinancialYear">Last financial year</option>
                                            <option value="Custom">Custom</option>

                                        </select>
                                    </div>
                                </div>
                                <div v-show="typeReport == 'Custom'" class="col-sm-6 ng-scope">
                                    <div class="form-group ">
                                        <label for="">Date Range</label>
                                        <date-picker class="datetime" v-model="employeeName" @close="closeDatePicker()" type="date" style="width: 100%;" range placeholder="Select date range" value-type="YYYY-MM-DD" format="DD/MM/YYYY"></date-picker>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="modal-footer">
                            <button class="btn btn-light" data-dismiss="modal" type="button" @click="closeModal()"> Close</button>
                            <button class="btn btn-primary" type="button" @click="requestAPI()"> View</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</modal>
</template>

<script>
import {
    GET_LIST_EMPLOYEE_REPORT,
    // GET_LEAVEBALALCES
} from "@/store/actionsType";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import log4javascript from 'log4javascript';
import * as Session from "@/utils/SessionStore"
import ApiManager from "@/api/ApiManager";
import axios from "axios";
import Token from '@/api/Token';

export default {
    name: "PayrollSummary",
    props: [],
    components: {
        DatePicker
    },
    created: function() {
        this.$logger = log4javascript.getLogger("PayrollSummary");
    },
    data() {
        return {
            listEmployee: [],
            listDropEmployee: [],
            edtEmployeeName: "",
            oldEmployeeName: "",
            employeeName: "",
            employeeSubmit: "",
            typeReport: "",
            dateRangeStart: "",
            dateRangeEnd: "",
            apiName: "",
        };
    },
    methods: {
        beforeOpen(event) {
            this.apiName = event.params.apiName,
                this.listEmployee = [],
                this.listDropEmployee = [],
                this.edtEmployeeName = "All",
                this.employeeSubmit = "",
                this.getListEmployeeReport(),
                this.typeReport = 'ThisFinancialYear'
            var now = new Date();
            
            if((now.getMonth()+1) >= 7) {
                var year = now.getFullYear()
            } else {
                year = now.getFullYear() - 1
            }
            this.employeeName = [year + '-07-01', (year+1) + '-06-30']
            this.closeDatePicker()
        },
        getDate(inputDate) {
            var date = new Date(inputDate);
            var day = date.getDate()
            var mount = date.getMonth() + 1
            var year = date.getFullYear()
            if (day.toString().length == 1) {
                day = "0" + day
            }
            if (mount.toString().length == 1) {
                mount = "0" + mount
            }
            return year + "-" + mount + "-" + day
        },
        closeModal() {
            this.$modal.hide('modal-Payroll-Summary');
        },
        clickAutocomplete() {
            this.oldEmployeeName = this.edtEmployeeName
            this.edtEmployeeName = ""
        },
        getBackAutocompete() {
            var timeout = setTimeout(() => {
                if(this.edtEmployeeName == "") {
                    this.edtEmployeeName = this.oldEmployeeName
                }
                clearTimeout(timeout)
            }, 300);
        },
        onTypeTypeReportChange() {
            this.typeReport = event.target.value
        },
        querySearch(queryString, cb) {
            var listDrop = this.listDropEmployee;
            queryString = this.edtEmployeeName
            var results = queryString ? listDrop.filter(this.createFilter(queryString)) : listDrop;
            cb(results);
        },
        createFilter(queryString) {
            return (listDropEmployee) => {
                return (listDropEmployee.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        handleSelect(item) {
            for (var i = 0; i < this.listEmployee.length; i++) {
                if (item.employeeId == this.listEmployee[i].employeeId) {
                    this.employeeSubmit = this.listEmployee[i]
                }
            }
        },
        closeDatePicker() {
            this.dateRangeStart = this.employeeName[0]
            this.dateRangeEnd = this.employeeName[1]
        },

        viewPaySlip(dataPdf, titleName) {
            this.$modal.show('modal-viewPaySlip-data', {
                link: dataPdf,
                titleName: titleName
            });
        },
        viewPaySlipPdf(link, titleName) {
            this.$modal.show('modal-viewPaySlip-pdf', {
                link: link,
                titleName: titleName
            });
        },
        //Request API
        getListEmployeeReport() {
            this.closeModal()
            this.$root.$refs.AppCyrus.start()
            var typeListEmployee = ""
            if (this.apiName === "openPayrollSummary") {
                typeListEmployee = "PAYROLL_ACTIVITY_SUMMARY"
            } else {
                typeListEmployee = "PAYROLL_EMPLOYEE_SUMMARY"
            }
            var request = {
                "companyId": Session.get("currenCompanyId"),
                "typeListEmployee": typeListEmployee,
            }
            this.$store.dispatch(GET_LIST_EMPLOYEE_REPORT, request)
                .then((response) => {
                    this.$root.$refs.AppCyrus.finish()
                    this.listEmployee = response
                    this.listDropEmployee.push({
                        "employeeId": 0,
                        "value": "All",
                    })
                    for (var i = 0; i < this.listEmployee.length; i++) {
                        this.listDropEmployee.push({
                            "employeeId": this.listEmployee[i].employeeId,
                            "value": this.listEmployee[i].firstName + " " + this.listEmployee[i].lastName,
                        })
                    }
                }).catch((error) => {
                    this.$logger.error(`getListEmployeeReport: ${error}`)
                    this.$root.$refs.AppCyrus.fail()
                });
        },
        requestAPI() {
            if (this.apiName == 'openPayrollSummary') {
                this.getPayrollActivitySummary()
            } else if (this.apiName == 'openEmployeeSummary') {
                this.getEmployeeSummary()
            }
        },
        openReportPdfInNewTab(linkAPI){
            axios({
                url: linkAPI,
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token.getToken(),
                    handshake: 'Basic ' + Token.getCodeHandShake()
                },
                responseType: 'blob'
            })
            .then((response) => {
                var file = new Blob([response.data], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                window.open(encodeURI(fileURL));
            })
            .catch((error) => {
                this.$logger.error(`openReportPdfInNewTab: ${error}`)
                if(error.response && error.response.status === 500){
                    if(!ApiManager.isCheckServer) {
                        ApiManager.checkServerIsUpdating()
                        .then((response) => {
                            if(!response.isEnable) {
                                window.location.href = "updateserver";
                            } else {
                                this.isError = true
                            }
                        }).catch(() => {
                            window.location.href = "updateserver";
                        })
                    }
                } else {
                    this.isError = true
                }
            });
        },
        getPayrollActivitySummary() {
            this.closeModal()
            var employeeId = 0;
            if (this.employeeSubmit.employeeId != undefined) {
                employeeId = this.employeeSubmit.employeeId
            }
            var request = {
                "companyId": Session.get("currenCompanyId"),
                "employeeId": employeeId,
                "typeReport": this.typeReport,
                "dateRangeStart": this.dateRangeStart,
                "dateRangeEnd": this.dateRangeEnd,
            }
            var stringRequest = "companyId=" + request.companyId + "&employeeId=" + request.employeeId + "&typeReport=" + request.typeReport + "&dateRangeStart=" + request.dateRangeStart + "&dateRangeEnd=" + request.dateRangeEnd
            var linkAPI = ApiManager.getUrlApi() + "/api/Report/PayRollActivitySummary?" + stringRequest
            this.openReportPdfInNewTab(linkAPI);
        },
        getEmployeeSummary() {
            this.closeModal()
            var employeeId = 0;
            if (this.employeeSubmit.employeeId != undefined) {
                employeeId = this.employeeSubmit.employeeId
            }
            var request = {
                "companyId": Session.get("currenCompanyId"),
                "employeeId": employeeId,
                "typeReport": this.typeReport,
                "dateRangeStart": this.dateRangeStart,
                "dateRangeEnd": this.dateRangeEnd,
            }
            var stringRequest = "companyId=" + request.companyId + "&employeeId=" + request.employeeId + "&typeReport=" + request.typeReport + "&dateRangeStart=" + request.dateRangeStart + "&dateRangeEnd=" + request.dateRangeEnd
            var linkAPI = ApiManager.getUrlApi() + "/api/Report/PayrollEmployeeSummary?" + stringRequest
            this.openReportPdfInNewTab(linkAPI);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
@import url("//unpkg.com/element-ui@2.13.1/lib/theme-chalk/index.css");
</style>
