<template>
<modal name="modal-messageSTP" @before-open="beforeOpen" with="auto" height="auto" style="z-index: 2000">
    <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog" aria-labelledby="modal-title" aria-describedby="modal-body" size="md" index="0" animate="animate" tabindex="-1" uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true" style="z-index: 1050; display: block; overflow: auto;">
        <div class="modal-dialog modal-md">
            <div class="modal-content" uib-modal-transclude="">
                <div class="modal-content text-center onboarding-modal modal-centered ng-scope">
                    <div class="modal-content" style="height: 400px">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                                STP Result
                            </h5>
                        </div>
                        <div class="modal-body">
                            <div v-for="(item) in result" :key="item" style="font-size: 1rem">
                                <div class="text-left" style="color: rgb(128, 128, 128)">
                                    <div class="badge text-white" v-bind:class=" item.severity_Code == 'Error' ? 'bg-red': item.severity_Code == 'Warning' ? 'bg-yellow' : 'bg-green'">{{item.severity_Code}}</div>{{ item.error_Code + ': ' + item.short_Description}}<br>{{item.detailed_Description}}
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-primary" data-dismiss="modal" type="button" v-on:click="this.closeModal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</modal>
</template>

<script>

export default {
    name: "MessageSTP",
    props: [],
    components: {},
    data() {
        return {
            result: []
        };
    },
    methods: {
        beforeOpen(event){
            this.result = event.params.data;
        },
        closeModal() {
            this.$modal.hide('modal-messageSTP');
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.badge {
    margin: 0px 5px;
}

.bg-error {
    background: #e9b27f86;
    margin-bottom: 10px;
}
</style>
