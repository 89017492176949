<template>
  <modal
    class="modal-body"
    name="modal-newemployeestep0"
    @before-open="beforeOpen"
    with="auto"
    height="auto"
  >
    <div
      uib-modal-window="modal-window"
      class="modal fade ng-scope ng-isolate-scope show"
      role="dialog"
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
      size="xxl"
      index="0"
      animate="animate"
      ng-style="{&#39;z-index&#39;: 1050 + $topModalIndex*10, display: &#39;block&#39;}"
      tabindex="-1"
      uib-modal-animation-class="fade"
      modal-in-class="show"
      modal-animation="true"
      style="z-index: 1050; display: block; overflow: auto"
    >
      <div class="modal-dialog modal-m">
        <div class="modal-content" uib-modal-transclude="">
          <div
            class="
              modal-content
              text-center
              onboarding-modal
              modal-centered
              ng-scope
            "
          >
            <button
              v-on:click="this.closeModal"
              aria-label="Close"
              class="box-title close"
              data-dismiss="modal"
              type="button"
            ></button>
            <div class="onboarding-slide">
              <div class="onboarding-side-by-side">
                <div class="headerimg">
                  <img class="img" alt="" src="/assets/img/img_step0.png" />
                </div>
                <div
                  class="onboarding-content with-gradient"
                  style="padding-bottom: 0px"
                >
                  <p class="title">Add your new employee</p>
                  <form
                    class="
                      ng-pristine ng-valid ng-valid-maxlength ng-valid-email
                    "
                    autocomplete="on"
                  >
                    <v-app>
                      <div class="row new-employee-modal">
                        <div class="col-sm-12" style="margin-bottom: 15px">
                          <div class="form-group">
                            <v-menu
                              v-model="newEmployeeStartDateMenu"
                              :close-on-content-click="false"
                              nudge-left="400px"
                              origin="top left"
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  :value="computedStartDateFormatted"
                                  class="ng-srartDate"
                                  label="* Start Date"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click:clear="newEmployeeInfo.startDate = null"
                                  :rules="[rules.required]"
                                  required
                                  outlined
                                  color="success"
                                  append-icon="mdi-calendar"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="newEmployeeInfo.startDate"
                                @change="newEmployeeStartDateMenu = false"
                                no-title
                              ></v-date-picker>
                            </v-menu>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <v-text-field
                              v-model="newEmployeeInfo.firstName"
                              class="ng-first-name"
                              label="* First Name"
                              type="text"
                              maxlength="40"
                              :rules="[rules.nameRules,rules.required]"
                              required
                              outlined
                              color="success"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <v-text-field
                              v-model="newEmployeeInfo.lastName"
                              class="ng-last-name"
                              label="* Last Name"
                              type="text"
                              maxlength="40"
                             :rules="[rules.nameRules,rules.required]"
                              required
                              outlined
                              color="success"
                            ></v-text-field>

                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <v-text-field
                              v-model="newEmployeeInfo.job"
                              class="ng-job-title"
                              label="* Job title"
                              type="text"
                              maxlength="40"
                              :rules="[rules.required]"
                              required
                              outlined
                              color="success"
                            ></v-text-field>

                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <v-text-field
                              v-model="newEmployeeInfo.email"
                              class="ng-email-adress"
                              label="* Email address"
                              type="email"
                              maxlength="255"
                              :rules="[rules.required]"
                              required
                              outlined
                              color="success"
                            ></v-text-field>

                          </div>
                        </div>
                        <hr />
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="txtSend">
                            <!--    Send an invitation to your employee so they can enter their personal, tax and bank account details on their own. -->
                          </div>
                        </div>
                      </div>
                    </v-app>
                  </form>
                </div>
              </div>
            </div>
            <div style="padding-bottom: 20px; margin-top: 0px">
              <div class="area-2btn text-center">
                <button
                  class="btn btn-warning btn-manually box-title mr-4 ng-scope"
                  v-on:click="openModalAddDetailManually()"
                  ng-if="currentIndex == 0"
                >
                  Add details manually <i class="icon-feather-edit"></i>
                </button>
                <button
                  class="btn btn-primary btn-invite box-title ng-binding"
                  v-on:click="openModalStep4()"
                  ng-disabled="loading"
                >
                  <span
                    class="spinner-grow spinner-grow-sm ng-hide"
                    ng-show="loading"
                  ></span>
                  Invite employee <i class="icon-feather-user-plus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import * as Validate from "@/utils/Validate.js";
import * as Utils from "@/utils/Utils.js";
import * as Session from "@/utils/SessionStore";
import { DATA_STEP0, CHECK_EMAIL } from "@/store/actionsType";
import log4javascript from 'log4javascript';

export default {
  name: "NewEmployeeModal",
  props: {},
  components: {},
  data() {
    return {
      newEmployeeInfo: {},
      isValidate: false,
      isChooseAddDetailManually: false,
      //Valadate
      rules: {
        required: (value) => !!value || "",
        animal: [(val) => (val || "").length > 0 || "This field is required"],
        //min: v => v.length >= 0 || 'Min 8 characters',
        // emailMatch: () => (`The email and password you entered don't match`),
        //nameRules:(v) => (v && v.length <= 10) || '',
        nameRules:v => /^[a-zA-Z ]*$/.test(v) || 'Only alphabets',
      },

      newEmployeeStartDateMenu: false,
    };
  },
  created: function() {
    this.$logger = log4javascript.getLogger("NewEmployeeStep0Modal");
  },
  methods: {
    beforeOpen(e) {
      if (e.params?.newEmployeeInfo) {
        this.isValidate = false;
        this.isChooseAddDetailManually = false
        this.newEmployeeStartDateMenu = false
        this.newEmployeeInfo = e.params.newEmployeeInfo;
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    closeModal() {
      this.$modal.hide("modal-newemployeestep0");
    },
    openModalAddDetailManually() {
      try {
        if (Validate.isOpenCheckValidate()) {
        this.checkValidate(false);
        if (this.isValidate) {
          this.isChooseAddDetailManually = true;
          this.checkEmail();
          
        }
      } else {
        this.closeModal();
        this.$modal.show("modal-newemployeestep1", {
          data: "data",
          newEmployeeInfo: this.newEmployeeInfo,
        });
      }
      }
      catch (err) {
        this.$logger.error(`openModalAddDetailManually: ${JSON.stringify(err)}`);
      }
      
    },
    closeModalAddDetailManually() {
      this.$modal.hide("modal-newemployeestep1");
    },
    openModalStep4() {
      if (Utils.isDisabled()) {
        if (Validate.isOpenCheckValidate()) {
          this.checkValidate(true);
          if (this.isValidate) {
            this.isChooseAddDetailManually = false;
            this.checkEmail();
          }
        } else {
          this.closeModal();
          this.$modal.show("modal-newemployeestep1", {
            data: "data",
            newEmployeeInfo: this.newEmployeeInfo,
          });
        }
      }
    },
    getDataStep0() {
      this.$store.dispatch(DATA_STEP0, this.newEmployeeInfo);
    },
    checkValidate(emailValidate) {
      // var day = window.$(".ng-Day")
      // var month = window.$(".ng-Month")
      // var year = window.$(".ng-Year")
      var startDate = window.$(".ng-srartDate");
      var firstName = window.$(".ng-first-name");
      var lastName = window.$(".ng-last-name");
      var jobTitle = window.$(".ng-job-title");
      if (emailValidate) {
         var emailAddress = window.$(".ng-email-adress");
          // Check Email Address
          if (!Validate.isEmail(this.newEmployeeInfo.email)) {
            emailAddress.addClass("is-invalid");
          } else {
            emailAddress.removeClass("is-invalid");
          }

      }
     
      //Check Day
      if (Validate.isEmpty(this.newEmployeeInfo.startDate)) {
        startDate.addClass("is-invalid");
      }
      
      // Check First Name
      if (Validate.isEmpty(this.newEmployeeInfo.firstName)) {
        firstName.addClass("is-invalid");
      } else {
        firstName.removeClass("is-invalid");
      }
      // Check Last Name
      if (Validate.isEmpty(this.newEmployeeInfo.lastName)) {
        lastName.addClass("is-invalid");
      } else {
        lastName.removeClass("is-invalid");
      }
      // Check Job Title
      if (Validate.isEmpty(this.newEmployeeInfo.job)) {
        jobTitle.addClass("is-invalid");
      } else {
        jobTitle.removeClass("is-invalid");
      }
     

      if (
        Validate.isEmpty(this.newEmployeeInfo.startDate) ||
        Validate.isEmpty(this.newEmployeeInfo.firstName) ||
        Validate.isEmpty(this.newEmployeeInfo.lastName) ||
        Validate.isEmpty(this.newEmployeeInfo.job) 
      ) {
        this.isValidate = false;
        
      } else {
        if (emailValidate === true) {
          if( !Validate.isEmail(this.newEmployeeInfo.email)) {
            this.isValidate = false;
          } else {
             this.isValidate = true;
          }
        } else {
          this.isValidate = true;
        }
      }
    },
    // Request API
    checkEmail() {
      if (Session.has("currenCompanyId")) {
        this.$root.$refs.AppCyrus.start();
        var modelRequest = {
          companyId: Session.get("currenCompanyId"),
          employeeId: 0,
          email: this.newEmployeeInfo.email,
        };
        this.$store
          .dispatch(CHECK_EMAIL, modelRequest)
          .then(() => {
            this.$root.$refs.AppCyrus.finish();
            this.getDataStep0();
            this.closeModal();
            if (this.isChooseAddDetailManually) {
              this.$modal.show("modal-newemployeestep1", {
                data: "data",
                newEmployeeInfo: this.newEmployeeInfo,
              });
            } else {
              this.$modal.show("modal-newemployeestep4", {
                isCheckStep4: true,
                inviteEmployee: true,
                newEmployeeInfo: this.newEmployeeInfo,
              });
            }
          })
          .catch(() => {
            this.$root.$refs.AppCyrus.fail();
          });
      }
    },
  },
  computed: {
    computedStartDateFormatted() {
      return Utils.formatDateToString(this.newEmployeeInfo.startDate);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.modal-body {
  max-height: calc(100vh - 0px);
  overflow-y: auto;
}
</style>
