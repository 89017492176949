<template>
  <dev>
    <!-- Add or update leave request -->
    <modal
      name="modal-createrequest"
      @before-open="beforeOpen"
      with="auto"
      height="auto"
    >
      <div
        uib-modal-window="modal-window"
        class="modal fade ng-scope ng-isolate-scope show"
        role="dialog"
        aria-labelledby="modal-title"
        aria-describedby="modal-body"
        size="xxl"
        index="0"
        animate="animate"
        ng-style="{&#39;z-index&#39;: 1050 + $topModalIndex*10, display: &#39;block&#39;}"
        tabindex="-1"
        uib-modal-animation-class="fade"
        modal-in-class="show"
        modal-animation="true"
        style="display: block; overflow: auto"
      >
        <div class="modal-dialog modal-md">
          <div class="modal-content" uib-modal-transclude="">
            <div
              class="
                modal-content
                text-center
                onboarding-modal
                modal-centered
                ng-scope">
              <div class="modal-content">
                <div
                  class="modal-header"
                  style="padding-bottom: 0px !important"
                >
                  <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style="text-align: center"
                  >
                    <span style="color: #334152" class="ng-binding"
                      >{{ styleLeave }} Leave</span
                    >
                  </h5>
                  <button
                    v-on:click="this.closeModal"
                    aria-label="Close"
                    class="box-title close"
                    data-dismiss="modal"
                    type="button"
                  ></button>
                </div>
                <div class="modal-body" style="padding-top: 10px !important">
                  <div class="text-left">
                    <div class="row">
                      <div class="col-sm-12">
                        <label for="Annual">
                          <input
                            type="radio"
                            id="Annual"
                            value="Annual"
                            v-model="styleLeave"
                            :checked="checked"
                          />
                          Annual leave
                        </label>
                        <label for="Sick" style="margin-left: 30px">
                          <input
                            type="radio"
                            id="Sick"
                            value="Sick"
                            v-model="styleLeave"
                            :checked="checked"
                          />
                          Sick leave
                        </label>
                      </div>
                    </div>
                    <div class="row" style="padding-top: 10px !important">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label for=""
                            >Employee Name
                            <span class="text-danger">*</span></label
                          >
                          <el-autocomplete
                            class="inline-input leave"
                            style="width: 100%; !important;"
                            v-model="employeeName"
                            :fetch-suggestions="querySearch"
                            placeholder="Please Input"
                            @select="handleSelect"
                            @blur="handleClickOutside"
                            v-bind:disabled="UserRole == 'Employee'"
                            select-when-unmatched="true"
                          ></el-autocomplete>
                          <div
                            class="invalid-feedback ng-selete-list"
                            ng-show="leaveRequestDetailsError.name"
                          >
                            Please select from list
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="startDate"
                                >Leave Period Start
                                    <span class="text-danger">*</span></label
                                >
                                <date-picker
                                    name="startDate"
                                    class="datetime selectedDates"
                                    v-model="startDate"
                                    @close="updateHours()"
                                    style="width: 100%"
                                    type="date"
                                    confirm="true"
                                    placeholder="Select start date"
                                    value-type="YYYY-MM-DD"
                                    format="DD/MM/YYYY"
                                ></date-picker>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="endDate"
                                >Leave Period End
                                    <span class="text-danger">*</span>
                                </label>
                                <date-picker
                                    name="endDate"
                                    class="datetime selectedDates"
                                    v-model="endDate"
                                    @close="updateHours()"
                                    style="width: 100%"
                                    type="date"
                                    confirm="true"
                                    :disabledDate="disabledEndDates"
                                    placeholder="Select end date"
                                    value-type="YYYY-MM-DD"
                                    format="DD/MM/YYYY"
                                ></date-picker>
                            </div>
                        </div>
                    </div>
                      <div class="row">
                          <div class="col-sm-6">
                              <div class="form-group">
                                  <label for="hours">Number of Hours</label>
                                  <v-text-field
                                      :disabled="this.startDate === '' || this.startDate !== this.endDate"
                                      name="hours"
                                      class="ng-tax-file-number"
                                      v-model="hours"
                                      placeholder="8"
                                      type="text"
                                      maxlength="40"
                                      outlined
                                      color="success"
                                      @input="updateHours()"
                                  ></v-text-field>
                              </div>
                          </div>
                      </div>
                    <div v-if="this.UserRole != 'Employee'" class="row">
                      <div class="col-sm-6">
                          <div class="form-group">
                          <label for="">Leave Status</label>
                          <v-select
                            :attach="true"
                            class="ng-Status-Residency"
                            v-model="status"
                            :items="listLeaveStatus"
                            required
                            outlined
                            color="success"
                          ></v-select>
                      </div></div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="">Note</label>
                          <textarea
                            v-model="note"
                            class="
                              form-control
                              ng-pristine ng-untouched ng-valid ng-empty
                            "
                            rows="5"
                            id="comment"
                            ng-model="leaveNote"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div
                      class="row fade ng-scope"
                      v-show="employeeSubmit != '' && leaveDate != ''"
                      style=""
                    >
                      <div class="col-sm-12">
                        <div
                          class="alert alert-warning"
                          role="alert"
                          style="line-height: 1.8"
                        >
                          <table
                            v-show="leaveEdit == undefined"
                            style="width: 100%"
                          >
                            <tbody>
                              <tr>
                                <td class="bold">Current leave balance</td>
                                <td class="text-right ng-binding bold">
                                  {{ currentLeaveBalance() }} hr
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <hr
                            v-show="leaveEdit == undefined"
                            class="mt-2 mb-2"
                          />
                          <table
                            v-show="leaveEdit == undefined"
                            style="width: 100%"
                          >
                            <tbody>
                              <tr v-show="projectedLeaveBalance() != 0">
                                <td style="width: 75%">
                                  Projected leave balance on
                                  {{ formatDateToString(startDate) }}
                                </td>
                                <td class="text-right ng-binding">
                                  {{ projectedLeaveBalance() }} hr
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <hr
                            v-show="
                              leaveEdit == undefined &&
                              projectedLeaveBalance() != 0
                            "
                            class="mt-2 mb-2"
                          />
                          <table style="width: 100%">
                            <tbody>
                              <tr>
                                <td class="ng-binding" style="width: 75%">
                                  Leave usage
                                </td>
                                <td class="text-right">
                                  <div class="row">
                                    <input
                                      v-model.number="totalHours"
                                      class="
                                        form-control
                                        text-right
                                        ng-totalHours
                                      "
                                      v-bind:class="
                                        calculateDateForLeave > 1
                                          ? 'disabled-address'
                                          : ''
                                      "
                                      style="width: 70%"
                                      type="number"
                                      placeholder=""
                                      required=""
                                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                      maxlength="3"
                                    />
                                    <div class="input-group-append">
                                      <div class="input-group-text">hr</div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <hr
                            v-show="leaveEdit == undefined"
                            class="mt-2 mb-2"
                          />
                          <table
                            v-show="leaveEdit == undefined"
                            style="width: 100%"
                            class="fade ng-scope text-danger"
                          >
                            <tbody>
                              <tr>
                                <td><b>Projected leave balance after</b></td>
                                <td class="text-right">
                                  <b class="ng-binding"
                                    >{{ balanceAfter() }} hr</b
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    class="btn btn-light"
                    data-dismiss="modal"
                    type="button"
                    v-on:click="this.closeModal"
                  >
                    Close
                  </button>
                  <button
                    class="btn btn-primary"
                    type="submit"
                    v-on:click="showAlert()"
                    ng-disabled="loading"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <!--------------------
    START - popup Delete Leave
    -------------------->
    <div
      id="popupDeleteLeave"
      class="ng-confirm ng-confirm-white ng-confirm-type-default hide-popup"
    >
      <div
        class="ng-confirm-bg"
        style="
          transition-duration: 0.4s;
          transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);
        "
      ></div>
      <div v-on:click="this.hilight" class="ng-confirm-scrollpane">
        <div class="ng-bs3-container container">
          <div
            class="
              ng-bs3-row
              row
              justify-content-md-center
              justify-content-sm-center
              justify-content-xs-center
              justify-content-lg-center
            "
          >
            <div
              class="
                ng-confirm-box-p
                col-md-4
                col-md-offset-4
                col-sm-6
                col-sm-offset-3
                col-xs-10
                col-xs-offset-1
              "
            >
              <div
                class="
                  ng-confirm-box
                  ng-confirm-hilight-shake
                  ng-confirm-type-animated
                "
                role="dialog"
                aria-labelledby="ng-confirm-box187226"
                tabindex="-1"
                style="
                  margin-top: 257.663px;
                  margin-bottom: 0px;
                  transition-duration: 0.4s;
                  transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);
                "
              >
                <div class="ng-confirm-closeIcon ng-confirm-el-hide">
                  <span>×</span>
                </div>
                <div class="ng-confirm-title-c">
                  <span class="ng-confirm-icon-c ng-confirm-el-hide"
                    ><i></i></span
                  ><span class="ng-confirm-title">Delete leave</span>
                </div>
                <div
                  class="ng-confirm-content-pane"
                  style="
                    height: 86.4px;
                    transition-duration: 0.4s;
                    transition-timing-function: cubic-bezier(
                      0.36,
                      0.55,
                      0.19,
                      1
                    );
                  "
                >
                  <div class="ng-confirm-content" id="ng-confirm-box187226">
                    <div class="ng-scope">
                      Please note that this leave data cannot be restored once
                      you delete it. Would you like to proceed?
                    </div>
                  </div>
                </div>
                <div class="ng-confirm-buttons">
                  <button
                    v-on:click="deleteLeave()"
                    type="button"
                    class="btn btn-primary"
                  >
                    <span class="ng-confirm-btn-text">Yes!</span>
                  </button>
                  <button
                    v-on:click="this.hidePopupDelete"
                    type="button"
                    class="btn btn-default"
                  >
                    <span class="ng-confirm-btn-text">close</span>
                  </button>
                </div>
                <div class="ng-confirm-clear"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="popupAlert"
      class="ng-confirm ng-confirm-white ng-confirm-type-default hide-popup"
    >
      <div
        class="ng-confirm-bg"
        style="
          transition-duration: 0.4s;
          transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);
        "
      ></div>
      <div v-on:click="this.hilight" class="ng-confirm-scrollpane">
        <div class="ng-bs3-container container">
          <div
            class="
              ng-bs3-row
              row
              justify-content-md-center
              justify-content-sm-center
              justify-content-xs-center
              justify-content-lg-center
            "
          >
            <div
              class="
                ng-confirm-box-p
                col-md-4
                col-md-offset-4
                col-sm-6
                col-sm-offset-3
                col-xs-10
                col-xs-offset-1
              "
            >
              <div
                class="
                  ng-confirm-box
                  ng-confirm-hilight-shake
                  ng-confirm-type-animated
                "
                role="dialog"
                aria-labelledby="ng-confirm-box187226"
                tabindex="-1"
                style="
                  margin-top: 257.663px;
                  margin-bottom: 0px;
                  transition-duration: 0.4s;
                  transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);
                "
              >
                <div class="ng-confirm-closeIcon ng-confirm-el-hide">
                  <span>×</span>
                </div>
                <div class="ng-confirm-title-c">
                  <span class="ng-confirm-icon-c ng-confirm-el-hide"
                    ><i></i></span
                  ><span class="ng-confirm-title">Alert</span>
                </div>
                <div
                  class="ng-confirm-content-pane"
                  style="
                    height: 86.4px;
                    transition-duration: 0.4s;
                    transition-timing-function: cubic-bezier(
                      0.36,
                      0.55,
                      0.19,
                      1
                    );
                  "
                >
                  <div class="ng-confirm-content" id="ng-confirm-box187226">
                    <div class="ng-scope">
                      Would you like to allow a negative leave balance of 0
                      hour(s)?
                    </div>
                  </div>
                </div>
                <div class="ng-confirm-buttons">
                  <button
                    v-on:click="addLeave()"
                    type="button"
                    class="btn btn-primary"
                  >
                    <span class="ng-confirm-btn-text">Yes!</span>
                  </button>
                  <button
                    v-on:click="hideAlert()"
                    type="button"
                    class="btn btn-default"
                  >
                    <span class="ng-confirm-btn-text">close</span>
                  </button>
                </div>
                <div class="ng-confirm-clear"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="popupAlertHolidays"
      class="ng-confirm ng-confirm-white ng-confirm-type-default hide-popup"
    >
      <div
        class="ng-confirm-bg"
        style="
          transition-duration: 0.4s;
          transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);
        "
      ></div>
      <div v-on:click="this.hilight" class="ng-confirm-scrollpane">
        <div class="ng-bs3-container container">
          <div
            class="
              ng-bs3-row
              row
              justify-content-md-center
              justify-content-sm-center
              justify-content-xs-center
              justify-content-lg-center
            "
          >
            <div
              class="
                ng-confirm-box-p
                col-md-4
                col-md-offset-4
                col-sm-6
                col-sm-offset-3
                col-xs-10
                col-xs-offset-1
              "
            >
              <div
                class="
                  ng-confirm-box
                  ng-confirm-hilight-shake
                  ng-confirm-type-animated
                "
                role="dialog"
                aria-labelledby="ng-confirm-box187226"
                tabindex="-1"
                style="
                  margin-top: 257.663px;
                  margin-bottom: 0px;
                  transition-duration: 0.4s;
                  transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);
                "
              >
                <div class="ng-confirm-closeIcon ng-confirm-el-hide">
                  <span>×</span>
                </div>
                <div class="ng-confirm-title-c">
                  <span class="ng-confirm-icon-c ng-confirm-el-hide"
                    ><i></i></span
                  ><span class="ng-confirm-title">Alert</span>
                </div>
                <div
                  class="ng-confirm-content-pane"
                  style="
                    height: 86.4px;
                    transition-duration: 0.4s;
                    transition-timing-function: cubic-bezier(
                      0.36,
                      0.55,
                      0.19,
                      1
                    );
                  "
                >
                  <div class="ng-confirm-content" id="ng-confirm-box187226">
                    <div class="ng-scope">
                      The date range you choose has weekends or public holidays?
                    </div>
                  </div>
                </div>
                <div class="ng-confirm-buttons">
                  <button
                    v-on:click="hideAlertHolidays()"
                    type="button"
                    class="btn-primary"
                  >
                    <span class="ng-confirm-btn-text">Yes!</span>
                  </button>
                  <button
                    v-on:click="hideAlertHolidays()"
                    type="button"
                    class="btn-default"
                  >
                    <span class="ng-confirm-btn-text">close</span>
                  </button>
                </div>
                <div class="ng-confirm-clear"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--------------------
    END - popup Confirm discard changes
    -------------------->
  </dev>
</template>

<script>
import * as Validate from "@/utils/Validate.js";
import * as Utils from "@/utils/Utils.js";
import * as Session from "@/utils/SessionStore";
import * as DataSelect from "@/utils/DataSelect.js";
import log4javascript from 'log4javascript';

import {
  GET_LIST_EMPLOYEE_LEAVE,
  GET_LIST_EMPLOYEE_LEAVE_EMPLOYEE_ONLY,
  ADD_LEAVE,
  UPDATE_LEAVE,
  DELETE_LEAVE,
  GET_LIST_HOLIDAYS,
} from "@/store/actionsType";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "CreateRequestModal",
  props: [],
  components: {
    DatePicker,
  },
  data() {
    return {
      /** pending, approved, rejected */
      status: "", 
      
      utils: Utils,
      
      /** annual, sick */
      styleLeave: "",

      /** object */
      leaveEdit: "",
      listEmployeesLeave: [],
      listDropEmployee: [],
      listHoidays: [],
      listAllHoidays: [],
      employeeName: "",
      leaveDate: [],
      startDate: "",
      endDate: "",
      totalHours: 0,
      hours: 0,
      note: "",
      employeeSubmit: "",
      calculateDateForLeave: 1,
      UserRole:this.$root.$refs.BaseLayout.UserRole,
      listLeaveStatus: DataSelect.getList("LeaveStatus"),

    };
  },

  created: function() {
      this.$logger = log4javascript.getLogger("CreateRequestModal");
  },

  methods: {
    
    beforeOpen(event) {
      // this component accept 2 props: leaveEdit and styleLeave
      this.leaveEdit = event.params.leaveEdit;
      
      this.styleLeave = this.leaveEdit?.type; 
      this.status = this.leaveEdit?.status; 
      
      this.getListEmployeeLeave();
      this.getListHolidays();
    },
    handleClickOutside() {
      // this.employeeName= this.employeeSubmit
      var found = false;
      for (var i = 0; i < this.listDropEmployee.length; i++) {
        if (this.employeeName != "") {
          if (this.employeeName === this.listDropEmployee[i].value) {
            found = true;
          }
        }
      }
      if (!found) {
        this.employeeName = "";
      }
    },
    closeModal() {
      this.resetDataInput();
      if (this.$root.$refs.Leave != undefined) {
        this.$root.$refs.Leave.reloadDataLeave(true);
      }
      this.$modal.hide("modal-createrequest");
    },
    resetDataInput() {
      this.employeeName = "";
      this.leaveDate = [];
      this.startDate = "";
      this.endDate = "";
      this.totalHours = 0;
      this.hours = 0;
      this.note = "";
      this.listEmployeesLeave = [];
      this.listDropEmployee = [];
      this.styleLeave = "";
      this.status = "";
    },
    hilight() {
      var elem = window.$(".ng-confirm-type-animated");
      if (elem.hasClass("ng-confirm-hilight")) {
        elem.removeClass("ng-confirm-hilight");
        elem.addClass("ng-confirm-hilight");
      } else {
        elem.addClass("ng-confirm-hilight");
        setTimeout(function () {
          elem.removeClass("ng-confirm-hilight");
        }, 500);
      }
    },
    hidePopupDelete() {
      if (this.$root.$refs.Leave != undefined) {
        this.$root.$refs.Leave.reloadDataLeave(true);
      }
      var elem = window.$("#popupDeleteLeave");
      elem.addClass("hide-popup");
    },

    showAlert() {

      if (!this.styleLeave) {
        Utils.toastError("Please select leave type");
        return;
      }

      // todo: why compare date to endDatePayRoll?
      if (this.currentLeaveBalance() == 0 &&
        this.compareTwoDates(this.employeeSubmit.endDatePayRoll, this.startDate) &&
        this.compareTwoDates(this.employeeSubmit.endDatePayRoll, this.endDate) ) 
      {
        var elem = window.$("#popupAlert");
        elem.removeClass("hide-popup");
      } else {
        this.addLeave();
      }
    },

    hideAlert() {
      var elem = window.$("#popupAlert");
      elem.addClass("hide-popup");
    },

    showAlertHolidays() {
      var elem = window.$("#popupAlertHolidays");
      elem.removeClass("hide-popup");
    },
    hideAlertHolidays() {
      var elem = window.$("#popupAlertHolidays");
      elem.addClass("hide-popup");
    },

    saveAndClose() {
      this.changeClassName();
    },
    changeClassName() {
      var employee = window.$(".ng-employee-name");
      var selete = window.$(".ng-selete-list");
      var leave = window.$(".ng-leave-period");
      if (Validate.isEmpty(this.employeeName)) {
        this.isValidate = true;
        employee.removeClass("ng-untouched");
        employee.addClass("ng-touched is-invalid");
        selete.addClass("ng-show");
      } else {
        this.isValidate = false;
        employee.removeClass("ng-touched is-invalid");
        employee.addClass("ng-untouched");
        selete.addClass("ng-hide");
      }
      if (Validate.isEmpty(this.leaveDate)) {
        leave.removeClass("ng-untouched");
        leave.addClass("ng-touched is-invalid");
      } else {
        leave.removeClass("ng-touched is-invalid");
        leave.addClass("ng-untouched");
      }
    },
    querySearch(queryString, cb) {
      var listDrop = this.listDropEmployee;
      var results = queryString
        ? listDrop.filter(this.createFilter(queryString))
        : listDrop;
      cb(results);
    },
    createFilter(queryString) {
      return (listDropEmployee) => {
        return (
          listDropEmployee.value
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {
      for (var i = 0; i < this.listEmployeesLeave.length; i++) {
        if (item.employeeId == this.listEmployeesLeave[i].employeeId) {
          this.employeeSubmit = this.listEmployeesLeave[i];
        }
      }
      this.getListHolidaysForEmployee();
      // this.startDate = this.leaveDate[0];
      // this.endDate = this.leaveDate[1];
      this.totalHours = this.leaveUsage();
    },
    updateHours() {
      // this.startDate = this.leaveDate[0];
      // this.endDate = this.leaveDate[1];
      this.totalHours = this.leaveUsage();
    },
    disabledEndDates(date){
      return new Date(date.setDate(date.getDate() + 1)).toISOString().split("T")[0] < this.startDate
    },
    /**
     * Calculates the current leave balance based on the selected leave style.
     * If the leave style is "Annual", it subtracts the total annual leave used from the total annual leave.
     * If the leave style is "Sick", it subtracts the total sick leave used from the total sick leave.
     * Returns the current leave balance as a rounded number.
     *
     * @returns {number} The current leave balance.
     */
    currentLeaveBalance() {
      var currentLeave = 0;
      if (this.styleLeave === "Annual") {
        currentLeave =
          this.employeeSubmit.totalAnnualLeave -
          this.employeeSubmit.totalAnnualLeaveUsed;
      } else if (this.styleLeave === "Sick") {
        currentLeave =
          this.employeeSubmit.totalSickLeave -
          this.employeeSubmit.totalSickLeaveUsed;
      }
      return Number(Utils.roundNumber(currentLeave));
    },
    projectedLeaveBalance() {
      var leaveBalance = 0;
      // datePayroll < startDate && datePayroll < endDate    
      if (
        this.compareTwoDates(
          this.employeeSubmit.endDatePayRoll,
          this.startDate
        ) &&
        this.compareTwoDates(this.employeeSubmit.endDatePayRoll, this.endDate)
      ) {
        var totalDay = this.calculateDate(
          this.employeeSubmit.endDatePayRoll,
          this.endDate
        );
        
        leaveBalance =
          this.currentLeaveBalance() +
          ((totalDay * 4) / 52) * this.employeeSubmit.workHoursAtTime;

      }
      else if (
        this.compareTwoDates(
          this.startDate,
          this.employeeSubmit.endDatePayRoll
        ) &&
        this.compareTwoDates(this.employeeSubmit.endDatePayRoll, this.endDate)
      ) {
        var totalDay2 = this.calculateDate(
          this.employeeSubmit.endDatePayRoll,
          this.endDate
        );
        leaveBalance =
          this.currentLeaveBalance() +
          ((totalDay2 * 4) / 52) * this.employeeSubmit.workHoursAtTime;
      }
      // datePayroll > startDate && datePayroll > endDate
      else {
        leaveBalance = 0;
      }
      return Number(Utils.roundNumberR1(leaveBalance));
    },
    
    /**
     * Calculates the leave usage based on the selected start and end dates.
     * If the start and end dates are the same, the leave usage is calculated based on the provided hours or the employee's default work hours.
     * If the start and end dates are different, the leave usage is calculated by considering holidays.
     * If there are any holidays between the start and end dates, an alert is shown.
     * @returns {number} The calculated leave usage.
     */
    leaveUsage() {
      if (this.startDate && this.endDate && this.startDate === this.endDate) {
        this.calculateDateForLeave = 1;
        return this.hours ? Number(Utils.roundNumber(this.hours)) : Number(Utils.roundNumber(this.employeeSubmit.workHoursAtTime));
      } 
      else
      {
        this.hours = 0;

        if (
          this.utils.isCheckHolidays(
            this.startDate,
            this.endDate,
            this.listHoidays
          )
        ) {
          this.showAlertHolidays();
        }
        
        this.calculateDateForLeave = this.utils.calculateDateWithHolidays(
          this.startDate,
          this.endDate,
          this.listHoidays
        );

        const leaveUsage = this.calculateDateForLeave * this.employeeSubmit.workHoursAtTime;
        return Number(Utils.roundNumber(leaveUsage));
      }
    },

    balanceAfter() {
      var balanceAfter = 0;
      // datePayroll < startDate && datePayroll < endDate
      if (
        this.compareTwoDates(
          this.employeeSubmit.endDatePayRoll,
          this.startDate
        ) &&
        this.compareTwoDates(this.employeeSubmit.endDatePayRoll, this.endDate)
      ) {
        balanceAfter = this.projectedLeaveBalance() - this.totalHours;
      }
      // datePayroll > startDate && datePayroll < endDate
      else if (
        this.compareTwoDates(
          this.startDate,
          this.employeeSubmit.endDatePayRoll
        ) &&
        this.compareTwoDates(this.employeeSubmit.endDatePayRoll, this.endDate)
      ) {
        balanceAfter = this.projectedLeaveBalance() - this.totalHours;
      }
      // datePayroll > startDate && datePayroll > endDate
      else {
        balanceAfter = this.currentLeaveBalance() - this.totalHours;
      }
      return Number(Utils.roundNumberR1(balanceAfter));
    },
    calculateDate(startDate, endDate) {
      var star = new Date(startDate);
      var end = new Date(endDate);
      var count = 0;
      var curDate = star;
      while (curDate <= end) {
        var dayOfWeek = curDate.getDay();
        if (!(dayOfWeek == 6 || dayOfWeek == 0)) {
          count++;
        }
        curDate.setDate(curDate.getDate() + 1);
      }
      return count;
    },
    formatDateToString(inputDate) {
      var m_names = new Array(
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      );
      var date = new Date(inputDate);
      var day = date.getDate();
      var month = date.getMonth();
      var year = date.getFullYear();
      var dateShow = day + "-" + m_names[month] + "-" + year;
      return dateShow;
    },
    /**
     * Compares two dates and returns true if the start date is before the end date.
     *
     * @param {string} startDate - The start date in string format.
     * @param {string} endDate - The end date in string format.
     * @returns {boolean} - True if the start date is before the end date, false otherwise.
     */
    compareTwoDates(startDate, endDate) {
      var date01 = new Date(startDate);
      var date02 = new Date(endDate);
      if (date01.valueOf() >= date02.valueOf()) {
        return false;
      }
      return true;
    },
    compareCurrentDates(date) {
      var date01 = new Date();
      var date02 = new Date(date);
      if (date01.valueOf() >= date02.valueOf()) {
        return false;
      }
      return true;
    },
    getDate(inputDate) {
      var date = new Date(inputDate);
      var day = date.getDate();
      var mount = date.getMonth() + 1;
      var year = date.getFullYear();
      if (day.toString().length == 1) {
        day = "0" + day;
      }
      if (mount.toString().length == 1) {
        mount = "0" + mount;
      }
      return year + "-" + mount + "-" + day;
    },
    checkValidate() {
      var date = window.$(".ng-totalHours");
      if (this.calculateDateForLeave === 1) {
        if (
          this.totalHours === "" ||
          this.totalHours > 24 ||
          this.totalHours <= 0
        ) {
          date.addClass("is-invalid");
          return false;
        }
      }
      date.removeClass("is-invalid");
      return true;
    },
    // Request API
    getListEmployeeLeave() {
      if (this.$root.$refs.BaseLayout.UserRole == "Employee") {
        if (Session.has("currentEmployeeId")) {
          //   var empName= Session.get("Username")
          //   var empId= Session.get("currentEmployeeId")
          const empId = Session.get("currentEmployeeId");

          this.$root.$refs.AppCyrus.start();
          this.$store
            .dispatch(
              GET_LIST_EMPLOYEE_LEAVE_EMPLOYEE_ONLY,
              empId
            )
            .then((response) => {
              this.$root.$refs.AppCyrus.finish();
              this.listEmployeesLeave = response;
              
              this.employeeName = `${this.listEmployeesLeave.firstName || ''} ${this.listEmployeesLeave.lastName || ''}`;
              this.employeeSubmit = this.listEmployeesLeave;
              this.employeeSubmit.employeeId = empId;
            })
            .catch((err) => {
              this.$logger.error(`submitForm: ${JSON.stringify(err)}`)
              this.$root.$refs.AppCyrus.fail();
            });
        }
      } else {
        if (Session.has("currenCompanyId")) {
          this.$root.$refs.AppCyrus.start();
          this.$store
            .dispatch(GET_LIST_EMPLOYEE_LEAVE, Session.get("currenCompanyId"))
            .then((response) => {
              this.$root.$refs.AppCyrus.finish();
              this.listEmployeesLeave = response;
              for (var i = 0; i < this.listEmployeesLeave.length; i++) {
                this.listDropEmployee.push({
                  employeeId: this.listEmployeesLeave[i].employeeId,
                  value:
                    this.listEmployeesLeave[i].firstName +
                    " " +
                    this.listEmployeesLeave[i].lastName,
                });
              }
              this.editLeave();
            })
            .catch(() => {
              this.$root.$refs.AppCyrus.fail();
            });
        }
      }
    },
    editLeave() {
      if (this.leaveEdit != undefined) {
        this.employeeName = this.leaveEdit.employeeName;
        this.note = this.leaveEdit.note;
        for (var j = 0; j < this.listEmployeesLeave.length; j++) {
          if (
            this.leaveEdit.employeeId == this.listEmployeesLeave[j].employeeId
          ) {
            this.employeeSubmit = this.listEmployeesLeave[j];
          }
        }
        if (this.listAllHoidays.length > 0) {
          this.getListHolidaysForEmployee();
        }
        this.leaveDate = [
          this.getDate(this.leaveEdit.startDate),
          this.getDate(this.leaveEdit.endDate),
        ];
        this.startDate = this.leaveDate[0];
        this.endDate = this.leaveDate[1];
        this.totalHours = this.leaveEdit.totalHours;

      }
    },

    async addLeave() {

      if (!this.checkValidate()) {
        return;
      }

      this.hideAlert();
      this.$root.$refs.AppCyrus.start();

      try {

        if (this.leaveEdit) 
        {
          const requestModel = {
            id: this.leaveEdit.id,
            companyId: Session.get("currenCompanyId"),
            employeeId: this.employeeSubmit.employeeId,
            employeeName: this.employeeName,
            type: this.styleLeave,
            payPeriod: this.employeeSubmit.payPeriod,
            startDate: this.startDate,
            endDate: this.endDate,
            totalHours: this.totalHours,
            note: this.note,
            status:this.status,
            workHoursAtTime: this.employeeSubmit.workHoursAtTime,
          };

          await this.$store.dispatch(UPDATE_LEAVE, requestModel);
        } 
        else
        {
          if(this.UserRole == 'Employee'){
            this.status='Pending';
          }
          const totalHours = this.computeTotalHours();

          const requestModel = {
            id: 0,
            companyId: Session.get("currenCompanyId"),
            employeeId: this.employeeSubmit.employeeId,
            employeeName: `${this.employeeSubmit.firstName} ${this.employeeSubmit.lastName}`,
            type: this.styleLeave,
            payPeriod: this.employeeSubmit.payPeriod,
            startDate: this.startDate,
            endDate: this.endDate,
            totalHours: totalHours,
            note: this.note,
            status:this.status,
            workHoursAtTime: this.employeeSubmit.workHoursAtTime,
          };

          await this.$store.dispatch(ADD_LEAVE, requestModel);
        }

        this.$root.$refs.AppCyrus.finish();
        this.closeModal();
      }
      catch(err) {
        this.$logger.error(`addLeave: ${JSON.stringify(err)}`)
        this.$root.$refs.AppCyrus.fail();
      }
    },

    deleteLeave() {
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(DELETE_LEAVE, Session.get("idLeave"))
        .then(() => {
          this.$root.$refs.AppCyrus.finish();
          this.hidePopupDelete();
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },
    getListHolidays() {
      this.$root.$refs.AppCyrus.start();
      var year = new Date().getFullYear();
      var request = {
        years: [year],
      };
      this.$store
        .dispatch(GET_LIST_HOLIDAYS, request)
        .then((response) => {
          this.$root.$refs.AppCyrus.finish();
          this.listAllHoidays = [];
          this.listAllHoidays = response.records;
          if (this.employeeSubmit != null || this.employeeSubmit != "") {
            this.getListHolidaysForEmployee();
          }
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },
    getListHolidaysForEmployee() {
      if (this.listHoidays.length == 0) {
        for (var i = 0; i < this.listAllHoidays.length; i++) {
          if (
            this.employeeSubmit.state.trim() ==
            this.listAllHoidays[i].jurisdiction.toUpperCase().trim()
          ) {
            this.listHoidays.push(this.listAllHoidays[i].date);
          }
        }
      }
    },
    /**
     * Computes the total number of hours for a timesheet request.
     * If the start date and end date are the same, it calculates the hours based on the provided hours or the employee's default work hours.
     * If the start date and end date are different, it calculates the hours based on the number of days between the dates and the employee's default work hours.
     * @returns {number} The total number of hours for the timesheet request.
     */
     computeTotalHours() {
      if (this.startDate && this.endDate && this.startDate === this.endDate) {
        this.calculateDateForLeave = 1;
        return this.hours ? Number(Utils.roundNumber(this.hours)) : Number(Utils.roundNumber(this.employeeSubmit.workHoursAtTime));
      } 
      
      this.calculateDateForLeave = this.utils.calculateDateWithHolidays(
        this.startDate,
        this.endDate,
        this.listHoidays
      );

      const leaveUsage = this.calculateDateForLeave * this.employeeSubmit.workHoursAtTime || 0;
      return Number(Utils.roundNumber(leaveUsage));
    }
  },

  
  computed: {

    
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
@import url("//unpkg.com/element-ui@2.13.1/lib/theme-chalk/index.css");
@import "/assets/css/leave.css";

.hide-popup {
  display: none;
}
</style>
