<template>
<dev>
    <modal name="modal-companySetting" @before-open="beforeOpen" with="auto" height="auto">
        <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog" aria-labelledby="modal-title" aria-describedby="modal-body" size="xxl" index="0" animate="animate" ng-style="{&#39;z-index&#39;: 1050 + $topModalIndex*10, display: &#39;block&#39;}" tabindex="-1" uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true" style="display: block; overflow: auto;">
            <div class="modal-dialog modal-xl">
                <div class="modal-content" uib-modal-transclude="">
                    <div class="modal-content text-center onboarding-modal modal-centered ng-scope">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel" style="text-align:center;">
                                    <span style="color:#334152" class="ng-binding">Company Setting</span>
                                </h5>
                                <button v-on:click="this.closeModal" aria-label="Close" class="box-title close" data-dismiss="modal" type="button"></button>
                            </div>
                            <div class="modal-body text-left">
                                <el-tabs type="border-card" v-model="activeName" style="margin-top: 10px;">
                                    <el-tab-pane label="Company Details" name="first">
                                        <div class="row mt-2">
                                            <div class="col-md-6">
                                                <label class="txt-title-input">
                                                    Business Name <span class="font-weight-normal">(Shown in payslips)</span> <span class="text-danger">*</span>
                                                </label>
                                                <input v-model="currentCompany.businessName" name="businessName" type="text" class="form-control col-sm-12 ng-businessName">
                                            </div>
                                            <div class="col-md-6">
                                                <label class="txt-title-input">Contact number <span class="text-danger">*</span></label>
                                                <div class="form-group">
                                                    <vue-tel-input v-model="currentCompany.contactNumber" class="form-control ng-contactNumber"></vue-tel-input>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-md-6">
                                                <label class="txt-title-input">ABN</label>
                                                <div class="form-group" style="height: 10px;">
                                                    {{currentCompany.companyABN}}
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="txt-title-input">Company Legal Name</label>
                                                <div class="form-group" style="height: 10px;">
                                                    {{currentCompany.companyName}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-md-6">
                                                <label class="txt-title-input">Address <span class="text-danger">*</span></label>
                                                <div class="form-group">
                                                    <vue-google-autocomplete country="au" v-bind:class="(isCheckFindMyAddress)?'disabled-address': ''" types="address" id="address" v-on:placechanged="getAddressData" v-model="address" name="address" type="text" placeholder="Address" class="form-control ng-address" required></vue-google-autocomplete>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="txt-title-input">Email <span class="text-danger">*</span></label>
                                                <div class="form-group" style="height: 10px;">
                                                    {{currentCompany.userEmail}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-check" style="margin-bottom: 10px;">
                                            <input v-model="isCheckFindMyAddress" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" value="" id="isCheckFindMyAddress" >
                                            <label class="form-check-label" for="isCheckFindMyAddress">
                                                I am unable to find my address
                                            </label>
                                        </div>
                                        <div v-show="isCheckFindMyAddress">
                                            <div class="row mt-2">
                                                <div class="col-md">
                                                    <label class="txt-title-input">Address 1 <span class="text-danger">*</span></label>
                                                    <div class="form-group">
                                                        <input types="address" id="address1" v-model="currentCompany.address1" name="address1" type="text" class="form-control ng-address1" :required= isCheckFindMyAddress>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-md-6">
                                                    <label class="txt-title-input">Address 2</label>
                                                    <div class="form-group  ">
                                                        <vue-google-autocomplete id="address2" v-model="currentCompany.address2" name="address2" type="text" placeholder="Address 2" class="form-control"></vue-google-autocomplete>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <label class="txt-title-input">Suburb <span class="text-danger">*</span></label>
                                                    <input v-model="currentCompany.suburb" name="suburb" type="text" class="form-control col-sm-12 ng-suburb" :required= isCheckFindMyAddress>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-md-6">
                                                    <label class="txt-title-input">Postcode <span class="text-danger">*</span></label>
                                                    <div class="form-group  ">
                                                        <input v-model="currentCompany.postCode" name="postCode" type="text" class="form-control ng-postcode" :required= isCheckFindMyAddress>
                                                        <!-- <vue-google-autocomplete  id="postcode"  v-on:placechanged="getPostCodeData" v-model="currentCompany.postCode" name="postCode" class="form-control" types="(regions)"></vue-google-autocomplete> -->
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <label class="txt-title-input">State <span class="text-danger">*</span></label>
                                                    <div class="form-group  ">
                                                        <select id="state" v-model="currentCompany.state" class="form-control ng-state" name="state" ng-model="companyData.a_s" style="">
                                                            <option value="ACT">ACT</option>
                                                            <option value="NSW">NSW</option>
                                                            <option value="NT">NT</option>
                                                            <option value="QLD">QLD</option>
                                                            <option value="SA">SA</option>
                                                            <option value="TAS">TAS</option>
                                                            <option value="VIC">VIC</option>
                                                            <option value="WA">WA</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </el-tab-pane>

                                    <el-tab-pane label="Payroll Details" name="second">
                                        <div class="row mt-2">
                                            <div class="col-md-6">
                                                <label class="txt-title-input">Work Hours <span class="text-danger">*</span></label>
                                                <div class="form-group">
                                                    <select v-model="workHours" @change="onWorkHoursChange($event)" name="workHours" class="form-control ng-pristine ng-valid ng-not-empty ng-touched" style="">
                                                        <option value="7.6" selected="selected">7.6 Hours per day</option>
                                                        <option value="8">8 Hours per day</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="txt-title-input">Pay Period</label>
                                                <div class="form-group  ">
                                                    <select v-model="companySettings.payPeriod" class="form-control ng-pristine ng-valid ng-not-empty ng-touched" name="state" ng-model="companyData.a_s" style="">
                                                        <option value="Weekly" selected="selected">Weekly</option>
                                                        <option value="Fortnightly">Fortnightly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-md-6">
                                                <label class="txt-title-input">Payroll Start Date<span class="text-danger">*</span></label>
                                                <div class="form-group">
                                                    <date-picker v-model="companySettings.payrollStartDate" type="date" value-type="YYYY-MM-DD" format="DD/MM/YYYY" style="width: 100%;" required></date-picker>
                                                </div>
                                            </div>
                                            <div v-show="this.workHours == 'Other'" class="col-md-6">
                                                <label class="txt-title-input">Hours per day<span class="text-danger">*</span></label>
                                                <div class="form-group">
                                                    <input v-model.number="workHoursOther" type="number" min="1" max="10" maxlength="2" class="form-control ng-other" :required="this.workHours == 'Other'">
                                                </div>
                                            </div>
                                        </div>
                                    </el-tab-pane>

                                    <el-tab-pane label="Leave Settings" name="third">
                                        <div ng-show="currentTab == 'leave'" class="" style="">
                                            <form name="payslipOptionsForm" class="ng-pristine ng-valid">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <h6 class="mb-2">Leave Settings</h6>
                                                        <div class="form-check" style="margin: 5px 0;">
                                                            <input v-model="companySettings.longServiceLeave" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" value="" id="allowLongServiceLeave" ng-model="companyData.le.lsl">
                                                            <label class="form-check-label" for="allowLongServiceLeave">
                                                                Allow long service leave (Coming soon)
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <h6 class="mb-2">Shown in payslip</h6>
                                                        <div class="form-check mt-3" style="margin: 5px 0;">
                                                            <input v-model="companySettings.annualLeaveBalance" class="form-check-input ng-pristine ng-untouched ng-valid ng-not-empty" type="checkbox" value="" id="annualLeaveBalanceInPayslip" ng-model="companyData.psl.al">
                                                            <label class="form-check-label" for="annualLeaveBalanceInPayslip">
                                                                Annual leave balance (Coming soon)
                                                            </label>
                                                        </div>
                                                        <div class="form-check ng-hide" style="margin: 5px 0;" ng-show="companyData.le &amp;&amp; companyData.le.lsl">
                                                            <input class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" value="" id="longServiceLeaveBalanceInPayslip" ng-model="companyData.psl.lsl">
                                                            <label class="form-check-label" for="longServiceLeaveBalanceInPayslip">
                                                                Long service leave balance
                                                            </label>
                                                        </div>
                                                        <div class="form-check" style="margin: 5px 0;">
                                                            <input v-model="companySettings.sickLeaveBalance" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" value="" id="sickLeaveBalanceInPayslip" ng-model="companyData.psl.sl">
                                                            <label class="form-check-label" for="sickLeaveBalanceInPayslip">
                                                                Personal/Carer's leave (Sick leave) balance (Coming soon)
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </el-tab-pane>
                                </el-tabs>
                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-light" data-dismiss="modal" type="button" v-on:click="this.closeModal"> Close</button>
                                <button @click="updateCompany()" class="btn btn-primary" style="margin-left: 15px;">Save <img class="icon-button" src="/assets/img/icon_save.png" /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
    <!--------------------
    START - popup Delete Company
    -------------------->
    <div id="popupDeleteCompany" class="ng-confirm ng-confirm-white ng-confirm-type-default hide-popup">
        <div class="ng-confirm-bg" style="transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);"></div>
        <div v-on:click="this.hilight" class="ng-confirm-scrollpane">
            <div class="ng-bs3-container container">
                <div class="ng-bs3-row row justify-content-md-center justify-content-sm-center justify-content-xs-center justify-content-lg-center">
                    <div class="ng-confirm-box-p col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">
                        <div class="ng-confirm-box ng-confirm-hilight-shake ng-confirm-type-animated" role="dialog" aria-labelledby="ng-confirm-box187226" tabindex="-1" style="margin-top: 257.663px; margin-bottom: 0px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
                            <div class="ng-confirm-closeIcon ng-confirm-el-hide"><span>×</span></div>
                            <div class="ng-confirm-title-c"><span class="ng-confirm-icon-c ng-confirm-el-hide"><i></i></span><span class="ng-confirm-title">Delete company</span></div>
                            <div class="ng-confirm-content-pane" style="height: 86.4px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
                                <div class="ng-confirm-content" id="ng-confirm-box187226">
                                    <div class="ng-scope">Please note that this company data cannot be restored once you delete it. Would you like
                                        to proceed?</div>
                                </div>
                            </div>
                            <div class="ng-confirm-buttons"><button v-on:click="deleteCompany()" type="button" class="btn btn-primary"><span class="ng-confirm-btn-text">Yes!</span></button>
                                <button v-on:click="this.hidePopupDelete" type="button" class="btn btn-default"><span class="ng-confirm-btn-text">close</span></button></div>
                            <div class="ng-confirm-clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="popupMoveComapnyToTrash" class="ng-confirm ng-confirm-white ng-confirm-type-default hide-popup">
        <div class="ng-confirm-bg" style="transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);"></div>
        <div v-on:click="this.hilight" class="ng-confirm-scrollpane">
            <div class="ng-bs3-container container">
                <div class="ng-bs3-row row justify-content-md-center justify-content-sm-center justify-content-xs-center justify-content-lg-center">
                    <div class="ng-confirm-box-p col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">
                        <div class="ng-confirm-box ng-confirm-hilight-shake ng-confirm-type-animated" role="dialog" aria-labelledby="ng-confirm-box187226" tabindex="-1" style="margin-top: 257.663px; margin-bottom: 0px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
                            <div class="ng-confirm-closeIcon ng-confirm-el-hide"><span>×</span></div>
                            <div class="ng-confirm-title-c"><span class="ng-confirm-icon-c ng-confirm-el-hide"><i></i></span><span class="ng-confirm-title">Move to trash</span></div>
                            <div class="ng-confirm-content-pane" style="height: 86.4px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
                                <div class="ng-confirm-content" id="ng-confirm-box187226">
                                    <div class="ng-scope">Would you like to move the company to trash?</div>
                                </div>
                            </div>
                            <div class="ng-confirm-buttons"><button v-on:click="moveCompanyToTrash()" type="button" class="btn btn-primary"><span class="ng-confirm-btn-text">Yes!</span></button>
                                <button v-on:click="this.hidePopupMoveToTrash" type="button" class="btn btn-default"><span class="ng-confirm-btn-text">close</span></button></div>
                            <div class="ng-confirm-clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="popupNotMoveComapnyToTrash" class="ng-confirm ng-confirm-white ng-confirm-type-default hide-popup">
        <div class="ng-confirm-bg" style="transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);"></div>
        <div v-on:click="this.hilight" class="ng-confirm-scrollpane">
            <div class="ng-bs3-container container">
                <div class="ng-bs3-row row justify-content-md-center justify-content-sm-center justify-content-xs-center justify-content-lg-center">
                    <div class="ng-confirm-box-p col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">
                        <div class="ng-confirm-box ng-confirm-hilight-shake ng-confirm-type-animated" role="dialog" aria-labelledby="ng-confirm-box187226" tabindex="-1" style="margin-top: 257.663px; margin-bottom: 0px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
                            <div class="ng-confirm-closeIcon ng-confirm-el-hide"><span>×</span></div>
                            <div class="ng-confirm-title-c"><span class="ng-confirm-icon-c ng-confirm-el-hide"><i></i></span><span class="ng-confirm-title">Not move to trash</span></div>
                            <div class="ng-confirm-content-pane" style="height: 86.4px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
                                <div class="ng-confirm-content" id="ng-confirm-box187226">
                                    <div class="ng-scope">You are using this company, so you cannot perform this action!</div>
                                </div>
                            </div>
                            <div class="ng-confirm-buttons"><button v-on:click="this.hidePopupNotMoveToTrash" type="button" class="btn btn-primary"><span class="ng-confirm-btn-text">OK</span></button></div>
                            <div class="ng-confirm-clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="popupAlert" class="ng-confirm ng-confirm-white ng-confirm-type-default hide-popup">
        <div class="ng-confirm-bg" style="transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);"></div>
        <div v-on:click="this.hilight" class="ng-confirm-scrollpane">
            <div class="ng-bs3-container container">
                <div class="ng-bs3-row row justify-content-md-center justify-content-sm-center justify-content-xs-center justify-content-lg-center">
                    <div class="ng-confirm-box-p col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">
                        <div class="ng-confirm-box ng-confirm-hilight-shake ng-confirm-type-animated" role="dialog" aria-labelledby="ng-confirm-box187226" tabindex="-1" style="margin-top: 257.663px; margin-bottom: 0px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
                            <div class="ng-confirm-closeIcon ng-confirm-el-hide"><span>×</span></div>
                            <div class="ng-confirm-title-c"><span class="ng-confirm-icon-c ng-confirm-el-hide"><i></i></span><span class="ng-confirm-title">Alert</span></div>
                            <div class="ng-confirm-content-pane" style="height: 86.4px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
                                <div class="ng-confirm-content" id="ng-confirm-box187226">
                                    <div class="ng-scope">Would you like to allow a negative leave balance of 0 hour(s)?</div>
                                </div>
                            </div>
                            <div class="ng-confirm-buttons"><button v-on:click="addLeave()" type="button" class="btn btn-primary"><span class="ng-confirm-btn-text">Yes!</span></button>
                                <button v-on:click="hideAlert()" type="button" class="btn btn-default"><span class="ng-confirm-btn-text">close</span></button></div>
                            <div class="ng-confirm-clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--------------------
    END - popup Confirm discard changes
    -------------------->
</dev>
</template>

<script>
import {
    DELETE_COMPANY,
    UPDATE_COMPANY,
    MOVE_TO_TRASH
} from "@/store/actionsType";
import DatePicker from 'vue2-datepicker';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import 'vue2-datepicker/index.css';
import * as Validate from "@/utils/Validate.js";
import * as Session from "@/utils/SessionStore"
import * as Utils from "@/utils/Utils"

export default {
    name: "CreateRequestModal",
    props: [],
    components: {
        DatePicker,
        VueGoogleAutocomplete
    },
    data() {
        return {
            currentCompany: "",
            companySettings: "",
            workHours: "",
            workHoursOther: "",
            activeName: 'first',
            isCheckFindMyAddress: false
        };
    },
    methods: {
        beforeOpen(event) {
            this.activeName = 'first'
            this.currentCompany = event.params.currentCompany;
            this.companySettings = this.currentCompany.companySettings;
            this.handleClick()
            this.address = this.currentCompany.address1 + ", " + this.currentCompany.suburb + ", " + this.currentCompany.state
        },
        closeModal() {
            this.resetDataInput()
            // if (this.$root.$refs.list != undefined) {
            //     this.$root.$refs.list.reloadData();
            // }
            this.$modal.hide('modal-companySetting');
        },
        resetDataInput() {
            this.edtEmployeeName = ""
            this.employeeName = ""
            this.totalHours = 0
            this.note = ""
            this.listEmployeesLeave = []
            this.listDropEmployee = []
        },
        hilight() {
            var elem = window.$(".ng-confirm-type-animated")
            if (elem.hasClass("ng-confirm-hilight")) {
                elem.removeClass("ng-confirm-hilight")
                elem.addClass("ng-confirm-hilight");
            } else {
                elem.addClass("ng-confirm-hilight");
                setTimeout(function () {
                    elem.removeClass("ng-confirm-hilight")
                }, 500);
            }
        },
        hidePopupDelete() {
            var elem = window.$("#popupDeleteCompany")
            elem.addClass("hide-popup")
        },
        hidePopupMoveToTrash() {
            var elem = window.$("#popupMoveComapnyToTrash")
            elem.addClass("hide-popup")
        },
        hidePopupNotMoveToTrash() {
            var elem = window.$("#popupNotMoveComapnyToTrash")
            elem.addClass("hide-popup")
        },
        getAddressData: function (addressData) {
            this.currentCompany.address1 = ""
            this.currentCompany.suburb = ""
            this.currentCompany.postCode = ""
            this.currentCompany.state = ""

            var street_number = ""
            if (addressData.street_number != undefined) {
                street_number = addressData.street_number + " "
            }
            this.currentCompany.address1 = street_number + addressData.route;
            this.currentCompany.suburb = addressData.locality;
            this.currentCompany.postCode = addressData.postal_code;
            this.currentCompany.state = addressData.administrative_area_level_1;
        },
        getPostCodeData : function(data) {            
            this.currentCompany.address1 = "";
            this.currentCompany.suburb = data.locality;
            this.currentCompany.postCode = data.postal_code;
            this.currentCompany.state = data.administrative_area_level_1;
        },
        handleClick() {

            if (this.companySettings.workHours == 7.6 || this.companySettings.workHours == 8) {
                this.workHours = this.companySettings.workHours
            } else {
                this.workHours = 'Other'
                this.workHoursOther = this.companySettings.workHours
            }
        },
        onWorkHoursChange(event) {
            this.workHours = event.target.value
            if (this.workHours == 'Other') {
                this.companySettings.workHours = Number(this.workHoursOther)
            } else {
                this.companySettings.workHours = Number(this.workHours)
            }
        },
        hideAlert() {
            var elem = window.$("#popupAlert")
            elem.addClass("hide-popup")
        },
        formatDateToString(inputDate) {
            var m_names = new Array("Jan", "Feb", "Mar",
                "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");
            var date = new Date(inputDate);
            var day = date.getDate();
            var month = date.getMonth();
            var year = date.getFullYear();
            var dateShow = day + "-" + m_names[month] + "-" + year;
            return dateShow
        },
        // Request API
        updateCompany() {
            if (this.workHours == 'Other') {
                this.companySettings.workHours = Number(this.workHoursOther)
            } else {
                this.companySettings.workHours = Number(this.workHours)
            }
            this.currentCompany.companySettings = this.companySettings
            Session.set("payPeriod", this.companySettings.payPeriod)
            Session.set("payrollStartDate", this.companySettings.payrollStartDate)
            if(this.checkValidate()){
                this.$root.$refs.AppCyrus.start()
                this.$store.dispatch(UPDATE_COMPANY, this.currentCompany)
                    .then(() => {
                        this.$root.$refs.AppCyrus.finish()
                        Utils.toastSuccess("Update company successful!");
                        this.closeModal();
                    }).catch(() => {
                        this.$root.$refs.AppCyrus.fail()
                    });
            }
        },
        checkValidate(){
            var isValidate = true;
            window.$('.is-invalid').removeClass('is-invalid')
            window.$('.el-tabs__item').css('color', '#909399')
            window.$('.el-tabs__item.is-active').css('color', '#409EFF')
            var validate = window.$('.ng-businessName')
            var tab = window.$('#tab-first')
            if (Validate.isEmpty(this.currentCompany.businessName)) {
                validate.addClass('is-invalid')
                tab.css('color', 'red')
                isValidate = false;
            }
            validate = window.$('.ng-contactNumber')
            var phone = this.currentCompany.contactNumber.replace(/\s+/g, '')
            if (Validate.isEmpty(phone)) {
                validate.addClass('is-invalid')
                tab.css('color', 'red')
                isValidate = false;
            }
            if (!Validate.isPhone(phone)) {
                validate.addClass('is-invalid')
                tab.css('color', 'red')
                isValidate = false;
            }

            if(!this.isCheckFindMyAddress){
                validate = window.$('.ng-address')
                if (Validate.isEmpty(this.address)) {
                    validate.addClass('is-invalid')
                    tab.css('color', 'red')
                    isValidate = false;
                }
            } else {
                validate = window.$('.ng-address1')
                if (Validate.isEmpty(this.currentCompany.address1)) {
                    validate.addClass('is-invalid')
                    tab.css('color', 'red')
                    isValidate = false;
                }
                validate = window.$('.ng-suburb')
                if (Validate.isEmpty(this.currentCompany.suburb)) {
                    validate.addClass('is-invalid')
                    tab.css('color', 'red')
                    isValidate = false;
                }
                validate = window.$('.ng-postcode')
                if (Validate.isEmpty(this.currentCompany.postCode)) {
                    validate.addClass('is-invalid')
                    tab.css('color', 'red')
                    isValidate = false;
                }
                validate = window.$('.ng-state')
                if (Validate.isEmpty(this.currentCompany.state)) {
                    validate.addClass('is-invalid')
                    tab.css('color', 'red')
                    isValidate = false;
                }
            }
            
            tab = window.$('#tab-second')
            validate = window.$('.mx-input')
            if (Validate.isEmpty(this.companySettings.payrollStartDate)) {
                validate.addClass('is-invalid')
                tab.css('color', 'red')
                isValidate = false;
            }
            if(this.workHours == 'Other'){
                validate = window.$('.ng-other')
                if (Validate.isEmpty(this.workHoursOther)) {
                    validate.addClass('is-invalid')
                    tab.css('color', 'red')
                    isValidate = false;
                }
            }
            return isValidate;
        },
        deleteCompany() {
            this.$root.$refs.AppCyrus.start()
            this.$store.dispatch(DELETE_COMPANY, Session.get("idCompany"))
                .then(() => {
                    this.$root.$refs.AppCyrus.finish()
                    this.hidePopupDelete()
                    if (this.$root.$refs.listCompany != undefined) {
                        this.$root.$refs.listCompany.deleteArchived(Session.get("idCompany"));
                    }
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
        },
        moveCompanyToTrash() {
            this.$root.$refs.AppCyrus.start()
            this.$store.dispatch(MOVE_TO_TRASH, Session.get("idCompany"))
                .then(() => {
                    this.$root.$refs.AppCyrus.finish()
                    this.hidePopupMoveToTrash()
                    if (this.$root.$refs.listCompany != undefined) {
                        this.$root.$refs.listCompany.changeArchived(Session.get("idCompany"));
                    }
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
        },
        
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
@import url("//unpkg.com/element-ui@2.13.1/lib/theme-chalk/index.css");
@import url('/assets/css/setting.css');

.hide-popup {
    display: none
}
</style>
