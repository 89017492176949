<template >
    <div class="sub_tab_inn">
      <h3>Invoices</h3>
      <!-- Table -->
      <div class="table_sec">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Invoice No.</th>
                <th>Issued Date</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in invoiceList" :key="item.id">
                <td>{{ item.invoiceNumber }}</td>
                <td>{{ formatDate(item.issuedDate) }}</td>
                <td>${{ item.totalAmount }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  <script>
  
  import apiManager from "@/api/ApiManager";
  import log4javascript from 'log4javascript';
  import * as Utils from "@/utils/Utils";
  
  export default {
    
    data() {
      return {
        invoiceList: [],
      };
    },
    created: function () {
      this.$logger = log4javascript.getLogger("Invoices");
      this.getInvoices();
    },
    computed: {},
    mounted() {},

    methods: {  
      formatDate(inputDate) {
        if (!inputDate) return "";
        return Utils.formatDateToString(inputDate);
      },
  
      async getInvoices() {
        this.$root.$refs.AppCyrus.start();
        try {
          this.invoiceList = await apiManager.get('/api/Subscription/invoices');
          this.$root.$refs.AppCyrus.finish();
        }
        catch(err) {
          this.$logger.error(`getInvoices: ${JSON.stringify(err)}`)
          this.$root.$refs.AppCyrus.fail();
        }
      },
  
    },
  };
  </script>


  <style scoped>
  .hide {
    display: none;
  }
  .showPayment:hover::after {
    content: "Payment Info";
    margin-top: -20px;
    margin-left: -50px;
  }
  .showPDF:hover::after {
    content: "View PDF";
    margin-top: -20px;
    margin-left: -50px;
  }
  .table-plain tbody tr,
  .table-plain tbody tr:hover,
  .table-plain tbody td {
    background-color: transparent;
    border: none;
  }
  </style>