<template>
  <div class="login-wrapper">
    <div v-if="showError" class="form-login" style="min-height: 300px">
      <h2>Cyruspay</h2>
      <div style="height: 150px">
        <h5>{{ errorMes.status }}</h5>
        <h6>{{ errorMes.message }}</h6>
      </div>
      <div class="login">
        <button
          class="submit-btn btn btn-primary"
          type="button"
          @click="gotoLogin()"
        >
          <span class="span">Go to login</span>
          <img class="imgbtnLogin" src="/assets/img/icon_login.png" />
        </button>
      </div>
    </div>
    <form
      v-else
      class="form-login"
      v-on:submit.prevent="registerAccount()"
      action=""
      method="post">
      <h2>Cyruspay</h2>
      
      <v-app>
        <v-row>
          <v-col class="format-input" cols="0" sm="12" md="12">
            <v-text-field
              v-model="edtLastName"
              label="Name"
              placeholder=""
              type="text"
              name="name"
              required
              outlined
            ></v-text-field>
          </v-col>
          <v-col class="format-input" cols="0" sm="12" md="12">
            <v-text-field
              class="input"
              autocomplete="username"
              v-model="edtEmail"
              label="Email"
              placeholder=""
              type="email"
              name="email"
              required
              outlined
            ></v-text-field>
          </v-col>
          <v-col class="format-input" cols="0" sm="12" md="12">
            <v-text-field
              autocomplete="new-password"
              v-on:keyup="passwordStrength($event)"
              v-on:keydown.space="(event) => event.preventDefault()"
              id="txtPassword"
              v-model="edtPassword"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              name="password"
              label="Password"
              placeholder=""
              class="input-group--focused"
              oninvalid="this.setCustomValidity('Password must be 8 characters and contain 1x Uppercase letter 1x lowercase letter 1x symbol 1x Number')"
              onchange="try{setCustomValidity('')}catch(e){}"
              @click:append="show2 = !show2"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              title="Password must be 8 characters and contain 1x Uppercase letter 1x lowercase letter 1x symbol 1x Number"
              required
              outlined
            ></v-text-field>
            <div class="d-flex align-items-start position-relative password_hint">
              <i class="icon-info"></i>
              <span>Password must be 8 characters and contain 1x Uppercase letter 1x lowercase letter 1x symbol 1x Number</span>
            </div>
            <div id="StrengthDisp" class="Strength"></div>
            
          </v-col>
          <v-col class="format-input" cols="0" sm="12" md="12">
            <v-select
              :attach="true"
              v-bind:disabled="isInvitationAccountant"
              v-model="role"
              :items="listRole"
              label="You are"
              outlined
            ></v-select>
          </v-col>
          <v-col class="format-input" cols="0" sm="12" md="12">
            <div class="d-flex align-center pb-2">
              <v-checkbox required v-model="checkedTC" class="TCCheckbox"></v-checkbox>
              <div class="txtAccount">I agree to
                <span class="forgot-pw os-dropdown-trigger" @click="visibleTC = true">terms and conditions</span>
              </div>
            </div>

          </v-col>
        </v-row>
      </v-app>
      <div class="login">
        <button class="submit-btn btn btn-primary" type="submit">
          <span class="span">Sign up</span>
          <img class="imgbtnLogin" src="/assets/img/icon_login.png" />
        </button>
      </div>
      <div class="line" />
      <div class="divnbtn">
        <div class="txtAccount">Already have an account?</div>
        <span class="forgot-pw os-dropdown-trigger" @click="gotoLogin()"
          >Sign in</span
        >
      </div>
    </form>

<!--  Terms and Conditions popup  -->
    <terms-and-conditions v-show="visibleTC" @tcAction="updateTCValue"></terms-and-conditions>
  </div>
</template>

<script>

// import firebase from 'firebase';
import {
  CHECK_CODE_ENCODE,
  REGISTER_ACCOUNT,
  ADD_ACCOUNTANT,
  SEND_EMAIL_COMFIRM,
  SAVE_DEVICE,
  USER_INFO,
  // LOGIN_WITH_GOOGLE
} from "@/store/actionsType";
import ApiManager from "@/api/ApiManager";
import * as Encode from "@/utils/Encodepassword";
import * as Session from "@/utils/SessionStore";
import * as DataSelect from "@/utils/DataSelect";
import TermsAndConditions from "@/views/welcome/TermsAndConditions.vue";
import log4javascript from 'log4javascript';

let strongPassword = new RegExp(
  "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{9,})"
);
let mediumPassword = new RegExp(
  "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
);

export default {
  name: "Register",
  components: {TermsAndConditions},

  data() {
    return {
      edtLastName: "",

      /** email is also username */
      edtEmail: "",

      edtPassword: "",
      role: "User",
      listRole: DataSelect.getList("listRole"),
      show2: false,
      isInvitationAccountant: false,
      code: "",
      companyId: "",
      showError: false,
      errorMes: null,
      visibleTC: false,
      checkedTC: false,
    };
  },

  mounted() {
    
  },
  created: function () {
    this.$logger = log4javascript.getLogger("Register");

    if (this.$route.query.code != undefined) {
      this.code = this.$route.query.code;
      this.companyId = this.$route.query.companyid;
      this.checkCodeEncode();
    }

    this.edtLastName = this.$route.query.name || '';
    this.edtEmail = this.$route.query.email || '';

  },

  methods: {
    updateTCValue(value) {
      if (value)
        this.checkedTC = value
      this.visibleTC = false;
    },
    passwordStrength(event) {
      this.strengthChecker(event.target.value);
    },
    strengthChecker(PasswordParameter) {
      let divStrengthDisp = document.getElementById("StrengthDisp");
      if (strongPassword.test(PasswordParameter)) {
        //divStrengthDisp.value = "Strong";
         divStrengthDisp.classList="Strength Strong";
         divStrengthDisp.textContent = "Strong";

      } else if (mediumPassword.test(PasswordParameter)) {
       
        //divStrengthDisp.style.backgroundColor = "blue";
        divStrengthDisp.classList="Good Strength";

         divStrengthDisp.textContent = "Medium";
      } else {
        //divStrengthDisp.value = "Weak";
        divStrengthDisp.classList="Weak Strength";
         divStrengthDisp.textContent = "Weak";
      }
    },
    checkCodeEncode() {
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(CHECK_CODE_ENCODE, this.code)
        .then((response) => {
          this.$root.$refs.AppCyrus.finish();
          if (!response.result) {
            this.$router.push({
              path: "/link-expired",
            });
          } else {
            this.isInvitationAccountant = true;
            this.role = "Accountant";
          }
        })
        .catch((err) => {
          this.$logger.error(`submitForm: ${JSON.stringify(err)}`)
          this.$root.$refs.AppCyrus.finish();
        });
    },
    async registerAccount() {
      this.$root.$refs.AppCyrus.start();
      var passWordEncode = await Encode.encryptPass(this.edtPassword);

      if (passWordEncode != null) {
        var dataRequest = {
          userName: this.edtEmail,
          email: this.edtEmail,
          passwordHash: passWordEncode,
          lastName: this.edtLastName,
          role: this.role,
        };
        
        this.$store
          .dispatch(REGISTER_ACCOUNT, dataRequest)
          .then((response) => {
            
            this.$root.$refs.AppCyrus.finish();
            if (!response) return;
            if (this.isInvitationAccountant) {
              var dataRequest = {
                codeEncode: this.code,
                companyId: this.companyId,
                email: this.edtEmail,
              };
              this.$root.$refs.AppCyrus.start();
              this.$store
                .dispatch(ADD_ACCOUNTANT, dataRequest)
                .then(() => {
                  this.$root.$refs.AppCyrus.finish();
                  this.successHandling(response);
                })
                .catch((error) => {
                  this.$logger.error(`registerAccount - addAccountant: ${error}`)
                  this.$root.$refs.AppCyrus.fail();
                  this.errorHandling(error);
                });
              } else {
                this.successHandling(response);
              }
          })
          .catch((err) => {
            this.$logger.error(`registerAccount: ${JSON.stringify(err)}`)
            this.$root.$refs.AppCyrus.fail();
            this.errorHandling();
          });
      } else {
        this.$root.$refs.AppCyrus.fail();
      }
    },
    successHandling(accuracy) {
      this.$store
        .dispatch(USER_INFO)
        .then((response) => {
          this.sendEmailComfirm(ApiManager.getUrlDomainUI() + "/confirmemail");
          Session.remove("currenCompanyId");
          Session.remove("currenCompanyName");
          Session.set("Username", response.lastName);

          var date = new Date();
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          date.setMilliseconds(0);
          Session.set("dateLogin", date);
          Session.set("Profile", {
            id: response.id,
            firstName: "",
            lastName: response.lastName,
            birthDate: date,
            email: this.edtEmail,
            role: this.role,
            creationTime: response.creationTime,
          });
          this.$store.dispatch(SAVE_DEVICE, response.id);
          if (this.isInvitationAccountant || accuracy.isSetupCompany) {
            this.$router.push({
              path: "/dashboard",
            });
          } else {
            // setup a new company after register
            this.$router.push({
              name: "company",
              params: {
                isNotBack: false,
                isRegister: true // indicate that this is a new register
              },
            });
          }
        })
        .catch((error) => {
          this.$logger.error(`successHandling - get user info: ${error}`)
          this.errorHandling(error);
        });
    },

    errorHandling(error) {
      
      if (error != undefined) {
        this.showError = true;
      } else {
        this.showError = false;
        error = "";
      }
      if (Session.get("hasGetHandshake")) {
        ApiManager.getHandShake()
          .then(() => {
            Session.set("hasGetHandshake", false);
            this.registerAccount();
          })
          .catch((err) => {
            this.$logger.error(`errorHandling - handshake: ${JSON.stringify(err)}`)
            if (this.$global.path != "/updateserver") {
              this.$modal.show("modal-tryhandshake", {
                data: "",
              });
            }
            this.errorMes = {
              status: "400",
              message: error.detail == undefined ? error : error.detail,
            };
          });
      } else {
        this.errorMes = {
          status: "400",
          message: error.detail == undefined ? error : error.detail,
        };
      }
    },
    sendEmailComfirm(callbackUrl) {
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(SEND_EMAIL_COMFIRM, callbackUrl)
        .then(() => {
          //response
          this.$root.$refs.AppCyrus.finish();
        })
        .catch((err) => {
          this.$logger.error(`sendEmailComfirm: ${JSON.stringify(err)}`)
          this.$root.$refs.AppCyrus.fail();
        });
    },
    gotoLogin() {
      var query = "";
      if (this.isInvitationAccountant) {
        query = "?companyid=" + this.companyId + "&code=" + this.code;
      }
      this.$router.push({
        path: "/login" + query,
      });
    },
    loginWithGoogle() {
      var date = new Date();
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 10; i++) {
        var string =
          string +
          characters.charAt(Math.floor(Math.random() * characters.length));
      }
      var state = "Security" + date.getTime() + string;
      window.location.href =
        "https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=391983556908-9pr4072jg6qj8ktres7nq2bbfrk33p90.apps.googleusercontent.com&scope=openid%20email%20profile&redirect_uri=" +
        ApiManager.getUrlDomainUI() +
        "/accuracy-gmail&state=" +
        state +
        "&hd=gmail.com";
      Session.set("state", state);

    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
.TCCheckbox {
  margin: 0 !important;
  padding: 0 !important;
}

.TCCheckbox .v-messages {
  display: none;
}
</style>

<style scoped>
@import "/assets/css/login.css";

.Weak {
  width: 20%;
  background-color: #dc3545;
  margin-top: 5px;
  height: 3px;
  color: #dc3545;
  font-weight: 500;
  font-size: 12px;
  border-radius: 25px;
}
.Good {
  width: 70%;
  background-color: #28a745;
  margin-top: 5px;
  height: 3px;
  color: #28a745;
  font-weight: 500;
  font-size: 12px;
}
.Strong {
  width: 100%;
  background-color: #d39e00;
  margin-top: 5px;
  height: 3px;
  color: #d39e00;
  font-weight: 500;
  font-size: 12px;
}
.Strength{
    margin-top: -20;
    margin-bottom: 32px;
}
.password_hint {
  bottom: 20%;
  padding-bottom: 5px;
}
.password_hint i {
  color: #09B585;
}
.password_hint span {
  padding-left: 6px;
  margin-bottom: 0px; 
  text-align: left; 
  font-size: 12px; 
  line-height: 15px;
}
</style>
